import { Injectable, Component, Inject, Renderer2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { BehaviorSubject, Observable } from "rxjs";
import { ApplicationTypeEnum } from "../core-ui.enums";
import { IApplication } from "../models/IApplication";
import {
  IThemeOptions,
  ThemeOptions,
  ThemeParam,
} from "../models/application-options";
import { DeviceService } from "./device.service";
import { TinyColor } from "@ctrl/tinycolor";

const GENESYS_COLOR = "#23395D";
const TALKDESK_COLOR = "#28025a";
const AUVIOUS_COLOR = "rgb(11, 34, 102)";
const STYLESHEET_ID = "app-auvious-custom-theme";

export enum ThemeVariableEnum {
  fontFamily = "--av-font-family",
  fontColor = "--av-font-color",
  fontColorDark = "--av-font-color-dark",
  fontColorLight = "--av-font-color-light",
  fontSize = " --av-font-size",

  backgroundColor = "--av-background-color",
  accentBackgroundColor = "--av-color-accent",
}

export enum ThemeSupportFlagEnum {
  backdropBlur = "data-theme-support-blur",
}

@Injectable()
export class ThemeService {
  private theme: IThemeOptions;
  private renderer: Renderer2;

  // BehaviorSubject sends the last emitted value, event to new subscribers who missed it
  private themeOptionsSubject: BehaviorSubject<IThemeOptions>;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.themeOptionsSubject = new BehaviorSubject(null);
  }

  public themeChanged$(): Observable<IThemeOptions> {
    return this.themeOptionsSubject;
  }

  public setRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  public setOption(key: string, value: string) {
    this.theme = {
      ...this.theme,
      [key]: value,
    };
    this.notifyThemeChange();
  }

  public setSupportFlag(flag: string, supported: boolean) {
    this.renderer.setAttribute(
      this.document.querySelector("html"),
      flag,
      String(supported)
    );
  }

  private createStylesheet(): HTMLStyleElement {
    // create style placeholder. This will get updated on view accept.
    const style = this.document.createElement("style");
    style.setAttribute(
      "nonce",
      (this.document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)
        .content
    );
    style.id = STYLESHEET_ID;
    this.document.head.appendChild(style);
    return style;
  }

  public addStylesheetRules(rules: string) {
    let style: HTMLStyleElement = this.document.getElementById(
      STYLESHEET_ID
    ) as HTMLStyleElement;
    if (!style) {
      style = this.createStylesheet();
    }

    // remove all previous stylesheets
    style.innerText = "";

    // @ts-expect-error
    !!style.styleSheet
      ? // @ts-expect-error
        (style.styleSheet.cssText = rules)
      : style.appendChild(this.document.createTextNode(rules));
  }

  public setOptions(options: IThemeOptions) {
    this.theme = ThemeOptions.create(options);
    this.notifyThemeChange();
  }

  public getOptions(): IThemeOptions {
    return this.theme;
  }

  private notifyThemeChange() {
    this.setDarkMode(this.theme.darkMode);
    this.setBackgroundMode(this.theme[ThemeParam.BACKGROUND_COLOR]);
    this.themeOptionsSubject.next(this.theme);
  }

  public setDarkMode(darkMode: boolean) {
    const themeMode = darkMode ? "dark" : "light";
    this.renderer.setAttribute(
      this.document.querySelector("html"),
      "data-av-theme",
      themeMode
    );
  }

  private setBackgroundMode(color: string) {
    if (!color) {
      return;
    }
    const isLight = new TinyColor(color).isLight();
    this.renderer.setAttribute(
      this.document.querySelector("html"),
      "data-av-background",
      isLight ? "light" : "dark"
    );
  }

  public setCSSVariables(variables: { [key: string]: string }) {
    if (!variables) {
      return;
    }
    Object.keys(variables).forEach((key) => {
      this.document.documentElement.style.setProperty(key, variables[key]);
    });
  }

  public getBackgroundColorForApplication(app: IApplication): string {
    switch (app?.getType()) {
      case ApplicationTypeEnum.GenesysCloud:
        return GENESYS_COLOR;
      case ApplicationTypeEnum.TalkdeskOpenID:
        return TALKDESK_COLOR;
      default:
        // if we are in an iframe we assume its only for Genesys (for now)
        return DeviceService.isIFrame ? GENESYS_COLOR : AUVIOUS_COLOR;
    }
  }

  public parseApplicationOptions(app: IApplication) {
    // update theme
    const options: IThemeOptions = Object.assign(
      {},
      app?.getConfig()?.publicParameters?.theme
    );
    if (!options.backgroundColor) {
      options.backgroundColor = this.getBackgroundColorForApplication(app);
    }
    this.setOptions(options);
  }
}
