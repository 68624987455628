<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'switch'">
    <div class="fx-row fx-align-center">
      <div>
        <ng-container [ngTemplateOutlet]="contentOutlet"></ng-container>
      </div>
      <label translate>
        {{ label }}
      </label>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'radio'">
    <label translate>
      {{ label }}
    </label>
    <div class="fx-column radio-options">
      <ng-container [ngTemplateOutlet]="contentOutlet"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'text'">
    <label translate
      >{{ label }}
      <ng-content select="[row-label]"></ng-content>
    </label>
    <div class="fx-row">
      <ng-container [ngTemplateOutlet]="contentOutlet"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'button'">
    <ng-container [ngTemplateOutlet]="contentOutlet"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'card'">
    <ng-container [ngTemplateOutlet]="contentOutlet"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <av-card type="warning">
      <av-card-body type="slim">av-control-row: Unsupported or missing type</av-card-body>
    </av-card>
  </ng-container>
</ng-container>

<div class="type-{{ type }}-help" *ngIf="!!help">
  <small class="text-muted">
    {{ help | translate }}
  </small>
</div>

<div *ngIf="isHelpProjected" class="type-{{ type }}-help-projected">
  <small class="text-muted">
    <ng-content select="[row-help]"></ng-content>
  </small>
</div>

<ng-template #contentOutlet>
  <ng-content></ng-content>
</ng-template>
