import {
  AuviousCommon,
  EventClientHandlers,
  Event,
  IEventClientSubscription,
  AuviousError,
} from "@auvious/common";
import { debug, debugError } from "../services/utils";
import { Observable, Subject } from "rxjs";

export class EventClient {
  subscription: IEventClientSubscription;

  constructor(private auviousCommonClient: AuviousCommon) {}

  public subscribeToTopic(topic: string) {
    this.auviousCommonClient.events.subscribe(topic);
  }

  public connect(): Observable<Event> {
    const observer: Subject<Event> = new Subject();
    const handlers: EventClientHandlers = {
      onMessageReceived: (event: Event) => {
        if (
          !event ||
          !event.payload ||
          event.payload.type === "KeepAliveReminder"
        ) {
          return;
        }
        observer.next(event);
      },
      onError: (error: AuviousError) => {
        debugError(error);
      },
      onConnectedFailed: (error: AuviousError) => {
        debugError(error);
      },
    };
    try {
      this.connectToEventServer(handlers);
    } catch (ex) {
      observer.error(ex);
      debug(ex);
    }
    return observer;
  }

  private connectToEventServer(handlers: EventClientHandlers): void {
    if (!this.auviousCommonClient.eventsEnabled) {
      throw new Error("Internal error: events not enabled.");
    } else if (!this.auviousCommonClient.eventsInitialized) {
      throw new Error("Internal error: events not initialized.");
    }

    if (!!this.subscription) {
      this.subscription.dispose();
    }

    this.subscription =
      this.auviousCommonClient.events.setEventHandlers(handlers);
  }
}
