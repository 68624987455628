import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  NotificationService,
  UserService,
  extractErrorMessage,
} from "../../../../../core-ui";
import { PureCloudService } from "../../../../services";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  GenesysInteraction,
  GenesysInvitationChannelEnum,
  IGenesysWorkflowExecuteRequest,
} from "../../../../models";
import {
  GENESYS_WORKFLOW_ADDRESS,
  GENESYS_WORKFLOW_AGENT_ID,
  GENESYS_WORKFLOW_AGENT_PARTICIPANT_ID,
  GENESYS_WORKFLOW_CONVERSATION_ID,
  GENESYS_WORKFLOW_MESSAGE,
} from "../../../../app.enums";
import { markFormFields } from "../../../../app.utils";

@Component({
  selector: "app-share-link-email-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
})
export class ShareLinkEmailAddressComponent implements OnInit {
  @Input() interaction: GenesysInteraction;
  @Input() channel: GenesysInvitationChannelEnum;
  @Input() link: string;
  @Input() workflow: string;

  @Output() dismiss: EventEmitter<void> = new EventEmitter();
  @Output() completed: EventEmitter<void> = new EventEmitter();

  address: string;
  isPosting = false;

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
    private genesys: PureCloudService,
    private user: UserService
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dismiss.emit();
  }

  async sendUsingWorkflow() {
    try {
      const request: IGenesysWorkflowExecuteRequest = {
        flowId: this.workflow,
        inputData: {
          [GENESYS_WORKFLOW_AGENT_ID]: this.user.getActiveUser().getId(),
          [GENESYS_WORKFLOW_AGENT_PARTICIPANT_ID]: this.interaction.getAgentId(),
          [GENESYS_WORKFLOW_CONVERSATION_ID]: this.interaction.getId(),
          [GENESYS_WORKFLOW_ADDRESS]: this.address,
          [GENESYS_WORKFLOW_MESSAGE]: this.interaction.getCustomerLink(
            this.link
          ),
        },
      };
      this.isPosting = true;
      await this.genesys.executeWorkflow(request);
      //
      this.notification.show("auvious", {
        body: this.translate.instant("Workflow executed"),
      });
      this.dismiss.emit();
      this.completed.emit();
    } catch (ex) {
      this.notification.error("Could not execute workflow", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isPosting = false;
    }
  }

  async send(form: UntypedFormGroup) {
    if (form.valid) {
      switch (this.channel) {
        case GenesysInvitationChannelEnum.queue:
          // nothing
          break;
        case GenesysInvitationChannelEnum.workflow:
          return this.sendUsingWorkflow();
      }
    } else {
      markFormFields(form);
    }
  }
}
