import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";

import { fadeOut, slideInToaster } from "../../core-ui.animations";
import { ConferenceStore } from "../../services";

@Component({
  selector: "app-hold",
  templateUrl: "./hold.component.html",
  styleUrls: ["./hold.component.scss"],
  animations: [slideInToaster, fadeOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldComponent implements OnInit {
  @Input() enabled: boolean;
  @Input() videoReady: boolean;

  constructor(private store: ConferenceStore) {}

  ngOnInit() {}

  get stream() {
    return this.store.mystream;
  }
}
