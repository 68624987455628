import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-page-compositions",
  templateUrl: "./compositions.component.html",
  styleUrls: ["./compositions.component.scss"],
})
export class CompositionsPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  go(interactionId: string) {
    this.router.navigate(["/interaction", interactionId, "recording"], {
      queryParamsHandling: "preserve",
    });
  }
}
