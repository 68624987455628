<div *ngIf="!isAdmin" class="container container-responsive">
  <h2 translate>Administrator role required</h2>
  <p translate>Please contact your administrator to setup the application.</p>
</div>
<div *ngIf="isAdmin" class="container container-responsive">
  <ng-container *ngIf="!loading">
    <div class="fx-row" *ngIf="isSettingsMode && !installationComplete">
      <h1 class="title" translate>Settings</h1>
    </div>

    <ng-container *ngIf="!isSettingsMode || installationComplete">
      <h1 class="title">
        <span translate>Hello</span> <span>{{ name }}</span>
      </h1>
      <h3 translate>Welcome to Auvious setup for Genesys PureCloud</h3>
    </ng-container>

    <!-- <div class="nav-action" *ngIf="isSettingsMode">
      <button avButton (click)="exit()"><span translate>Exit</span> &rarr;</button>
    </div> -->

    <div class="wizard" *ngIf="!installationComplete">
      <div class="wizard-row" *ngFor="let step of steps; let i = index">
        <div
          class="wizard-step"
          [class.wizard-step-disabled]="
            !isStorageConfigured &&
            step.state === wizardItemStateVisited &&
            step.key === 'recorder'
          "
          (click)="select(step)"
          [ngClass]="{
            'wizard-step-active': step.state === wizardItemStateActive,
            'wizard-step-visited': step.state === wizardItemStateVisited
          }"
        >
          <span *ngIf="!isSettingsMode">{{ i + 1 }}</span>
          <span *ngIf="isSettingsMode && step.state === wizardItemStateActive"
            >&#8722;</span
          >
          <span *ngIf="isSettingsMode && step.state === wizardItemStateVisited"
            >&#43;</span
          >
        </div>
        <div class="wizard-item">
          <ng-container *ngIf="step.key === 'integration'">
            <h2 (click)="select(step)" class="wizard-item-title" translate>
              Integration
            </h2>
            <ng-container *ngIf="step.state === wizardItemStateActive">
              <p translate>
                Please select the integration instance you would like to setup.
              </p>
              <select name="app" [(ngModel)]="appToInstall">
                <option *ngFor="let app of apps" [ngValue]="app">
                  {{ app.instance.name }}
                </option>
              </select>
              <div class="margin-top-10">
                <button avButton *ngIf="i > 0" (click)="backStep(i)">
                  &larr; <span translate>Back</span>
                </button>
                <button
                  avButton
                  ofType="stroked"
                  (click)="integrationOptionsReady(i)"
                >
                  <span translate>Next</span> &rarr;
                </button>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="step.key === 'finish'">
            <h2 (click)="select(step)" class="wizard-item-title" translate>
              Ready to install
            </h2>
            <ng-container *ngIf="step.state === wizardItemStateActive">
              <p translate>
                Press the following button and the magic will happen.
              </p>
              <div>
                <button
                  avButton
                  class="margin-right-20"
                  *ngIf="steps > 1"
                  (click)="backStep(i)"
                >
                  &larr; <span translate>Back</span>
                </button>
                <button
                  avButton
                  ofType="stroked"
                  id="btn-setup"
                  translate
                  [disabled]="installing || (apps.length > 1 && !appToInstall)"
                  class="btn-transparent"
                  (click)="install()"
                >
                  Install now
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="installationComplete">
      <p translate>Installation complete</p>
      <p>
        <a [routerLink]="['/welcome']" [queryParams]="queryParams" translate>
          Continue to your application</a
        >
      </p>
    </ng-container>
  </ng-container>
</div>
