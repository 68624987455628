<div class="time-point"></div>
<div class="time-line"></div>

<div class="fx-row fx-align-start fx-justify-space-between">
  <div class="time-hour text-muted">
    {{ item.timestamp | amDateFormat : "HH:mm:ss" }}
  </div>
  <div>
    <av-tooltip [message]="endpoint" position="left">
      <small>{{ user }}</small>
    </av-tooltip>
  </div>
</div>

<div>
  <b>{{ item.type }}</b>
</div>

<div class="fx-row fx-align-center fx-justify-space-between">
  <div [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'Click'">
      <div class="action-result">
        <small class="text-muted"><b translate>target</b>:</small>
        {{ item.name }}
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Input'">
      <div class="action-result">
        <small class="text-muted"><b translate>target</b>:</small> input
        {{ item.name }}
      </div>
      <div class="action-result">
        <small class="text-muted"><b translate>value</b>:</small>
        {{ item.value }}
      </div>
    </ng-container>

    <div *ngSwitchCase="'UserJoined'"></div>
  </div>
  <div>
    <button
      (click)="goto()"
      ofType="stroked"
      size="xs"
      class="time-go fx-column fx-align-center"
      avButton
    >
      <span translate>Go</span>
      <span>&rarr;</span>
    </button>
  </div>
</div>
