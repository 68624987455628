import { Component, ElementRef, Input, OnInit } from "@angular/core";
import Map from "ol/Map";
import View from "ol/View";
import { OSM } from "ol/source";
import TileLayer from "ol/layer/Tile";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Point, Circle as CircleGeom } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { Feature } from "ol";
import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from "ol/style.js";
import { IGeolocationCoordinates } from "../../../../core-ui";

@Component({
  selector: "av-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  @Input() point: IGeolocationCoordinates;

  map: Map;
  vectorSource: VectorSource;

  constructor(public host: ElementRef<HTMLDivElement>) {}

  ngOnInit(): void {
    this.createMap(this.point);
    this.addPoint(this.point);
  }

  private createMap(center: IGeolocationCoordinates) {
    const view = new View({
      center: fromLonLat([center.longitude, center.latitude]),
      zoom: 16,
      maxZoom: 22,
    });

    const tileLayer = new TileLayer({
      source: new OSM(),
    });

    this.vectorSource = new VectorSource();

    const vectorLayer = new VectorLayer({ source: this.vectorSource });

    this.map = new Map({
      target: this.host.nativeElement,
      layers: [tileLayer, vectorLayer],
      view,
    });
  }

  private addPoint(point: IGeolocationCoordinates) {
    const center = fromLonLat([point.longitude, point.latitude]);

    // geometry (show accuracy in a circle)
    const circleGeometry = new CircleGeom(center, point.accuracy ?? 20);

    // point
    const circleFeature = new Feature({
      geometry: circleGeometry, //new Point(center),
    });

    // style
    // Step 6: Style the circle
    const circleStyle = new Style({
      stroke: new Stroke({
        color: "rgba(51, 102, 255, 1)",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(51, 102, 255, 0.2)", // Blue with some transparency
      }),
    });
    // const circleStyle = new Style({
    //   image: new CircleStyle({
    //     radius: point.accuracy || 5,
    //     fill: new Fill({
    //       color: "rgba(51, 102, 255, 0.2)",
    //     }),
    //     stroke: new Stroke({
    //       color: "rgba(51, 102, 255, 1)",
    //       width: 2,
    //     }),
    //   }),
    // });

    circleFeature.setStyle(circleStyle);

    const iconFeature = new Feature({
      geometry: new Point(
        fromLonLat([this.point.longitude, this.point.latitude])
      ),
    });

    var iconStyle = new Style({
      image: new Icon({
        anchor: [24, 48],
        anchorXUnits: "pixels",
        anchorYUnits: "pixels",
        size: [48, 48],
        opacity: 1,
        src: "/modules/shared/assets/images/point.svg",
      }),
    });

    iconFeature.setStyle(iconStyle);

    this.vectorSource.addFeature(circleFeature);
    this.vectorSource.addFeature(iconFeature);
  }
}
