import { Injectable } from "@angular/core";
import { IEndpoint } from "@auvious/rtc";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";

import { BaseEvent } from "../../core-ui/models/IEvent";

import {
  AuviousRtcService,
  ConferenceService,
  UserCapabilityEnum,
  UserRoleEnum,
} from "../../core-ui";
import { NotificationService } from "../../core-ui/services/notification.service";

class QrUrlCreatedEvent extends BaseEvent {
  public static type = "QrUrlCreatedEvent";
  qrUrl: string;
  constructor(qrUrl: string) {
    super("QrUrlCreatedEvent");
    this.qrUrl = qrUrl;
  }
}

@Injectable()
export class QRService {
  private qrDataSubject: Subject<string>;

  constructor(
    private conferenceService: ConferenceService,
    private alertService: NotificationService,
    private rtcService: AuviousRtcService
  ) {
    this.qrDataSubject = new Subject<string>();

    firstValueFrom(this.rtcService.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable
          .pipe(
            filter((data) => QrUrlCreatedEvent.type === data?.payload?.type)
          )
          .subscribe((data) => this.qrDataSubject.next(data.payload.qrUrl));
      }
    );
  }

  get QRLinkAvailable$(): Observable<string> {
    return this.qrDataSubject.asObservable();
  }

  sendQRLinkEvent(url: string) {
    const event = new QrUrlCreatedEvent(url);
    this.getParticipantsThatSupportQRCodeMessage().forEach((endpoint) =>
      this.rtcService
        .sendEventMessage(endpoint.username, endpoint.endpoint, event)
        .catch(() =>
          this.alertService.warn("failed to deliver to some endpoints")
        )
    );
  }

  showQR(url: string) {
    this.qrDataSubject.next(url);
  }

  isThereACustomerWhichSupportsQRCodeLink(): boolean {
    return this.getParticipantsThatSupportQRCodeMessage().length > 0;
  }

  private getParticipantsThatSupportQRCodeMessage(): IEndpoint[] {
    const endpoints = this.conferenceService.getParticipants();

    return Object.keys(endpoints)
      .filter((key) => this.supportsQRCodeLink(endpoints[key]))
      .map((key) => endpoints[key]);
  }

  private supportsQRCodeLink(endpoint: IEndpoint): boolean {
    const roles = endpoint?.metadata?.roles || [];
    const capabilities = endpoint?.metadata?.capabilities || [];

    const hasCustomerRole = roles.includes(UserRoleEnum.customer);
    const hasQrCapability = capabilities.includes(UserCapabilityEnum.qrLink);

    return hasCustomerRole && hasQrCapability;
  }
}
