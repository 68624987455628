import { ConversationTypeEnum } from "@auvious/integrations";
import {
  IInteraction,
  IInteractionMessageOptions,
} from "../../../core-ui/models/IInteraction";
import { IInteractionStrategy } from "../../../core-ui/models/strategies";
import { EndpointTypeEnum, ProtectedTicketService } from "../../../core-ui";
import { WIDGET_COBROWSE_REQUEST_PREFIX } from "../../../app/app.enums";

export class DefaultInteractionStrategy implements IInteractionStrategy {
  private interaction: IInteraction;

  constructor(private ticketService: ProtectedTicketService) {}

  isActiveInteractionAvailable(): boolean {
    return !!this.interaction;
  }

  getActiveInteraction(): IInteraction {
    return this.interaction;
  }

  setActiveInteraction(interaction: IInteraction) {
    this.interaction = interaction;
  }

  clearActiveInteraction() {
    this.interaction = null;
  }

  discoverActiveInteraction() {
    return null;
  }

  retrieveInteractionData() {
    return null;
  }

  registerConversationEventHandlers(handlers) {}

  prepareInteractionScheduleRequest() {
    return null;
  }

  scheduleInteraction() {
    return null;
  }

  createEmailInteraction() {
    return null;
  }

  createSMSInteraction() {
    return null;
  }

  invite() {
    return null;
  }

  getInvitation(
    forConversationType: ConversationTypeEnum,
    ticket: string,
    options?: IInteractionMessageOptions
  ): string {
    let message;
    switch (forConversationType) {
      case ConversationTypeEnum.cobrowse:
        message = [
          WIDGET_COBROWSE_REQUEST_PREFIX,
          options?.requestType || "view",
          ticket,
        ].join(":");
        break;
      case ConversationTypeEnum.videoCall:
      case ConversationTypeEnum.voiceCall:
      case ConversationTypeEnum.chat:
        message = this.ticketService.getTicketWithDomain(ticket);
        break;
    }
    return message;
  }

  postMessageToInteraction() {
    return null;
  }

  getChatTranscript() {
    return null;
  }

  getInteractionDetails() {
    return null;
  }
}
