import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from "@angular/core";

@Component({
  selector: "av-pager",
  templateUrl: "./pager.component.html",
  styleUrls: ["./pager.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagerComponent implements OnInit {
  @Input()
  set totalPages(value: number) {
    this._totalPages = value;
    this.pages = [];
    for (let p = 0; p < this._totalPages; p++) {
      this.pages.push(p);
    }
  }
  get totalPages() {
    return this._totalPages;
  }

  @Input() pageIndex = 0;

  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  pages: number[] = [];
  _totalPages: number;

  constructor() {}

  ngOnInit(): void {}

  change(index: number) {
    this.pageIndex = index;
    this.pageChange.emit(index);
  }
}
