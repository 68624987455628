import { AuviousRtcService } from "./rtc.service";
import {
  IRecorderOptions,
  IRecorderResponse,
  IRecorderInfo,
  IRecorderSessionInfo,
  ITranscript,
  IRecorderCobrowseInfo,
  IRecorderCobrowseUrl,
  IStorageTestSummary,
} from "../models";
import { ApiResource } from "@auvious/common";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IHook } from "../models/interfaces";
import { debugError } from "../../app/app.utils";

@Injectable()
export class RecorderService {
  private recorderResource: ApiResource;
  private recorderEventSubject: Subject<IRecorderInfo> = new Subject();
  private preStopHooks: IHook[] = [];

  constructor(private rtcService: AuviousRtcService) {
    this.rtcService.common$.subscribe((common) => {
      this.recorderResource = common.apiResourceFactory("rtc-recorder/api");
    });
  }

  public propagateEvent(event: IRecorderInfo) {
    this.recorderEventSubject.next(event);
  }

  public get eventReceived$(): Observable<IRecorderInfo> {
    return this.recorderEventSubject.asObservable();
  }

  public registerPreStopHook(hook: IHook) {
    this.preStopHooks.push(hook);
  }

  private async runPreStopHooks() {
    try {
      return Promise.all(this.preStopHooks.map((hook) => hook.run()));
    } catch (ex) {
      debugError(ex);
    }
  }

  public start(options: IRecorderOptions): Promise<IRecorderResponse> {
    return this.recorderResource.create(options, {
      urlPostfix: "recordings/start",
    });
  }

  public async stop(
    recorderId: string,
    conversationId: string,
    instanceId: string
  ): Promise<void> {
    await this.runPreStopHooks();

    return this.recorderResource.create(null, {
      urlPostfix: `recordings/${conversationId}/${recorderId}/${instanceId}/stop`,
    });
  }

  public getInfo(
    recorderId: string,
    conversationId: string,
    instanceId: string
  ): Promise<IRecorderInfo> {
    return this.recorderResource.get({
      urlPostfix: `recordings/${conversationId}/${recorderId}/${instanceId}`,
    });
  }

  public getSessionInfo(
    recorderId: string,
    conversationId: string,
    instanceId: string
  ): Promise<IRecorderSessionInfo> {
    if (recorderId && conversationId && instanceId) {
      return this.recorderResource.get({
        urlPostfix: `recordings/${conversationId}/${recorderId}/${instanceId}/storage/info`,
      });
    }
  }

  public storeRecorderInfo(conversationId: string, recorder: IRecorderInfo) {
    // this.storage.setItem(`${KEY_RECORDER}${conversationId}`, JSON.stringify(recorder));
  }

  public clearRecorderInfo(conversationId) {
    // this.storage.removeItem(`${KEY_RECORDER}${conversationId}`);
  }

  public reset() {
    this.preStopHooks = [];
  }

  public async getStoredRecorderInfo(conversationId): Promise<IRecorderInfo> {
    try {
      const data = await this.recorderResource.get({
        urlPostfix: `recordings/${conversationId}/active`,
      });
      return data;
      // data = JSON.parse(this.storage.getItem(`${KEY_RECORDER}${conversationId}`));
      // return data;
    } catch (ex) {
      return null;
    }
  }

  public async getCobrowseRecordings(
    conversationId: string
  ): Promise<IRecorderCobrowseInfo> {
    try {
      return this.recorderResource.get({
        urlPostfix: `recordings/${conversationId}/cobrowse`,
      });
    } catch {
      return null;
    }
  }

  public async getCobrowseRecordingUrl(
    conversationId: string,
    recorderId: string,
    instanceId: string,
    sessionId: string
  ): Promise<IRecorderCobrowseUrl> {
    try {
      return this.recorderResource.get({
        urlPostfix: `recordings/${conversationId}/${recorderId}/${instanceId}/cobrowse/${sessionId}/url/attachment`,
      });
    } catch {
      return null;
    }
  }

  public testStorageProviderConnection(
    applicationId: string
  ): Promise<IStorageTestSummary> {
    return this.recorderResource.create(null, {
      urlPostfix: `storageproviders/${applicationId}/verify`,
    });
  }

  public addTranscript(script: ITranscript) {
    return this.recorderResource.create(script, {
      urlPostfix: "recordings/chat/transcript/add",
    });
  }

  public getTranscript(conversationId: string): Promise<ITranscript> {
    return this.recorderResource.get({
      urlPostfix: `recordings/${conversationId}/chat/transcript`,
    });
  }
}
