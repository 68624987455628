import { TranslateService } from "@ngx-translate/core";
import { INotificationOptions } from "../../../../core-ui/models";
import { INotificationStrategy } from "../../../../core-ui/models/strategies";

import { PureCloudService } from "../../../services/purecloud.service";

export class GenesysCloudNotificationStrategy implements INotificationStrategy {
  constructor(
    private pureCloudService: PureCloudService,
    private translate: TranslateService
  ) {}

  show(title: string, options?: INotificationOptions) {
    const nTitle = options?.body ? this.translate.instant(title) : "Auvious";
    const nMessage =
      options && options.body
        ? this.translate.instant(options.body)
        : this.translate.instant(title);
    this.pureCloudService.showToastPopup(nTitle, nMessage);
  }
}
