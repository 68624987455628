<av-card type="warning" *ngIf="isWarnOpen">
  <av-card-body type="slim">
    <div class="fx-row fx-align-center fx-justify-center">
      <b translate>System failure detected. Check your internet connection and click on 'Reconnect' when ready.</b>
      <button
        avButton
        color="primary"
        [loading]="isLoading"
        [disabled]="isLoading"
        (click)="authenticate()"
        translate
      >
        Reconnect
      </button>
    </div>
  </av-card-body>
</av-card>
