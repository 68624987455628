<div
  [attr.data-tid]="muted ? 'volume/muted' : 'volume/unmuted'"
  aria-hidden="true"
  class="volume-indicator"
>
  <ng-container *ngIf="!muted">
    <av-icon name="mic" mode="light" class="volume-indicator-icon"></av-icon>
    <div class="volume-indicator-container">
      <div class="volume-indicator-strength" #volumeRef></div>
    </div>
  </ng-container>

  <av-icon
    *ngIf="muted"
    class="volume-indicator-icon"
    name="mic-mute"
    mode="light"
  ></av-icon>
</div>
