import {
  Component,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import * as moment from "moment";

import {
  AppConfigService,
  CompositionService,
  MediaPlayerService,
  DeviceService,
} from "../../../../core-ui/services";
import {
  AgentParam,
  IComposition,
  IMediaPlayerPlayRequest,
} from "../../../../core-ui/models";
import { CompositionStateEnum } from "../../../../core-ui/core-ui.enums";
import { zoomInOut } from "../../../../core-ui/core-ui.animations";
import { NotificationService } from "../../../../core-ui/services/notification.service";
import { UserService } from "../../../../core-ui/services/user.service";
import { extractErrorMessage } from "../../../../core-ui/services/utils";

@Component({
  selector: "app-composition-row",
  templateUrl: "./composition-row.component.html",
  styleUrls: ["./composition-row.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [zoomInOut],
})
export class CompositionRowComponent implements OnInit {
  @Input() composition: IComposition;

  @Output() composeRequested: EventEmitter<IComposition> = new EventEmitter();
  @Output() cancelRequested: EventEmitter<IComposition> = new EventEmitter();
  @Output() navigate: EventEmitter<string> = new EventEmitter();
  @Output() removed: EventEmitter<IComposition> = new EventEmitter();

  downloadUrl: string;
  playUrl: string;
  lang: string;
  loadingLink = false;
  loadingRemove = false;
  isConfirmOpen = false;

  stateSubmitted = CompositionStateEnum.submitted;
  stateQueued = CompositionStateEnum.queued;
  stateProcessing = CompositionStateEnum.processing;
  stateCancelled = CompositionStateEnum.cancelled;
  stateFailed = CompositionStateEnum.failed;
  stateCompleted = CompositionStateEnum.completed;

  isSupervisor = false;
  isAdmin = false;
  canRemove = false;

  constructor(
    private compositionService: CompositionService,
    config: AppConfigService,
    private notification: NotificationService,
    private cd: ChangeDetectorRef,
    private mediaPlayerService: MediaPlayerService,
    user: UserService
  ) {
    this.lang = config.language;
    this.isSupervisor = user.isSupervisor;
    this.isAdmin = user.isAdmin;
    this.canRemove = config.agentParamEnabled(
      AgentParam.COMPOSITION_DELETE_ENABLED
    );
  }

  ngOnInit() {}

  async play() {
    if (!this.playUrl) {
      const response = await this.compositionService.getUrl(
        this.composition,
        "inline"
      );
      this.playUrl = response.url;
    }

    const request: IMediaPlayerPlayRequest = {
      sourceUrl: this.playUrl,
      sourceType: this.composition.type,
      sourceFileName: this.composition.name,
    };
    this.mediaPlayerService.play(request);
  }

  async download() {
    await this.getDownloadLink();
  }

  async getDownloadLink(): Promise<string> {
    if (!this.downloadUrl) {
      try {
        this.loadingLink = true;
        const response = await this.compositionService.getUrl(
          this.composition,
          "attachment"
        );
        this.downloadUrl = response.url;
      } catch (ex) {
        this.notification.success("Could not retrieve download link", {
          body: ex.message || ex,
        });
      } finally {
        this.loadingLink = false;
        this.cd.detectChanges();
      }
    }
    return this.downloadUrl;
  }

  retry() {
    this.composeRequested.emit(this.composition);
  }

  cancel() {
    this.cancelRequested.emit(this.composition);
  }

  go() {
    this.navigate.emit(this.composition.conversationId);
  }

  async remove() {
    try {
      this.loadingRemove = true;
      await this.compositionService.deleteComposition(
        this.composition.conversationId,
        this.composition.id
      );
      this.removed.emit(this.composition);
    } catch (ex) {
      this.notification.error("Composition", { body: extractErrorMessage(ex) });
    } finally {
      this.loadingRemove = false;
      this.isConfirmOpen = false;
      this.cd.detectChanges();
    }
  }

  get canDownload() {
    return true; /*!this.device.isIFrame;*/
  }

  get canPlay() {
    return !DeviceService.isMobile;
  }

  get isCancelVisible() {
    return (
      this.composition.state === CompositionStateEnum.processing ||
      this.composition.state === CompositionStateEnum.submitted ||
      this.composition.state === CompositionStateEnum.queued
    );
  }

  get isRemoveEnabled() {
    return this.isSupervisor && this.canRemove;
  }

  getDuration(d) {
    // return moment.duration(d).humanize();
    const duration = moment.duration(d);
    return (
      (duration.hours() > 0 ? `${duration.hours()}h ` : "") +
      `${duration.minutes()}m ${duration.seconds()}s`
    );
  }

  get icon() {
    switch (this.composition.type) {
      case "AUDIO":
        return "file-audio";
      case "VIDEO":
        return "file-video";
    }
  }

  get iconTooltip() {
    switch (this.composition.type) {
      case "AUDIO":
        return "Audio file";
      case "VIDEO":
        return "Video file";
    }
  }
}
