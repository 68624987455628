import { Component, OnInit, Input, HostBinding, Optional } from "@angular/core";
import { Subscription } from "rxjs";
import { ThemeService } from "../../../../core-ui/services";
import { CardComponent } from "../card/card.component";

@Component({
  selector: "av-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  @Input() size: "lg" | "md" | "xs";
  @Input() mode: "dark" | "light";

  isDarkMode = false;

  @HostBinding("class") get class() {
    let mode = this.mode;
    if (!mode) {
      mode = this.parent ? (this.isDarkMode ? "light" : "dark") : "light";
    }
    return `av-spinner-${this.size} av-spinner-mode-${mode}`;
  }

  subscription = new Subscription();

  constructor(
    @Optional() private parent: CardComponent,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        this.isDarkMode = theme?.darkMode;
      })
    );
  }
}
