import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";

@Component({
  selector: "av-card-header",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.scss"],
})
export class CardHeaderComponent implements OnInit, AfterViewInit {
  @Input() subtitle: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  @ViewChild("closeBtn", { read: ElementRef })
  closeBtn!: ElementRef<HTMLButtonElement>;

  isCloseVisible = false;
  constructor() {}

  ngOnInit(): void {
    this.isCloseVisible = this.close.observers.length > 0;
  }

  ngAfterViewInit(): void {
    this.closeBtn?.nativeElement?.focus();
  }

  @HostListener("document:keydown.escape", ["$event"]) keydown(
    e: KeyboardEvent
  ) {
    if (this.isCloseVisible) {
      this.cancel();
    }
  }

  cancel() {
    this.close.emit();
  }
}
