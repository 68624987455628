import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "av-card-row-responsive",
  templateUrl: "./card-row-responsive.component.html",
  styleUrls: ["./card-row-responsive.component.scss"],
})
export class CardRowResponsiveComponent implements AfterViewInit {
  @ViewChild("rowPrimary") primaryRef: ElementRef<HTMLDivElement>;

  @Input() icon: string;
  @Input() iconTooltip: string;
  @Input() flex = 3;
  @Input() bodyHidden = false;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.primaryRef.nativeElement, "flex", this.flex);
  }
}
