import {
  AppConfigService,
  IConfig,
  ConversationDestinationEnum,
  ConversationOriginEnum,
  IUserDetails,
  IPagedResponse,
  IUser,
  AppointmentChannelEnum,
  IApplicationConfig,
  IGenesysApplicationConfig,
  AgentParam,
  PublicParam,
  AgentFeatureEnum,
} from "../../../../core-ui";
import {
  auviousLicense,
  INSTALLED_TOKEN,
  PARAM_APPLICATION_ID,
  PARAM_CONVERSATION_DESTINATION,
  PARAM_CONVERSATION_ID,
} from "../../../../app/app.enums";
import { PureCloudService } from "../../../../app/services";
import { IIntegrationStrategy } from "../../../../core-ui/models/strategies";
import { GenesysIntegration } from "../../GenesysIntegration";
import { IIntegration } from "../../IIntegration";

interface IGenesysConfig extends IConfig {
  PURECLOUD_OAUTH_CLIENT_ID: string;
  PURECLOUD_OAUTH_MAP: { [environment: string]: string };
  PURECLOUD_APP_PREFIX: string;
  PURECLOUD_APP_NAME: string;
  PURECLOUD_TYPING_INTERVAL: number;
  PURECLOUD_APP_URL: string;
}

export class GenesysCloudIntegrationStrategy implements IIntegrationStrategy {
  private config: IGenesysConfig;

  constructor(
    private pureCloudService: PureCloudService,
    private appConfigService: AppConfigService,
    private applicationId: string
  ) {
    // we assume we will have all the IGenesysConfig params
    this.config = appConfigService.config as IGenesysConfig;

    // identifier to separate auvious integrations from other marketplace apps
    this.pureCloudService.setPcIntegrationTypeId(
      this.config.PURECLOUD_APP_NAME
    );
  }

  async installIntegration(
    integration: IIntegration,
    destination?: ConversationDestinationEnum
  ) {
    switch (destination) {
      case ConversationDestinationEnum.INTERACTION_WIDGET:
        return this.pureCloudService.installInteractionWidget(
          integration.getName(),
          this.getIntegrationURL(destination),
          this.config.PURECLOUD_APP_URL
        );
      default:
        return this.pureCloudService.install(
          integration as GenesysIntegration,
          this.getIntegrationURL(ConversationDestinationEnum.EMBEDDED)
        );
    }
  }

  async deleteIntegration(id: string): Promise<void> {
    return this.pureCloudService.deleteIntegration(id);
  }

  getIntegrationId() {
    return (
      this.appConfigService.getApplicationConfig() as IGenesysApplicationConfig
    ).integrationId;
  }

  isInstalled(integration: IIntegration): boolean {
    return (
      integration.getUrl()?.includes(INSTALLED_TOKEN) &&
      integration.getUrl()?.includes(this.applicationId)
    );
  }

  getIntegrations(): Promise<IIntegration[]> {
    return this.pureCloudService.getInstances();
  }

  supportsInteractionDestination(
    destination: ConversationDestinationEnum
  ): boolean {
    return [
      ConversationDestinationEnum.INTERACTION_WIDGET,
      ConversationDestinationEnum.EMBEDDED,
      ConversationDestinationEnum.MOBILE_OFFICE,
    ].includes(destination);
  }

  supportsInteractionOrigin(origin: ConversationOriginEnum): boolean {
    return [
      ConversationOriginEnum.APPOINTMENT,
      ConversationOriginEnum.CALLBACK,
      ConversationOriginEnum.CHAT,
      ConversationOriginEnum.EMBEDDED,
      ConversationOriginEnum.POPUP,
      ConversationOriginEnum.WIDGET,
    ].includes(origin);
  }

  supportsAppointmentChannel(channel: AppointmentChannelEnum): boolean {
    return [
      AppointmentChannelEnum.EMAIL,
      AppointmentChannelEnum.SMS,
      AppointmentChannelEnum.WEBHOOK,
    ].includes(channel);
  }

  supportsAgentFeature(feature: AgentFeatureEnum): boolean {
    return true;
  }

  // member roles are inherited from genesys cloud
  supportsMemberRoles(): boolean {
    return false;
  }

  getIntegrationURL(destination?: ConversationDestinationEnum) {
    switch (destination) {
      case ConversationDestinationEnum.EMBEDDED:
        return (
          `${this.config.PURECLOUD_APP_URL}/welcome` +
          "?pcEnvironment={{pcEnvironment}}" +
          "&lang={{pcLangTag}}" +
          `&${PARAM_APPLICATION_ID}=${this.applicationId}`
        );
      case ConversationDestinationEnum.INTERACTION_WIDGET:
        return (
          `${this.config.PURECLOUD_APP_URL}/welcome` +
          "?pcEnvironment={{pcEnvironment}}" +
          "&lang={{pcLangTag}}" +
          `&${PARAM_CONVERSATION_ID}={{pcConversationId}}` +
          `&${PARAM_APPLICATION_ID}=${this.applicationId}` +
          `&${PARAM_CONVERSATION_DESTINATION}=${ConversationDestinationEnum.INTERACTION_WIDGET}`
        );
    }
  }

  getIntegrationUserId(user: IUser): string {
    return user.getId();
  }

  getOauthClientId(environment?: string) {
    if (!!environment) {
      return (
        !!this.config.PURECLOUD_OAUTH_MAP &&
        this.config.PURECLOUD_OAUTH_MAP[environment]
      );
    }
    return this.config.PURECLOUD_OAUTH_CLIENT_ID;
  }

  getIntegrationIdentifier(): string {
    return this.config.PURECLOUD_APP_NAME;
  }

  public get pureCloudAppPrefix(): string {
    return this.config.PURECLOUD_APP_PREFIX;
  }

  public get pureCloudTypingInterval(): number {
    return this.config.PURECLOUD_TYPING_INTERVAL || 1000 * 60; // 1 minute;
  }

  public async getMember(id: string): Promise<IUserDetails> {
    try {
      const user = await this.pureCloudService.getUserDetails(id);
      return {
        id: user.id,
        name: user.name,
        displayName: user.displayName || user.name,
        joinDate: new Date().toISOString(),
        email: user.email,
        avatarUrl: user.avatarUrl,
      };
    } catch (ex) {
      // do nothing
    }
    return null;
  }

  public async getMembers(
    page: number,
    size: number
  ): Promise<IPagedResponse<IUserDetails>> {
    try {
      return await this.pureCloudService.getActiveUsers(page, size);
    } catch (ex) {}
    return null;
  }

  hasLicense(key: auviousLicense): boolean {
    return true;
  }

  getExportableConfig(config: IGenesysApplicationConfig): IApplicationConfig {
    try {
      const deepCopy = JSON.stringify(config);
      const conf: IGenesysApplicationConfig = JSON.parse(deepCopy);

      // remove params that are application specific
      delete conf.pcEnvironment;
      delete conf.integrationId;
      delete conf.organizationId;
      delete conf.agentParameters[AgentParam.CALLBACK_DEFAULT_QUEUE];
      delete conf.publicParameters[PublicParam.GENESYS_WEB_MESSAGING_CONFIG];
      return conf;
    } catch (ex) {
      return config;
    }
  }
}
