<ng-content></ng-content>

<div
  class="cobrowse-frame"
  #cobrowseFrame
  [class.cobrowse-frame-open]="isCobrowseFrameOpen"
  [ngStyle]="frameSize?.container"
>
  <!-- assets/empty.html -->
  <!-- <iframe
    #frame
    src="cobrowser/public/remote/1"
    data-tid="cobrowse/frame"
    id="cobrowse-frame"
    [class.sketch-frame-active]="sketchActive"
    [ngStyle]="frameSize?.frame"
    sandbox="allow-same-origin"
  ></iframe> -->

  <iframe
    #frame
    src="cobrowser/public/remote/1"
    data-tid="cobrowse/frame"
    id="cobrowse-frame"
    [class.sketch-frame-active]="sketchActive"
    [ngStyle]="frameSize?.frame"
  ></iframe>

  <ng-content select="[cobrowse-pointers]"></ng-content>
</div>
