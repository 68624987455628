import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import {
  EndpointTypeEnum,
  TerminateReasonEnum,
  ICallQualityOptions,
} from "../core-ui.enums";
import { Action } from "../models";
import { debugError } from "./utils";
import { IEndpoint } from "@auvious/rtc";
import { CobrowseRequestType } from "@auvious/integrations";

// eslint-disable-next-line no-shadow
export enum windowActionType {
  READY = "READY",
  LEAVE_CALL = "LEAVE_CALL",
  CALL_STARTED = "CALL_STARTED",
  CALL_ENDED = "CALL_ENDED",
  CALL_ON_HOLD = "CALL_ON_HOLD",
  CALL_QUALITY_OPTIONS = "CALL_QUALITY_OPTIONS",
  FEEDBACK_REQUESTED = "FEEDBACK_REQUESTED",
  AGENT_MUTED = "AGENT_MUTED",
  AGENT_VOLUME_CHANGE = "AGENT_VOLUME_CHANGE",
  DEVICE_CAPTURE = "DEVICE_CAPTURE",
  DEVICE_CAPTURE_ERROR = "DEVICE_CAPTURE_ERROR",
  DEVICE_CAPTURE_SUCCESS = "DEVICE_CAPTURE_SUCCESS",
  DEVICE_CAPTURE_STOP = "DEVICE_CAPTURE_STOP",
  DEVICE_CAPTURE_STOP_SUCCESS = "DEVICE_CAPTURE_STOP_SUCCESS",
  MUTE_CHANGE_REQUESTED = "MUTE_CHANGE_REQUESTED",
  MUTE_CHANGED = "MUTE_CHANGED",
  SCREEN_SHARE_AVAILABILITY_CHANGED = "SCREEN_SHARE_AVAILABILITY_CHANGED",
  COBROWSE_VIEW_REQUEST = "COBROWSE_VIEW_REQUEST",
  COBROWSE_TERMINATE_REQUEST = "COBROWSE_TERMINATE_REQUEST",
  COBROWSE_STARTED = "COBROWSE_STARTED",
  COBROWSE_TERMINATED = "COBROWSE_TERMINATED",
  CONSENT_REQUEST = "CONSENT_REQUEST",
  CONSENT_RESPONSE = "CONSENT_RESPONSE",
  THEME_READY = "THEME_READY",
  TRANSFER = "TRANSFER",
  CUSTOMER_METADATA_READY = "CUSTOMER_METADATA_READY",
  RECORDER_STARTED = "RECORDER_STARTED",
  RECORDER_ENDED = "RECORDER_ENDED",
  TEST_DEVICE_PERMISSIONS_SUCCESS = "TEST_DEVICE_PERMISSIONS_SUCCESS",
  TEST_DEVICE_PERMISSIONS_ERROR = "TEST_DEVICE_PERMISSIONS_ERROR",
  CONVERSATION_PANEL_TOGGLE = "CONVERSATION_PANEL_TOGGLE",
}

export class CallEndedAction implements Action {
  type = windowActionType.CALL_ENDED;
  constructor(
    public payload?: { reason?: TerminateReasonEnum; error?: Error }
  ) {}
}
export class CallStartedAction implements Action {
  type = windowActionType.CALL_STARTED;
  constructor(
    public payload: { audio: boolean; video: boolean; displayCapture: boolean }
  ) {}
}
export class CallHoldChangedAction implements Action {
  type = windowActionType.CALL_ON_HOLD;
  constructor(public payload: boolean) {}
}
export class FeedbackRequestedAction implements Action {
  type = windowActionType.FEEDBACK_REQUESTED;
}
export class AgentMutedAction implements Action {
  type = windowActionType.AGENT_MUTED;
  constructor(public payload: boolean) {}
}
export class AgentVolumeChangeAction implements Action {
  type = windowActionType.AGENT_VOLUME_CHANGE;
  constructor(public payload: number) {}
}
export class DeviceCaptureErrorAction implements Action {
  type = windowActionType.DEVICE_CAPTURE_ERROR;
  constructor(public payload: Error) {}
}
export class DeviceCaptureSuccessAction implements Action {
  type = windowActionType.DEVICE_CAPTURE_SUCCESS;
}
export class DeviceCaptureStopSuccessAction implements Action {
  type = windowActionType.DEVICE_CAPTURE_STOP_SUCCESS;
}
export class MuteChanged implements Action {
  type = windowActionType.MUTE_CHANGED;
  constructor(public payload: { kind: "audio" | "video"; enabled: boolean }) {}
}
export class ScreenShareAvailabilityChangedAction implements Action {
  type = windowActionType.SCREEN_SHARE_AVAILABILITY_CHANGED;
  constructor(public payload: boolean) {}
}
export class CobrowseViewRequestAction implements Action {
  type = windowActionType.COBROWSE_VIEW_REQUEST;
  constructor(
    public payload: {
      ticket: string;
      originator: IEndpoint;
      requestType: CobrowseRequestType;
    }
  ) {}
}
export class ConsentRequestAction implements Action {
  type = windowActionType.CONSENT_REQUEST;
}
export class ConsentResponseAction implements Action {
  type = windowActionType.CONSENT_RESPONSE;
  constructor(public payload: boolean) {}
}
export class TransferAction implements Action {
  type = windowActionType.TRANSFER;
}
export class ReadyAction implements Action {
  type = windowActionType.READY;
}
export class RecorderStartedAction implements Action {
  type = windowActionType.RECORDER_STARTED;
}
export class RecorderEndedAction implements Action {
  type = windowActionType.RECORDER_ENDED;
}
export class TestDevicePermissionsSuccess implements Action {
  type = windowActionType.TEST_DEVICE_PERMISSIONS_SUCCESS;
}
export class TestDevicePermissionsError implements Action {
  type = windowActionType.TEST_DEVICE_PERMISSIONS_ERROR;
  constructor(public payload: Error) {}
}
export class ToggleConversationPanelAction implements Action {
  type = windowActionType.CONVERSATION_PANEL_TOGGLE;
  constructor(public payload: boolean) {}
}

export class SetCallQualityOptionsAction implements Action {
  type = windowActionType.CALL_QUALITY_OPTIONS;
  constructor(public payload: ICallQualityOptions) {}
}

export class CobrowseStartedAction implements Action {
  type = windowActionType.COBROWSE_STARTED;
  constructor() {}
}

export class CobrowseTerminateRequestAction implements Action {
  type = windowActionType.COBROWSE_TERMINATE_REQUEST;
  constructor() {}
}

@Injectable()
export class WindowEventService {
  private windowMessageSubject = new Subject<Action>();

  // * @param topic the parent's url (window.origin of window.parent)
  private topic: string;

  constructor() {}

  /**
   * Subscribes to window 'message' topic and returns the events that only apply to this app.
   * The same channel is used by pureCloud so we need to discard those messages.
   */
  public init(topic: string): Observable<Action> {
    if (!this.topic) {
      window.addEventListener("message", this.onMessage);
      this.topic = topic;
    }

    return this.windowMessageSubject;
  }

  /**
   * sends a message to parent iFrame
   *
   * @param action class that implements Action
   */
  public sendMessage(action: Action) {
    try {
      if (!this.topic) {
        return;
      }

      const target = window.opener || window.parent;

      if (target) {
        target.postMessage(action, this.topic);
      }
    } catch (ex) {
      // most probably action.payload is not serialisable
      debugError(ex);
    }
  }

  private onMessage = (event) => {
    // filter only our events
    if (event.data && event.data.type in windowActionType) {
      this.windowMessageSubject.next(event.data);
    }
  };

  public destroy() {
    this.topic = undefined;
    window.removeEventListener("message", this.onMessage);
  }
}
