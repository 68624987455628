import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from "@angular/core";
import {
  ConversationFactory,
  IMessage,
  IQuickReply,
  ISessionResponseStructuredMessageBodyContentButtonResponse,
} from "@auvious/integrations";
import {
  NotificationService,
  extractErrorMessage,
} from "../../services";
import { ConversationComponent } from "../conversation/conversation.component";
import moment from "moment";

const KEY_METADATA_REPLY_TO_MESSAGE_ID =
  "auvious.origin.widget.message.reply.id";
@Component({
  selector: "app-conversation-item",
  templateUrl: "./conversation-item.component.html",
  styleUrls: ["./conversation-item.component.scss"],
})
export class ConversationItemComponent implements OnInit {
  @Input() message: IMessage;

  @HostBinding("class") get class() {
    return {
      local: this.message.isLocal,
      pending: this.message.status === "sent",
    };
  }

  typeText = "text";
  typeBot = "bot";
  typeFile = "file";

  timestamp: string;
  isLink: boolean;

  constructor(
    private notification: NotificationService,
    private conversationRef: ConversationComponent
  ) {}

  ngOnInit(): void {
    if (this.message.timestamp) {
      this.timestamp = this.dateToHTML(this.message.timestamp);
    }
    if (this.message.text) {
      const regex =
        /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/g;
      this.isLink = regex.test(this.message.text);
    }
  }

  async downloadFile() {
    try {
      await ConversationFactory.instance.downloadFile(this.message.file);
    } catch (ex) {
      this.notification.error("File Download failed", {
        body: "Please try again",
      });
    }
  }

  dateToHTML(timestamp: Date) {
    const date = moment(timestamp);
    if (date.isBefore(new Date(), "day")) {
      return date.format("dd Do, HH:mm");
    }
    return date.format("HH:mm");
  }

  quickReply(reply: IQuickReply) {
    const content: ISessionResponseStructuredMessageBodyContentButtonResponse[] =
      [
        {
          contentType: "ButtonResponse",
          buttonResponse: {
            payload: reply.payload,
            text: reply.text,
            type: "QuickReply",
          },
        },
      ];
    const metadata = {
      content,
      [KEY_METADATA_REPLY_TO_MESSAGE_ID]: this.message.id,
    };

    try {
      ConversationFactory.instance.sendMessage(reply.text, "content", metadata);
    } catch (ex) {
      this.notification.error("Quick reply failed", {
        body: extractErrorMessage(ex),
      });
    }
  }

  get hasReplied(): boolean {
    return this.conversationRef.messages.some(
      (m) => m.metadata?.[KEY_METADATA_REPLY_TO_MESSAGE_ID] === this.message.id
    );
  }
}
