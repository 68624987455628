import {
  ErrorHandler,
  Injector,
  ModuleWithProviders,
  NgModule,
  NO_ERRORS_SCHEMA,
  Provider,
} from "@angular/core";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";

import { SketchModule } from "./components/sketch/sketch.module";
import { SharedModule } from "../modules/shared/shared.module";

import { AssetService } from "@auvious/asset";
import { ClientService } from "@auvious/client";
import { FileTransferService as RTCFileTransferService } from "@auvious/filetransfer";
import { InteractionService } from "@auvious/interaction";
import { RatingService as AVRatingService } from "@auvious/rating";
import { SnapshotService as RTCSnapshotService } from "@auvious/snapshot";

import {
  ActivityIndicatorComponent,
  BackgroundEffectComponent,
  BackgroundEffectsComponent,
  BitratePickerComponent,
  CaptionsControlComponent,
  CobrowseEventItemComponent,
  CobrowseFrameComponent,
  CobrowseSessionComponent,
  CompositionExportsComponent,
  CompositionManagerComponent,
  ConversationComponent,
  ConversationItemComponent,
  DisplayCaptureComponent,
  DeviceSettingsComponent,
  DeviceSetupComponent,
  FreezeFrameComponent,
  HoldComponent,
  MagnifyGlassComponent,
  MediaPlayerComponent,
  NetworkIndicatorComponent,
  NotificationComponent,
  NotificationManagerComponent,
  PointAreaComponent,
  PointComponent,
  PointControlsComponent,
  PointerComponent,
  RatingComponent,
  RecorderComponent,
  RoomComponent,
  SnapshotSessionComponent,
  StreamControlsComponent,
  StreamViewComponent,
  ThemeBaseComponent,
  TileComponent,
  TileControlsComponent,
  TileInfoComponent,
  TimerComponent,
  VolumeIndicatorComponent,
  WaitingComponent,
  ConnectionStatusComponent,
} from "./components";

import { ConversationInputComponent } from "./components/conversation-input/conversation-input.component";
import { StreamPlayButtonComponent } from "./components/stream-play-button/stream-play-button.component";
import { AppInjector } from "./models/AppInjector";
import {
  MediaRulesService,
  PointerService,
  WindowService,
  KeyboardService,
} from "./services";
import { ActivityIndicatorService } from "./services/activity-indicator.service";
import { AnalyticsService } from "./services/analytics.service";
import { AppConfigService } from "./services/app.config.service";
import { ApplicationService } from "./services/application.service";
import { AppointmentService } from "./services/appointment.service";
import { ASRService } from "./services/asr.service";
import { AuditService } from "./services/audit.service";
import { AuthProviderService } from "./services/auth.provider.service";
import { AuthenticationService } from "./services/authentication.service";
import { CobrowseService } from "./services/cobrowse.service";
import { CompositionService } from "./services/composition.service";
import { ConferenceService } from "./services/conference.service";
import { DeviceService } from "./services/device.service";
import {
  GenericErrorHandler,
  provideErrorHandler,
  SentryErrorHandler,
} from "./services/error-handlers.service";
import { EventService } from "./services/event.service";
import { FileTransferService } from "./services/file-transfer.service";
import { LocalMediaService } from "./services/local.media.service";
import { MediaEffectsService } from "./services/media.effects.service";
import { MediaPlayerService } from "./services/media.player.service";
import { MetricsService } from "./services/metrics.service";
import { NotificationService } from "./services/notification.service";
import { RatingService } from "./services/rating.service";
import { RecorderService } from "./services/recorder.service";
import { AuviousRtcService } from "./services/rtc.service";
import { SketchService } from "./services/sketch.service";
import { SnapshotService } from "./services/snapshot.service";
import { StorageAssetService } from "./services/storage.asset.service";
import { ConferenceStore } from "./services/store/conference.store";
import { TagManagerService } from "./services/tag-manager.service";
import { ThemeService } from "./services/theme.service";
import {
  ProtectedTicketService,
  PublicTicketService,
} from "./services/ticket.service";
import { UserService } from "./services/user.service";
import { VoiceDetectionService } from "./services/voice-detection.service";
import { WindowEventService } from "./services/window.event.service";
import { BandwidthAdaptationService } from "./services/bandwidth.adaptation.service";
import { SearchService } from "./services/search.service";
import { WebhookService } from "./services/webhook.service";

export interface CoreUiModuleConfig {
  errorHandler?: Provider;
}

@NgModule({
  declarations: [
    StreamViewComponent,
    ActivityIndicatorComponent,
    DeviceSetupComponent,
    RoomComponent,
    TimerComponent,
    PointComponent,
    HoldComponent,
    VolumeIndicatorComponent,
    RatingComponent,
    NetworkIndicatorComponent,
    MediaPlayerComponent,
    WaitingComponent,
    ThemeBaseComponent,
    CompositionManagerComponent,
    StreamPlayButtonComponent,
    PointerComponent,
    StreamControlsComponent,
    CobrowseSessionComponent,
    TileComponent,
    PointAreaComponent,
    TileControlsComponent,
    TileInfoComponent,
    RecorderComponent,
    DisplayCaptureComponent,
    FreezeFrameComponent,
    DeviceSettingsComponent,
    BackgroundEffectsComponent,
    BackgroundEffectComponent,
    SnapshotSessionComponent,
    NotificationManagerComponent,
    NotificationComponent,
    BitratePickerComponent,
    CompositionExportsComponent,
    PointControlsComponent,
    CobrowseFrameComponent,
    CobrowseEventItemComponent,
    CaptionsControlComponent,
    MagnifyGlassComponent,
    ConversationComponent,
    ConversationItemComponent,
    ConversationInputComponent,
    ConnectionStatusComponent,
  ],
  imports: [
    SharedModule,
    SketchModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  providers: [
    DeviceService,
    AuviousRtcService,
    ActivityIndicatorService,
    ConferenceService,
    AppConfigService,
    SentryErrorHandler,
    GenericErrorHandler,
    PointerService,
    TagManagerService,
    WindowEventService,
    WindowService,
    KeyboardService,
    RecorderService,
    RatingService,
    CompositionService,
    ThemeService,
    PublicTicketService,
    ProtectedTicketService,
    UserService,
    ApplicationService,
    AuthenticationService,
    NotificationService,
    EventService,
    CobrowseService,
    VoiceDetectionService,
    LocalMediaService,
    MediaEffectsService,
    SnapshotService,
    FileTransferService,
    StorageAssetService,
    SketchService,
    MediaPlayerService,
    AuthProviderService,
    MediaRulesService,
    AnalyticsService,
    MetricsService,
    AuditService,
    ASRService,
    AppointmentService,
    ConferenceStore,
    BandwidthAdaptationService,
    SearchService,
    WebhookService,
    {
      provide: RTCSnapshotService,
      useFactory: (rtcService: AuviousRtcService) =>
        new RTCSnapshotService(rtcService.common$, rtcService.rtc$, true),
      deps: [AuviousRtcService],
    },
    {
      provide: AssetService,
      useFactory: (rtcService: AuviousRtcService) =>
        new AssetService(rtcService.common$),
      deps: [AuviousRtcService],
    },
    {
      provide: RTCFileTransferService,
      useFactory: (rtcService: AuviousRtcService) =>
        new RTCFileTransferService(rtcService.common$, rtcService.rtc$),
      deps: [AuviousRtcService],
    },
    {
      provide: ClientService,
      useFactory: (rtcService: AuviousRtcService) =>
        new ClientService(rtcService.common$),
      deps: [AuviousRtcService],
    },
    {
      provide: InteractionService,
      useFactory: (rtcService: AuviousRtcService) =>
        new InteractionService(rtcService.common$ as any),
      deps: [AuviousRtcService],
    },
    {
      provide: AVRatingService,
      useFactory: (rtcService: AuviousRtcService) =>
        new AVRatingService(rtcService.common$, rtcService.rtc$),
      deps: [AuviousRtcService],
    },
  ],
  exports: [
    SharedModule,
    RoomComponent,
    ActivityIndicatorComponent,
    CompositionManagerComponent,
    WaitingComponent,
    ThemeBaseComponent,
    PointerComponent,
    CobrowseSessionComponent,
    SnapshotSessionComponent,
    NotificationManagerComponent,
    CompositionExportsComponent,
    MediaPlayerComponent,
    MagnifyGlassComponent,
    ConversationComponent,
    TimerComponent,
    ConnectionStatusComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CoreUiModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }

  static forRoot(
    config?: CoreUiModuleConfig
  ): ModuleWithProviders<CoreUiModule> {
    return {
      ngModule: CoreUiModule,
      providers:
        !!config && !!config.errorHandler
          ? [config.errorHandler]
          : [
              {
                provide: ErrorHandler,
                useFactory: provideErrorHandler,
                deps: [Injector],
              },
            ],
    };
  }
}

export * from "./models";
