<av-card class="fx-column fx-flex-1">
  <av-card-body
    type="thin"
    [noHeader]="true"
    class="fx-flex-1 fx-column fx-align-stretch"
    #bodyRef
  >
    <app-conversation-item
      [message]="m"
      *ngFor="let m of messages | orderBy : 'timestamp'"
    ></app-conversation-item>
  </av-card-body>
  <av-card-footer>
    <app-conversation-input></app-conversation-input>
  </av-card-footer>
</av-card>
