import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  AgentParam,
  AppConfigService,
  GenericErrorHandler,
  IInteraction,
  IQueue,
  NotificationService,
} from "../../../../core-ui";
import { InteractionService, QueueService } from "../../../services";
import { TranslateService } from "@ngx-translate/core";
import { IShareLinkAction } from "../share-link.component";
import { GenesysInvitationChannelEnum } from "../../../models";

@Component({
  selector: "app-share-link-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class ShareLinkEmailComponent implements OnInit, IShareLinkAction {
  @Input() interaction: IInteraction;
  @Input() link: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Output() confirm: EventEmitter<{
    channel: GenesysInvitationChannelEnum;
    queue?: IQueue;
    workflow?: string;
  }> = new EventEmitter();

  emailQueues: IQueue[] = [];
  isSelectingEmailQueue = false;

  constructor(
    private interactionService: InteractionService,
    private errorHandler: GenericErrorHandler,
    private notification: NotificationService,
    private translate: TranslateService,
    private queueService: QueueService,
    private config: AppConfigService
  ) {}

  async ngOnInit() {
    if (!this.isWorkflowEnabled) {
      this.emailQueues = await this.queueService.getEmailQueues();
    }
  }

  async emailQueueSelected(queue) {
    try {
      this.isSelectingEmailQueue = false;

      await this.interactionService.createEmailInteraction(
        queue.id,
        this.interaction.getCustomerEmail(),
        this.interaction.getCustomerLink(this.link),
        this.interaction.getCustomerLink(this.link)
      );

      // this.shareOpen = false;
      this.openChange.emit(false);

      // this.renewCustomerLink();
      this.completed.emit();

      this.notifyAgent();
    } catch (ex) {
      this.errorHandler.handleError(ex);
      this.notification.error((ex.body && ex.body.message) || ex.message || ex);
    }
  }

  shareLink() {
    this.isWorkflowEnabled ? this.shareUsingWorkflow() : this.shareUsingQueue();
  }

  shareUsingWorkflow() {
    this.openChange.emit(false);
    this.confirm.emit({
      channel: GenesysInvitationChannelEnum.workflow,
      workflow: this.config.agentParam(AgentParam.EMAIL_WORKFLOW_ID),
    });
  }

  private notifyAgent() {
    this.notification.show("auvious", {
      body: this.translate.instant(
        "Please check your interactions for a new email interaction."
      ),
    });
  }

  async shareUsingQueue() {
    if (this.isSelectingEmailQueue) {
      return (this.isSelectingEmailQueue = false);
    }
    try {
      if (this.emailQueues.length === 1) {
        // only one queue, prepare email for that queue.
        this.interactionService.createEmailInteraction(
          this.emailQueues[0].id,
          this.interaction.getCustomerEmail(),
          this.interaction.getCustomerLink(this.link)
        );
        // this.shareOpen = false;
        this.openChange.emit(false);

        // this.renewCustomerLink();
        this.completed.emit();

        this.notifyAgent();
      } else if (this.emailQueues.length > 1) {
        // show available queues for the user to choose from
        this.isSelectingEmailQueue = true;
      } else {
        // no outbound email have been setup for the existing queues
        this.notification.show("auvious", {
          body: this.translate.instant(
            "There are no queues available that have an outbound email address setup."
          ),
        });
      }
    } catch (ex) {
      // this.activityService.loading(false);
      this.errorHandler.handleError(ex);
      this.notification.error(
        (ex.body && ex.body.message) ||
          ex.message ||
          "Could not retrieve email queues"
      );
    }
  }

  get isWorkflowEnabled() {
    return (
      this.config.agentParamEnabled(AgentParam.EMAIL_WORKFLOW_ENABLED) &&
      !!this.config.agentParam(AgentParam.EMAIL_WORKFLOW_ID)
    );
  }

  get isEmailAvailable() {
    return this.isWorkflowEnabled
      ? true
      : this.interaction.supportsEmails() && this.emailQueues.length > 0;
  }
}
