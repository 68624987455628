import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-share-link-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
})
export class ShareLinkScheduleComponent implements OnInit {
  @Output() request: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  callbackRequest() {
    this.request.emit();
  }
}
