import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AppConfigService,
  AppointmentParticipantStateEnum,
  AppointmentService,
  IApplicationDTO,
  ITicket,
  PublicParam,
  TicketTypeEnum,
} from "../../../core-ui";
import {
  IThemeOptions,
  ThemeOptions,
} from "../../../core-ui/models/application-options/ThemeOptions";
import moment from "moment";
import { PARAM_DISPLAY_NAME } from "../../app.enums";
import { debug, debugError } from "../../app.utils";

@Component({
  selector: "app-wait-page",
  templateUrl: "./wait.component.html",
  styleUrls: ["./wait.component.scss"],
})
export class WaitPageComponent implements OnInit {
  options: IApplicationDTO;
  ticket: ITicket;
  theme: IThemeOptions;
  type: "callback" | "appointment";
  queueId: string;
  startAt: Date;
  timezone: string;
  queueName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointment: AppointmentService,
    config: AppConfigService
  ) {
    this.route.data.subscribe((data) => {
      this.ticket = data.ticket;
      this.theme = ThemeOptions.create(
        this.ticket.properties.theme || config.publicParam(PublicParam.THEME)
      );
      this.startAt = new Date(this.ticket.properties.scheduled_date);
      this.queueId = this.ticket.properties.queueId;
      this.queueName = this.ticket.properties.queueName;
      this.timezone = this.ticket.properties.timezone;
      this.type = [
        TicketTypeEnum.AppointmentScheduleTicket,
        TicketTypeEnum.AppointmentSingleScheduleTicket,
      ].includes(this.ticket.type)
        ? "appointment"
        : "callback";
    });
  }

  async ngOnInit() {
    // get total miliseconds to the scheduled date and retry then
    const scheduledFor = moment(this.startAt);
    const duration = moment.duration(scheduledFor.diff(moment()));
    const milis =
      (duration.days() * 24 * 60 * 60 +
        duration.hours() * 60 * 60 +
        duration.minutes() * 60 +
        duration.seconds()) *
      1000;

    // if (this.type === "appointment") {
    //   this.updateParticipantState();
    // }

    setTimeout(this.retryNow.bind(this), milis);
  }

  private retryNow() {
    let path;
    switch (this.type) {
      case "appointment":
        path = ["/q", this.ticket.id];
        break;
      case "callback":
        path = ["/t", this.ticket.id];
        break;
    }

    this.router.navigate(path, {
      queryParams: {
        [PARAM_DISPLAY_NAME]: this.ticket.properties?.customer_name,
      },
      replaceUrl: true,
    });
  }

  private async updateParticipantState() {
    try {
      const appointment = await this.appointment.get(
        this.ticket.properties.appointmentId
      );
      debug(appointment);
      // this.appointment.updateParticipantState(
      //   this.ticket.properties.appointmentId,
      //   appointment,
      //   AppointmentParticipantStateEnum.WAITING
      // );
    } catch (ex) {
      debugError(ex);
    }
  }
}
