import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PureCloudAuthService } from "./purecloud.auth.service";
import { PureCloudEventService } from "./purecloud.event.service";
import { AppConfigService } from "../../core-ui/services";
import { debug, debugError, loadScript } from "../app.utils";
import {
  GenesysCloudMemberState,
  GenesysCloudMemberRole,
  API_V2,
  KEY_PC_ENVIRONMENT,
  WIDGET_REFERRER_ID,
  KEY_APPLICATION_ID,
  CUSTOMER_PHONE_NUMBER_ATTRIBUTE,
  KEY_ROOM_NAME,
  KEY_EMAIL,
  DEFAULT_EMAIL,
  CHAT_KEY_EMAIL,
  WIDGET_CONVERSATION_TYPE,
  INTERACTION_WIDGET_TYPE,
  PARAM_GENESYS_ENV,
  WIDGET_MODE,
  TAG_CUSTOMER_CALLBACK_ORIGIN,
  KEY_APPOINTMENT_ID,
  TAG_CUSTOMER_INVITED,
} from "../app.enums";
import {
  IConversationInfo,
  IGenesysPageableResponse,
  IGenesysAgent,
  ICallbackRequest,
} from "../models";

import {
  ConversationTypeEnum,
  OriginModeEnum,
} from "../../core-ui/core-ui.enums";
import { GenericErrorHandler } from "./error-handlers.service";
import { GenesysIntegration } from "../models/GenesysIntegration";
import {
  IGenesysConversation,
  IGenesysConversationDetails,
  IGenesysConversationParticipant,
  IGenesysConversationParticipantChannel,
  IGenesysConversationSummary,
  IGenesysInstanceInfo,
  IGenesysPageableWebMessagingCollection,
  IGenesysWebChatMessage,
  IGenesysWebMessagingMessage,
  IGenesysWorkflow,
  IGenesysWorkflowExecuteRequest,
} from "../models/IGenesysUser";
import { TAG_CUSTOMER_TRANSFER_ROOM } from "../app.enums";
import {
  IApplicationLifecycleEventHandlers,
  IConversationEventHandlers,
  ConversationOriginEnum,
  ConversationChannelEnum,
} from "../../core-ui";
import type purecloud from "purecloud-platform-client-v2";
import { sessionStore } from "@auvious/utils";
import { PublicParam } from "../../core-ui/models/application-options/PublicOptions";
import {
  IMessage,
  IGenesysWebMessagingDeployment,
} from "@auvious/integrations";

@Injectable()
export class PureCloudService {
  private platformClient: typeof purecloud;
  private client: purecloud.ApiClientClass;
  private conversationsApi;
  private integrationsApi;
  private analyticsApi;
  private usersApi;
  private clientApp;
  private groupsApi;
  private routingApi;
  private webMessagingApi;
  private webDeploymentsApi;
  private architectApi;
  private webChatApi;
  private widgetsApi;

  private authService: PureCloudAuthService;
  private eventService: PureCloudEventService;

  private activeConversationInfo: IConversationInfo;

  private integrationTypeId: string;

  private _applicationId: string;
  private _conversationId: string;
  private _appInstances: GenesysIntegration[] = [];

  private _lifecycleHandlers: IApplicationLifecycleEventHandlers;

  constructor(
    private zone: NgZone,
    private config: AppConfigService,
    private errorHandler: GenericErrorHandler,
    private translateService: TranslateService
  ) {}

  public async init(pcEnvironment?: string): Promise<boolean> {
    // coming from query param on first load.
    // save on local storage because auth redirect will clear it
    if (!!pcEnvironment) {
      this.setPcEnvironment(pcEnvironment);
    } else {
      // try to retrieve from localstorage, in case this is a redirect
      pcEnvironment = this.pcEnvironment;
    }
    // ClientApp constructor will raise an exception if no pcEnvironment is provided
    try {
      const options = !!pcEnvironment
        ? { pcEnvironment }
        : { pcEnvironmentQueryParam: PARAM_GENESYS_ENV };

      const { default: clientApp } = await import("purecloud-client-app-sdk");
      this.clientApp = new clientApp(options);
      await this.initClientAPI();

      return true;
    } catch (ex) {
      return false;
    }
  }

  public setPcIntegrationTypeId(value: string) {
    this.integrationTypeId = value;
  }
  public get pcIntegrationTypeId(): string {
    return this.integrationTypeId;
  }

  // switched to sessionStorage, since using localStorage would cause issues when the same app
  // is accessed on the same browser but on different regions on separate windows/tabs
  public setPcEnvironment(value: string) {
    sessionStore.setItem(KEY_PC_ENVIRONMENT, value);
  }
  public get pcEnvironment(): string {
    return sessionStore.getItem(KEY_PC_ENVIRONMENT);
  }

  public setApplicationId(value: string, persist?: boolean) {
    this._applicationId = value;
    if (persist) {
      sessionStore.setItem(KEY_APPLICATION_ID, value);
    }
  }
  public get applicationId(): string {
    return this._applicationId || sessionStore.getItem(KEY_APPLICATION_ID);
  }
  public removeApplicationId() {
    this._applicationId = null;
    sessionStore.removeItem(KEY_APPLICATION_ID);
  }

  public setConversationId(value: string) {
    this._conversationId = value;
  }
  public get conversationId(): string {
    return this._conversationId;
  }
  public removeConversationId() {
    this._conversationId = null;
  }

  public setApplicationInstances(value: GenesysIntegration[]) {
    this._appInstances = value;
  }
  public get applicationInstances(): GenesysIntegration[] {
    return this._appInstances;
  }

  public isInitialised() {
    return !!this.clientApp;
  }

  private async initClientAPI() {
    await loadScript("purecloud-client.js");
    this.platformClient = window.require("platformClient");
    if (!this.platformClient) {
      return debug("platformClient not available in window");
    }
    this.client = this.platformClient.ApiClient.instance;
    // Set PureCloud settings
    this.client.setEnvironment(this.clientApp.pcEnvironment);
    this.client.setPersistSettings(
      this.config.publicParam(
        PublicParam.ACCESS_TOKEN_AGENT_SESSION_STORE_ENABLED
      ),
      "auvious"
    );
    this.config.pcEnvironment = this.clientApp.pcEnvironment;

    this.conversationsApi = new this.platformClient.ConversationsApi();
    const notificationsApi = new this.platformClient.NotificationsApi();
    this.usersApi = new this.platformClient.UsersApi();
    this.integrationsApi = new this.platformClient.IntegrationsApi();
    this.groupsApi = new this.platformClient.GroupsApi();
    this.routingApi = new this.platformClient.RoutingApi();
    this.analyticsApi = new this.platformClient.AnalyticsApi();
    this.webMessagingApi = new this.platformClient.WebMessagingApi();
    this.webDeploymentsApi = new this.platformClient.WebDeploymentsApi();
    this.architectApi = new this.platformClient.ArchitectApi();
    this.webChatApi = new this.platformClient.WebChatApi();
    this.widgetsApi = new this.platformClient.WidgetsApi();

    this.authService = new PureCloudAuthService(this.client, this.usersApi);
    this.eventService = new PureCloudEventService(notificationsApi);

    this.clientApp.lifecycle.bootstrapped();
    this.clientApp.lifecycle.addFocusListener(this.onAppFocus.bind(this));
    this.clientApp.lifecycle.addBlurListener(this.onAppBlur.bind(this));
    this.clientApp.lifecycle.addStopListener(this.onAppStop.bind(this));
  }

  public registerLifecycleEventHandlers(
    handlers: IApplicationLifecycleEventHandlers
  ) {
    this._lifecycleHandlers = handlers;
  }

  public bootstrapAgent() {
    return new Promise<void>((resolve, reject) => {
      this.zone.run((_) => {
        this.authService
          .tryGetUser()
          .then((me) => this.subscribeToNotifications(me.id).then(resolve))
          .catch((error) => {
            this.onError(error);
            reject(error);
          });
      });
    });
  }

  public setGenesysClientToken(token: string) {
    if (!!this.client) {
      this.client.setAccessToken(token);
    }
  }

  public user(): IGenesysAgent {
    return !!this.authService && this.authService.user();
  }

  public get conversationInfo(): IConversationInfo {
    return this.activeConversationInfo;
  }

  public get conversationType(): ConversationTypeEnum {
    return this.conversationInfo && this.conversationInfo.type;
  }

  public clearConversationInfo() {
    this.activeConversationInfo = null;
    this._conversationId = null;
  }

  public async getWebMessagingDeployment(
    deploymentId: string
  ): Promise<IGenesysWebMessagingDeployment> {
    return this.webDeploymentsApi.getWebdeploymentsDeploymentConfigurations(
      deploymentId
    );
  }

  public async getWebChatDeployment(deploymentId: string): Promise<any> {
    return this.widgetsApi.getWidgetsDeployment(deploymentId);
  }

  /**
   * PureCloud lifecycle events
   */

  private onError(error) {
    debug(error);
    this.errorHandler.handleError(error);
  }

  private onAppFocus() {
    if (this._lifecycleHandlers && this._lifecycleHandlers.focused) {
      this._lifecycleHandlers.focused();
    }
  }
  private onAppBlur() {
    if (this._lifecycleHandlers && this._lifecycleHandlers.blurred) {
      this._lifecycleHandlers.blurred();
    }
  }
  private onAppStop() {
    try {
      this.clientApp.lifecycle.removeFocusListener(this.onAppFocus.bind(this));
      this.clientApp.lifecycle.removeBlurListener(
        this,
        this.onAppBlur.bind(this)
      );
      if (this._lifecycleHandlers && this._lifecycleHandlers.stopped) {
        this._lifecycleHandlers.stopped();
      }
    } catch (ex) {
      debugError(ex);
    }
  }

  public registerConversationEventHandlers(
    handlers: IConversationEventHandlers
  ) {
    if (this.eventService) {
      this.eventService.registerEventHandlers(handlers);
    }
  }

  private subscribeToNotifications(userId) {
    return new Promise<void>((resolve) => {
      this.eventService.subscribeToNotifications
        .bind(this.eventService)()
        .then((_) => this.eventService.listenToUserTopics(userId).then(resolve))
        .catch((ex) => this.errorHandler.handleError(ex));
    });
  }

  public async postMessageToMessaging(
    conversationId: string,
    communicationId: string,
    message: string
  ) {
    const payload = { textBody: message };
    try {
      await this.conversationsApi.postConversationsMessageCommunicationMessages(
        conversationId,
        communicationId,
        payload
      );
    } catch (ex) {
      debugError("postConversationsMessageCommunicationMessages", ex);
    }
  }

  public async postMessageToConversation(
    conversationId,
    communicationId,
    message,
    bodyType: "standard" | "notice" = "standard"
  ): Promise<any> {
    const payload = { body: message, bodyType };
    try {
      await this.conversationsApi.postConversationsChatCommunicationMessages(
        conversationId,
        communicationId,
        payload
      );
    } catch (ex) {
      debugError("postConversationsChatCommunicationMessages", ex);
    }
  }

  public prepareEmail(
    queueId,
    toAddress,
    message,
    messageHTML?,
    subject?
  ): Promise<any> {
    const payload = {
      toAddress,
      subject: subject || this.translateService.instant("Join this call"),
      direction: "OUTBOUND",
      textBody: message,
      htmlBody: messageHTML || message,
      queueId,
    };
    return this.conversationsApi.postConversationsEmails(payload);
  }

  /**
   * requires permission conversation:message:create
   *
   * @param queueId
   * @param toPhoneNumber
   */
  public async prepareSMS(queueId, toPhoneNumber): Promise<any> {
    // try to find an existing sms interaction
    try {
      const conversationData = await this.conversationsApi.getConversations();
      const existingConversation = conversationData?.entities?.find(
        (entity) => {
          const agent = entity.participants.find((p) => p.purpose === "agent");
          if (agent) {
            const message = agent.messages?.find(
              (m) => m.toAddress?.addressNormalized === toPhoneNumber
            );
            return !!message;
          }
          return false;
        }
      );

      if (existingConversation) {
        return existingConversation;
      }
    } catch (ex) {
      // nothing
    }

    const payload = {
      queueId,
      toAddress: toPhoneNumber,
      toAddressMessengerType: "sms",
      useExistingConversation: true,
    };
    return this.conversationsApi.postConversationsMessages(payload);
  }

  public async sendSMS(conversationId, message): Promise<any> {
    const conversation = await this.conversationsApi.getConversation(
      conversationId
    );
    // const me = this.user.
    const communicationId = conversation.participants.find(
      (p) => p.purpose === "agent" && p.userId === this.user().id
    )?.messages?.[0].id;
    if (!communicationId) {
      throw new Error("communication not found");
    }
    try {
      const payload = { textBody: message };
      return this.conversationsApi.postConversationsMessageCommunicationMessages(
        conversationId,
        communicationId,
        payload
      );
    } catch (ex) {
      debugError(ex);
    }
  }

  public postTypingIndicatorToConversation(
    conversationId,
    communicationId
  ): Promise<any> {
    return this.conversationsApi.postConversationsChatCommunicationTyping(
      conversationId,
      communicationId
    );
  }

  public getActiveUsers(
    page: number = 1,
    size: number = 200
  ): Promise<IGenesysPageableResponse<IGenesysAgent>> {
    const opts = {
      pageSize: size, // Number | Page size
      pageNumber: page, // Number | Page number
      state: "active", // String | Only list users of this state
    };
    return this.usersApi.getUsers(opts);
  }

  public disconnectUserFromConversation(
    conversationId: string,
    participantId: string
  ): Promise<any> {
    return this.conversationsApi.patchConversationsChatParticipant(
      conversationId,
      participantId,
      { state: "DISCONNECTED" }
    );
  }

  public tagCustomer(conversationId, participantId, map: any): Promise<any> {
    const payload = { attributes: map };
    if (!this.conversationsApi || !conversationId) {
      return;
    }
    return this.conversationsApi.patchConversationParticipantAttributes(
      conversationId,
      participantId,
      payload
    );
  }

  private extractPhoneNumber(sip: string) {
    const regex = /(?<=tel:)([^:@]+)/;
    const phone = regex.exec(sip);
    return phone?.[0];
  }

  private hydrateConversation(
    data: IGenesysConversationSummary
  ): IConversationInfo {
    const cTypeKey = `context.${WIDGET_CONVERSATION_TYPE}`;
    const room = this.extractRoom(data);
    const info: IConversationInfo = {
      agentId: data.agent.id,
      customerId: data.customer.id,
      customerName: data.customer.name,
      customerEmail:
        data.customer.attributes[CHAT_KEY_EMAIL] || // coming from chat
        data.customer.attributes[KEY_EMAIL] || // coming from callback
        DEFAULT_EMAIL,
      customerPhone: !!data.callback
        ? data.callback.callbackNumbers.length > 0 &&
          data.callback.callbackNumbers[0]
        : data.channel === ConversationChannelEnum.genesysCall
        ? this.extractPhoneNumber(data.customer.address)
        : data.customer.attributes[CUSTOMER_PHONE_NUMBER_ATTRIBUTE],
      conversationId: data.conversation.id,
      communicationId: data.interaction?.id || data.callback?.id,
      legacy: data.legacy,
      queueId: data.agent.queueId,
      room,
      origin: data.origin,
      type:
        cTypeKey in data.customer.attributes
          ? (data.customer.attributes[cTypeKey] as ConversationTypeEnum)
          : !!data.callback
          ? ConversationTypeEnum.callback
          : data.channel === ConversationChannelEnum.genesysCall
          ? ConversationTypeEnum.videoCall
          : ConversationTypeEnum.chat,
      customerInvited:
        data.customer.attributes[TAG_CUSTOMER_INVITED] === "true",
      originMode: data.mode,
      channel: data.channel,
      appointmentId: data.appointmentId,
      transferred: !!data.customer.attributes[TAG_CUSTOMER_TRANSFER_ROOM],
      customerMetadata: data.customer.attributes,
    };
    return info;
  }

  public async getConversationInfoById(
    id: string,
    channel: ConversationChannelEnum
  ): Promise<IConversationInfo> {
    try {
      if (!id) {
        return null;
      }
      const conversation = await this.conversationsApi.getConversation(id);
      // channel === ConversationChannelEnum.genesysCall
      // ? await this.conversationsApi.getConversationsCall(id)
      // : await this.conversationsApi.getConversation(id);

      const data = this.discoverConversationDetails([conversation], false);
      if (!data) {
        return null;
      }
      const info = this.hydrateConversation(data);
      return info;
    } catch (ex) {
      // do nothing
      debugError(ex);
      return null;
    }
  }

  /**
   * https://developer.genesys.cloud/forum/t/cannot-get-webmessaging-messages-as-an-agent-403/18748/2
   */
  public async getWebMessagingMessages(
    conversationId
  ): Promise<
    IGenesysPageableWebMessagingCollection<IGenesysWebMessagingMessage>
  > {
    try {
      const messageIds = [];
      const conversation = await this.conversationsApi.getConversation(
        conversationId
      );
      if (!conversation) {
        throw new Error("web-messaging-no-conversation-found");
      }

      conversation.participants
        .filter((p) => p.purpose === "customer" || p.purpose === "agent")
        .forEach((p) => {
          p.messages.forEach((m) => {
            m.messages.forEach((ms) => {
              if (
                !messageIds.includes(ms.messageId) &&
                ms.messageMetadata?.type !== "Event"
              ) {
                messageIds.push(ms.messageId);
              }
            });
          });
        });

      if (messageIds.length === 0) {
        return { entities: [] };
      }

      const response =
        await this.conversationsApi.postConversationsMessageMessagesBulk(
          conversationId,
          { body: messageIds }
        );

      return response;
    } catch (ex) {
      debugError(ex);
      throw ex;
    }
  }

  public getChatMessages(
    conversationId: string
  ): Promise<IGenesysPageableResponse<IGenesysWebChatMessage>> {
    const opts = {
      // 'after': "after_example", // String | If specified, get the messages chronologically after the id of this message
      // 'before': "before_example", // String | If specified, get the messages chronologically before the id of this message
      sortOrder: "ascending", // String | Sort order
      maxResults: 100, // Number | Limit the returned number of messages, up to a maximum of 100
    };
    return this.conversationsApi.getConversationsChatMessages(
      conversationId,
      opts
    );
  }

  public async getConversationMessages(
    id: string,
    channel: ConversationChannelEnum
  ) {
    switch (channel) {
      case ConversationChannelEnum.genesysWebMessaging:
      case ConversationChannelEnum.genesysWhatsApp:
      case ConversationChannelEnum.genesysFacebookMessenger:
      case ConversationChannelEnum.genesysMessaging:
        try {
          const messagePage = await this.getWebMessagingMessages(id);
          const entities: IMessage[] = messagePage.entities.map((m) => ({
            id: m.id,
            senderId: m.fromAddress,
            senderName: m.direction === "inbound" ? "Customer" : "Agent",
            text: m.textBody,
            isLocal: m.normalizedMessage.direction === "Inbound",
            timestamp: new Date(m.timestamp),
            status: "ack",
            type: "text",
          }));
          return entities;
        } catch (ex) {
          throw ex;
        }
      case ConversationChannelEnum.genesysWebChat:
        try {
          const data = await Promise.all([
            this.getChatMessages(id),
            this.getConversation(id),
          ]);
          const messagePage = data[0];
          const conversation = data[1];
          const membersMap: { [sessionId: string]: string } = {};
          const purposeMap: {
            [sessionId: string]: GenesysCloudMemberRole;
          } = {};

          // for some reason, the chat transcript user.id is the chat.id of the conversation participants
          conversation.participants.forEach((p) => {
            p.chats.forEach((s) => {
              membersMap[s.id] = p.name;
              purposeMap[s.id] = p.purpose;
            });
          });

          const entities: IMessage[] = messagePage.entities
            .filter(
              (m) =>
                m.bodyType === "notice" ||
                m.bodyType === "text" ||
                m.bodyType === "standard"
            )
            .map((m) => ({
              id: m.id,
              senderId: m.sender.id,
              senderName: membersMap[m.sender.id],
              text: m.body,
              isLocal: purposeMap[m.sender.id] === "customer",
              timestamp: new Date(m.timestamp),
              status: "ack",
              type: "text",
            }));
          return entities;
        } catch (ex) {
          throw ex;
        }
    }
  }

  private extractRoom(data: IGenesysConversationSummary): string {
    return (
      // in a callback interaction the attribute is not prefixed
      data.customer.attributes[KEY_ROOM_NAME] ||
      // in a chat interaction the attribute is prefixed with 'context.'
      data.customer.attributes[`context.${KEY_ROOM_NAME}`] ||
      // when we transfer a chat, we store to the attributes the room name so that the new agent will join
      // the same room as the customer
      data.customer.attributes[TAG_CUSTOMER_TRANSFER_ROOM]
    );
  }

  public async discoverActiveConversation(): Promise<IConversationInfo> {
    try {
      let conversations: any[];
      if (!!this.conversationId) {
        const conversation = await this.conversationsApi.getConversation(
          this.conversationId
        );
        conversations = [conversation];
      } else {
        const conversationData = await this.conversationsApi.getConversations();
        conversations = conversationData.entities;
      }
      if (conversations.length > 0) {
        const data = this.discoverConversationDetails(conversations);
        if (!data) {
          throw new Error("no-active-conversation");
        }
        this.activeConversationInfo = this.hydrateConversation(data);
        return this.activeConversationInfo;
      } else {
        throw new Error("no-conversations-available");
      }
    } catch (ex) {
      throw ex;
    }
  }

  public async createCallback(callbackRequest: ICallbackRequest): Promise<any> {
    try {
      const data = await this.conversationsApi.postConversationsCallbacks(
        callbackRequest
      );
      return data;
    } catch (ex) {
      debugError(ex);
      throw ex;
    }
  }

  public showToastPopup(title, message) {
    this.clientApp.alerting.showToastPopup(title, message);
  }

  private discoverConversationDetails(
    conversations: IGenesysConversation[],
    activeOnly = true
  ): IGenesysConversationSummary {
    let connectedAgent;
    let connectedCustomer;
    let agentInteraction;
    let agentCallback;
    let origin;
    let mode;
    let legacy = false;
    let appointmentId: string;
    let channel: ConversationChannelEnum;
    let appointmentKey;

    const activeConversation = conversations.find((conversation) => {
      conversation.participants.forEach((participant) => {
        let type = null;

        // support chat and web messenger
        if ("chats" in participant && participant.chats.length > 0) {
          type = "chats";
          channel = ConversationChannelEnum.genesysWebChat;
          appointmentKey = `context.${KEY_APPOINTMENT_ID}`;
        } else if (
          "messages" in participant &&
          participant.messages.length > 0
        ) {
          type = "messages";
          channel = PureCloudService.getConversationChannel(
            participant.messages[0]
          );
          appointmentKey = KEY_APPOINTMENT_ID;
        } else if ("calls" in participant && participant.calls.length > 0) {
          type = "calls";
          channel = ConversationChannelEnum.genesysCall;
          appointmentKey = KEY_APPOINTMENT_ID;
        }

        if (!!type && participant[type].length > 0) {
          // ----- This is the same as in purecloud.event.service but we have a different model here
          // todo: extract logic to separate function and share with event.service
          const agents = conversation.participants.filter(
            (p) => p.purpose === GenesysCloudMemberRole.AGENT
          );

          const agentsThatAreMe = conversation.participants.filter(
            (a) => a.userId === this.user().id
          );

          // if I transfer the call to a queue but comes back to me by that queue, I will be two times
          // one as transfer and one new
          const agentsThatAreMeButTransferred = agentsThatAreMe.filter(
            (a) => a[type]?.[0]?.disconnectType !== "transfer"
          );

          const agentsThatAreNotMe = agents.filter(
            (a) =>
              a.userId !== this.user().id &&
              a[type]?.[0]?.state !== GenesysCloudMemberState.DISCONNECTED
          );

          const nextAvailableAgent: IGenesysConversationParticipant =
            this.extractMember(agentsThatAreNotMe, type);

          const agent: IGenesysConversationParticipant = this.extractMember(
            agentsThatAreMeButTransferred.length > 0
              ? agentsThatAreMeButTransferred
              : agentsThatAreMe,
            type
          );

          if (!agent) {
            throw new Error("agent-disconnected");
          }

          /**
           * NOTE:
           * If we discard this event(A) and wait for the other agent to accept the call(B) and at that time trigger the
           * 'transferring' event, then on an auto-start flow, the 2nd agent joins before the 1st agent was disconnected
           * from the call, ending up having both agents on the call..
           * Otherwise we could just return here and use the 'transfer to queue' 'if' statement(C) along with the rules
           * from the 'agent transferred'(B) to group the events into one 'if'(C).
           */

          //A: I just selected an agent to transfer the call
          if (
            agent[type][0].state === GenesysCloudMemberState.CONNECTED &&
            nextAvailableAgent?.[type][0].state ===
              GenesysCloudMemberState.ALERTING
          ) {
            throw new Error("conversation-transferring");
          }

          //B: The other agent accepted the transfer
          if (
            !!nextAvailableAgent &&
            agent[type][0].state === GenesysCloudMemberState.DISCONNECTED &&
            !agent.wrapup && // this is 'ended' event
            [
              GenesysCloudMemberState.CONNECTED,
              GenesysCloudMemberState.ALERTING,
            ].includes(nextAvailableAgent[type][0].state)
          ) {
            throw new Error("conversation-transferred");
          }

          if (
            //C: I just selected a queue to transfer the call
            !nextAvailableAgent &&
            agent[type][0].state === GenesysCloudMemberState.DISCONNECTED &&
            agent[type][0].disconnectType === "transfer"
          ) {
            if (!!agent.wrapup) {
              throw new Error("conversation-transferring");
            } else {
              throw new Error("conversation-transferred");
            }
          }

          // ---------- END of common logic

          const connectedInteraction = participant[type].find(
            (chat) => chat.state === GenesysCloudMemberState.CONNECTED
          );

          if (!!connectedInteraction) {
            switch (channel) {
              // connectedInteraction.provider = "PureCloud Messaging" for web messenger
              case ConversationChannelEnum.genesysWhatsApp:
              case ConversationChannelEnum.genesysFacebookMessenger:
                legacy = false;
                break;
              default:
                legacy = connectedInteraction.provider !== API_V2;
                break;
            }
            if (
              participant.purpose === GenesysCloudMemberRole.AGENT &&
              !connectedInteraction.held
            ) {
              connectedAgent = participant;
              agentInteraction = connectedInteraction;
            } else if (
              [
                GenesysCloudMemberRole.CUSTOMER,
                GenesysCloudMemberRole.USER,
              ].includes(participant.purpose)
            ) {
              connectedCustomer = participant;
            }
            if (connectedCustomer) {
              /* when getting the origin, priorty has the query param. if not found, look for customer attributes.
                this hapens because a widget conversation may be answered from mobile-office so we need to
                preserve the mobile-office origin.
                This update is handled by the conversation.resolver
                */
              origin = !!connectedCustomer.attributes[appointmentKey]
                ? ConversationOriginEnum.APPOINTMENT
                : !!connectedCustomer.attributes[
                    `context.${WIDGET_REFERRER_ID}`
                  ]
                ? ConversationOriginEnum.WIDGET
                : channel === ConversationChannelEnum.genesysCall
                ? ConversationOriginEnum.PHONE
                : ConversationOriginEnum.CHAT;

              mode = !!connectedCustomer.attributes[`context.${WIDGET_MODE}`]
                ? connectedCustomer.attributes[`context.${WIDGET_MODE}`]
                : OriginModeEnum.website;

              appointmentId = connectedCustomer.attributes[appointmentKey];
            }
          }
        } else if (
          "callbacks" in participant &&
          participant.callbacks.length > 0
        ) {
          channel = ConversationChannelEnum.genesysCallback;

          const connectedCallback = activeOnly
            ? participant.callbacks.find(
                (cb) => cb.state === GenesysCloudMemberState.CONNECTED
              )
            : participant.callbacks?.[0];
          if (!!connectedCallback) {
            // origin = ConversationOriginEnum.CALLBACK;
            if (
              participant.purpose === GenesysCloudMemberRole.AGENT &&
              !connectedCallback.held
            ) {
              connectedAgent = participant;
              agentCallback = connectedCallback;
            } else if (
              [
                GenesysCloudMemberRole.CUSTOMER,
                GenesysCloudMemberRole.USER,
              ].includes(participant.purpose)
            ) {
              connectedCustomer = participant;
              origin =
                TAG_CUSTOMER_CALLBACK_ORIGIN in connectedCustomer.attributes
                  ? connectedCustomer.attributes[TAG_CUSTOMER_CALLBACK_ORIGIN]
                  : ConversationOriginEnum.WIDGET;
            }
          }
        }
      });
      return !!connectedCustomer && !!connectedAgent;
    });
    return (!!agentInteraction || !!agentCallback) &&
      !!connectedCustomer &&
      !!connectedAgent
      ? {
          agent: connectedAgent,
          customer: connectedCustomer,
          conversation: activeConversation,
          interaction: agentInteraction,
          callback: agentCallback,
          legacy,
          origin,
          mode,
          channel,
          appointmentId,
        }
      : null;
  }

  public static getConversationChannel(
    message: IGenesysConversationParticipantChannel
  ): ConversationChannelEnum {
    switch (message.type) {
      case "webmessaging":
        return ConversationChannelEnum.genesysWebMessaging;
      case "whatsapp":
        return ConversationChannelEnum.genesysWhatsApp;
      case "facebook":
        return ConversationChannelEnum.genesysFacebookMessenger;
      default:
        return ConversationChannelEnum.genesysMessaging;
    }
  }

  public async getQueues(pageNumber = 1): Promise<any> {
    const opts = {
      pageSize: 100,
      pageNumber,
      sortBy: "name",
    };
    try {
      const pager = await this.routingApi.getRoutingQueues(opts);
      if (pager.pageCount > pager.pageNumber) {
        return pager.entities.concat(
          await this.getQueues(pager.pageNumber + 1)
        );
      }
      return pager.entities;
    } catch (err) {
      this.errorHandler.handleError(err);
      return [];
    }
  }

  private extractMember(
    members: IGenesysConversationParticipant[],
    channel: string
  ): IGenesysConversationParticipant {
    if (members.length === 1) {
      return members[0];
    } else if (members.length > 1) {
      // comes from 'make eligible ..'
      return (
        members.find(
          (a) =>
            a[channel][0].state === GenesysCloudMemberState.ALERTING ||
            a[channel][0].state === GenesysCloudMemberState.CONNECTED
        ) ||
        members.find(
          (a) =>
            a[channel][0].state === GenesysCloudMemberState.DISCONNECTED &&
            a[channel][0].disconnectType === "client"
        ) ||
        members.find(
          (a) =>
            a[channel][0].state === GenesysCloudMemberState.DISCONNECTED &&
            !a.wrapup
        ) ||
        members.find(
          (a) =>
            a[channel][0].state === GenesysCloudMemberState.DISCONNECTED &&
            !!a.wrapup
        ) ||
        members.find(
          (a) => a[channel][0].state === GenesysCloudMemberState.DISCONNECTED
        )
      );
    }
  }

  public async getMyQueues(pageNumber = 1): Promise<any> {
    const opts = {
      pageSize: 100,
      pageNumber,
      sortBy: "name",
    };
    try {
      const pager = await this.routingApi.getRoutingQueuesMe(opts);
      if (pager.pageCount > pager.pageNumber) {
        return pager.entities.concat(
          await this.getQueues(pager.pageNumber + 1)
        );
      }
      return pager.entities;
    } catch (err) {
      this.errorHandler.handleError(err);
      return [];
    }
  }

  /**
   * Get details of the current user
   */
  public getUserDetails(id: string): Promise<IGenesysAgent> {
    const opts = {
      state: "active", // String | Only list users of this state
    };
    return this.usersApi.getUser(id, opts);
  }

  /**
   * Get all auvious apps instances
   */
  public getInstances(): Promise<GenesysIntegration[]> {
    return new Promise((resolve, reject) => {
      this.getExistingApps()
        .then((auviousInstances) => {
          if (auviousInstances.length === 0) {
            return reject("no-app-installed");
          }
          const configs = [];
          auviousInstances.forEach((instance) => {
            const p = new Promise((rs, rj) => {
              this.integrationsApi
                .getIntegrationConfigCurrent(instance.id)
                .then((config) => rs(new GenesysIntegration(instance, config)))
                .catch(rj);
            });
            configs.push(p);
          });
          Promise.all(configs)
            .then((apps) => {
              resolve(apps);
            })
            .catch(reject);
        })
        .catch((ex) => {
          debug(ex);
          return reject(ex.body || ex);
        });
    });
  }

  /**
   * Get existing apps based on the prefix
   *
   * @returns PureCloud Integrations
   */
  private getExistingApps(): Promise<any[]> {
    const integrationsOpts = {
      pageSize: 100,
    };

    return this.integrationsApi
      .getIntegrations(integrationsOpts)
      .then((data) =>
        data.entities.filter((entity) =>
          [
            // "premium-app-example", // comment in for premium app
            this.integrationTypeId,
            INTERACTION_WIDGET_TYPE,
          ].includes(entity.integrationType.id.toLowerCase().trim())
        )
      )
      .catch((ex) => Promise.reject(ex));
  }

  /**
   * Delete all existing PremiumApp instances
   *
   * @returns
   */
  private deletePureCloudApps() {
    return this.getExistingApps().then((apps) => {
      // console.log(apps);
      const delApp = [];

      if (apps.length > 0) {
        // Filter results before deleting
        apps
          .map((entity) => entity.id)
          .forEach((iid) => {
            delApp.push(this.integrationsApi.deleteIntegration(iid));
          });
      }
      return Promise.all(delApp);
    });
  }

  public install(app: GenesysIntegration, url: string): Promise<void> {
    const instanceInfo: IGenesysInstanceInfo = {
      name: app.getName(),
      type: "standalone",
      url,
    };
    return this.updateInstance(app.getId(), instanceInfo, app.getVersion());
  }

  private updateInstance(
    id: string,
    instance: IGenesysInstanceInfo,
    version: number
  ) {
    debug("Creating instance: " + instance.name);
    const integrationConfig = {
      body: {
        name: instance.name,
        version,
        properties: {
          url: instance.url, // <--- marks the app that is installed
          displayType: instance.type,
          featureCategory: "",
          // permissions: "camera,microphone,fullscreen,display-capture" // not allowed in premium apps
          // 'groupFilter': instance.groups.map((groupName) => groupData[groupName]).filter(g => g !== undefined)
        },
        advanced: {},
        notes: "", // INSTALLED_TOKEN,
        credentials: {},
      },
    };

    // integrationsData.push(data);
    return this.integrationsApi.putIntegrationConfigCurrent(
      id,
      integrationConfig
    );
  }

  public async deleteIntegration(id) {
    return this.integrationsApi.deleteIntegration(id);
  }

  // assign to group or notify the need to assign to group. Write instructions to GettingStartedGuide
  public async installInteractionWidget(
    name: string,
    url: string,
    baseUrl: string
  ) {
    // There are 3 steps for creating the app instances
    // 1. Create instance of a custom-client-app
    // 2. Configure the app
    // 3. Activate the instances
    try {
      // 1. ---->
      const integrationBody = {
        body: {
          integrationType: {
            id: INTERACTION_WIDGET_TYPE,
          },
        },
      };

      const instance = await this.integrationsApi.postIntegrations(
        integrationBody
      );
      debug(`Created integration: ${instance}`);

      // 2. ---->
      const integrationConfig = {
        body: {
          name,
          version: 1,
          properties: {
            communicationTypeFilter: "chat,callback,webmessaging",
            url,
            sandbox:
              "allow-forms,allow-modals,allow-popups,allow-presentation,allow-same-origin,allow-scripts",
            permissions: "microphone,camera,fullscreen,display-capture",
          },
          notes: "", // required by service,
          credentials: {}, // required by service
          advanced: {
            lifecycle: {
              ephemeral: false,
              hooks: {
                stop: true,
                blur: true,
                focus: true,
                bootstrap: true,
              },
            },
            icon: {
              vector: `${baseUrl}/assets/images/logo.svg`,
            },
            monochromicIcon: {
              vector: `${baseUrl}/assets/images/cam-thick.svg`,
            },
          },
        },
      };

      const config = await this.integrationsApi.putIntegrationConfigCurrent(
        instance.id,
        integrationConfig
      );
      debug(`Configured integration : ${config}`);

      // 3. ---->
      const opts = {
        body: {
          intendedState: "ENABLED",
        },
      };

      const pt = await this.integrationsApi.patchIntegration(instance.id, opts);
      debug(`enabled integration: ${instance.id}`);
    } catch (ex) {
      debugError(ex);
      throw ex;
    }
  }

  //// =======================================================
  ////      GROUPS
  //// =======================================================

  /**
   * Gets the existing groups on PureCloud based on Prefix
   *
   * @return PureCloud Group Objects
   */
  public getExistingGroups() {
    // Query bodies
    const groupSearchBody = {
      query: [
        {
          fields: ["name"],
          value: "auvious", // this.prefix,
          operator: "OR",
          type: "STARTS_WITH",
        },
      ],
    };

    return this.groupsApi.postGroupsSearch(groupSearchBody);
  }

  /**
   * Delete existing groups from PureCloud org
   *
   * @returns
   */
  private deletePureCloudGroups() {
    return this.getExistingGroups().then((groups) => {
      const delGroup = [];

      if (groups.total > 0) {
        groups.results
          .map((grp) => grp.id)
          .forEach((gid) => {
            delGroup.push(this.groupsApi.deleteGroup(gid));
          });
      }

      return Promise.all(delGroup);
    });
  }

  /**
   * Add PureCloud groups based on installation data
   *
   * @returns Group Data Object {'grp-name': 'grp-id'}
   */
  private addGroups() {
    const groupPromises = [];
    const groupData = {};
    const groupName = "dev"; // this.prefix + group.name
    // this.installationData.groups.forEach((group) => {
    const groupBody = {
      name: groupName,
      description: "dev-descr", // group.description,
      type: "official",
      rulesVisible: true,
      visibility: "public",
    };
    // console.log(groupBody);

    groupPromises.push(
      this.groupsApi
        .postGroups(groupBody)
        .then((data) => {
          debug("Created group: " + groupName);
          groupData[groupName] = data.id;
        })
        .catch((err) => console.log(err))
    );
    // });

    return Promise.all(groupPromises).then(() => groupData);
  }

  public getChatConversation(conversationId: string): Promise<{
    id: string;
    participants: {
      id: string;
      name: string;
      purpose: "customer" | "acd" | "agent";
      user?: { id: string };
    }[];
  }> {
    return this.conversationsApi.getConversationsChat(conversationId);
  }

  /**
   * App not authorized to use scope [analytics, analytics:readonly]
   *
   * @param conversationId
   * @returns
   */
  public getConversationDetails(
    conversationId: string
  ): Promise<IGenesysConversationDetails> {
    return this.analyticsApi.getAnalyticsConversationDetails(conversationId);
  }

  public getConversation(
    conversationId: string
  ): Promise<IGenesysConversation> {
    return this.conversationsApi.getConversation(conversationId);
  }

  public getChatTranscript(conversationId: string): Promise<{
    pageSize: number;
    entities: {
      id: string;
      body?: string;
      bodyType: "member-join" | "member-leave" | "standard" | "notice";
      timestamp: string;
      selfUri: string;
      sender: { id: string };
      conversation: { id: string; selfUri: string };
    }[];
  }> {
    const opts = {
      // String | If specified, get the messages chronologically after the id of this message
      // 'after': 'after_example',

      // String | If specified, get the messages chronologically before the id of this message
      // 'before': 'before_example',

      // String | Sort order
      sortOrder: "ascending",

      // Number | Limit the returned number of messages, up to a maximum of 100
      // 'maxResults': 100
    };
    return this.conversationsApi.getConversationsChatMessages(
      conversationId,
      opts
    );
  }

  public async getWorkflows(pageNumber = 1): Promise<IGenesysWorkflow[]> {
    const opts = {
      type: ["WORKFLOW"], // [String] | Type
      pageNumber, // Number | Page number
      pageSize: 25, // Number | Page size
      sortBy: "name", // String | Sort by
      sortOrder: "asc", // String | Sort order
      // 'id': ["id_example"], // [String] | ID
      // 'name': "name_example", // String | Name
      // 'description': "description_example", // String | Description
      // 'nameOrDescription': "nameOrDescription_example", // String | Name or description
      // 'publishVersionId': "publishVersionId_example", // String | Publish version ID
      // 'editableBy': "editableBy_example", // String | Editable by
      // 'lockedBy': "lockedBy_example", // String | Locked by
      // 'lockedByClientId': "lockedByClientId_example", // String | Locked by client ID
      // 'secure': "secure_example", // String | Secure
      deleted: "false", // Boolean | Include deleted
      includeSchemas: "true", // Boolean | Include variable schemas
      // 'publishedAfter': 2015-01-01T12:00:00-0600, 2015-01-01T18:00:00Z, 2015-01-01T12:00:00.000-0600, 2015-01-01T18:00:00.000Z, 2015-01-01, // String | Published after
      // 'publishedBefore': 2015-01-01T12:00:00-0600, 2015-01-01T18:00:00Z, 2015-01-01T12:00:00.000-0600, 2015-01-01T18:00:00.000Z, 2015-01-01, // String | Published before
      // 'divisionId': ["divisionId_example"] // [String] | division ID(s)
    };

    try {
      const pager: IGenesysPageableResponse<IGenesysWorkflow> =
        await this.architectApi.getFlows(opts);
      if (pager.pageCount > pager.pageNumber) {
        return pager.entities.concat(
          await this.getWorkflows(pager.pageNumber + 1)
        );
      }
      return pager.entities;
    } catch (err) {
      this.errorHandler.handleError(err);
      return [];
    }
  }

  public executeWorkflow(request: IGenesysWorkflowExecuteRequest) {
    return this.architectApi.postFlowsExecutions(request);
  }
}
