<ng-container *ngIf="!isModeTranslate">
  <av-menu-item type="separator"></av-menu-item>

  <av-menu-item icon="cc" [arrow]="true" [disabled]="isDisabled">
    <ng-container *ngIf="!sourceLanguage">{{
      "Closed Captions" | translate
    }}</ng-container>
    <ng-container *ngIf="!!sourceLanguage">{{
      sourceLanguage.name
    }}</ng-container>

    <av-card sub-menu [backdrop]="false">
      <av-card-body type="slim">
        <av-menu>
          <av-menu-item
            (select)="requestDisable()"
            [checked]="!sourceLanguage"
            data-tid="captions-control/disable"
          >
            {{ "Inactive" | translate }}
          </av-menu-item>

          <div
            *ngIf="loading"
            class="fx-row fx-justify-center container-pad-sm"
          >
            <av-spinner size="xs"></av-spinner>
          </div>

          <av-menu-item
            (select)="changeLanguage(language)"
            [disabled]="isLanguageDisabled"
            [checked]="language.id === sourceLanguage?.id"
            *ngFor="let language of languages"
            data-tid="captions-control/change-language"
          >
            {{ language.name }}
          </av-menu-item>
        </av-menu>
      </av-card-body>
    </av-card>
  </av-menu-item>
</ng-container>

<ng-container *ngIf="isTranslationPickerAvailable">
  <av-tooltip message="Translate" [enabled]="!isLanguagePickerVisible">
    <button
      avButton
      color="primary"
      ofType="icon"
      size="xxs"
      [disabled]="isDisabled"
      (click)="isLanguagePickerVisible = !isLanguagePickerVisible"
      data-tid="captions-control/toggle-language-picker"
    >
      <av-icon *ngIf="!isLanguagePickerVisible" name="globe"></av-icon>
      <av-icon *ngIf="isLanguagePickerVisible" name="close-big"></av-icon>
    </button>
  </av-tooltip>

  <av-card
    *ngIf="isLanguagePickerVisible"
    [popup]="true"
    vAlign="top"
    hAlign="right"
    arrow="bottom-right"
    [backdrop]="false"
    class="language-picker"
    [@slideInOut]="true"
  >
    <av-card-body type="slim">
      <av-menu>
        <div *ngIf="loading" class="fx-row fx-justify-center container-pad-sm">
          <av-spinner size="xs"></av-spinner>
        </div>

        <av-menu-item
          (select)="changeTranslationLanguage(language)"
          [disabled]="loading"
          [checked]="language.id === sourceLanguage?.id"
          *ngFor="let language of languages"
          data-tid="captions-control/change-translation-language"
        >
          {{ language.name }}
        </av-menu-item>
      </av-menu>
    </av-card-body>
  </av-card>
</ng-container>

<!-- translation languages 
  <av-menu-item icon="globe" [arrow]="true" [disabled]="isTargetDisabled">
    <ng-container *ngIf="!targetLanguage">
      {{ "Caption Translation" | translate }}</ng-container
    >
    <ng-container *ngIf="!!targetLanguage">{{
      targetLanguage.name
    }}</ng-container>

    <av-card sub-menu [backdrop]="false" *ngIf="sourceLanguage">
      <av-card-body type="slim">
        <av-menu>
          <av-menu-item
            (select)="requestDisableTranslation()"
            [checked]="!targetLanguage"
          >
            {{ "Inactive" | translate }}
          </av-menu-item>

          <av-menu-item
            (select)="changeTargetLanguage(language)"
            [disabled]="isLanguageDisabled"
            [checked]="language.code === targetLanguage?.code"
            *ngFor="let language of targetLanguages"
          >
            {{ language.name }}
          </av-menu-item>
        </av-menu>
      </av-card-body>
    </av-card>
  </av-menu-item>
</ng-container>
-->
<!-- 
<div class="control" *ngIf="user.isAgent">
    <button avButton [active]="isActive" [loading]="loading" [disabled]="isDisabled"  ofType="icon"
        [size]="size" id="btn-asr" (click)="toggle()">
        <av-icon *ngIf="!loading" [mode]="isActive ? 'dark' : 'basic'" name="cc"></av-icon>
    </button> 

      <av-card *ngIf="isCardVisible" arrow="bottom" type="control-options" hAlign="center" [menu]="true" vAlign="top"
        [backdrop]="true" [popup]="true">
        <div class="caption-title" translate>Closed Captions</div>
        <av-card-body type="slim">
            <av-menu>
                <av-menu-item [arrow]="true">     
    
                </av-menu-item>
            </av-menu>
        </av-card-body>
    </av-card>
 </div> 
-->
