<div
  [attr.aria-label]="'Connection lost' | translate"
  class="stream-notification"
  *ngIf="isEndpointSick"
  translate
>
  Connection lost
</div>

<div
  [aria-label]="'Video is poped out' | translate"
  class="stream-poped-out"
  *ngIf="isPopedOut"
  translate
>
  Video is poped out
</div>

<app-stream-view
  #streamView
  [class.hidden]="isMutedVideo || isFreezeFrameVisible || isPopedOut"
  [id]="stream.id"
  [type]="stream.type"
  [muted]="isLocal"
  (autoplayBlocked)="autoplayBlocked($event)"
  (mediaElementReady)="streamElementReady($event)"
  (mediaElementDestroyed)="streamElementDestroyed($event)"
>
</app-stream-view>

<av-spinner *ngIf="isFilterLoading" size="md"></av-spinner>

<app-freeze-frame
  [stream]="stream"
  [mirror]="isMirror"
  [blur]="isFreezeFrameBlurred"
  [class.blur]="isFreezeFrameBlurred"
  [class.open]="isFreezeFrameVisible"
  [class.zoom]="isZoomOn"
  [render]="isFreezeFrameVisible"
  *ngIf="!isScreen && isStreamElementReady"
>
</app-freeze-frame>

<div class="tile-screen-prompt" *ngIf="isScreenHelpActive">
  <h2 translate>You are presenting</h2>
  <button
    avButton
    ofType="stroked"
    [themable]="false"
    (click)="stopScreen()"
    translate
    data-tid="tile/stop-screenshare"
  >
    Stop presenting
  </button>
  <div class="container-pad-md fx-column" *ngIf="isMirroringHelpActive">
    <small translate class="text-muted"
      >To avoid mirroring don't share your entire screen or browser
      window.</small
    >
    <small translate class="text-muted"
      >Share just a tab or a different window instead.</small
    >
  </div>
</div>

<div
  [attr.aria-label]="('Participant\'s name is ' | translate) + displayName"
  class="tile-name"
  [class.hidden]="isHiddenTooSmall"
  [class.tile-name-volume]="isMutedVideo && avatar"
  *ngIf="isDisplayNameAvailable"
  data-tid="tile/participant-name"
>
  {{ displayName }}
</div>

<div class="tile-avatar" *ngIf="isMutedVideo && avatar">
  <img
    alt="Participant avatar"
    [attr.aria-label]="'Participant avatar' | translate"
    [src]="avatar"
    (error)="avatarError()"
  />
</div>

<app-volume-indicator
  *ngIf="isMutedVideo"
  [class.small-avatar]="avatar"
  [class.small]="isFloating || avatar"
  [stream]="stream"
  [muted]="isMutedAudio"
>
</app-volume-indicator>

<div
  class="sketch-actions"
  [class.sketch-actions-condensed]="isSketchActionsCondensed"
  *ngIf="isSketchActive"
>
  <app-point-controls
    [stream]="stream"
    [interaction]="interaction"
    [column]="isSketchActionsCondensed"
    (controlSelected)="pointControlSelected()"
  >
  </app-point-controls>
  <app-sketch-controls
    [room]="sketchId"
    [stream]="stream"
    [column]="isSketchActionsCondensed"
    (controlStateChanged)="sketchControlChanged($event)"
  >
  </app-sketch-controls>
</div>

<app-sketch-area
  class="sketch-area"
  id="{{ sketchId }}"
  [endpoint]="stream.originator"
  [sync]="true"
  [stream]="stream"
  [class.sketch-area-active]="isSketchAreaActive"
  [class.sketch-area-focused]="isSketchControlActive"
  (connected)="sketchConnected($event)"
  (ended)="sketchEnded($event)"
>
</app-sketch-area>

<ng-content></ng-content>
