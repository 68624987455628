<div class="tabs-container">
  <div class="tabs">
    <av-menu [responsive]="true">
      <av-menu-item
        mode="dark"
        [arrow]="true"
        (select)="tabSet(tabSearch)"
        [active]="isActiveTab(tabSearch)"
      >
        {{ "Search" | translate }}
      </av-menu-item>
      <av-menu-item
        mode="dark"
        [arrow]="true"
        (select)="tabSet(tabMyExports)"
        [active]="isActiveTab(tabMyExports)"
      >
        {{ "My exports" | translate }}
      </av-menu-item>
    </av-menu>
  </div>
  <div class="tab-pane-container">
    <div class="tab-pane" *ngIf="isActiveTab(tabSearch)">
      <av-card [backdrop]="false" class="search-bar">
        <form
          (ngSubmit)="trySearch(queryString, f)"
          #f="ngForm"
          class="fx-row fx-align-center fx-justify-space-between"
        >
          <av-icon name="magnify" mode="dark"></av-icon>

          <input
            type="search"
            name="query"
            required
            [(ngModel)]="queryString"
            placeholder="{{ 'conversation id...' | translate }}"
          />
          <button avButton type="submit" color="accent" translate>
            Search
          </button>
        </form>
      </av-card>

      <div
        class="text-muted margin-top-20"
        *ngIf="querySubmitted && !recording && !queryLoading"
        translate
      >
        No recording found for the provided conversation id
      </div>

      <div *ngIf="!!recording" class="composition-container">
        <app-composition-recording
          [recording]="recording"
        ></app-composition-recording>
      </div>
    </div>
    <div class="tab-pane" *ngIf="isActiveTab(tabMyExports)">
      <app-composition-exports></app-composition-exports>
    </div>
  </div>
</div>
