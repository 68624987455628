import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IInteraction, GenericErrorHandler } from "../../../../core-ui";
import { InteractionService } from "../../../services";
import { AnalyticsService } from "../../../../core-ui/services/analytics.service";
import { IShareLinkAction } from "../share-link.component";

@Component({
  selector: "app-share-link-widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.scss"],
})
export class ShareLinkWidgetComponent implements OnInit, IShareLinkAction {
  @Input() interaction: IInteraction;
  @Input() ticket: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private interactionService: InteractionService,
    private analyticsService: AnalyticsService,
    private errorHandler: GenericErrorHandler
  ) {}

  ngOnInit(): void {}

  shareLink() {
    try {
      this.interactionService.invite(
        this.interaction.getType(),
        this.interaction,
        this.ticket
      );
      // this.analyticsService.trackInvitationSent(this.interaction);
      // this.renewCustomerLink();
      this.completed.emit();
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }
}
