import {
  IConversationAuthClient,
  IConversationAuthCode,
} from "@auvious/integrations";

export class NoAuthClient implements IConversationAuthClient {
  getRestorableState: () => object;
  restoreState: (state: object) => void;
  authenticate(code: IConversationAuthCode) {
    return undefined;
  }
  getAuthCode(...params: any[]) {
    return undefined;
  }
  async register() {
    return undefined;
  }
  async logout() {}
}
