/* eslint-disable compat/compat */
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import {
  ApiResource,
  AuviousCommon,
  Event,
  IAuviousClientArgs,
} from "@auvious/common";
import {
  AuviousRtc,
  AuviousRtcFactory,
  IConference,
  ICreateConferenceOptions,
  IEndpoint,
  IRegisterOptions,
} from "@auvious/rtc";
import { MediaDevices } from "@auvious/media-tools";

import { ErrorPageCode } from "../../app/app.enums";
import { EventClient } from "../models";
import { IEvent } from "../models/IEvent";
import { AppConfigService } from "./app.config.service";
import { GenericErrorHandler } from "./error-handlers.service";
import { debug, debugError } from "./utils";
import { Timers } from "@auvious/utils";

@Injectable()
export class AuviousRtcService {
  private auviousCommonClient: AuviousCommon;
  userEndpointApiResource: ApiResource;

  client: AuviousRtc;
  private eventService: EventClient;

  private eventObservable: Observable<Event>;
  private eventObservableAvailable: Subject<Observable<Event>> =
    new ReplaySubject();

  private commonSubject: Subject<AuviousCommon>;
  private rtcSubject: Subject<AuviousRtc>;

  private _connectionRegistered = new Subject<IEndpoint>();
  public connectionRegistered$ = this._connectionRegistered.asObservable();

  private conferenceResource: ApiResource;

  constructor(
    private config: AppConfigService,
    private errorHandler: GenericErrorHandler,
    router: Router
  ) {
    try {
      this.client = AuviousRtcFactory.create();
      this.commonSubject = new BehaviorSubject(null);
      this.rtcSubject = new BehaviorSubject(this.client);

      MediaDevices.setConstraints("auvious.rtc", {
        video: {
          // width: { ideal: 640 },
          // height: { ideal: 360 },
          // width: { ideal: 960 },
          // height: { ideal: 540 },
          width: { ideal: 1280 }, //
          height: { ideal: 720 }, //  for 16/9,  960 for 4/3
          // aspectRatio: 16 / 9, // not seems to be working
          frameRate: 30,
        },
      });

      // send a keepalive request manually just to check that the tokens are still valid
      window.addEventListener("online", () => {
        this.manualKeepAlive();
      });
    } catch (ex) {
      debugError(ex);
      router.navigate(["/error", ErrorPageCode.GENERIC_ERROR]);
    }
  }

  public get common$(): Observable<AuviousCommon> {
    return this.commonSubject?.pipe(filter((value) => !!value));
  }

  public get rtc$(): Observable<AuviousRtc> {
    return this.rtcSubject?.pipe(filter((value) => !!value));
  }

  public getAuviousCommonClient(): AuviousCommon {
    return this.auviousCommonClient;
  }

  /**
   *
   * @param userId the user id, aka username or 'sub' claim
   * @param userEndpointId the user endpoint id, use null to send to all active user endpoints
   * @param event the event conforming to interface IEvent
   */
  public async sendEventMessage(
    userId: string,
    userEndpointId: string,
    event: IEvent
  ) {
    const body = { userId, userEndpointId, event };
    const messagesSent = await this.userEndpointApiResource.create(body, {
      urlPostfix: "sendEvent",
    });
    return messagesSent;
  }

  public subscribeToEventTopic(topic: string): Observable<Event> {
    this.eventService.subscribeToTopic(topic);
    return this.eventObservable;
    // cannot use this right now, e.topic is undefined
    // return this.eventObservable.pipe(
    //     filter((e) => e.topic === topic)
    // );
  }

  public async createAuviousCommonClient(): Promise<AuviousCommon> {
    const assetsLocation = "/assets/scripts";

    const timers = new Timers(assetsLocation);
    await timers.init();

    const auviousClientArgs: IAuviousClientArgs = {
      serverUrl: null,
      mqttUrl: this.config.mqttUrl,
      saveAuthTokenState: false,
      useStandardOAuth2: !this.config.auviousAuth,
      eventServerJwtOn: true,
      refreshEventClientConnectionOnUpdatedCredentials: false,
      assetsLocation,
      timers,
    };

    if (!this.auviousCommonClient) {
      this.auviousCommonClient = new AuviousCommon(auviousClientArgs);
      this.commonSubject.next(this.auviousCommonClient);
      this.userEndpointApiResource =
        this.auviousCommonClient.apiResourceFactory("rtc-api/users/endpoints");
      this.eventService = new EventClient(this.auviousCommonClient);
      this.eventObservable = this.eventService.connect();
      this.eventObservableAvailable.next(this.eventObservable);
      this.eventObservableAvailable.complete();
      this.conferenceResource = this.auviousCommonClient.apiResourceFactory(
        "rtc-api/conferences"
      );
    }

    return this.auviousCommonClient;
  }

  public getEventObservableAvailable(): Observable<Observable<Event>> {
    return this.eventObservableAvailable;
  }

  public async register(): Promise<AuviousCommon> {
    const options: IRegisterOptions = {
      auviousCommonClient: this.auviousCommonClient,
      serverKeepAliveReminderOn: false,
      eventHandlers: {
        // connecting: () => {
        //   debug("connecting");
        // },
        // connected: () => {
        //   debug("connected");
        // },
        connectionError: (error) => {
          this.errorHandler.notifyConnectionError(error);
        },
        registered: (me: IEndpoint) => {
          this._connectionRegistered.next(me);
        },
        disconnected: () => {
          debug("disconnected");
        },
        unregistered: () => {
          debug("unregistered");
        },
        registrationFailed: (e) => {
          debug("registration failed", e);
        },
        warning: (e) => {
          debug("warning", e);
        },
      },
      // keepAliveSeconds: 300,
    };

    if (this.client.registered()) {
      return this.auviousCommonClient;
    }

    await this.client.register(options);

    return this.auviousCommonClient;
  }

  public registered(): boolean {
    return this.client.registered();
  }

  public unregister(): Promise<void> {
    if (!this.client) {
      return Promise.reject("no client available");
    }
    return this.client.unregister();
  }

  public logout(): Promise<void> {
    return this.auviousCommonClient.logout();
  }

  public async createConference(
    options?: ICreateConferenceOptions
  ): Promise<IConference> {
    const metadata = {
      ...options.metadata,
      // eslint-disable-next-line
      participant_limit: String(this.config.participantLimit),
    };

    try {
      const conf = await this.client.createConference({
        ...options,
        metadata,
      });
      return conf;
    } catch (ex) {
      this.errorHandler.handleNotAuthenticatedError(ex);
    }
  }

  public async endConference(conferenceId: string, reason?: string) {
    this.conferenceResource.create(
      {
        conferenceId,
        reason,
        userEndpointId: this.myself.endpoint,
      },
      {
        urlPostfix: "/end",
      }
    );
  }

  private manualKeepAlive() {
    const body = { userEndpointId: this.client.endpoint() };
    this.userEndpointApiResource.create(body, {
      urlPostfix: "keepalive",
    });
  }

  public get myself(): IEndpoint {
    return {
      username: this.client.identity(),
      endpoint: this.client.endpoint(),
    };
  }

  public isFullscreen() {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
  }

  public exitFullscreen() {
    return (
      document.exitFullscreen?.() ||
      document.webkitExitFullscreen?.() ||
      document.mozCancelFullScreen?.() ||
      document.msExitFullscreen?.()
    );
  }

  public async requestFullscreen(element: HTMLElement) {
    return (
      element.requestFullscreen?.() ||
      element.webkitRequestFullscreen?.() ||
      element.msRequestFullscreen?.() ||
      element.mozRequestFullScreen?.()
    );
  }

  public async toggleFullScreen(
    elm = document.documentElement
  ): Promise<boolean> {
    try {
      if (this.isFullscreen()) {
        await this.exitFullscreen();
        return false;
      } else {
        await this.requestFullscreen(elm);
        return true;
      }
    } catch (ex) {
      this.errorHandler.handleError(ex);
      return false;
    }
  }

  public identity(): string {
    return this.client.identity();
  }

  public endpoint(): string {
    return this.client.endpoint();
  }
}
