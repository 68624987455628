import {
  Component,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ElementRef,
  OnDestroy,
  Optional,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { ThemeService } from "../../../../core-ui/services";
import { CardFooterComponent } from "../card-footer/card-footer.component";
import { CardComponent } from "../card/card.component";
import { TinyColor } from "@ctrl/tinycolor";
import { IThemeOptions } from "../../../../core-ui";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[avButton]",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() loading = false;
  @Input() color:
    | "primary"
    | "accent"
    | "basic"
    | "warn"
    | "link"
    | "success"
    | "danger" = "basic";
  @Input() ofType: "basic" | "flat" | "stroked" | "icon" = "flat";
  @Input() size: "md" | "sm" | "xs" | "xxs" | "block" | "lg" = "md";
  @Input() raised = false;

  public changes$ = new Subject<{ [propertyName: string]: SimpleChange }>();

  private subscriptions: Subscription;
  private theme: IThemeOptions;

  spinnerMode: string;
  isInCard: boolean;

  private spinnerModeMap = {
    card: {
      basic: {
        basic: "mode",
        primary: "mode",
        accent: "mode",
        success: "mode",
        warn: "mode",
        danger: "mode",
        link: "mode",
      },
      flat: {
        basic: "mode",
        primary: "light",
        accent: "light",
        success: "light",
        warn: "light",
        danger: "light",
        link: "mode",
      },
      stroked: {
        basic: "mode",
        primary: "mode",
        accent: "mode",
        success: "mode",
        warn: "mode",
        danger: "mode",
        link: "mode",
      },
      icon: {
        basic: "dark",
        primary: "light",
        accent: "light",
        success: "light",
        warn: "light",
        danger: "light",
        link: "mode",
      },
    },
    main: {
      basic: {
        basic: "theme-background",
        primary: "theme-background",
        accent: "theme-background",
        success: "theme-background",
        warn: "theme-background",
        danger: "theme-background",
        link: "theme-background",
      },
      flat: {
        basic: "dark",
        primary: "light",
        accent: "light",
        success: "light",
        warn: "light",
        danger: "light",
        link: "theme-background",
      },
      stroked: {
        basic: "theme-background",
        primary: "theme-background",
        accent: "theme-background",
        success: "theme-background",
        warn: "theme-background",
        danger: "theme-background",
        link: "theme-background",
      },
      icon: {
        basic: "dark",
        primary: "light",
        accent: "light",
        success: "light",
        warn: "light",
        danger: "light",
        link: "theme-background",
      },
    },
  };

  constructor(
    private themeService: ThemeService,
    public host: ElementRef<HTMLButtonElement>,
    private renderer: Renderer2,
    @Optional() private card?: CardComponent,
    @Optional() private cardFooter?: CardFooterComponent
  ) {
    this.subscriptions = new Subscription();
  }

  @HostBinding("class") get class() {
    return {
      "btn-loading": this.loading,
      "btn-in-card": this.isInCard,

      "btn-color-basic": this.color === "basic",
      "btn-color-primary": this.color === "primary",
      "btn-color-accent": this.color === "accent",
      "btn-color-warn": this.color === "warn",
      "btn-color-danger": this.color === "danger",
      "btn-color-link": this.color === "link",
      "btn-color-success": this.color === "success",

      "btn-type-basic": this.ofType === "basic",
      "btn-type-flat": this.ofType === "flat",
      "btn-type-stroked": this.ofType === "stroked",
      "btn-type-icon": this.ofType === "icon",

      "btn-size-xxs": this.size === "xxs",
      "btn-size-xs": this.size === "xs",
      "btn-size-sm": this.size === "sm",
      "btn-size-md": this.size === "md",
      "btn-size-lg": this.size === "lg",

      "btn-size-block": this.size === "block",

      "btn-raised": this.raised,
    };
  }

  ngOnInit(): void {
    this.isInCard = this.getIsInCard();

    this.subscriptions.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        this.theme = theme;
        this.spinnerMode = this.getSpinnerMode();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changes$.next(changes);
  }

  getIsInCard() {
    return (
      (!!this.card && this.card?.type === "container") ||
      (!!this.cardFooter && this.cardFooter?.type === "container")
    );
  }

  getSpinnerMode() {
    const container = this.isInCard ? "card" : "main";
    const result = this.spinnerModeMap[container][this.ofType]?.[this.color];
    switch (result) {
      case "theme-background":
        if (!this.theme?.backgroundColor) {
          return "light";
        }
        const color = new TinyColor(this.theme.backgroundColor);
        return color.isDark() ? "light" : "dark";
      case "mode":
        return this.theme?.darkMode ? "light" : "dark";
      default:
        return result;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
