import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  AuviousRtcService,
  CompositionService,
  debugError,
} from "../../services";
import {
  CompositionNotification,
  IComposition,
  PageableRequestBuilder,
} from "../../models";
import { ActivityIndicatorService } from "../../services/activity-indicator.service";
import { NotificationService } from "../../services/notification.service";
import { Event, PagedCollection } from "@auvious/common";
import { firstValueFrom, Subscription } from "rxjs";

@Component({
  selector: "app-composition-exports",
  templateUrl: "./composition-exports.component.html",
  styleUrls: ["./composition-exports.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositionExportsComponent implements OnInit {
  historyLoading = false;
  historyPager: PagedCollection<IComposition>;
  historyData: IComposition[] = [];
  currentPage = 0;
  subscriptions: Subscription = new Subscription();

  @Output() navigate: EventEmitter<string> = new EventEmitter();

  constructor(
    private activityService: ActivityIndicatorService,
    private compositionService: CompositionService,
    private alertService: NotificationService,
    private cd: ChangeDetectorRef,
    rtc: AuviousRtcService
  ) {
    firstValueFrom(rtc.getEventObservableAvailable()).then(
      (eventObservable) => {
        this.subscriptions.add(
          eventObservable.subscribe(this.handleEvent.bind(this))
        );
      }
    );
  }

  async ngOnInit() {
    await this.nextPageLoading(0);
  }

  async handleEvent(event: Event) {
    if (
      !event.payload ||
      event.payload.type !== "CompositionStateChangedEvent"
    ) {
      return;
    }
    const n = new CompositionNotification(event);
    const data = await this.nextPage(this.currentPage);
    if (!data) {
      return;
    }
    const updatedComposition = data.find((d) => d.id === n.getCompositionId());
    if (!updatedComposition) {
      return;
    }
    this.historyData = this.history.map((c) =>
      c.id === updatedComposition.id ? updatedComposition : c
    );
    this.cd.detectChanges();
  }

  async nextPageLoading(page: number) {
    this.activityService.loading(true);
    this.historyData = await this.nextPage(page);
    this.cd.markForCheck();
  }

  async nextPage(page: number): Promise<IComposition[]> {
    this.currentPage = page;
    const pageable = new PageableRequestBuilder()
      .page(page)
      .size(10)
      .sort("created,desc")
      .build();
    this.historyLoading = true;
    try {
      this.historyPager = await this.compositionService.query(pageable);
      return this.historyPager.content;
    } catch (ex) {
      this.alertService.error(ex.message || ex);
      debugError(ex);
      return null;
    } finally {
      this.activityService.loading(false);
      this.historyLoading = false;
      this.cd.detectChanges();
    }
  }

  go(conversationId) {
    this.navigate.emit(conversationId);
  }

  removed(composition: IComposition) {
    this.nextPageLoading(this.currentPage);
  }

  trackByFn(index: number, el: IComposition) {
    return el.id + el.state;
  }

  async cancelExport(composition: IComposition) {
    try {
      this.activityService.loading(true);
      await this.compositionService.cancelComposition(
        composition.conversationId,
        composition.id
      );
    } catch (ex) {
      this.alertService.error(ex.message || ex);
    } finally {
      this.activityService.loading(false);
    }
  }

  /* view getters */

  get history() {
    return this.historyData || [];
  }
}
