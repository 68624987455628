import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: "av-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  @Input() responsive = false;

  private subscriptions: Subscription;

  @HostBinding("class") get class() {
    return {
      "menu-responsive": this.responsive,
    };
  }
  constructor(private host: ElementRef<HTMLDivElement>) {
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    this.host.nativeElement.setAttribute("role", "menu");
  }
}
