import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ConversationService } from "../../../app/services/conversation.service";

@Component({
  selector: "app-conversation-input",
  templateUrl: "./conversation-input.component.html",
  styleUrls: ["./conversation-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationInputComponent implements OnInit {
  text: string;

  constructor(
    private conversation: ConversationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  send() {
    this.conversation.sendMessage(this.text, "standard");
    this.text = null;
    this.cd.detectChanges();
  }
}
