import { Component, OnInit } from "@angular/core";
import { ActivityIndicatorService } from "../../services/activity-indicator.service";
import { fadeOut, zoomInOut } from "../../core-ui.animations";

@Component({
  selector: "app-activity-indicator",
  templateUrl: "./activity-indicator.component.html",
  styleUrls: ["./activity-indicator.component.scss"],
  animations: [zoomInOut, fadeOut],
})
export class ActivityIndicatorComponent implements OnInit {
  visible: boolean;
  message: string;
  icon: string;
  backdropVisible: boolean;

  constructor(private activity: ActivityIndicatorService) {}

  ngOnInit() {
    this.activity.message$.subscribe((val) => (this.message = val));
    this.activity.isVisible$.subscribe((val) => (this.visible = val));
    this.activity.icon$.subscribe((val) => (this.icon = val));
    this.activity.backdrop$.subscribe((val) => (this.backdropVisible = val));
  }
}
