import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { IStream } from "@auvious/rtc";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { debug, VoiceDetectionService } from "../../services";
@Component({
  selector: "app-volume-indicator",
  templateUrl: "./volume-indicator.component.html",
  styleUrls: ["./volume-indicator.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumeIndicatorComponent implements AfterViewInit, OnDestroy {
  @ViewChild("volumeRef") volumeElem: ElementRef<HTMLDivElement>;

  @Input() stream: IStream;
  @Input() muted: boolean;

  mutliplier = 1.3;
  subscriptions: Subscription;
  level = 0;

  constructor(private voiceDetectionService: VoiceDetectionService) {
    this.subscriptions = new Subscription();
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.voiceDetectionService.volumeLevel$
        .pipe(filter((v) => v.streamId === this.stream.id))
        .subscribe((volume) => {
          if (this.volumeElem) {
            this.volumeElem.nativeElement.style.height =
              // volume.level * this.mutliplier + "px";
              volume.level + "%";
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
