import { INotificationEvent } from "../INotificationEvent";
import { NotificationTypeEnum } from "../../core-ui.enums";

export class ConversationNotification implements INotificationEvent {
  private _clickedCb: () => void;
  private _dismissedCb: () => void;
  constructor(
    private _title: string,
    private _body: string,
    private _id: string
  ) {}

  getId() {
    return this._id;
  }

  getTitle() {
    return this._title;
  }

  getType() {
    return NotificationTypeEnum.conversation;
  }

  getSentAt() {
    return new Date();
  }

  getBody() {
    return this._body;
  }

  getColor(): "danger" | "warning" | "info" | "success" | "container" {
    return "container";
  }

  getTimeToLive(): number {
    return 0;
  }

  canShow() {
    return true;
  }

  click() {
    this._clickedCb?.();
  }

  dismiss() {
    this._dismissedCb?.();
  }

  onDismissed(callback: () => void) {
    this._dismissedCb = callback;
  }

  onClicked(callback: () => void) {
    this._clickedCb = callback;
  }
}
