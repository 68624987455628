import {
  Directive,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ElementRef,
} from "@angular/core";

@Directive({
  selector: "[avFileDragDrop]",
})
export class FileDragDropDirective {
  @Input() avFileDragDrop: { enabled: boolean };
  @Input() avFileAllowedExtensions: string[];

  @Output() filesDropped: EventEmitter<File[]> = new EventEmitter();

  constructor(private host: ElementRef<HTMLDivElement>) {}

  @HostListener("dragover", ["$event"]) onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.avFileDragDrop.enabled) {
      this.host.nativeElement.classList.add("av-drag-over");
    }
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.avFileDragDrop.enabled) {
      this.host.nativeElement.classList.remove("av-drag-over");
    }
  }

  @HostListener("drop", ["$event"]) onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.avFileDragDrop.enabled) {
      this.host.nativeElement.classList.remove("av-drag-over");

      let files: FileList = event.dataTransfer.files;
      if (files.length > 0) {
        // add any validation required
        this.filesDropped.emit(Array.from(files));
      }
    }
  }

  private validateExtensions(files: FileList): boolean {
    if (this.avFileAllowedExtensions.length === 0) {
      return true;
    }
    let extensions: string[] = [];
    const extensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;

    // Array.from(files)
    //   .map(
    //     (x) => x.name.toLowerCase().match(extensionPattern)
    //     // //
    //   )
    //   .map((ext) => extensions.push(ext));

    const forbidden = extensions.filter(
      (x) => !this.avFileAllowedExtensions.includes(x)
    );
    const valid = forbidden.length === 0;
    return valid;
  }

  //   private validateExtensions(files: FileList): boolean {
  //     if (this.allowedExtensions.length == 0) return true;

  //     let extensions: string[] = [];
  //     const extensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
  //     Array.from(files).map(x =>
  //       x.name
  //         .toLowerCase()
  //         .match(extensionPattern)
  //         .map((ext) => extensions.push(ext))
  //     );

  //     const forbidden = extensions.filter(
  //       (x) => !this.allowedExtensions.includes(x)
  //     );
  //     const valid = forbidden.length == 0;
  //     return valid;
  //   }
}
