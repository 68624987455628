<div class="effect" [ngSwitch]="effect.type">
  <ng-container *ngSwitchCase="typeNone">
    <av-icon
      [attr.aria-label]="'Remove background effect' | translate"
      name="none"
    ></av-icon>
  </ng-container>
  <ng-container *ngSwitchCase="typeBlur">
    <av-icon
      [attr.aria-label]="'Blur background' | translate"
      name="background-blur"
    ></av-icon>
  </ng-container>
  <div
    *ngSwitchCase="typeImage"
    class="fx-row fx-align-center fx-justify-center full-height"
  >
    <img
      #image
      [attr.aria-label]="'Set photo as background' | translate"
      *ngIf="url"
      [src]="url"
      (error)="failed()"
    />
    <av-spinner *ngIf="!url" mode="dark" size="xs"></av-spinner>
  </div>
</div>
