<form (ngSubmit)="send()" class="fx-row fx-flex-1">
  <input
    type="text"
    placeholder="{{ 'Type a message...' | translate }}"
    required
    avInput
    name="message"
    [(ngModel)]="text"
  />
</form>
