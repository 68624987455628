import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";

import { IUser } from "../models/IUser";
import { IAuthenticationStrategy } from "../models/strategies";
import { ApplicationService } from "./application.service";
import { sessionStore } from "@auvious/utils";

@Injectable()
export class AuthenticationService implements IAuthenticationStrategy {
  constructor(private applicationService: ApplicationService) {}

  authenticate(route: ActivatedRouteSnapshot): Promise<IUser> {
    return this.applicationService
      .getActiveApplication()
      .authenticationStrategy()
      .authenticate(route);
  }

  removeAccessToken(route: ActivatedRouteSnapshot) {
    const code = route.queryParamMap.get("code");
    if (code) {
      sessionStore.removeItem(code);
    }
  }
}
