import { Injectable, Injector, Type } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { from, Observable, of } from "rxjs";
import { concatMap, first } from "rxjs/operators";
import { ISyncable } from "./ISyncable";

@Injectable()
export class SyncGuardHelper  {
  public constructor(public injector: Injector) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return from(route.data.guards).pipe(
      concatMap((value) => {
        const guard = this.injector.get<ISyncable>(value as Type<ISyncable>);
        const result = guard.canActivate(route, state);
        if (result instanceof Observable) {
          return result;
        } else if (result instanceof Promise) {
          return from(result);
        } else {
          return of(result);
        }
      }),
      first((x) => x === false || x instanceof UrlTree, true)
    );
  }
}
