import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  IQueue,
  NotificationService,
  UserService,
  extractErrorMessage,
} from "../../../../../core-ui";
import { InteractionService, PureCloudService } from "../../../../services";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  GenesysInteraction,
  GenesysInvitationChannelEnum,
  IGenesysWorkflowExecuteRequest,
  Interaction,
} from "../../../../models";
import {
  GENESYS_WORKFLOW_ADDRESS,
  GENESYS_WORKFLOW_AGENT_ID,
  GENESYS_WORKFLOW_AGENT_PARTICIPANT_ID,
  GENESYS_WORKFLOW_CONVERSATION_ID,
  GENESYS_WORKFLOW_MESSAGE,
  PHONE_REGEX,
} from "../../../../app.enums";
import { markFormFields } from "../../../../app.utils";

@Component({
  selector: "app-share-link-sms-number",
  templateUrl: "./number.component.html",
  styleUrls: ["./number.component.scss"],
})
export class ShareLinkSMSNumberComponent implements OnInit {
  @Input() interaction: GenesysInteraction;
  @Input() queue: IQueue;
  @Input() channel: GenesysInvitationChannelEnum;
  @Input() link: string;
  @Input() workflow: string;

  @Output() dismiss: EventEmitter<void> = new EventEmitter();
  @Output() completed: EventEmitter<void> = new EventEmitter();

  phonePattern = PHONE_REGEX;
  phoneNumber: string;
  isPostingSMS = false;

  constructor(
    private interactionService: InteractionService,
    private notification: NotificationService,
    private translate: TranslateService,
    private genesys: PureCloudService,
    private user: UserService
  ) {}

  ngOnInit(): void {
    this.phoneNumber = this.interaction.getCustomerPhoneNumber();
  }

  cancelSMS() {
    this.dismiss.emit();
  }

  async sendUsingWorkflow() {
    try {
      this.isPostingSMS = true;
      const request: IGenesysWorkflowExecuteRequest = {
        flowId: this.workflow,
        inputData: {
          [GENESYS_WORKFLOW_AGENT_ID]: this.user.getActiveUser().getId(),
          [GENESYS_WORKFLOW_AGENT_PARTICIPANT_ID]:
            this.interaction.getAgentId(),
          [GENESYS_WORKFLOW_CONVERSATION_ID]: this.interaction.getId(),
          [GENESYS_WORKFLOW_ADDRESS]: this.phoneNumber,
          [GENESYS_WORKFLOW_MESSAGE]: this.interaction.getCustomerLink(
            this.link
          ),
        },
      };
      await this.genesys.executeWorkflow(request);
      //
      this.notification.show("auvious", {
        body: this.translate.instant("Workflow executed"),
      });
      this.dismiss.emit();
      this.completed.emit();
    } catch (ex) {
      this.notification.error("Could not execute workflow", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isPostingSMS = false;
    }
  }

  async sendUsingQueue() {
    try {
      this.isPostingSMS = true;
      // only one queue, prepare email for that queue.
      const interaction = await this.interactionService.createSMSInteraction(
        this.queue.id,
        this.phoneNumber
      );
      // post sms to interaction
      const tmpInteraction = new Interaction(interaction.id);
      await this.interactionService.postMessageToInteraction(
        tmpInteraction,
        this.interaction.getCustomerLink(this.link),
        { type: "sms" }
      );
      // clean up
      // this.isPhoneNumberValidation = false;
      this.dismiss.emit();

      // this.renewCustomerLink();
      this.completed.emit();

      this.notification.show("auvious", {
        body: this.translate.instant(
          "Please check your interactions for a new SMS interaction."
        ),
      });
    } catch (ex) {
      this.notification.error(
        (ex.body && ex.body.message) || ex.message || "Could not prepare SMS"
      );
    } finally {
      this.isPostingSMS = false;
      // this.cdr.detectChanges();
    }
  }

  async sendSMS(form: UntypedFormGroup) {
    if (form.valid) {
      switch (this.channel) {
        case GenesysInvitationChannelEnum.queue:
          return this.sendUsingQueue();
        case GenesysInvitationChannelEnum.workflow:
          return this.sendUsingWorkflow();
      }
    } else {
      markFormFields(form);
    }
  }
}
