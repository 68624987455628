import { Injectable } from "@angular/core";
import { ClientService, IClient } from "@auvious/client";
import { IAuthClient } from "../models";
import { UserRoleEnum } from "../core-ui.enums";
import { ApiResource } from "@auvious/common";
import { AuviousRtcService } from "./rtc.service";

@Injectable()
export class AuthProviderService {
  constructor(private clientService: ClientService) {}

  async createClient(
    description: string,
    roles: string[]
  ): Promise<IAuthClient> {
    return this.clientService.create(description, roles);
  }

  listClients(): Promise<IAuthClient[]> {
    return this.clientService.list();
  }

  revokeClient(clientId: string) {
    return this.clientService.delete(clientId);
  }

  updateClient(clientId: string, description: string, roles?: string[]) {
    return this.clientService.update(clientId, description, roles);
  }
}
