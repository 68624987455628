import { Component, OnInit } from "@angular/core";
import { DeviceService } from "../../../core-ui";
import { UserService } from "../../../core-ui/services/user.service";

@Component({
  selector: "app-thank-you",
  templateUrl: "./thank-you.component.html",
  styleUrls: ["./thank-you.component.scss"],
})
export class ThankYouComponent implements OnInit {
  constructor(
    private deviceService: DeviceService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.getActiveUser()?.unauthenticate();
  }
}
