<av-search class="fx-flex-1" (queryChange)="queryChange($event)"></av-search>
<div role="list" class="filter-container">
  <av-filter-item
    role="listitem"
    [attr.aria-label]="contactNames()?.[member.id]"
    [checked]="isChecked(member.id)()"
    [value]="member"
    (changed)="selectionChanged($event)"
    *ngFor="let member of contactsFiltered()"
  >
    {{ contactNames()?.[member.id] }}
  </av-filter-item>
  <button
    [attr.aria-label]="'Load more users' | translate"
    *ngIf="!isLastPage()"
    avButton
    size="block"
    [loading]="loading()"
    (click)="more()"
    translate
  >
    Load more
  </button>
  <div *ngIf="isEmptySearch()" class="container-pad-sm text-muted" translate>
    No results found
  </div>
</div>
