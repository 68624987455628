import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, Subscription, zip } from "rxjs";
import { timeout } from "rxjs/operators";

import { MediaFilterTypeEnum } from "../../core-ui.enums";
import { IMediaFilter } from "../../models";
import {
  GenericErrorHandler,
  KeyboardService,
  KeyCodes,
  MediaEffectsService,
} from "../../services";
import { debug } from "../../services/utils";

@Component({
  selector: "app-background-effect",
  templateUrl: "./background-effect.component.html",
  styleUrls: ["./background-effect.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundEffectComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() effect: IMediaFilter;
  @Input() active: boolean;

  @Output() selected: EventEmitter<IMediaFilter> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() error: EventEmitter<IMediaFilter> = new EventEmitter();

  @ViewChild("image") imageRef: ElementRef<HTMLImageElement>;

  typeNone = MediaFilterTypeEnum.none;
  typeBlur = MediaFilterTypeEnum.backgroundBlur;
  typeImage = MediaFilterTypeEnum.backgroundImage;

  url: string;

  private subscription: Subscription = new Subscription();
  private viewInitSubject: Subject<void> = new Subject();
  private urlLoaded: Subject<string> = new Subject();

  constructor(
    private effects: MediaEffectsService,
    private cd: ChangeDetectorRef,
    private errorHandler: GenericErrorHandler
  ) {}

  ngOnInit(): void {
    // wait for all observables to complete in order to apply existing effect
    // try to fix bug when effect was applied before image url was loaded
    zip(
      this.viewInitSubject.asObservable(),
      this.urlLoaded.asObservable()
    ).subscribe(() => {
      if (this.active) {
        this.effects.setFilter(this.effect, this.imageRef?.nativeElement);
      }
    });

    if (this.effect.type === MediaFilterTypeEnum.backgroundImage) {
      this.subscription.add(
        this.effect.url?.pipe(timeout({ first: 10000 })).subscribe({
          next: (url) => {
            debug("bkg-effect: url loaded ", url, this.effect);

            this.url = url;
            this.cd.detectChanges();

            this.urlLoaded.next(url);
          },
          error: (error) => {
            debug("bkg-effect: url timed out ", this.url, this.effect);
            this.errorHandler.handleError(error);

            if (!this.url) {
              this.error.emit(this.effect);
            }
          },
        })
      );
    } else {
      this.urlLoaded.next(null);
    }
  }

  ngAfterViewInit() {
    this.viewInitSubject.next();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    debug("bkg-effect: destroyed", this.effect);
  }

  failed() {
    debug("bkg-effect: failed", this.effect);
    this.error.emit(this.effect);
  }

  @HostBinding("class") get class() {
    return {
      active: this.active,
    };
  }

  @HostListener("keyup", ["$event"])
  onSpaceKey(event: KeyboardEvent) {
    if (
      KeyCodes.Space === event.code ||
      KeyCodes.Enter === event.code ||
      KeyCodes.NumpadEnter === event.code
    ) {
      this.click();
    }
  }

  @HostListener("click")
  click() {
    if (
      (this.effect.type === this.typeImage && this.url) ||
      this.effect.type !== this.typeImage
    ) {
      this.effects.setFilter(this.effect, this.imageRef?.nativeElement);
      this.selected.emit(this.effect);
    }
  }
}
