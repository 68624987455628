<app-waiting
  *ngIf="!!startAt && !isError"
  [startAt]="startAt"
  [theme]="theme"
  [timezone]="timezone"
  [queueName]="queueName"
></app-waiting>
<div *ngIf="isDebug">state: {{ state() }}</div>
<div class="text-center container-pad-md" *ngIf="!startAt && !isLoading">
  <h1 translate>Oups!</h1>
  <h3 translate>Appointment not found...</h3>
  <p translate>
    Most probably the meeting was cancelled and our notification hasn't reached
    you yet. We are sorry for any inconvenience created.
  </p>
</div>

<div class="text-center container-pad-md" *ngIf="isError">
  <h1 translate>Something unexpected happened...</h1>
  <h3 translate>
    We could not communicate with the service. We are sorry for any
    inconvenience created.
  </h3>
  <p *ngIf="errorMessage">{{ "Reason" | translate }}: {{ errorMessage }}</p>
  <!-- <av-card type="danger" class="error">
      <av-card-header>{{'Something unexpected happened...' | translate}}</av-card-header>
        <av-card-body>
        <h3 translate>We could not communicate with the service. We are sorry for any inconvenience created.</h3>
        <p *ngIf="errorMessage">{{'Reason'| translate}}: {{errorMessage}}</p>
        </av-card-body>
    </av-card> -->
</div>
