export interface IRecorderOptions {
  conversationId: string;
  conferenceId: string;
  applicationId: string;
  audio: boolean;
  video: boolean;
}

export interface IRecorderResponse extends IRecorderOptions {
  instanceId: string;
  recorderId: string; // topic for websocket
  storageProvider: string;
  state: RecorderStateEnum;
  started: boolean;
  recovered: boolean;
  stopped: boolean;
  error?: IRecorderError;
  streams: [];
}

export interface IRecorderInfo {
  instanceId: string;
  recorderId: string;
  conversationId: string;
  state: RecorderStateEnum;
  error?: IRecorderError;
}

export interface IRecorderCobrowseInfo {
  cobrowseSessions: {
    cobrowseSessionId: string;
    recorderId: string;
    recorderInstanceId: string;
    uploaded: string;
  }[];
  conversationId: string;
}

export interface IRecorderCobrowseUrl {
  url: string;
  validUntil: string;
}

export interface IRecorderMessage {
  id?: string;
  sentAt?: string;
  senderId?: string;
  message: {
    type: "RECORDING";
    payload: IRecorderInfo;
  };
}

export interface IRecorderError {
  code: string;
  occured: string;
  message: string;
}

// eslint-disable-next-line no-shadow
export enum RecorderStateEnum {
  loading = "LOADING",
  initialised = "INITIALISED",
  active = "ACTIVE",
  failed = "FAILED",
  aborted = "ABORTED",
  stopped = "STOPPED",
}

export interface IRecorderSessionInfo {
  applicationId: string;
  provider: string;
  url: string;
}

// export interface IRecorderStateChangedEvent {
//     conversationId: string;
//     instanceId: string;
//     recorderId: string;
//     recorderState: IRecorderState;
//     timestamp: string;
// }
