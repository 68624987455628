import { UserService } from "../services";
import { ActivityIndicatorService } from "../services/activity-indicator.service";
import { AppConfigService } from "../services/app.config.service";
import { NotificationService } from "../services/notification.service";
import { AppInjector } from "./AppInjector";

export class BasePage {
  private _loading = false;
  protected activity: ActivityIndicatorService;
  protected config: AppConfigService;
  protected notification: NotificationService;
  protected userService: UserService;
  constructor() {
    this.activity = AppInjector.injector.get(ActivityIndicatorService);
    this.config = AppInjector.injector.get(AppConfigService);
    this.notification = AppInjector.injector.get(NotificationService);
    this.userService = AppInjector.injector.get(UserService);
  }

  set loading(value) {
    this._loading = value;
    this.activity.loading(value);
  }

  get loading() {
    return this._loading;
  }

  get lang() {
    return this.config.language;
  }
}
