<ng-container [ngSwitch]="asset.category">
  <div class="asset-container" *ngSwitchCase="categoryImage">
    <img
      (load)="loaded()"
      *ngIf="!!url"
      (error)="error()"
      #image
      [class.loaded]="isLoaded"
      [src]="url"
    />

    <av-spinner class="loader" *ngIf="isLoading" size="xs"></av-spinner>
  </div>

  <ng-container *ngSwitchCase="categoryDocument">
    <div class="pill">{{ asset.filename || asset.id }}</div>
  </ng-container>
</ng-container>

<button
  class="remove"
  avButton
  [raised]="true"
  size="xxs"
  ofType="icon"
  *ngIf="isLoaded"
  [loading]="isLoading"
  (click)="remove()"
  data-tid="asset/remove"
>
  <av-icon name="trash"></av-icon>
</button>

<ng-content></ng-content>
