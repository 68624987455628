import { Component, OnInit, Input, HostBinding } from "@angular/core";

@Component({
  selector: "av-card-row",
  templateUrl: "./card-row.component.html",
  styleUrls: ["./card-row.component.scss"],
})
export class CardRowComponent implements OnInit {
  @Input() size: "md" | "sm" | "xs" = "md";

  constructor() {}

  @HostBinding("class") get class() {
    return { ["av-card-row-size-" + this.size]: true };
  }

  ngOnInit(): void {}
}
