import { ActivatedRouteSnapshot } from "@angular/router";
import { IUserDetails } from "../../core-ui/models/IUser";
import { GenesysCloudMemberDirection, GenesysCloudMemberRole, GenesysCloudMemberState } from "../app.enums";
import { ConversationChannelEnum, ConversationOriginEnum } from "../../core-ui";
import { OriginModeEnum } from "@auvious/integrations";

export interface IGenesysPageableResponse<T> {
  entities: T[];
  firstUri: string;
  lastUri: string;
  selfUri: string;
  pageCount: number;
  pageNumber: number;
  pageSize: number;
  total: number;
}

export interface IGenesysPageableWebMessagingCollection<T> {
  entities: T[];
}

export interface IGenesysWebChatMessage {
  body?: string;
  bodyType: "member-join" | "member-leave" | "notice" | "text" | "standard";
  conversation: {
    id: string;
    selfUri: string;
  };
  id: string;
  selfUri: string;
  sender: {
    id: string;
  };
  timestamp: string;
}

export interface IGenesysWebMessagingMessage {
  conversationId: string;
  direction: string;
  fromAddress: string;
  id: string;
  messengerType: string;
  normalizedMessage: {
    channel: { [key: string]: string };
    direction: string;
    id: string;
    metadata: { [key: string]: string };
    text: string;
    type: string;
  };
  providerMessageId: string;
  selfUri: string;
  status: string;
  textBody: string;
  timestamp: string;
  toAddress: string;
}

export interface IGenesysWorkflowVersion {
  id: string;
  name: string;
  commitVersion: string;
  configurationVersion: string;
  secure: boolean;
}

export interface IGenesysEntity {
  id: string;
  name: string;
}

export interface IGenesysWorkflowSchema {
  type: string;
  title: string;
  description: string;
  properties: { [key: string]: string };
}
export interface IGenesysWorkflow {
  id: string;
  name: string;
  active: boolean;
  deleted: boolean;
  system: boolean;
  checkedInVersion: IGenesysWorkflowVersion;
  publishedVersion: {
    datePublished: string;
    generationId: string;
    inputSchema: IGenesysWorkflowSchema;
    outputSchema: IGenesysWorkflowSchema;
    supportedLanguages: { language: string; isDefault: boolean }[];
  } & IGenesysWorkflowVersion;
  currentOperation: {
    actionName: string;
    actionStatus: string;
    complete: boolean;
    errorDetails: string[];
    id: string;
    user: IGenesysEntity;
  };
  division: IGenesysEntity;
  inputSchema: IGenesysWorkflowSchema;
  outputSchema: IGenesysWorkflowSchema;
  supportedLanguages: { language: string; isDefault: boolean }[];
  type: "WORKFLOW"; // there are other types but we are only interested in workflow for now
}

export enum GenesysInvitationChannelEnum {
  queue = "queue",
  workflow = "workflow",
}

export interface IGenesysWorkflowExecuteRequest {
  inputData: {
    [key: string]: string;
  };
  flowId: string;
}

export interface IGenesysUser {
  isModerator: boolean;
  id: string;
  authenticate(route: ActivatedRouteSnapshot): Promise<IAuthData>;
}

export interface IGenesysAgent extends IUserDetails {
  acdAutoAnswer: boolean;
  division: {
    id: string;
    name: string;
    selfUri: string;
  };
  disconnectType?: "transfer" | "client";

  id: string;
  connectedTime: string;
  images: { imageUri: string; resolution: string }[];
  user: { id: string };
  queue: { id: string };
  selfUri: string;
  state: GenesysCloudMemberState;
  username: string;
  purpose: GenesysCloudMemberRole;
  wrapup?: { id: string };
  organization?: {
    defaultCountryCode: string;
    defaultLanguage: string;
    defaultSiteId: string;
    deletable: boolean;
    domain: string;
    features: any;
    id: string;
    name: string;
    productPlatform: string;
    selfUri: string;
    state: string;
    thirdPartyOrgId: string;
    thirdPartyOrgName: string;
    voicemailEnabled: false;
  };
}

/**
 *
 */

export interface IGenesysQueue {
  connectedTime: string;
  disconnectType?: string;
  endTime: string;
  held: boolean;
  id: string;
  initialState: GenesysCloudMemberState;
  name: string;
  provider: string;
  purpose: string;
  state: GenesysCloudMemberState;
  wrapupRequired: boolean;
}

export interface IAuthData {
  room: string;
  participant: string;
  applicationId?: string;
  // error?: number;
}

export interface ICallbackRequest {
  // scriptId: String,
  queueId: string;
  routingData: {
    queueId?: string;
    // languageId: String,
    // priority: Number,
    // skillIds: [String],
    preferredAgentIds?: string[];
  };
  callbackUserName: string;
  callbackNumbers: string[];
  callbackScheduledTime: string;
  // countryCode: String,
  // validateCallbackNumbers: Boolean,
  data: { [key: string]: string };
}

export interface IGenesysInstanceInfo {
  name: string;
  type: string;
  url: string;
  groups?: string[];
  advanced?: any;
  communicationTypeFilter?: string;
  sandbox?: string;
  queueIdFilterList?: string[];
}

export interface IGenesysConversationSummary {
  agent: any;
  customer: any;
  conversation: any;
  interaction: any;
  callback: any;
  legacy: boolean;
  origin: ConversationOriginEnum;
  mode: OriginModeEnum;
  channel: ConversationChannelEnum;
  appointmentId: string;
}

export interface IGenesysConversation {
  id: string;
  startTime: string;
  endTime: string;
  divisions: any[];
  participants: IGenesysConversationParticipant[];
}

export interface IGenesysConversationParticipant {
  id: string;
  name: string;
  userId: string;
  purpose: GenesysCloudMemberRole;
  attributes: any;
  queueId: string;
  queueName: string;
  wrapup?: {
    code: string;
    durationSeconds: number;
    endTime: string;
    notes: string;
  };

  chats?: IGenesysConversationParticipantChannel[];
  calls?: IGenesysConversationParticipantChannel[];
  messages?: IGenesysConversationParticipantChannel[];
  callbacks?: IGenesysConversationParticipantChannel[];
  emails?: IGenesysConversationParticipantChannel[];
}

export interface IGenesysConversationParticipantChannel {
  id: string;
  state: GenesysCloudMemberState;
  type: string;
  disconnectType: string;
  connectedTime: string;
  held: boolean;
}

export interface IGenesysConversationDetails {
  conversationId: string;
  conversationStart: string;
  conversationEnd: string;
  divisionIds: string[];
  originatingDirection: string;
  participants: {
    participantId: string;
    participantName: string;
    purpose: GenesysCloudMemberRole;
    sessions: {
      sessionId: string;
      direction: GenesysCloudMemberDirection;
    }[];
  }[];
}
