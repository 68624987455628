import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subscription } from "rxjs";
import {
  SQ_VIDEO_BITRATE_INDEX,
  HQ_VIDEO_BITRATE_INDEX,
  LQ_VIDEO_BITRATE_INDEX,
  VIDEO_BITRATE_OPTIONS,
  StreamTrackKindEnum,
} from "../../core-ui.enums";
import { ConferenceService, debugError, StreamState } from "../../services";
import { TileComponent } from "../tile/tile.component";
import { MediaRulesService } from "../../services/media.rules.service";
import { IPublishedStream } from "@auvious/rtc";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "app-bitrate-picker",
  templateUrl: "./bitrate-picker.component.html",
  styleUrls: ["./bitrate-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BitratePickerComponent implements OnInit {
  @Input() stream: StreamState;

  @Output() changed: EventEmitter<void> = new EventEmitter();

  bitrates = VIDEO_BITRATE_OPTIONS;

  qualities = this.bitrates.map((value, index) => {
    switch (index) {
      case LQ_VIDEO_BITRATE_INDEX:
        return { ...value, short: "LQ", long: "Low Quality" };
      case HQ_VIDEO_BITRATE_INDEX:
        return { ...value, short: "HQ", long: "High Quality" };
      case SQ_VIDEO_BITRATE_INDEX:
        return { ...value, short: "SQ", long: "Standard Quality" };
    }
  });

  activeQuality = this.qualities[SQ_VIDEO_BITRATE_INDEX];

  isOpen = false;

  subscription: Subscription = new Subscription();

  constructor(
    private mediaRules: MediaRulesService,
    private conference: ConferenceService,
    private notification: NotificationService,
    @Optional() private tile: TileComponent
  ) {}

  ngOnInit(): void {
    this.activeQuality = this.qualities.find(
      (q) => q.value === this.mediaRules.getQualityForStream(this.stream.id)
    );
  }

  async bitrateChange(option: {
    value: number;
    label: string;
    short: string;
    long: string;
  }) {
    try {
      this.activeQuality = option;
      this.isOpen = false;
      const stream = this.conference.getStream(
        this.stream.id
      ) as IPublishedStream;
      if (!stream) {
        throw new Error("published stream not found");
      }
      await stream.updateVideoBitrate(option.value);
      this.mediaRules.setStreamQuality(this.stream.id, option.value);
      this.changed.emit();
    } catch (ex) {
      this.notification.error("Bitrate change failed", {
        body: "Could not complete this action. Please try again",
      });
      debugError(ex);
    }
  }

  get tooltip() {
    return `${this.activeQuality.long}`;
  }

  get tooltipPosition() {
    return this.tile?.isLayoutFloating ? "bottom" : "right";
  }

  get position() {
    return this.tile?.isLayoutFloating ? "right" : "left";
  }

  get isDisabled() {
    return this.stream.video.state === 'muted' || this.stream.video.state === 'disabled';
  }
}
