import { AuviousRtcService } from "./rtc.service";
import { IMetricDatum } from "../models";
import { ApiResource } from "@auvious/common";
import { Injectable } from "@angular/core";
import {  MetricTypeEnum } from "../core-ui.enums";
import { GenericErrorHandler } from "./error-handlers.service";

@Injectable()
export class MetricsService {
  private metricResource: ApiResource;

  constructor(
    private rtcService: AuviousRtcService,
    private errorHandler: GenericErrorHandler
  ) {
    this.metricResource =
      this.rtcService.getAuviousCommonClient().apiResourceFactory(
        "api/reporting/metrics"
      );
  }

  public getYearly(
    type: MetricTypeEnum,
    startYear: number,
    endYear: number
  ): Promise<IMetricDatum[]> {
    const params = new URLSearchParams();
    params.set("startYear", String(startYear));
    params.set("endYear", String(endYear));

    return this.metricResource
      .get({
        urlPostfix: `yearly/${type}`,
        params,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getWeekly(
    type: MetricTypeEnum,
    year: number
  ): Promise<IMetricDatum[]> {
    const params = new URLSearchParams();
    params.set("year", String(year));

    return this.metricResource
      .get({
        urlPostfix: `weekly/${type}`,
        params,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getTotal(type: MetricTypeEnum): Promise<IMetricDatum> {
    return this.metricResource
      .get({
        urlPostfix: `total/${type}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getMonthly(
    type: MetricTypeEnum,
    year: number
  ): Promise<IMetricDatum[]> {
    const params = new URLSearchParams();
    params.set("year", String(year));

    return this.metricResource
      .get({
        urlPostfix: `monthly/${type}`,
        params,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getDaily(
    type: MetricTypeEnum,
    year: number,
    month: number
  ): Promise<IMetricDatum[]> {
    const params = new URLSearchParams();
    params.set("year", String(year));
    params.set("month", String(month));

    return this.metricResource
      .get({
        urlPostfix: `daily/${type}`,
        params,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getHourly(
    type: MetricTypeEnum,
    year: number,
    month: number,
    day: number
  ): Promise<IMetricDatum[]> {
    const params = new URLSearchParams();
    params.set("year", String(year));
    params.set("month", String(month));
    params.set("day", String(day));

    return this.metricResource
      .get({
        urlPostfix: `hourly/${type}`,
        params,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }
}
