import { Injectable, NgZone } from "@angular/core";
import {
  ApiCommandResource,
  ApiQueryResource,
  Asr,
  IOrganizationLanguage,
  IProviderLanguages,
  OrganizationLanguage,
  ReconnectionReason,
  SpeechToTextProviderType,
} from "@auvious/asr";
import { ApiResource, AuviousCommon, PagedCollection } from "@auvious/common";
import { AuviousRtcService } from "./rtc.service";
import {
  ISpeechToTextTranscriptChunk,
  ISpeechToTextTranscriptStatus,
} from "../models/interfaces";
import { filter, Observable, Subject } from "rxjs";
import { ConferenceService } from "./conference.service";
import { LocalMediaService } from "./local.media.service";
import {
  ApplicationProviderTypeEnum,
  StreamTrackKindEnum,
  ASROfflineTranscriptEvent,
} from "../core-ui.enums";
import { NotificationService } from "./notification.service";
import { GenericErrorHandler } from "./error-handlers.service";
import { IProviderLanguage } from "../models";

export interface IASREventHandlers {
  ready?: () => void;
  reconnecting?: (payload: {
    delay: number;
    times: number;
    reason: ReconnectionReason;
  }) => void;
  reconnected?: () => void;
  transcript?: (transcript: {
    userId: string;
    transcript: string;
    isFinal: boolean;
  }) => void;
  transcriptFailed?: () => void;
  translationFailed?: () => void;
}

@Injectable()
export class ASRService {
  private apiCommand: ApiCommandResource;
  private apiQuery: ApiQueryResource;
  private instance: Asr;
  private common: AuviousCommon;
  private offlineResource: ApiResource;
  private asrOfflineEventSubject: Subject<{
    id: string;
    type: ASROfflineTranscriptEvent;
  }> = new Subject();

  private _chunk = new Subject<ISpeechToTextTranscriptChunk>();
  public chunk$ = this._chunk.asObservable();

  private _reset = new Subject<void>();
  public reset$ = this._reset.asObservable();

  private _enabled = new Subject<void>();
  public enabled$ = this._enabled.asObservable();

  private _disabled = new Subject<void>();
  public disabled$ = this._disabled.asObservable();

  constructor(
    rtc: AuviousRtcService,
    private local: LocalMediaService,
    private conference: ConferenceService,
    private notification: NotificationService,
    private logger: GenericErrorHandler,
    private zone: NgZone
  ) {
    rtc.common$.subscribe((c) => {
      this.apiCommand = new ApiCommandResource(c);
      this.apiQuery = new ApiQueryResource(c);
      this.common = c;
      this.offlineResource = c.apiResourceFactory("api/asr-offline");
    });

    this.local.streamMutedChange$
      .pipe(
        filter(
          (s) => s.trackKind === StreamTrackKindEnum.audio && this.isEnabled
        )
      )
      .subscribe(async (s) => {
        try {
          await this.setStream(
            s.muted || this.conference.isConferenceOnHold
              ? null
              : s.stream.mediaStream
          );
        } catch (ex) {
          this.logger.handleError(ex);
          this.notification.error("Closed Captions", {
            body: "Could not start captions service",
          });
        }
      });
  }

  public propagateEvent(event: {
    id: string;
    type: ASROfflineTranscriptEvent;
  }) {
    this.asrOfflineEventSubject.next(event);
  }

  public get eventReceived$(): Observable<{
    id: string;
    type: ASROfflineTranscriptEvent;
  }> {
    return this.asrOfflineEventSubject.asObservable();
  }

  public get query(): ApiQueryResource {
    return this.apiQuery;
  }

  public get cmd(): ApiCommandResource {
    return this.apiCommand;
  }

  public removeOrganizationLanguage(organizationLanguageId: string) {
    return this.offlineResource
      .delete({
        urlPostfix: "/language/" + organizationLanguageId,
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public createOrganizationLanguage(
    applicationId: string,
    language: IProviderLanguage
  ): Promise<{ organizationLanguageId: string }> {
    return this.offlineResource
      .create(
        {
          applicationId,
          languageCode: language.code,
          languageName: language.name,
          model: language.model,
          providerType: language.provider,
        },
        {
          urlPostfix: "/organization/language",
        }
      )
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public createTranscriptRequest(
    conversationId: string,
    organizationLanguageId: string
  ): Promise<{ conversationId: string; id: string }> {
    return this.offlineResource
      .create(
        {
          conversationId,
          organizationLanguageId,
        },
        { urlPostfix: "/request" }
      )
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public removeTranscript(transcriptId: string) {
    return this.offlineResource
      .delete({
        urlPostfix: "/transcript/" + transcriptId,
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public async getProviderLanguages(
    providerType: SpeechToTextProviderType,
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IProviderLanguages>> {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("size", pageSize.toString());

    return new PagedCollection<IProviderLanguages>(
      await this.offlineResource
        .get({
          params,
          urlPostfix: providerType + "/languages/available",
        })
        .catch((ex) => {
          this.logger.handleNotAuthenticatedError(ex);
          throw ex;
        })
    );
  }

  public getTranscriptForConversation(
    conversationId: string
  ): Promise<ISpeechToTextTranscriptStatus> {
    return this.offlineResource
      .get({
        urlPostfix: "/conversation/" + conversationId,
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public async getOrganizationLanguages(
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IOrganizationLanguage>> {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("size", pageSize.toString());

    return new PagedCollection<OrganizationLanguage>(
      await this.offlineResource
        .get({
          params,
          urlPostfix: "/organization/language",
        })
        .catch((ex) => {
          this.logger.handleNotAuthenticatedError(ex);
          throw ex;
        })
    );
    // return this.offlineResource.get({
    //   urlPostfix: "/organization/language",
    // });
  }

  public getTranscriptURL(
    conversationId: string,
    transcriptId: string,
    type: "inline" | "attachment"
  ): Promise<{ url: string; validUntil: string }> {
    return this.offlineResource
      .get({
        urlPostfix: `/player/${conversationId}/${transcriptId}/url/${type}`,
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public init(
    applicationId: string,
    conferenceId: string,
    handlers: IASREventHandlers
  ): void {
    this.instance = new Asr(this.common, {
      assetsPath: "/assets/asr/",
      applicationId,
      conferenceId,
    });
    this.instance.events.on(
      "ready",
      this.zone.run(() => handlers.ready)
    );
    this.instance.events.on(
      "reconnecting",
      this.zone.run(() => handlers.reconnecting)
    );
    this.instance.events.on(
      "reconnected",
      this.zone.run(() => handlers.reconnected)
    );
    this.instance.events.on("transcript", (e: ISpeechToTextTranscriptChunk) => {
      this.zone.run(() => {
        this._chunk.next(e);
        handlers.transcript?.(e);
      });
    });
    this.instance.events.on(
      "transcriptFailed",
      this.zone.run(() => handlers.transcriptFailed)
    );
    this.instance.events.on(
      "translationFailed",
      this.zone.run(() => handlers.translationFailed)
    );
  }

  /**
   * wait for 'ready' event, possible 'reconnecting' too
   *
   * @param organizationLanguageId  string
   * @param record boolean | optional. Record transcript or not. Storage provider must exist
   * @param translate boolean | optional. Translate transcript or not
   */
  public async enable(
    organizationLanguageId: string,
    record?: boolean,
    translate?: boolean
  ): Promise<void> {
    try {
      await this.instance.enable(organizationLanguageId, translate, record);
      this._enabled.next();
    } catch (ex) {
      throw ex;
    }
  }

  // public startTranslation(languageCode: string) {
  //   this.instance.startTranslate(languageCode);
  // }

  // public stopTranslation() {
  //   this.instance.stopTranslate();
  // }

  public disable(notify = true): Promise<void> {
    if (notify) {
      this._reset.next();
      this._disabled.next();
    }
    return this.instance?.disable();
  }

  public get isEnabled() {
    return this.instance?.isEnabled();
  }

  public get isInitialised() {
    return !!this.instance;
  }

  /**
   * Changes stream set for transcription.
   * If null or no audio tracks included, transcription is paused and should be called again with valid stream to resume.
   * if mediastream changes, set it again, with small interruptions.
   *
   * @param stream MediaStream
   * @returns boolean
   */
  public async setStream(stream: MediaStream): Promise<boolean> {
    return this.instance.setStream(stream);
  }

  public async destroy(conferenceId?: string) {
    await this.disable();
    if (!this.instance) {
      return;
    }
    this.instance.events.clear();
    this.instance = undefined;
    if (conferenceId) {
      // destroys transcriptId
      this.cmd.cleanUserConfiguration(conferenceId);
    }
  }
}
