import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "av-card-row-column",
  templateUrl: "./card-row-column.component.html",
  styleUrls: ["./card-row-column.component.scss"],
})
export class CardRowColumnComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() tooltip: string;

  constructor() {}

  ngOnInit(): void {}
}
