import { Injectable } from "@angular/core";
import { IEndpoint } from "@auvious/rtc";
import {
  FileTransfer,
  FileTransferService as RTCFileTransferService,
  TransferOptions,
} from "@auvious/filetransfer";
import { FileTransferNotification, IInteraction } from "../models";
import { ApplicationService } from "./application.service";
import { NotificationService } from "./notification.service";
import { AuviousRtcService } from "./rtc.service";
import { MAX_FILE_SIZE_UPLOAD } from "../core-ui.enums";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class FileTransferService {
  constructor(
    private transferService: RTCFileTransferService,
    private applicationService: ApplicationService,
    private notificationService: NotificationService,
    private rtcService: AuviousRtcService,
    private translate: TranslateService
  ) {
    this.transferService.incoming().subscribe((file) => {
      const notification = new FileTransferNotification(file);
      this.notificationService.fileReceived(notification);
    });
  }

  async sendFileToParticipant(
    file: File,
    participant: IEndpoint,
    interaction: IInteraction
  ) {
    let id;
    try {
      if (file.size > MAX_FILE_SIZE_UPLOAD) {
        return this.notificationService.warn("File size too large", {
          body: "The maximum file size is 100MB.",
        });
      }

      id = this.notificationService.info("Please wait", {
        ttl: 0,
        body: `${this.translate.instant("Uploading")}  ${file.name}...`,
      });
      await this.sendFile(file, participant, interaction);
      this.notificationService.success("File sent successfully");
    } catch (ex) {
      this.notificationService.error("File upload failed", {
        body: ex.message || ex,
      });
    } finally {
      this.notificationService.dismiss(id);
    }
  }

  async sendFile(
    blob: File,
    target: IEndpoint,
    interaction: IInteraction
  ): Promise<FileTransfer> {
    const options: TransferOptions = {
      blob,
      filename: blob.name,
      targetId: target.username,
      targetType: "user",
      applicationId: this.applicationService.getActiveApplication().getId(),
      conferenceId: interaction.getRoom(),
      interactionId: interaction.getId(),
    };

    return this.transferService.transfer(options);
  }

  async getFilesOfConference(
    conferenceId: string,
    notify = true
  ): Promise<FileTransfer[]> {
    const files = await this.transferService.findByConferenceId(conferenceId);
    if (notify) {
      this.notify(files);
    }
    return files;
  }

  async getFilesOfInteraction(
    interactionId: string,
    notify = true
  ): Promise<FileTransfer[]> {
    const files = await this.transferService.findByInteractionId(interactionId);
    if (notify) {
      this.notify(files);
    }
    return files;
  }

  async getFilesOfInteractionFiltered(
    interactionId: string,
    notify = true
  ): Promise<FileTransfer[]> {
    const files = await this.transferService.findByInteractionId(
      interactionId,
      true
    );
    if (notify) {
      this.notify(files);
    }
    return files;
  }

  private notify(files: FileTransfer[]) {
    files
      ?.filter((file) => file.targetId === this.rtcService.identity())
      .forEach((file) => {
        this.notificationService.fileReceived(
          new FileTransferNotification(file)
        );
      });
  }
}
