import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
} from "@angular/core";
import { IEndpoint, INetworkQualityReportEvent } from "@auvious/rtc";
import { ConferenceService, isDebugOn } from "../../services";
import { zoomInOut } from "../../core-ui.animations";
import { Subscription } from "rxjs";
import { TileComponent } from "../tile/tile.component";
import { OnDestroy } from "@angular/core";

@Component({
  selector: "app-network-indicator",
  templateUrl: "./network-indicator.component.html",
  styleUrls: ["./network-indicator.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [zoomInOut],
})
export class NetworkIndicatorComponent implements OnInit, OnDestroy {
  @Input() endpoint: string;

  private subscriptions: Subscription;
  private report: INetworkQualityReportEvent;

  constructor(
    private conferenceService: ConferenceService,
    private cdr: ChangeDetectorRef,
    @Optional() private tile: TileComponent
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.conferenceService.networkQualityReport$.subscribe((report) => {
        this.report = report;
        this.cdr.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get isVisible() {
    return true; /*!!this.quality;*/
  }

  get position() {
    return !!this.tile ? "right" : "bottom";
  }

  get quality() {
    return (
      this.report?.participantIndicatorMap[this.endpoint] || {
        averageJitter: 0,
        averageNetworkQuality: 100,
        averageRtt: 0,
        grade: "OPTIMAL",
      }
    );
  }

  get isDebug() {
    return isDebugOn();
  }

  get isGood() {
    return (
      !this.quality || (!!this.quality && this.quality.grade === "OPTIMAL")
    );
  }

  get isLow() {
    return !!this.quality && this.quality.grade === "SUBOPTIMAL";
  }

  get isBad() {
    return !!this.quality && this.quality.grade === "BAD";
  }

  get jitter() {
    return !this.quality
      ? "n/a"
      : this.quality.averageJitter > 0
      ? this.quality.averageJitter
      : "0";
  }

  get packetLoss() {
    return !!this.quality ? 100 - this.quality.averageNetworkQuality : "0";
  }

  get rtt() {
    return !this.quality
      ? "n/a"
      : this.quality.averageRtt > 0
      ? this.quality.averageRtt
      : "0";
  }
}
