<section>
  <av-tooltip [enabled]="tooltip" [message]="tooltip">
    <small role="columnheader" class="text-muted" translate>
      <ng-content select="[column-label]"></ng-content>
      {{ label }}
    </small>
    <div>
      <ng-content select="[column-value]"></ng-content>
      {{ value }}
    </div>
  </av-tooltip>
</section>
