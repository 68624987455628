import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { isSupported, localStore, sessionStore } from "@auvious/utils";

import { ErrorPageCode } from "../../app.enums";

@Injectable()
export class AgentCompatibilityGuard  {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
  //   try{
  //   // Without storage authentication will not work. It actually could work, if we accepted whatever state we receive
  //   // without checking the stored state, but this leaves our app open to csrf attacks.
  //   if (!isSupported(localStorage) || !isSupported(sessionStore)) {
  //     return this.router.createUrlTree([
  //       "/error",
  //       ErrorPageCode.COOKIES_DISABLED,
  //     ]);
  //   }
  // } catch(ex){

  // }

    return true;
  }
}
