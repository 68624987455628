import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostBinding,
} from "@angular/core";
import { IAsset } from "@auvious/asset";
import { timeout } from "rxjs/operators";
import { StorageAssetService } from "../../../../core-ui/services";
import { NotificationService } from "../../../../core-ui/services/notification.service";
import { AssetCategoryEnum } from "../../../../core-ui/core-ui.enums";
import { ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "av-asset",
  templateUrl: "./asset.component.html",
  styleUrls: ["./asset.component.scss"],
})
export class AssetComponent implements OnInit {
  @Input() asset: IAsset;

  @Output() removed: EventEmitter<IAsset> = new EventEmitter();

  isLoading = true;
  isLoaded = false;

  categoryImage = AssetCategoryEnum.background;
  categoryDocument = AssetCategoryEnum.i18n;

  url: string;

  constructor(
    private storage: StorageAssetService,
    private notification: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  @HostBinding("class") get class() {
    return {
      "asset-category-image": this.asset.category === this.categoryImage,
      "asset-category-document": this.asset.category === this.categoryDocument,
    };
  }

  ngOnInit(): void {
    this.asset?.signedUrl.pipe(timeout(5000)).subscribe(
      (url) => {
        this.url = url;
        this.cd.detectChanges();
      },
      (error) => {
        if (!this.url) {
          this.error();
        }
      }
    );
    if (this.asset.category.includes(this.categoryDocument)) {
      this.loaded();
    }
  }

  loaded() {
    this.isLoading = false;
    this.isLoaded = true;
    this.cd.detectChanges();
  }

  error() {
    this.removed.emit(this.asset);
    this.cd.detectChanges();
  }

  async remove() {
    this.isLoading = true;
    try {
      await this.storage.deleteAsset(this.asset.id);
      this.removed.emit(this.asset);
    } catch (ex) {
      this.notification.error("Could not delete asset", {
        body: ex.message || ex,
      });
    } finally {
      this.isLoading = false;
      this.cd.detectChanges();
    }
  }
}
