import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IMediaPlayerPlayRequest } from "../models";

@Injectable()
export class MediaPlayerService {
  playRequestSubject: Subject<IMediaPlayerPlayRequest> = new Subject();

  constructor() {}

  play(request: IMediaPlayerPlayRequest) {
    this.playRequestSubject.next(request);
  }

  get playRequest$(): Observable<IMediaPlayerPlayRequest> {
    return this.playRequestSubject.asObservable();
  }
}
