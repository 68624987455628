import * as moment from "moment";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Input,
} from "@angular/core";

@Component({
  selector: "app-timer",
  templateUrl: "./timer.component.html",
  styleUrls: ["./timer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() countdown: string;

  hours: string;
  minutes: string;
  seconds: string;
  secondOff = false;

  private totalSeconds = 0;

  private timerInterval;
  private secondsInterval;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.countdown) {
      const duration = moment.duration(this.countdown);
      this.minutes = this.pad(duration.get("minutes"));
      this.seconds = this.pad(duration.get("seconds"));
      this.hours = null;
      this.totalSeconds = duration.asSeconds();
    } else {
      this.minutes = "00";
      this.seconds = "00";
      this.hours = null;
    }
    this.timerInterval = setInterval(() => this.setTime(), 1000);
  }

  ngOnDestroy() {
    clearInterval(this.timerInterval);
  }

  setTime() {
    if (this.totalSeconds <= 0 && this.countdown) {
      clearInterval(this.timerInterval);
      return;
    }
    this.countdown ? --this.totalSeconds : ++this.totalSeconds;

    this.seconds = this.pad(this.totalSeconds % 60);
    this.minutes = this.pad(Math.floor(Number(this.totalSeconds / 60)));
    const hours = Math.floor(Math.floor(Number(this.totalSeconds / 60)) / 60);
    if (hours > 0) {
      this.hours = this.pad(hours);
      this.minutes = this.pad(Math.floor(Number(this.totalSeconds / 60) % 60));
    }
    this.cd.detectChanges();
  }

  pad(val) {
    const valString = val + "";
    return valString.length < 2 ? "0" + valString : valString;
  }
}
