<av-card [backdrop]="false" class="cobrowse-launcher">
  <av-card-header
    subtitle="Send this PIN to your customer to start the session."
    (close)="close()"
  >
    <span *ngIf="isCobrowse" translate>Co-browse</span>
    <span *ngIf="isDisplayCapture" translate>Screen Share</span>
  </av-card-header>

  <av-card-body class="cobrowse-launcher-body" type="spotlight">
    <av-spinner *ngIf="!ticketId" mode="dark" size="md"></av-spinner>
    <span class="ticket">{{ ticketId }}</span>
  </av-card-body>
  <av-card-footer>
    <!-- <av-card type="info">
      <av-card-body type="thin">
        Please wait for the customer to connect.
      </av-card-body>
    </av-card> -->
    <button
      [attr.aria-label]="'Invite customer in a co-browse session' | translate"
      avButton
      [disabled]="!ticketId || connecting"
      [loading]="connecting"
      *ngIf="isChatAvailable"
      size="block"
      color="accent"
      (click)="invite()"
      translate
      data-tid="cobrowse/send-invitation"
    >
      <ng-container *ngIf="!connecting">Send invitation</ng-container>
      <ng-container *ngIf="connecting">Connecting...</ng-container>
    </button>

    <button
      [attr.aria-label]="'Invite customer in a co-browse session' | translate"
      avButton
      [disabled]="!ticketId || connecting"
      [loading]="connecting"
      *ngIf="canGenerateChatInvitation"
      size="block"
      color="accent"
      (click)="copyChatInvitation()"
      translate
      data-tid="cobrowse/copy-chat-invitation"
    >
      Copy invitation
    </button>

    <button
      [attr.aria-label]="'Copy co-browse PIN to clipboard' | translate"
      avButton
      [disabled]="!ticketId || connecting"
      size="block"
      (click)="copy()"
      translate
      data-tid="cobrowse/copy-pin"
    >
      Copy PIN
    </button>
  </av-card-footer>
</av-card>
