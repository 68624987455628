import { Injectable } from "@angular/core";

@Injectable()
export class MenuService {
  _unsavedChangesCb: () => Promise<void>;

  constructor() {}

  checkForUnsavedChanges(): Promise<void> {
    return this._unsavedChangesCb?.();
  }

  registerUnsavedChangesFn(fn: () => Promise<void>) {
    this._unsavedChangesCb = fn;
  }
}
