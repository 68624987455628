import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ApiResource } from "@auvious/common";

import { AuviousRtcService } from "./rtc.service";
import {
  IApplication,
  IApplicationConfig,
  IApplicationDTO,
} from "../models/IApplication";
import { ApplicationTypeEnum } from "../core-ui.enums";
import { firstValueFrom } from "rxjs";
import { GenericErrorHandler } from "./error-handlers.service";

@Injectable()
export class ApplicationService {
  private appResource: ApiResource;
  private activeApplication: IApplication;

  constructor(
    private rtcService: AuviousRtcService,
    private httpClient: HttpClient,
    private errorHandler: GenericErrorHandler
  ) {}

  createApiResource() {
    if (!this.rtcService.getAuviousCommonClient()) {
      throw new Error("Not Found: Auvious Common Client");
    }

    if (!!this.appResource) {
      return;
    }

    this.appResource = this.rtcService
      .getAuviousCommonClient()
      .apiResourceFactory("security");
  }

  get isTalkdeskApp() {
    return (
      this.activeApplication?.getType() === ApplicationTypeEnum.TalkdeskOpenID
    );
  }

  get isGenesysCloudApp() {
    return this.activeApplication?.getType() === ApplicationTypeEnum.GenesysCloud;
  }

  get isNiceApp() {
    return this.activeApplication?.getType() === ApplicationTypeEnum.NiceOpenID;
  }

  get isStandaloneApp() {
    return (
      this.activeApplication?.getType() === ApplicationTypeEnum.StandaloneOpenID
    );
  }

  getActiveApplication(): IApplication {
    return this.activeApplication;
  }

  setActiveApplication(application: IApplication) {
    this.activeApplication = application;
  }

  get(applicationId: string): Promise<IApplicationDTO> {
    if (!!this.appResource) {
      return this.appResource
        .get({
          urlPostfix: `applications/${applicationId}`,
        })
        .catch((ex) => {
          this.errorHandler.handleNotAuthenticatedError(ex);
          throw ex;
        });
    }

    return firstValueFrom(
      this.httpClient.get<IApplicationDTO>(
        `/security/applications/${applicationId}`
      )
    );
  }

  create(
    type: ApplicationTypeEnum,
    config: IApplicationConfig
  ): Promise<{ id: string }> {
    const app: IApplicationDTO = { type, config };
    return this.appResource
      ?.create(app, { urlPostfix: "applications" })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  update(
    applicationId: string,
    type: ApplicationTypeEnum,
    config: IApplicationConfig
  ): Promise<void> {
    const app: IApplicationDTO = { type, config };
    return this.appResource
      ?.update(app, {
        urlPostfix: `applications/${applicationId}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  remove(applicationId: string): Promise<void> {
    return this.appResource
      ?.delete({ urlPostfix: `applications/${applicationId}` })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }
}
