import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-page-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
