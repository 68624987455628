import { Event, Util } from "@auvious/common";
import { INotificationEvent } from "../INotificationEvent";
import {
  CompositionStateEnum,
  NotificationTypeEnum,
} from "../../core-ui.enums";

export class CompositionNotification implements INotificationEvent {
  private _type: NotificationTypeEnum;
  private _timestamp: Date;
  // private _userEndpointId: string;
  // private _userId: string;
  private _compositionId: string;
  private _compositionState: CompositionStateEnum;
  private _canShow: boolean;
  private _id: string;

  constructor(event: Event) {
    if (!event.payload) {
      return null;
    }
    try {
      this._id = Util.uuidgen();
      this._type = event.payload.type;
      this._timestamp = new Date(event.payload.timestamp);
      // this._userEndpointId = event.payload.userEndpointId;
      // this._userId = event.payload.userId;
      this._compositionId = event.payload.compositionId;
      this._compositionState = event.payload
        .compositionState as CompositionStateEnum;
      this._canShow = ![
        CompositionStateEnum.submitted,
        CompositionStateEnum.queued,
        CompositionStateEnum.initialized,
      ].includes(this._compositionState);
    } catch (ex) {
      return null;
    }
  }
  getId() {
    return this._id;
  }
  getType() {
    return this._type;
  }
  getSentAt() {
    return this._timestamp;
  }
  // getUserEndpointId() { return this._userEndpointId; }
  // getUserId() { return this._userId; }
  getCompositionId() {
    return this._compositionId;
  }
  getCompositionState() {
    return this._compositionState;
  }
  canShow() {
    return this._canShow;
  }
  getColor(): "danger" | "warning" | "info" | "success" | "container" {
    return "container";
  }
  getTitle() {
    return "Composition alert";
  }
  getBody() {
    switch (this._compositionState) {
      case CompositionStateEnum.completed:
        return "Composition has finished processing";
      case CompositionStateEnum.failed:
        return "Composition failed to process";
      case CompositionStateEnum.processing:
        return "Composition started processing";
      case CompositionStateEnum.cancelled:
        return "Composition cancelled";
    }
  }
  getTimeToLive(): number {
    return 0;
  }
}
