import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppConfigService } from "../../../core-ui";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard {
  constructor(private config: AppConfigService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.config.pageNotFoundRedirectURL) {
      window.location.href = this.config.pageNotFoundRedirectURL;
      return false;
    }
    return true;
  }
}
