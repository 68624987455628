import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Optional,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { TinyColor } from "@ctrl/tinycolor";
import { Subscription } from "rxjs";
import { ThemeService } from "../../../../core-ui/services";
import { CardComponent } from "../card/card.component";
import { HostBinding } from "@angular/core";
import { ButtonComponent } from "../button/button.component";
@Component({
  selector: "av-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() type: "svg" | "png" = "svg";
  @Input() size: "xs" | "sm" | "md" | "lg";
  @Input()
  set mode(value: "dark" | "light") {
    this._mode = value;
    this.url = this.getUrl();
  }
  get mode() {
    return this._mode;
  }

  private subscriptions: Subscription;
  private isDarkMode = false;
  private isLightBackground = false;
  private container: "card" | "main" | "button";
  private _mode;

  private buttonModeMap = {
    card: {
      flat: {
        basic: "mode",
        primary: "light",
        accent: "light",
        success: "light",
        warn: "light",
        danger: "light",
        link: "mode",
      },
      icon: {
        basic: "dark",
        primary: "light",
        accent: "light",
        success: "light",
        warn: "light",
        danger: "light",
        link: "mode",
      },
      basic: {
        basic: "mode",
        primary: "mode",
        accent: "mode",
        success: "mode",
        warn: "mode",
        danger: "mode",
        link: "mode",
      },
      stroked: {
        basic: "mode",
        primary: "mode",
        accent: "mode",
        success: "mode",
        warn: "mode",
        danger: "mode",
        link: "mode",
      },
    },
    main: {
      flat: {
        basic: "dark",
        primary: "light",
        accent: "light",
        success: "light",
        warn: "light",
        danger: "light",
        link: "background-color",
      },
      icon: {
        basic: "dark",
        primary: "light",
        accent: "light",
        success: "light",
        warn: "light",
        danger: "light",
        link: "background-color",
      },
      basic: {
        basic: "background-color",
        primary: "background-color",
        accent: "background-color",
        success: "background-color",
        warn: "background-color",
        danger: "background-color",
        link: "background-color",
      },
      stroked: {
        basic: "background-color",
        primary: "background-color",
        accent: "background-color",
        success: "background-color",
        warn: "background-color",
        danger: "background-color",
        link: "background-color",
      },
    },
  };

  url: string;

  constructor(
    private themeService: ThemeService,
    private cd: ChangeDetectorRef,
    @Optional() private card: CardComponent,
    @Optional() private button: ButtonComponent
  ) {
    this.subscriptions = new Subscription();
    if (!!button) {
      this.container = "button";
    } else if (!!card && card.type !== "ghost") {
      this.container = "card";
    } else {
      this.container = "main";
    }
  }

  @HostBinding("class") get class() {
    return {
      [`size-${this.size}`]: !!this.size,
    };
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        this.isDarkMode = theme?.darkMode;
        if (theme?.backgroundColor) {
          this.isLightBackground = new TinyColor(
            theme?.backgroundColor
          ).isLight();
        }
        this.url = this.getUrl();
        this.cd.detectChanges();
      })
    );
    if (!!this.button && !this.mode) {
      this.subscriptions.add(
        this.button.changes$.subscribe((changes) => {
          if (changes.color?.currentValue !== changes.color?.previousValue) {
            this.url = this.getUrl();
            this.cd.detectChanges();
          }
        })
      );
    }
  }

  getUrl(): string {
    let mode = this.mode;
    if (!mode) {
      switch (this.container) {
        case "main":
          mode = this.isLightBackground ? "dark" : "light";
          break;
        case "card":
          if (this.card?.isSecondary) {
            mode = this.card?.isLightBackground ? "dark" : "light";
          } else {
            mode = this.isDarkMode ? "light" : "dark";
          }

          break;
        case "button":
          const container = !!this.card ? "card" : "main";
          const result =
            this.buttonModeMap[container][this.button.ofType][
              this.button.color
            ];
          switch (result) {
            case "mode":
              mode = this.isDarkMode ? "light" : "dark";
              break;
            case "background-color":
              mode = this.isLightBackground ? "dark" : "light";
              break;
            default:
              mode = result as any;
          }
          break;
      }
    }
    return `modules/shared/assets/images/${this.name}-${mode}.${this.type}`;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
