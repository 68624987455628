<div
  class="color-picker-container"
  [class.color-picker-container-error]="error"
>
  <av-tooltip message="Color">
    <div class="fx-row fx-align-center fx-justify-start">
      <div
        class="color-picker-input fx-row fx-align-center fx-justify-start"
        [class.color-picker-input-disabled]="disabled"
        (click)="toggle()"
      >
        <div
          class="color"
          [class.color-empty]="isEmptyColor"
          [style.backgroundColor]="bkgColor"
        ></div>
        <div class="color-label">{{ colorLabel }}</div>
      </div>
      <button
        *ngIf="canReset"
        avButton
        [disabled]="disabled"
        color="link"
        (click)="clear()"
        size="xs"
        translate
      >
        Reset
      </button>
    </div>
  </av-tooltip>

  <div
    class="color-picker-popup"
    [class.color-picker-popup-offset]="canReset"
    [@slideFromUp]="true"
    *ngIf="!!color && iscolorPickerVisible"
  >
    <color-twitter
      [color]="color"
      
      [colors]="defeaultColors"
      triangle="top-right"
      (onChangeComplete)="colorChangeComplete($event)"
    >
    </color-twitter>
  </div>
</div>
