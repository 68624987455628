import {
  IProviderLanguages,
  ILanguage,
  SpeechToTextProviderType,
  IOrganizationLanguage,
  TranslationProviderType,
} from "@auvious/asr";

export interface IProviderLanguage {
  name: string;
  code: string;
  model?: string;
  provider?: SpeechToTextProviderType;
  checked?: boolean;
}

export class ProviderLanguage implements IProviderLanguage {
  public readonly name: string;
  public readonly code: string;
  public readonly model: string;
  public readonly provider: SpeechToTextProviderType;

  public readonly checked = false;

  constructor(
    language: IProviderLanguages | ILanguage | IOrganizationLanguage
  ) {
    if (this.isAsrProvider(language)) {
      this.name = language.languageName;
      this.code = language.id.languageCode;
      this.model = language.model;
      this.provider = language.id.providerType;
    } else if (this.isOrganizationLanguage(language)) {
      this.name = language.languageName;
      this.code = language.languageCode;
      this.provider = language.providerType;
      this.model = language.model;
    } else {
      this.name = language.name;
      this.code = language.code;
    }
  }

  private isAsrProvider(object: any): object is IProviderLanguages {
    return "id" in object;
  }

  private isOrganizationLanguage(object: any): object is IOrganizationLanguage {
    return "organizationLanguageId" in object;
  }
}

export class ProviderOrganizationLanguage extends ProviderLanguage {
  public readonly id: string;
  // public readonly translateLanguages: IProviderLanguage[];
  public readonly translateProvider: TranslationProviderType;

  constructor(language: IOrganizationLanguage) {
    super(language);
    // this.translateLanguages = language.targetLanguages?.map(
    //   (l) => new ProviderLanguage(l)
    // );
    this.id = language.organizationLanguageId;
    this.translateProvider = language.translateProviderType;
  }
}
