import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { sessionStore } from "@auvious/utils";
import {
  CobrowseContextEnum,
  KEY_COBROWSE_CONTEXT,
  KEY_COBROWSE_SESSION_ID,
  KEY_REDIRECT_URI,
} from "../../../core-ui/core-ui.enums";
import { ISyncable } from "./ISyncable";
import { AppConfigService } from "../../../core-ui/services/app.config.service";
import { PublicParam } from "../../../core-ui/models/application-options/PublicOptions";

@Injectable({
  providedIn: "root",
})
export class AuthRedirectGuard  implements ISyncable {
  constructor(private router: Router, private config: AppConfigService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const redirectUri = sessionStore.getItem(KEY_REDIRECT_URI);
    if (!redirectUri) {
      // if we don't know where to go, go to welcome
      if (route.url[0].path === "redirect") {
        return this.router.createUrlTree(["/welcome"], {
          queryParams: route.queryParams,
        });
      }

      const existingSession = sessionStore.getItem(KEY_COBROWSE_SESSION_ID);
      const existingSessionContext = sessionStore.getItem(KEY_COBROWSE_CONTEXT);
      if (
        existingSession &&
        existingSessionContext === CobrowseContextEnum.STANDALONE
      ) {
        return this.router.createUrlTree(["/cobrowse"], {
          queryParams: route.queryParams,
        });
      }

      // otherwise continue in the page we are
      return true;
    }
    sessionStore.removeItem(KEY_REDIRECT_URI);

    // remove authentication related keys since they are only used to get the access token
    return this.router.createUrlTree([redirectUri], {
      queryParams: {
        ...route.queryParams,
        // the key for the session stored access token is the code so we need to keep it
        code: this.config.publicParam(
          PublicParam.ACCESS_TOKEN_AGENT_SESSION_STORE_ENABLED
        )
          ? route.queryParamMap.get("code")
          : null,
        prompt: null,
        authuser: null,
        scope: null,
      },
    });
  }
}
