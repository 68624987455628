<div *ngIf="chunk" class="fx-row fx-align-start">
  <div>
    <div class="caption-item-avatar">
      <img *ngIf="imgUrl" [src]="imgUrl" alt="avatar" />
      <span *ngIf="!imgUrl">{{ initials }}</span>
    </div>
  </div>
  <div class="fx-column fx-align-start caption-item-container">
    <label class="caption-item-user">{{ username }}</label>
    <div class="caption-item-chunk">
      <ng-content></ng-content>
    </div>
  </div>
</div>
