<div class="menu-item-label" #label>
  <av-icon *ngIf="!!icon && !loading" size="xs" [name]="icon"></av-icon>
  <av-spinner *ngIf="loading" size="xs"></av-spinner>
  <span role="text">
    <ng-content></ng-content>
  </span>
</div>
<div class="menu-item-info" *ngIf="!!info">
  <av-tooltip position="left" [message]="info">
    <span class="info">i</span>
  </av-tooltip>
</div>

<div class="menu-item-postfix text-muted" *ngIf="postfix">
  <small>{{ postfix }}</small>
</div>

<div class="menu-item-chevron" #arrow *ngIf="arrow"></div>

<!-- [class.menu-item-submenu-visible]="isSubMenuVisible()" -->
<div
  class="menu-item-submenu" 
  [class.menu-item-submenu-left]="isSubMenuOutOfWindow"
>

  <ng-content select="[sub-menu]"></ng-content>
</div>
