<div
  class="hold-container fx-column fx-align-center fx-justify-center"
  [class.hold-container-enabled]="enabled"
>
  <app-freeze-frame
    class="frame"
    [stream]="stream"
    [local]="true"
    [mirror]="true"
    [render]="enabled"
    *ngIf="videoReady"
  >
  </app-freeze-frame>
  <div class="container">
    <av-icon name="pause" class="icon-hold"></av-icon>
    <h2 translate>Your call is on hold</h2>
    <h3 class="blink" translate>Please wait</h3>
  </div>
</div>
