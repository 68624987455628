import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "av-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
})
export class TooltipComponent implements OnInit {
  @Input() help: boolean;
  @Input() message: string;
  @Input() enabled = true;
  @Input() position: "top" | "bottom" | "left" | "right" = "top";
  @Input() wrap: boolean;

  constructor() {}

  ngOnInit(): void {}
}
