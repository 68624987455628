import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { debug } from "../../services";
import { IArea } from "../../models";
import { IReceiverEventMap } from "@auvious/cobrowser";

@Component({
  selector: "app-cobrowse-frame",
  templateUrl: "./cobrowse-frame.component.html",
  styleUrls: ["./cobrowse-frame.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobrowseFrameComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  set viewSize(value: IArea) {
    if (!value) {
      return;
    }

    if (
      value.width > 0 &&
      !!this._viewSize &&
      (value.width !== this._viewSize.width ||
        value.height !== this._viewSize.height) // &&
      // !this.cobrowseFrameRef.nativeElement?.contentDocument?.onscroll
    ) {
      // this.cobrowseFrameRef.nativeElement.contentDocument.onscroll = this.frameScroll;
      this.cobrowseFrameRef.nativeElement?.contentDocument?.addEventListener(
        "scroll",
        this.frameScroll
      );
    }
    this._viewSize = value;
  }

  get viewSize() {
    return this._viewSize;
  }

  @Input() sketchActive = false;
  @Input() cobrowseActive = false;

  @Output() viewSizeChanged = new EventEmitter<{
    frame: IArea;
    container: IArea;
    sketch: IArea;
  }>();
  @Output() mouseRatioChanged = new EventEmitter<number>();
  @Output() containerRatioChanged = new EventEmitter<number>();
  @Output() frameScrollChanged = new EventEmitter<
    Partial<IReceiverEventMap["mousemove"]>
  >();
  @Output() containerScrollChanged = new EventEmitter<
    Partial<IReceiverEventMap["mousemove"]>
  >();

  private _viewSize: IArea;
  private isFullWidth = false;
  private containerPadding = 20;
  private resizeRatio = 1;
  private mouseRatio = 1;
  private frameScrollPosition: Partial<IReceiverEventMap["mousemove"]> = {
    left: 0,
    top: 0,
  };
  private viewportScrollPosition: Partial<IReceiverEventMap["mousemove"]> = {
    left: 0,
    top: 0,
  };

  @ViewChild("cobrowseFrame")
  cobrowseFrameContainerRef: ElementRef<HTMLDivElement>;
  @ViewChild("frame") cobrowseFrameRef: ElementRef<HTMLIFrameElement>;

  constructor(
    private cardBodyRef: ElementRef<HTMLDivElement>,
    private cd: ChangeDetectorRef
  ) {}

  @HostListener("window:resize", ["$event"])
  resize() {
    this.cd.detectChanges();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // this.cobrowseFrameRef.nativeElement?.contentDocument?.addEventListener('scroll', this.frameScroll);
    this.cobrowseFrameContainerRef.nativeElement?.addEventListener(
      "scroll",
      this.viewportScroll
    );

    this.containerRatioChanged.emit(this.resizeRatio);
    this.mouseRatioChanged.emit(this.mouseRatio);
  }

  ngOnDestroy() {
    if (
      !this.cobrowseFrameRef ||
      !this.cobrowseFrameRef.nativeElement?.contentDocument
    ) {
      return;
    }
    this.cobrowseFrameRef.nativeElement.contentDocument.onscroll = undefined;
    // this.cobrowseFrameRef.nativeElement?.contentDocument?.removeEventListener('scroll', this.frameScroll);
    this.cobrowseFrameContainerRef.nativeElement.removeEventListener(
      "scroll",
      this.viewportScroll
    );
  }

  @HostBinding("class") get class() {
    return {
      "cobrowse-body-width-overflow": this.isWidthOverflow,
      "cobrowse-body-height-overflow": this.isHeightOverflow,
    };
  }

  public expand(): boolean {
    this.isFullWidth = !this.isFullWidth;
    if (this.isFullWidth) {
      this.mouseRatio = 1;
    } else {
      this.cobrowseFrameContainerRef.nativeElement.scrollTop = 0;
      this.cobrowseFrameContainerRef.nativeElement.scrollLeft = 0;
      this.mouseRatio = this.resizeRatio;
    }
    this.mouseRatioChanged.emit(this.mouseRatio);
    return this.isFullWidth;
  }

  frameScroll = async (event: Event) => {
    let top: number;
    let left: number;

    if ((event.target as Document).scrollingElement) {
      top = (event.target as Document).scrollingElement.scrollTop;
      left = (event.target as Document).scrollingElement.scrollLeft;
    } else {
      top = (event.target as Document).defaultView.window.scrollY;
      left = (event.target as Document).defaultView.window.scrollX;
    }

    this.frameScrollPosition = {
      top: Math.floor(top),
      left: Math.floor(left),
    };

    this.frameScrollChanged.emit({
      top: this.scrollTop,
      left: this.scrollLeft,
    });
  };

  viewportScroll = (event) => {
    const element = event.target;
    this.viewportScrollPosition = {
      top: element.scrollTop,
      left: element.scrollLeft,
    };
    this.containerScrollChanged.emit({
      top: this.scrollTop,
      left: this.scrollLeft,
    });
  };

  get scrollTop() {
    return this.frameScrollPosition.top + this.viewportScrollPosition.top;
  }
  get scrollLeft() {
    return this.frameScrollPosition.left + this.viewportScrollPosition.left;
  }

  get isWidthOverflow() {
    const containerSize =
      this.cardBodyRef?.nativeElement.getBoundingClientRect();
    return this.isFullWidth && this.viewSize.width > containerSize.width;
  }

  get isHeightOverflow() {
    const containerSize =
      this.cardBodyRef?.nativeElement.getBoundingClientRect();
    return this.isFullWidth && this.viewSize.height > containerSize.height;
  }

  get iFrame(): HTMLIFrameElement {
    return this.cobrowseFrameRef.nativeElement;
  }

  get isCobrowseFrameOpen() {
    return this.cobrowseActive && !!this.frameSize;
  }

  get frameSize() {
    const containerSize =
      this.cardBodyRef?.nativeElement.getBoundingClientRect();
    if (!containerSize || !this.viewSize) {
      return;
    }
    let frame;

    let container: { width: string; height: string };

    containerSize.width -= this.containerPadding;
    containerSize.height -= this.containerPadding;

    if (this.isFullWidth) {
      // if we overflow we have double scrolling. in this case, limit to container Size and only one scroll appears.
      const size: IArea = {
        width:
          this.viewSize.width > containerSize.width
            ? containerSize.width
            : this.viewSize.width,
        height:
          this.viewSize.height > containerSize.height
            ? containerSize.height
            : this.viewSize.height,
      };

      container = {
        width: size.width + "px",
        height: size.height + "px",
      };
      frame = {
        frame: {
          width: this.viewSize.width + "px",
          height: this.viewSize.height + "px",
          transform: "scale(1)",
        },
        container,
        sketch: {
          ...container,
        },
      };
      this.viewSizeChanged.emit(frame);
      return frame;
    }

    const resizeRatioPerWidth = containerSize.width / this.viewSize.width;
    const resizeRatioPerHeight = containerSize.height / this.viewSize.height;

    const fitWidthSize: IArea = {
      width: containerSize.width,
      height: resizeRatioPerWidth * this.viewSize.height,
    };

    const ratio =
      fitWidthSize.height > containerSize.height
        ? resizeRatioPerHeight
        : resizeRatioPerWidth;

    const resizeRatio = ratio < 1 ? ratio : 1;

    if (resizeRatio !== this.resizeRatio) {
      this.resizeRatio = resizeRatio;
    }
    this.containerRatioChanged.emit(this.resizeRatio);

    this.mouseRatio = this.resizeRatio;
    this.mouseRatioChanged.emit(this.mouseRatio);

    container = {
      width: this.viewSize.width * this.resizeRatio + "px",
      height: this.viewSize.height * this.resizeRatio + "px",
    };

    frame = {
      frame: {
        width: this.viewSize.width + "px",
        height: this.viewSize.height + "px",
        transform: `scale(${this.resizeRatio})`,
      },
      container,
      sketch: {
        ...container,
      },
    };
    this.viewSizeChanged.emit(frame);
    return frame;
  }
}
