import { AuviousRtcService } from "./rtc.service";
import {
  PageableRequest,
  IComposition,
  IRecordingComposition,
} from "../models";
import {
  CompositionTypeEnum,
  CompositionAudioFormatEnum,
  CompositionVideoFormatEnum,
  CompositionResolutionEnum,
  CompositionLayoutEnum,
} from "../core-ui.enums";

import { ApiResource, PagedCollection } from "@auvious/common";
import { Injectable } from "@angular/core";
import { GenericErrorHandler } from "./error-handlers.service";

@Injectable()
export class CompositionService {
  private compositionResource: ApiResource;

  constructor(
    private rtcService: AuviousRtcService,
    private errorHandler: GenericErrorHandler
  ) {
    this.compositionResource =
      this.rtcService.getAuviousCommonClient().apiResourceFactory("composition/api");
  }

  query(page: PageableRequest): Promise<PagedCollection<IComposition>> {
    return this.compositionResource
      .get({
        urlPostfix: "query/composition",
        params: page.getParams(),
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  findRecording(conversationId: string): Promise<IRecordingComposition> {
    return this.compositionResource
      .get({
        urlPostfix: `query/conversation/${conversationId}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  deleteComposition(
    conversationId: string,
    compositionId: string
  ): Promise<void> {
    return this.compositionResource
      .delete({
        urlPostfix: `${conversationId}/${compositionId}/delete`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  cancelComposition(
    conversationId: string,
    compositionId: string
  ): Promise<void> {
    return this.compositionResource
      .create(null, {
        urlPostfix: `${conversationId}/${compositionId}/cancel`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  requestComposition(
    conversationId: string,
    name: string,
    type: CompositionTypeEnum,
    videoFormat: CompositionVideoFormatEnum = CompositionVideoFormatEnum.mp4,
    audioFormat: CompositionAudioFormatEnum = CompositionAudioFormatEnum.mp3,
    resolution: CompositionResolutionEnum = CompositionResolutionEnum.low,
    layout: CompositionLayoutEnum = CompositionLayoutEnum.grid,
    priority: "1" | "2" = "1"
  ): Promise<{ id: string; conversationId: string }> {
    return this.compositionResource
      .create(
        {
          name,
          conversationId,
          layout,
          type,
          videoFormat,
          audioFormat,
          resolution,
          priority,
        },
        {
          urlPostfix: "request",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  getUrl(
    composition: IComposition,
    type?: "inline" | "attachment"
  ): Promise<{ url: string; validUntil: string }> {
    return this.compositionResource
      .get({
        urlPostfix: `player/${composition.conversationId}/${composition.id}/url/${type}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }
}
