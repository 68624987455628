<app-theme-base>
  <app-user-menu></app-user-menu>
  <router-outlet></router-outlet>
</app-theme-base>

<app-activity-indicator></app-activity-indicator>
<app-notification-manager></app-notification-manager>
<app-media-player></app-media-player>
<app-connection-status></app-connection-status>

<!-- The root portal host for popups and popovers -->
<!-- <div class="overlay">
  <ng-container avPortalHost="root"></ng-container>
</div> -->
