import { Component, OnInit } from "@angular/core";
import { slideFromUp, zoomInOut } from "../../core-ui.animations";
import { ConferenceService } from "../../services";

@Component({
  selector: "app-stream-play-button",
  templateUrl: "./stream-play-button.component.html",
  styleUrls: ["./stream-play-button.component.scss"],
  animations: [slideFromUp, zoomInOut],
})
export class StreamPlayButtonComponent implements OnInit {
  visible: boolean;

  constructor(private conferenceService: ConferenceService) {}

  ngOnInit() {
    this.conferenceService.streamsNeedToPlay$.subscribe((play) => {
      this.visible = play;
    });
  }

  play() {
    this.visible = false;
    this.conferenceService.playPausedStreams();
  }
}
