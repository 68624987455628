import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
} from "@angular/core";

@Component({
  selector: "av-control-row",
  templateUrl: "./control-row.component.html",
  styleUrls: ["./control-row.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlRowComponent implements OnInit {
  @Input() type: "switch" | "text" | "button" | "card" | "radio";
  @Input() label: string;
  @Input() help: string;
  @Input() inline: boolean;
  @Input() inlineFlex: number;

  @HostBinding("class") get class() {
    return {
      ["type-" + this.type]: true,
      inline: this.inline,
      ["inline-flex-" + this.inlineFlex]: this.inline && this.inlineFlex,
    };
  }

  constructor() {}

  ngOnInit(): void {}

  get isHelpProjected() {
    return !["card", "button"].includes(this.type);
  }
}
