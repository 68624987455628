import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  Renderer2,
  HostBinding,
  ViewChild,
} from "@angular/core";
import { ISpeechToTextTranscriptChunk } from "../../../../core-ui/models/interfaces";
import { ASRService } from "../../../../core-ui/services/asr.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { ThemeService } from "../../../../core-ui/services/theme.service";
import { SizeEnum } from "../../../../core-ui/core-ui.enums";
import { DeviceService } from "../../../../core-ui/services/device.service";
import { Util } from "@auvious/common";

@Component({
  selector: "app-captions",
  templateUrl: "./captions.component.html",
  styleUrls: ["./captions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptionsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  batchChunkMap: Map<string, ISpeechToTextTranscriptChunk> = new Map();
  userFinalChunkMap: Map<string, boolean> = new Map();
  userCurrentBatchMap: Map<string, string> = new Map();

  supportsBackdropBlur = false;
  ttl = 5 * 1000;

  @ViewChild("captions") captionsRef: ElementRef<HTMLDivElement>;

  constructor(
    private cd: ChangeDetectorRef,
    private asr: ASRService,
    private host: ElementRef<HTMLDivElement>,
    private theme: ThemeService,
    private renderer: Renderer2
  ) {}

  @HostBinding("class") get class() {
    return {
      blur: this.supportsBackdropBlur,
      "is-mobile": DeviceService.isMobile,
    };
  }

  ngOnInit(): void {
    this.supportsBackdropBlur = !DeviceService.isFirefox;

    this.subscription.add(
      this.theme.themeChanged$().subscribe((theme) => {
        if (!theme) {
          return;
        }
        let fontSize;
        switch (theme.captionsFontSize) {
          case SizeEnum.small:
            fontSize = "1.1em";
            break;
          case SizeEnum.medium:
            fontSize = "1.2em";
            break;
          case SizeEnum.large:
            fontSize = "1.3em";
            break;
        }
        this.renderer.setStyle(
          this.host.nativeElement,
          "color",
          theme.captionsFontColor
        );
        this.renderer.setStyle(
          this.host.nativeElement,
          "background-color",
          theme.captionsBackgroundColor
        );
        this.renderer.setStyle(this.host.nativeElement, "font-size", fontSize);
      })
    );
    this.subscription.add(
      this.asr.chunk$.pipe(filter((c) => !!c)).subscribe((chunk) => {
        const wasFinal = this.userFinalChunkMap.get(chunk.userId);

        if (wasFinal || !this.userCurrentBatchMap.has(chunk.userId)) {
          // create a new batch for this user
          const batchId = Util.uuidgen();
          this.userCurrentBatchMap.set(chunk.userId, batchId);
        }
        // if this is a final chunk, mark it so as the next chunk for this user gets a new batch id
        this.userFinalChunkMap.set(chunk.userId, chunk.isFinal);

        // if a chunk is not final, just replace the chunk with the new one for the specific user
        const currentBatch = this.userCurrentBatchMap.get(chunk.userId);

        this.batchChunkMap.set(currentBatch, chunk);

        // remove final chunks after X sec
        if (chunk.isFinal) {
          setTimeout(() => {
            this.batchChunkMap.delete(currentBatch);
            this.cd.detectChanges();
          }, this.ttl);
        }

        this.cd.detectChanges();
        this.scrollToBottom();
      })
    );
    this.subscription.add(
      this.asr.reset$.subscribe((_) => {
        this.batchChunkMap = new Map();
        this.userFinalChunkMap = new Map();
        this.userCurrentBatchMap = new Map();
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  scrollToBottom() {
    this.host.nativeElement.scrollTo({
      behavior: "smooth",
      top: this.captionsRef.nativeElement.clientHeight,
    });
  }

  trackChunk(index, el: ISpeechToTextTranscriptChunk) {
    return el.userId;
  }

  get chunks(): ISpeechToTextTranscriptChunk[] {
    // get the last 2 items
    return Array.from(this.batchChunkMap.values()).slice(-2);
  }
}
