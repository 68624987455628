import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  signal,
  computed,
} from "@angular/core";
import {
  AgentParam,
  AppConfigService,
  ApplicationService,
  ApplicationTypeEnum,
  ConversationDestinationEnum,
  ConversationOriginEnum,
  GenericErrorHandler,
  IInteraction,
  IQueue,
  MediaRulesService,
  NotificationService,
  ProtectedTicketService,
  STREAM_CONTROLS_LEFT_OFFSET_THRESHOLD,
  ScheduleTypeEnum,
  SizeEnum,
  TicketTypeEnum,
  UserService,
  WindowService,
  copyTextToClipboard,
  slideInOut,
} from "../../../core-ui";
import { ConversationTypeEnum } from "@auvious/integrations";
import { TranslateService } from "@ngx-translate/core";
import {
  InteractionService,
  InvitationService,
  QRService,
} from "../../services";
import { PARAM_CONVERSATION_ORIGIN } from "../../app.enums";
import { AnalyticsService } from "../../../core-ui/services/analytics.service";
import { GenesysInvitationChannelEnum } from "../../models";
import { Subscription } from "rxjs";
export interface IShareLinkAction {
  shareLink: () => void;
}

@Component({
  selector: "app-share-link",
  templateUrl: "./share-link.component.html",
  styleUrls: ["./share-link.component.scss"],
  animations: [slideInOut],
})
export class ShareLinkComponent implements OnInit, OnDestroy {
  @Input() interaction: IInteraction;
  @Input() isOriginWidgetWithoutVideo: boolean;

  @ViewChild("menuRef", { read: ElementRef }) menuRef: ElementRef<HTMLElement>;
  @ViewChild("menuButtonRef", { read: ElementRef })
  menuButtonRef: ElementRef<HTMLElement>;

  shareOpen = signal(false);
  isCallbackRequest = false;
  isAgent: boolean;
  isTestApplication: boolean;
  customerLink: string;
  customerTicket: string;
  agentLink: string;
  isPhoneNumberValidation: boolean;
  isEmailValidation: boolean;
  smsQueue: IQueue;
  channel: GenesysInvitationChannelEnum;
  workflow: string;
  subscription = new Subscription();
  buttonSize = SizeEnum.small;

  constructor(
    private config: AppConfigService,
    private notification: NotificationService,
    private translate: TranslateService,
    private user: UserService,
    private applicationService: ApplicationService,
    private invitationService: InvitationService,
    private ticketService: ProtectedTicketService,
    private errorHandler: GenericErrorHandler,
    private qrService: QRService,
    private analyticsService: AnalyticsService,
    private interactionService: InteractionService,
    private winService: WindowService,
    private cd: ChangeDetectorRef,
    private mediaRules: MediaRulesService
  ) {}

  async ngOnInit() {
    this.isAgent = this.user.isAgent;
    this.isTestApplication =
      this.applicationService.getActiveApplication().getType() ===
      ApplicationTypeEnum.Test;

    this.agentLink = this.invitationService.prepareAgentInvitation(
      this.interaction
    );
    if (!this.user.isGuestAgent) {
      this.renewCustomerLink();
    }
    if (
      !(
        this.isOriginWidget ||
        this.isOriginCallback ||
        this.isOriginAppointment ||
        this.config.agentParamEnabled(AgentParam.AUTO_INVITE_CUSTOMER)
      )
    ) {
      this.toggleShare();
    }
    this.subscription.add(
      this.winService.streamControlsContainerPositionChange$.subscribe((e) => {
        this.buttonSize =
          e.left < STREAM_CONTROLS_LEFT_OFFSET_THRESHOLD
            ? SizeEnum.extraSmall
            : SizeEnum.small;
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener("document:keydown.escape", ["$event"]) keydown(
    e: KeyboardEvent
  ) {
    if (this.shareOpen()) {
      this.toggleShare();
    }
  }

  public inviteCustomer() {
    this.interactionService.invite(
      this.interaction.getType(),
      this.interaction,
      this.customerTicket
    );
    this.analyticsService.trackInvitationSent(this.interaction);
  }

  protected optionsClick(e: Event) {
    e.stopPropagation();
  }

  protected toggleShare() {
    if (this.shareOpen()) {
      this.shareOpen.set(false);
    } else if (
      !this.isCallbackRequest &&
      !this.isPhoneNumberValidation &&
      !this.isEmailValidation
    ) {
      this.shareOpen.set(true);
    }
    if (this.isCallbackRequest) {
      this.isCallbackRequest = false;
    }
    if (this.isPhoneNumberValidation) {
      this.isPhoneNumberValidation = false;
    }
    if (this.isEmailValidation) {
      this.isEmailValidation = false;
    }

    this.cd.detectChanges();

    if (this.shareOpen()) {
      this.winService
        .onClickedOutside(
          this.menuRef.nativeElement,
          this.menuButtonRef.nativeElement
        )
        .then(() => {
          if (this.shareOpen()) this.toggleShare();
        });
    }
  }

  public open() {
    this.shareOpen.set(true);
    this.winService
      .onClickedOutside(
        this.menuRef.nativeElement,
        this.menuButtonRef.nativeElement
      )
      .then(() => {
        if (this.shareOpen()) this.toggleShare();
      });
  }

  protected confirmSMS(payload: {
    channel: GenesysInvitationChannelEnum;
    queue?: IQueue;
    workflow?: string;
  }) {
    this.isPhoneNumberValidation = true;
    this.setFlowData(payload);
  }

  protected confirmEmail(payload: {
    channel: GenesysInvitationChannelEnum;
    queue?: IQueue;
    workflow?: string;
  }) {
    this.isEmailValidation = true;
    this.setFlowData(payload);
  }

  private setFlowData(payload: {
    channel: GenesysInvitationChannelEnum;
    queue?: IQueue;
    workflow?: string;
  }) {
    this.smsQueue = payload.queue;
    this.channel = payload.channel;
    this.workflow = payload.workflow;
  }

  protected callbackRequest() {
    this.isCallbackRequest = true;
    this.shareOpen.set(false);
  }

  protected closeCallbackPanel() {
    this.isCallbackRequest = false;
  }

  protected openChange(value: boolean) {
    this.shareOpen.set(value);
  }

  protected completed() {
    this.renewCustomerLink();
  }

  protected async renewCustomerLink() {
    try {
      const ticketRequest = this.invitationService.prepareTicketRequest(
        this.config.agentParamEnabled(AgentParam.SINGLE_USE_TICKET_ENABLED)
          ? TicketTypeEnum.SingleUseTicket
          : TicketTypeEnum.MultiUseTicket,
        this.config.agentParam(AgentParam.TICKET_LENGTH) ?? 6,
        this.interaction.getRoom(),
        this.interaction.getCustomerId(),
        this.interaction.getId(),
        this.interaction.getCustomerName()
      );
      this.customerTicket = await this.ticketService.createTicket(
        ticketRequest
      );
      this.customerLink = this.ticketService.getTicketWithDomain(
        this.customerTicket
      );
    } catch (ex) {
      this.errorHandler.handleError(ex);
      this.notification.error("Oups!", {
        body: "Could not create shareable link",
      });
    }
  }

  protected copyCustomerLink() {
    if (this.customerLink) {
      copyTextToClipboard(this.interaction.getCustomerLink(this.customerLink));
      this.notification.show("auvious", {
        body: this.translate.instant(
          "The video link has been copied to your clipboard"
        ),
      });
      this.shareOpen.set(false);
    }
    // this.renewCustomerLink();
    this.completed();
  }

  protected copyAgentLink() {
    if (this.agentLink) {
      const link = this.enrichAgentLink(this.agentLink);
      copyTextToClipboard(link);
      this.notification.show("auvious", {
        body: this.translate.instant(
          "The agent link has been copied to your clipboard"
        ),
      });
      this.shareOpen.set(false);
    } else {
      this.notification.error("auvious", {
        body: "Could not generate agent link",
      });
    }
  }

  private enrichAgentLink(agentLink: string): string {
    let link = this.interaction.getCustomerLink(agentLink);
    if (this.interaction.getOrigin() === ConversationOriginEnum.WIDGET) {
      const l = new URL(link);
      l.searchParams.set(
        PARAM_CONVERSATION_ORIGIN,
        ConversationOriginEnum.WIDGET
      );
      link = l.toString();
    }
    return link;
  }

  protected showQR() {
    this.qrService.showQR(this.enrichAgentLink(this.agentLink));
    this.shareOpen.set(false);
  }

  protected get isShareToggleActive() {
    return computed(
      () =>
        this.shareOpen() ||
        this.isCallbackRequest ||
        this.isPhoneNumberValidation ||
        this.isEmailValidation
    );
  }

  protected get isDestinationMobileOffice() {
    return (
      this.interaction.getDestination() ===
      ConversationDestinationEnum.MOBILE_OFFICE
    );
  }

  protected get isOriginWidget() {
    return this.interaction.getOrigin() === ConversationOriginEnum.WIDGET;
  }

  protected get isOriginCallback() {
    return this.interaction.getType() === ConversationTypeEnum.callback;
  }

  protected get isOriginAppointment() {
    return this.interaction.getOrigin() === ConversationOriginEnum.APPOINTMENT;
  }

  protected get isSendChatWidgetAvailable() {
    return this.isOriginWidgetWithoutVideo;
  }

  protected get isEmailAvailable() {
    return !this.isDestinationMobileOffice;
  }

  protected get isSMSAvailable() {
    return !this.isDestinationMobileOffice;
  }

  protected get isQRCustomerAvailable() {
    return (
      this.mediaRules.isQRInvitationAvailable &&
      this.interaction.getType() !== ConversationTypeEnum.voiceCall
    );
  }

  protected get isQRAgentAvailable() {
    return this.mediaRules.isQRInvitationAvailable;
  }

  protected get isCallbackAvailable() {
    return (
      this.config.agentParamEnabled(AgentParam.SCHEDULE_ENABLED) &&
      this.interaction.supportsScheduledCalls() &&
      this.config.agentParam(AgentParam.SCHEDULE_CHANNEL) ===
        ScheduleTypeEnum.genesysCallback
    );
  }

  protected get isSendChatAvailable() {
    return (
      this.interaction.supportsMessages() &&
      !this.isOriginWidget &&
      !this.isOriginCallback
    );
  }

  protected get isAgentLinkAvailable() {
    return (
      this.isAgent &&
      !this.isTestApplication &&
      this.mediaRules.isAgentInvitationAvailable
    );
  }

  protected get isCustomerLinkAvailable() {
    return this.mediaRules.isCustomerInvitationAvailalbe;
  }
}
