import { IInteraction } from "../../../../core-ui/models/IInteraction";
import { IAnalyticsStrategy } from "../../../../core-ui/models/strategies";
import {
  KEY_ROOM_NAME,
  TAG_CUSTOMER_ENDED_AT,
  TAG_CUSTOMER_INVITED,
  TAG_CUSTOMER_RECORDING_INSTANCE_ID,
  TAG_CUSTOMER_RECORDING_RECORDER_ID,
  TAG_CUSTOMER_REQUESTED_AT,
  TAG_CUSTOMER_TICKET_SENT_AT,
} from "../../../app.enums";
import { IRecorderInfo, IRecorderSessionInfo } from "../../../../core-ui";
import { TAG_CUSTOMER_TRANSFER_ROOM } from "../../../app.enums";
import { NiceService } from "../../../services/nice.service";

export class NiceAnalyticsStrategy implements IAnalyticsStrategy {
  constructor(private nice: NiceService) {}

  trackCallRequested(interaction: IInteraction): Promise<void> {
    return this.nice.updateContactCustomFields(interaction.getId(), {
      [TAG_CUSTOMER_REQUESTED_AT]: new Date(),
    });
  }
  trackInvitationSent(interaction: IInteraction): Promise<void> {
    return this.nice.updateContactCustomFields(interaction.getId(), {
      [TAG_CUSTOMER_TICKET_SENT_AT]: new Date(),
      [TAG_CUSTOMER_INVITED]: true,
      [KEY_ROOM_NAME]: interaction.getRoom(),
    });
  }
  trackCallEnding(interaction: IInteraction): Promise<void> {
    return this.nice.updateContactCustomFields(interaction.getId(), {
      // we must clear the ticket sent, in case a second agent wants to send the ticket
      [TAG_CUSTOMER_INVITED]: false,
    });
  }
  trackCallEnded(interaction: IInteraction): Promise<void> {
    return this.nice.updateContactCustomFields(interaction.getId(), {
      [TAG_CUSTOMER_ENDED_AT]: new Date(),
      // if the agent terminated the call, we must update with a null room name
      // so that the agent can join again if he wants by creating a new room
      [KEY_ROOM_NAME]: interaction.getRoom() || "",
    });
  }

  trackConferenceRoomEnded(interaction: IInteraction): Promise<void> {
    return this.nice.updateContactCustomFields(interaction.getId(), {
      [KEY_ROOM_NAME]: "",
    });
  }

  trackCallTransferred(interaction: IInteraction): Promise<void> {
    return this.nice.updateContactCustomFields(interaction.getId(), {
      [TAG_CUSTOMER_TRANSFER_ROOM]: interaction.getRoom(),
    });
  }
  trackRecordingStarted(
    recorder: IRecorderInfo,
    interaction: IInteraction,
    type: "media" | "co-browse"
  ): Promise<void> {
    return this.nice.updateContactCustomFields(interaction.getId(), {
      [TAG_CUSTOMER_RECORDING_INSTANCE_ID]: recorder.instanceId,
      [TAG_CUSTOMER_RECORDING_RECORDER_ID]: recorder.recorderId,
    });
  }
  trackRecordingStopped(
    recorderSession: IRecorderSessionInfo,
    interaction: IInteraction
  ): void {
    // empty
  }
}
