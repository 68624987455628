import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiResource } from "@auvious/common";
import { sessionStore } from "@auvious/utils";
import { ContactStatus } from "@nice-devone/nice-cxone-chat-web-sdk";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";
import {
  AppConfigService,
  AuviousRtcService,
  ConversationChannelEnum,
  ConversationOriginEnum,
  ConversationTypeEnum,
  decodeBase64,
  IConversationEventHandlers,
  OriginModeEnum,
  UserService,
} from "../../core-ui";
import {
  KEY_ROOM_NAME,
  TAG_CUSTOMER_TRANSFER_ROOM,
  WIDGET_CONVERSATION_TYPE,
  WIDGET_REFERRER_ID,
} from "../app.enums";
import { debugError } from "../app.utils";
import { IConversationInfo } from "../models";
import { INiceTokenResponse } from "../models/NiceTokenResponse";
import {
  DigitalUserDetails,
  OutboundMessage,
} from "../models/strategies/nice/req.types";
import { Case, Contact } from "../models/strategies/nice/types";
import {
  NiceWSEvents,
  PushUpdateEventType,
} from "../models/strategies/nice/wss.types";
import { GenericErrorHandler } from "./error-handlers.service";
import { IntegrationService } from "./integration.service";

export interface INiceConfig {
  private: false;
  area: string;
  cluster: string;
  domain: "niceincontact.com";
  acdDomain: "nice-incontact.com";
  uhDomain: "nice-incontact.com";
  ui_endpoint: string;
  auth_endpoint: string;
  api_endpoint: string;
  tenantId: string;
}

export interface INicePageableResponse {
  skip: number;
  top: number;
  totalRecords: number;
}
export interface INiceUsersPageableResponse extends INicePageableResponse {
  users: {
    id: string;
    userName: string;
    firstName: string;
    middleName?: string;
    fullName?: string;
    lastName: string;
    emailAddress: string;
    role: string;
  }[];
}

interface ICXAgentSettings {
  maxConferenceParties: 3;
  deleteCommitmentId: 3;
  deleteCommitmentString: "CanRemoveWithoutNotes";
  persistentPanels: [
    {
      persistentPanelId: 1073741843;
      persistentPanelURI: "https://auvious.video/welcome?aid=gitlab";
      persistentPanelLabel: "Auvious";
    }
  ];
  DataDogAppID: "1f10628f-e0e5-490f-9863-2f46ab70b886";
  DataDogClientToken: "pube32616fde4c64e84d3f10b7820888d1d";
  DataDogSampleRate: "0";
  raygunApiKeyMAX: "b4leBlZK9cavh12kpmFWvw==";
  raygunApiKeySupervisor: "EAdPsYpWDyPNp6wRaDKTxQ==";
  googleAccountNumberMAX: "UA-54462450-1";
  googleAccountNumberSupervisor: "UA-73874198-1";
  wfoWebsiteUrl: "https://na1.nice-incontact.com";
  wfoWebServiceUrl: "https://na1-ws.nice-incontact.com";
  wfoApiUrl: "https://na1-api.nice-incontact.com";
  maxClientVersion: "f09fc5f34c";
  webRTCType: "AudioCodes";
  webRTCWSSUrls: [
    "wss://wrtc-pri.niceincontact.com",
    "wss://wrtc-sec.niceincontact.com"
  ];
  webRTCICEUrls: [];
  webRTCServerDomain: "niceincontact.com";
  webRTCDNIS: "+1700499991932";
  webRTCUserId: null;
  webRTCUserToken: null;
  webRTCEnterprise: null;
  helpSiteVersion: null;
  emergencyPhoneNumbers: [];
  presenceSyncApiUrl: "https://presencesync.niceincontact.com";
  presenceSyncWebSocketUrl: "wss://websocket-na1.niceincontact.com";
  cxaClientVersion: "8aba596458";
  isExternalDirConfigure: false;
  VQMIntegratedSoftPhoneMonitoring: false;
  VQMMonitoringKey: null;
  CXADataDogAppID: "defd4a41-83ae-4a46-b8b4-42a794e87dc6";
  CXADataDogClientToken: "pubc51e7576d7f1b0cb558cdb1253e2a624";
  digitalEngagementUrl: "https://app-de-na1.niceincontact.com/system/auth/jwt-login?redirectUrl=https%3A%2F%2Fapp-de-na1.niceincontact.com%2Fcare%3FembeddedClient%3D1";
}

interface ICXToken {
  role: {
    legacyId: "Administrator" | "Agent";
    id: string;
    lastUpdateTime: number;
    secondaryRoles: [];
  };
  views: {};
  icSPId: string;
  icAgentId: string;
  sub: string;
  iss: string;
  given_name: string;
  aud: string;
  icBUId: number;
  name: string;
  tenantId: string;
  family_name: string;
  tenant: string;
  icClusterId: string;
  iat: number;
  exp: number;
}

interface ICXRefreshTokenResponse {
  access_token: string;
  token_type: "Bearer";
  issued_token_type: string;
  refresh_token: string;
  expires_in: 3600;
  id_token: string;
}

interface CXEvent {
  IISHost: string;
  VCHost: string;
  Type: string;
  [key: string]: any;
}

interface ICXAgentState extends CXEvent {
  IISHost: string;
  VCHost: string;
  Type: "AgentState";
  CurrentState: "Available";
  CurrentOutReason: "";
  NextStates: {
    State: "Available";
    OutReason: "";
  }[];
  StartTimeUTC: string;
  StartTime: string;
  IsAcw: "False";
  NextIsAcw: "false";
  AcwTimeout: "0";
  IsExternal: "false";
}

interface ICXAgentSettingsChange extends CXEvent {
  IISHost: string;
  VCHost: string;
  Type: "MchAgentSettingsChangeEvent";
  ChatThreshold: "3";
  EmailThreshold: "1";
  WorkItemThreshold: "3";
  RequestContact: "True";
  ContactAutoFocus: "1";
  TotalContactCount: "4";
  DeliveryMode: "1";
  VoiceThreshold: "1";
  SmsThreshold: "1";
  DigitalThreshold: "3";
}

interface ICXAgentSessionStart extends CXEvent {
  IISHost: string;
  VCHost: string;
  Type: "AgentSessionStart";
  BusNo: string;
  AgentId: string;
  StationId: "0";
  StationPhoneNumber: string;
  StationCallerId: string;
  SessionId: string;
  DialerCampaign: "";
  DialerCampaignStartTime: string;
  SupervisorPermissionLevel: "4";
  CanMask: "True";
  AgentSchedulePermission: "";
  ScoreRecordingsPermission: "False";
  HideAgentStatePermission: "";
  ClientConnectorPort: "0";
  CanMultiPartyConference: "";
  MaxConcurrentChats: "3";
  CanRecord: "True";
  EnabledForMCH: "True";
  UseCustomerCard: "True";
  AgentUUId: string;
  EntityMode: "false";
}

interface ICXAgentSessionEnd extends CXEvent {
  Type: "AgentSessionEnd";
  Success: "True";
  Message: "";
}

interface ICXRemoteAgentSessionEnd extends CXEvent {
  Type: "RemoteAgentSessionEnd";
  Message: "RemoteLogoff";
}

interface ICXWorkItemContactEvent extends CXEvent {
  Type: "WorkItemContactEvent";
  /** number */
  ContactID: string;
  Status: "Incoming" | "Active" | "Disconnected";
  // these three are custom
  WorkItemId: string;
  WorkItemPayload: string;
  WorkItemType: string;
  AgentId: "1218";
  SkillId: "1528";
  StartTimeUTC: "2013-09-11T05:15:44.000Z";
  LastStateChangeTimeUTC: "2013-09-11T05:15:44.000Z";
  ScreenPopUrl: string;
  ScreenPopUrlVariables: {
    [key: string]: string;
  };
  RefusalTimeout: "45";
  FinalState: "False";
}

interface ICXChatContactEvent extends CXEvent {
  Type: "ChatContactEvent";
  ContactID: "1569822";
  RoomId: "2";
  Status: "Incoming";
  Skill: "1509";
  StartTime: "2013-09-11T05:30:39.000Z";
  LastStateChangeTime: "2013-09-11T05:30:39.000Z";
  ScreenPopUrl: "";
  RefusalTimeout: "45";
  IsActive: "True";
  Messages: {
    Text: string;
    TimeStamp: string;
    PartyType: "Client";
  }[];
  FinalState: "False";
}

interface ICXChatText extends CXEvent {
  Type: "ChatText";
  RoomId: "2";
  Label: "SystemSaysHello";
  Message: "$Localized:ChatSessionEnded";
  PartyType: "Agent" | "System" | "Client";
  TimeStamp: string;
}

type CXEvents =
  | ICXAgentState
  | ICXAgentSettingsChange
  | ICXAgentSessionStart
  | ICXAgentSessionEnd
  | ICXRemoteAgentSessionEnd
  | ICXWorkItemContactEvent
  | ICXChatContactEvent
  | ICXChatText;

interface CXPollResult {
  sessionId: "string";
  events: CXEvents[];
}

enum PollState {
  idle,
  polling,
  done,
}

const CUSTOM_FIELDS_KEY = "auvious";
// const CUSTOMER_CUSTOM_FIELDS_KEY = "auvious-customer-metadata";

@Injectable()
export class NiceService {
  static clientId = "aed98444-7b80-4201-be1f-186525911b0a";

  public agentId: number;
  public dfoAgentId: number;
  public fullName: string;
  public tenantId: string;

  private serviceConfig: INiceConfig = null;
  // TODO: should this be in app config?
  private clusterApi =
    "https://api-{cluster}.nice-incontact.com/incontactapi/services/v29.0";
  private areaApi: string; // = "https://api-na1.niceincontact.com";
  private securityApi: ApiResource;
  private proxyApi: ApiResource;
  private sessionID: string;

  private accessToken: string;
  private refreshToken: string;

  private pollState = PollState.idle;
  private pollingTimeout = 60;
  private lastPoll = 0;
  private pollThrottle = 5000;

  private handlers: IConversationEventHandlers = {};
  private initTask = Promise.resolve();

  private contacts: Contact[] = [];

  constructor(
    private config: AppConfigService,
    private errorHandler: GenericErrorHandler,
    private httpClient: HttpClient,
    private rtc: AuviousRtcService,
    private user: UserService,
    private integration: IntegrationService
  ) {
    // console.warn(this.config);
  }

  private get headers() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.accessToken}`,
    };
  }

  private get timers() {
    return this.rtc.getAuviousCommonClient().getTimers();
  }

  public registerConversationEventHandlers(
    handlers: IConversationEventHandlers
  ) {
    this.handlers = handlers || {};
  }

  private retrieveAuviousIdentMetadata(fields: Contact["customFields"]) {
    const fieldValue = fields.find(
      ({ ident }) => ident === CUSTOM_FIELDS_KEY
    )?.value;
    let props: Partial<{ room: string }> = {};

    try {
      props = fieldValue ? JSON.parse(fieldValue) : {};
      return props;
    } catch (ex) {
      debugError(ex);
    }
    return {};
  }

  private appendContact(contact: Contact) {
    const index = Math.max(
      0,
      this.contacts.findIndex((c) => c.contactId === contact.contactId)
    );

    this.contacts[index] = contact;
  }

  public removeContact(id) {
    this.contacts = this.contacts.filter((c) => c.id !== id);
  }

  private parseContact(contact: Contact) {
    if (contact.status === "closed") {
      return undefined;
    }
    const metadata = this.retrieveAuviousIdentMetadata(
      contact.customFields || []
    );
    return {
      agentId: contact.inboxAssignee + "",
      channel: ConversationChannelEnum.cxoneDFO,
      communicationId: contact.interactionId,
      conversationId: contact.id,
      customerId: contact.endUser.id,
      legacy: false,
      origin: metadata[WIDGET_REFERRER_ID]
        ? ConversationOriginEnum.WIDGET
        : ConversationOriginEnum.CHAT,
      room:
        metadata.room ||
        metadata[KEY_ROOM_NAME] ||
        metadata[TAG_CUSTOMER_TRANSFER_ROOM] ||
        undefined,
      type: metadata[WIDGET_CONVERSATION_TYPE] || ConversationTypeEnum.chat,
      customerName: contact.endUser.fullName?.trim() || "",
      originMode: OriginModeEnum.website,
      queueId: contact.routingQueueId,
      transferred:
        (contact.inboxPreAssigneeUser &&
          contact.inboxPreAssigneeUser.id !== contact.inboxAssignee) ||
        !!metadata[TAG_CUSTOMER_TRANSFER_ROOM],
      customerMetadata: metadata,
    };
  }

  private async getContact(caseId: string): Promise<Contact> {
    try {
      if (!this.areaApi) await this.initTask;

      const contact: Contact = await this.proxyApi.create(
        {
          url: `${this.areaApi}/dfo/3.0/contacts/${caseId}`,
          method: "GET",
          headers: this.headers,
        },
        { urlPostfix: "proxy?detail" }
      );

      this.appendContact(contact);
      return contact;
    } catch (ex) {
      debugError(ex);
      return undefined;
    }
  }

  public async updateContactCustomFields(
    caseId: string,
    fields: { [key: string]: any }
  ) {
    if (!this.dfoAgentId) await this.initTask;

    let customFields = {};
    try {
      const response = await this.getContact(caseId);
      customFields = this.retrieveAuviousIdentMetadata(response.customFields);
    } catch (ex) {
      debugError("could not retrieve contact", ex);
    }

    const payload = [
      {
        ident: CUSTOM_FIELDS_KEY,
        value: JSON.stringify({
          ...customFields,
          ...fields,
        }),
      },
    ];

    try {
      // https://developer.niceincontact.com/API/DigitalEngagementAPI#/Contact/put_contacts__contactNumber__custom_fields
      await this.proxyApi.create(
        {
          url: `${this.areaApi}/dfo/3.0/contacts/${caseId}/custom-fields`,
          method: "PUT",
          headers: this.headers,
          body: JSON.stringify(payload),
        },
        { urlPostfix: "proxy?custom-fields" }
      );

      // update cached version
      const cachedContact = this.contacts.find((c) => c.id === caseId);
      if (cachedContact) {
        const index = Math.max(
          0,
          cachedContact.customFields.findIndex(
            (c) => c.ident === CUSTOM_FIELDS_KEY
          )
        );
        cachedContact.customFields[index] = payload[0];
        this.appendContact(cachedContact);
      }
    } catch (ex) {
      debugError("could not update customFields", ex);
    }
  }

  public async getUsers(
    page: number,
    size: number
  ): Promise<INiceUsersPageableResponse> {
    const body = {
      fields: [
        "id",
        "userName",
        "firstName",
        "middleName",
        "lastName",
        "role",
        "fullName",
        "emailAddress",
      ],
      filter: {
        impersonated: ["false"],
        status: ["ACTIVE", "PENDING", "UNREGISTERED"],
      },
      orderBy: { fullName: "ASC" },
      skip: page * size,
      top: size,
    };

    const users: INiceUsersPageableResponse = await this.proxyApi.create(
      {
        url: `${this.serviceConfig.ui_endpoint}/user-management/v2/users/search`,
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(body),
      },
      { urlPostfix: "proxy?users" }
    );

    if (typeof users === "string") {
      throw new Error("invalid-format");
    }

    return users;
  }

  public async discoverActiveConversation(): Promise<IConversationInfo> {
    if (!this.dfoAgentId) await this.initTask;

    // https://developer.niceincontact.com/API/DigitalEngagementAPI#/Contact/get_contacts
    const params = new URLSearchParams();
    params.set(
      "endUserIdOnExternalPlatform[]",
      this.integration
        .getIntegrationUserId(this.user.getActiveUser())
        ?.split(":")?.[1]
    );
    params.set("query", "NOT(status=closed)");
    params.set("status", 'List["new"]');

    let slots: { hits: string; data: Contact[] };
    try {
      slots = await this.proxyApi.create(
        {
          url: `${this.areaApi}/dfo/3.0/contacts?${params.toString()}`,
          method: "GET",
          headers: this.headers,
        },
        { urlPostfix: "proxy?contacts" }
      );
    } catch (ex) {
      debugError(ex);
    }

    const contact = slots?.data?.[0];
    if (contact) {
      this.appendContact(contact);
      return this.parseContact(contact);
    }
    return null;
  }

  public async retrieveInteractionData(id: string) {
    const contact = await this.retrieveContact(id);
    return contact ? this.parseContact(contact) : null;
  }

  public async retrieveContact(id: string): Promise<Contact> {
    return this.contacts.find((c) => c.id === id) || this.getContact(id);
  }

  public async postOutboundMessage(caseId: string, text: string) {
    const contact = this.contacts.find((c) => c.id === caseId);

    if (!contact) return;

    const response: { consumerContact: Contact } = await this.proxyApi.create(
      {
        url: `${this.areaApi}/dfo/3.0/channels/${contact.channelId}/outbound`,
        method: "POST",
        headers: this.headers,
        body: JSON.stringify({
          attachments: [],
          messageContent: { payload: { text }, type: "TEXT" },
          recipients: [contact.recipients[0]],
          thread: { idOnExternalPlatform: contact.threadIdOnExternalPlatform },
        } as OutboundMessage),
      },
      { urlPostfix: "proxy?outbound" }
    );

    if (response.consumerContact) {
      this.appendContact(response.consumerContact);
    }
  }

  /** start polling for events indefinitely */
  public async init(token: INiceTokenResponse): Promise<void> {
    let resolveTask: () => void;
    this.initTask = new Promise((resolve) => (resolveTask = resolve));

    this.securityApi ??= this.rtc
      .getAuviousCommonClient()
      .apiResourceFactory("security");

    this.proxyApi ??= this.rtc
      .getAuviousCommonClient()
      .apiResourceFactory("api/cx");

    this.accessToken = token.oidc_access_token;
    this.refreshToken = token.oidc_refresh_token;

    if (this.pollState !== PollState.idle) return;

    const jwtDecoded: ICXToken = JSON.parse(
      decodeBase64(this.accessToken.split(".")[1])
    );

    this.agentId = +jwtDecoded.icAgentId;
    this.tenantId = jwtDecoded.tenantId;
    this.serviceConfig = await this.getServiceConfig();
    this.clusterApi = this.clusterApi.replace(
      "{cluster}",
      jwtDecoded.icClusterId
    );

    this.refreshAccessToken(token.oidc_expires_in * 1000 + Date.now());

    try {
      const [joinSession, agentSettings] = await Promise.all([
        firstValueFrom(
          this.httpClient.post<{ sessionId: string }>(
            `${this.clusterApi}/agent-sessions/join?asAgentId=${this.agentId}`,
            null,
            {
              headers: this.headers,
            }
          )
        ),
        firstValueFrom(
          this.httpClient.get<ICXAgentSettings>(
            `${this.clusterApi}/agents/${this.agentId}/agent-settings`,
            {
              headers: this.headers,
            }
          )
        ),
      ]);

      this.areaApi = new URL(agentSettings.digitalEngagementUrl).origin;
      this.sessionID = joinSession.sessionId;
      this.pollState = PollState.polling;

      const me: DigitalUserDetails = await this.proxyApi.create(
        {
          url: `${this.areaApi}/dfo/3.0/me`,
          method: "GET",
          headers: this.headers,
        },
        { urlPostfix: "proxy?me" }
      );

      this.dfoAgentId = me.user.id;
      this.fullName =
        me.user.fullName || me.user.firstName + " " + me.user.surname;
      this.fullName.trim();
    } catch (ex) {
      debugError(ex);
    } finally {
      resolveTask();
      this.initTask = Promise.resolve();
    }

    this.setupWebsocket(
      this.areaApi.replace("https://app-", "wss://event-hub-")
    );

    this.loopPoll();
  }

  private async getServiceConfig(): Promise<INiceConfig> {
    const storageKey = "auvious.cx.one.config";
    const strConfig = sessionStore.getItem(storageKey);

    if (strConfig) {
      return JSON.parse(strConfig);
    } else {
      const openidConfig = await firstValueFrom(
        this.httpClient.get<INiceConfig>(
          `https://cxone.niceincontact.com/.well-known/cxone-configuration?tenantId=${this.tenantId}`
        )
      );

      sessionStore.setItem(storageKey, JSON.stringify(openidConfig));
      return openidConfig;
    }
  }

  private async setupWebsocket(url: string) {
    const websocket = new WebSocket(url);

    // @ts-ignore
    window.cxone = this;

    const heartbeatInterval = 30000;
    let heartbeatTimer = -1;

    websocket.onopen = () => {
      websocket.send(
        JSON.stringify({
          action: "register",
          payload: {
            token: this.accessToken,
          },
        })
      );

      heartbeatTimer = window.setInterval(() => {
        websocket.send(JSON.stringify({ action: "heartbeat" }));
      }, heartbeatInterval);
    };

    websocket.onmessage = (event: MessageEvent<NiceWSEvents | string>) => {
      const payload: NiceWSEvents =
        typeof event.data === "string" && event.data.startsWith("{")
          ? JSON.parse(event.data)
          : event.data;

      if (payload?.eventType) this.handleWSEvent(payload);
    };

    websocket.onerror = (error) => {
      console.warn("WebSocket error:", error);
    };

    websocket.onclose = async (event) => {
      console.log("WebSocket connection closed", event);

      if (heartbeatTimer !== -1) {
        clearInterval(heartbeatTimer);
      }

      // restart in case of error
      if (event.code > 1002 && this.pollState === PollState.polling) {
        await this.waitFor(this.pollThrottle);
        this.setupWebsocket(url);
      }
    };
  }

  private waitFor(timeout: number) {
    return new Promise((resolve) => this.timers.setTimeout(resolve, timeout));
  }

  /**
   * @param expiresAt - seconds since epoch
   */
  private async refreshAccessToken(expiresAt: number) {
    await this.waitFor(
      Math.ceil(Math.max(1000, expiresAt - Date.now()) * 0.75)
    );

    let response: ICXRefreshTokenResponse;

    while (expiresAt < Date.now()) {
      try {
        response = await this.securityApi.create(
          {
            refresh_token: this.refreshToken,
          },
          { urlPostfix: "nice/refresh_token" }
        );

        break;
      } catch (ex) {
        this.errorHandler.handleError(ex);
        await this.waitFor(60 * 1000);
      }
    }

    this.accessToken = response.access_token;
    this.refreshToken = response.refresh_token;

    this.refreshAccessToken(response.expires_in * 1000 + Date.now());
  }

  private async loopPoll() {
    if (this.pollState === PollState.done) return;

    if (Date.now() - this.lastPoll < this.pollThrottle)
      await new Promise((resolve) =>
        setTimeout(resolve, this.pollThrottle - (Date.now() - this.lastPoll))
      );

    this.lastPoll = Date.now();
    this.poll();
  }

  private async poll() {
    try {
      const url = `${this.clusterApi}/agent-sessions/${this.sessionID}/get-next-event?timeout=${this.pollingTimeout}`;
      const result = await firstValueFrom(
        this.httpClient.get<CXPollResult>(url, {
          headers: this.headers,
        })
      );

      this.sessionID = result.sessionId;

      for (const event of result.events) this.handleEvent(event);
    } catch (ex) {
      if (!Number.isInteger(ex.status) || ex.status >= 400)
        this.errorHandler.handleError(ex);
    } finally {
      this.loopPoll();
    }
  }

  private handleEvent(event: CXEvents) {
    console.warn("polling", event);

    switch (event.Type) {
      case "AgentSessionEnd":
      case "RemoteAgentSessionEnd":
        this.pollState = PollState.done;
        break;
      default:
        break;
    }
  }

  // user requests chat when sends message
  // case with status new even when assigned
  // agent responds, case status pending
  // user responds, case status open
  private async handleWSEvent(event: NiceWSEvents) {
    console.warn("ws", event);

    switch (event.eventType) {
      case PushUpdateEventType.ASSIGNED_AGENT_CHANGED:
        {
          const conversation = event.data.case as Case;
          const endUser = conversation.authorEndUserIdentity;

          if (event.data.case.status === ContactStatus.CLOSED) {
            return;
          } else if (event.data.inboxAssignee?.agentId === this.agentId) {
            this.handlers.agentJoined?.(
              `${event.data.inboxAssignee.firstName} ${event.data.inboxAssignee.surname}`.trim()
            );

            // @ts-expect-error
            this.appendContact(conversation as Contact);

            this.handlers.customerJoined?.(endUser?.fullName);

            this.handlers.started?.({
              channel: ConversationChannelEnum.cxoneDFO,
              conversationId: conversation.id,
              origin: ConversationOriginEnum.CHAT,
              room: null,
              type: ConversationTypeEnum.chat,
            });
          } else if (!event.data.inboxAssignee) {
            // inboxAssignee is null
            this.handlers.transferring?.(conversation.id!);
          } else if (!event.data.previousInboxAssignee) {
            // inboxAssignee is someone else
            this.handlers.transferred?.(conversation.id!);
          } else if (
            event.data.previousInboxAssignee?.agentId === this.agentId
          ) {
            // inboxAssignee is someone else
            await this.handlers.transferring?.(conversation.id!);
            this.handlers.transferred?.(conversation.id!);
          }
        }

        break;
      case PushUpdateEventType.CONTACT_STATUS_CHANGED:
        if (event.data.case.status === ContactStatus.CLOSED) {
          const conversation: Case = event.data.case as Case;
          this.handlers.ended?.(conversation.id!);
        }

        break;
      default:
        break;
    }
  }

  // which conversations are not closed?
  // GET https://api-de-na1.niceincontact.com/dfo/3.0/contacts?query=NOT%20%28status%20%3D%20closed%29'
}
