<av-card [popup]="true" vAlign="top">
  <!-- [@slideInOut]="true" // caused page to reload for some reason -->
  <form #f="ngForm">
    <av-card-header
      (close)="cancel()"
      subtitle="Please fill in the email address"
      translate
    >
      Customer's Email
    </av-card-header>
    <av-card-body>
      <av-control-row type="text">
        <input
          required
          placeholder="ex: customer@email.com"
          #formEmail="ngModel"
          name="address"
          [(ngModel)]="address"
          avInput
          email
          type="email"
          data-tid="conference/input-email-address"
        />
      </av-control-row>
      <div
        class="form-error"
        *ngIf="formEmail.touched && formEmail.hasError('required')"
        translate
      >
        Required
      </div>
      <div
        class="form-error"
        *ngIf="formEmail.touched && formEmail.hasError('email')"
        translate
      >
        Invalid email
      </div>
    </av-card-body>
    <av-card-footer>
      <button
        avButton
        [loading]="isPosting"
        [disabled]="isPosting"
        size="block"
        (click)="send(f.form)"
        color="accent"
        data-tid="conference/send-email"
        translate
      >
        Send Email
      </button>
    </av-card-footer>
  </form>
</av-card>
