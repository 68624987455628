export interface ICallHoldState {
  loading?: boolean;
  isOnHold?: boolean;
  username?: string;
  endpoint?: string;
}

export const DEFAULT_CALL_HOLD_STATE: ICallHoldState = {
  loading: false,
  isOnHold: false,
};
