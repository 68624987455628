import { Injectable } from "@angular/core";
import { ITicket } from "../../../core-ui/models";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { PublicTicketService, ThemeService } from "../../../core-ui";
import { ErrorPageCode } from "../../../app/app.enums";
import { PARAM_TICKET_ID } from "../../../core-ui/core-ui.enums";

@Injectable({ providedIn: "root" })
export class TicketResolver  {
  private ticketNotFound() {
    this.router.navigate(["/error", ErrorPageCode.SCHEDULE_NOT_FOUND]);
    return null;
  }

  constructor(
    private themeService: ThemeService,
    private ticketService: PublicTicketService,
    private router: Router
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ITicket> {
    const ticket = route.paramMap.get(PARAM_TICKET_ID);
    if (!ticket) {
      return this.ticketNotFound();
    }
    try {
      const ticketData = await this.ticketService.getTicket(ticket);
      if (ticketData.properties.theme) {
        this.themeService.setOptions(ticketData.properties.theme);
      }
      return ticketData;
    } catch (ex) {
      return this.ticketNotFound();
    }
  }
}
