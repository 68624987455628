import {
  KEY_ROOM_NAME,
  WIDGET_REFERRER_ID,
  WIDGET_CONVERSATION_TYPE,
  TAG_CUSTOMER_CALLBACK_ORIGIN,
  KEY_APPOINTMENT_ID,
  GenesysCloudMemberState,
  GenesysCloudMemberRole,
  GenesysCloudMemberDirection,
} from "../app.enums";
import { debug } from "../app.utils";
import {
  ConversationTypeEnum,
  ConversationChannelEnum,
} from "../../core-ui/core-ui.enums";
import {
  IConversationEventHandlers,
  ConversationOriginEnum,
} from "../../core-ui";
import { IGenesysAgent } from "../models";
import { PureCloudService } from "./purecloud.service";

type conversationState =
  | "alerted"
  | "started"
  | "ending"
  | "ended"
  | "transferring"
  | "transferred"
  | "held";

export class PureCloudEventService {
  private notificationChannel;
  private webSocket: WebSocket;

  private conversationsTopic;
  private chatTopic;
  private callbackTopic;
  private messageTopic;
  private phoneTopic;
  private websocketConnectRetries = 0;

  private handlers: IConversationEventHandlers = {};

  private eventState: Map<string, conversationState> = new Map();

  private userId: string;

  constructor(private notificationsApi) {}

  public registerEventHandlers(handlers: IConversationEventHandlers) {
    this.handlers = handlers || {};
  }

  public subscribeToTopics(topics: string[]): Promise<void> {
    // Subscribe to authenticated user's communications
    const body = topics.map((topic) => ({ id: topic }));
    return new Promise((resolve) => {
      this.notificationsApi
        .postNotificationsChannelSubscriptions(
          this.notificationChannel.id,
          body
        )
        .then((sub) => {
          debug("added subscription ", sub);
          resolve();
        });
    });
  }

  public subscribeToNotifications(): Promise<void> {
    // Create notification channel
    return this.notificationsApi.postNotificationsChannels().then((channel) => {
      debug("channel: ", channel);
      this.notificationChannel = channel;
      // Set up web socket
      this.connectToWebsocket(this.notificationChannel.connectUri);
    });
  }

  private connectToWebsocket(uri: string) {
    if (this.websocketConnectRetries < 5) {
      // Set up web socket
      this.webSocket = new WebSocket(uri);
      this.webSocket.onmessage = this.handleNotification.bind(this);
      this.webSocket.onopen = () => {
        this.websocketConnectRetries = 0;
      };
      this.webSocket.onerror = (e) => {
        this.webSocket.close();
      };
      this.webSocket.onclose = () => {
        // reconnect after 1 sec
        setTimeout(() => {
          ++this.websocketConnectRetries;
          this.connectToWebsocket(uri);
        }, 1000);
      };
    } else {
      throw new Error("Maximum retries to connect to websocket reached");
    }
  }

  public listenToUserTopics(userId: string): Promise<void> {
    this.userId = userId;
    // this.conversationsTopic = `v2.routing.queues.${userId}.conversations`;
    this.chatTopic = `v2.users.${userId}.conversations.chats`;
    this.callbackTopic = `v2.users.${userId}.conversations.callbacks`;
    this.messageTopic = `v2.users.${userId}.conversations.messages`;
    this.phoneTopic = `v2.users.${userId}.conversations.calls`;
    return this.subscribeToTopics([
      this.chatTopic,
      this.callbackTopic,
      this.messageTopic,
      this.phoneTopic,
    ]);
  }

  private extractMember(members: any[]) {
    if (members.length === 1) {
      return members[0];
    } else if (members.length > 1) {
      // comes from 'make eligible ..'
      return (
        members.find(
          (a) =>
            a.state === GenesysCloudMemberState.ALERTING ||
            a.state === GenesysCloudMemberState.CONNECTED
        ) ||
        members.find(
          (a) =>
            a.state === GenesysCloudMemberState.DISCONNECTED &&
            a.disconnectType === "client"
        ) ||
        members.find(
          (a) => a.state === GenesysCloudMemberState.DISCONNECTED && !a.wrapup
        ) ||
        members.find(
          (a) => a.state === GenesysCloudMemberState.DISCONNECTED && !!a.wrapup
        ) ||
        members.find((a) => a.state === GenesysCloudMemberState.DISCONNECTED)
      );
    }
  }

  // Handle incoming PureCloud notification from WebSocket
  private handleNotification(message) {
    // Parse notification string to a JSON object
    const notification = JSON.parse(message.data);

    // Discard unwanted notifications such as  Heartbeat
    if (notification.topicName.toLowerCase() === "channel.metadata") {
      return;
    }

    if (!("eventBody" in notification)) {
      return;
    }
    if (!("participants" in notification.eventBody)) {
      return;
    }

    const conversationId = notification.eventBody.id;

    const me = notification.eventBody.participants.find(
      (p) => p.user?.id === this.userId
    );

    const customers: any[] = notification.eventBody.participants.filter(
      (p) => p.purpose === GenesysCloudMemberRole.CUSTOMER
    );

    const agents: IGenesysAgent[] = notification.eventBody.participants.filter(
      (p) => p.purpose === GenesysCloudMemberRole.AGENT
    );

    // const queue: IGenesysQueue = notification.eventBody.participants.find(
    //   (p) => p.purpose === GenesysCloudMemberRole.QUEUE
    // );

    const customer = this.extractMember(customers);

    const agentsThatAreMe = agents
      .filter((a) => a.user?.id === this.userId)
      .sort(
        (a, b) =>
          new Date(b.connectedTime).valueOf() -
          new Date(a.connectedTime).valueOf()
      );
    // if I transfer the call to a queue but comes back to me by that queue, I will be two times
    // one as transfer and one new
    const agentsThatAreMeButTransferred = agentsThatAreMe.filter(
      (a) => a.disconnectType !== "transfer"
    );

    const agentsThatAreNotMe = agents.filter(
      (a) =>
        a.user?.id !== this.userId &&
        a.state !== GenesysCloudMemberState.DISCONNECTED
    );

    const nextAvailableAgent = this.extractMember(agentsThatAreNotMe);

    const agent: IGenesysAgent = this.extractMember(
      agentsThatAreMeButTransferred.length > 0
        ? agentsThatAreMeButTransferred
        : agentsThatAreMe
    );

    if (!customer || !agent) {
      return;
    }

    /**
     * NOTE:
     * If we discard this event(A) and wait for the other agent to accept the call(B) and at that time trigger the
     * 'transferring' event, then on an auto-start flow, the 2nd agent joins before the 1st agent was disconnected
     * from the call, ending up having both agents on the call..
     * Otherwise we could just return here and use the 'transfer to queue' 'if' statement(C) along with the rules
     * from the 'agent transferred'(B) to group the events into one 'if'(C).
     */

    //A: I just selected an agent to transfer the call
    if (
      agent.state === GenesysCloudMemberState.CONNECTED &&
      nextAvailableAgent?.state === GenesysCloudMemberState.ALERTING
    ) {
      return (
        this.canNotify(conversationId, "transferring") &&
        this.handlers.transferring?.(conversationId)
      );
    }

    //B: The other agent accepted the transfer
    if (
      !!nextAvailableAgent &&
      agent.state === GenesysCloudMemberState.DISCONNECTED &&
      !agent.wrapup && // this is 'ended' event
      [
        GenesysCloudMemberState.CONNECTED,
        GenesysCloudMemberState.ALERTING,
      ].includes(nextAvailableAgent?.state)
    ) {
      return (
        this.canNotify(conversationId, "transferred") &&
        this.handlers.transferred?.(conversationId)
      );
    }

    if (
      //C: I just selected a queue to transfer the call
      !nextAvailableAgent &&
      agent.state === GenesysCloudMemberState.DISCONNECTED &&
      agent.disconnectType === "transfer"
    ) {
      if (!!agent.wrapup) {
        return (
          this.canNotify(conversationId, "transferred") &&
          this.handlers.transferred?.(conversationId)
        );
      } else {
        return (
          this.canNotify(conversationId, "transferring") &&
          this.handlers.transferring?.(conversationId)
        );
      }
    }

    /* this block has the 1st agent leave the call only when the 2nd agent has answered 
    // stop the flow if we just selected an agent
    if (
      agent.state === GenesysCloudMemberState.CONNECTED &&
      nextAvailableAgent?.state === GenesysCloudMemberState.ALERTING
    ) {
      return;
    }

    if (
      //I just selected a queue to transfer the call
      (!nextAvailableAgent &&
        agent.state === GenesysCloudMemberState.DISCONNECTED &&
        agent.disconnectType === "transfer") ||
      // The agent I transferred the call picked it up
      (!!nextAvailableAgent &&
        agent.state === GenesysCloudMemberState.DISCONNECTED &&
        [
          GenesysCloudMemberState.CONNECTED,
          GenesysCloudMemberState.ALERTING,
        ].includes(nextAvailableAgent?.state))
    ) {
      if (!!agent.wrapup) {
        return (
          this.canNotify(conversationId, "transferred") &&
          this.handlers.transferred?.(conversationId)
        );
      } else {
        return (
          this.canNotify(conversationId, "transferring") &&
          this.handlers.transferring?.(conversationId)
        );
      }
    }
    */

    // no transfer, just normal flow
    const room =
      customer.attributes[KEY_ROOM_NAME] ||
      customer.attributes[`context.${KEY_ROOM_NAME}`];

    switch (notification.topicName.toLowerCase()) {
      case this.chatTopic.toLowerCase():
        debug("chat notification: ", notification);

        this.decideState(
          customer,
          agent,
          conversationId,
          ConversationChannelEnum.genesysWebChat,
          room
        );
        break;
      case this.callbackTopic.toLowerCase():
        debug("callback notification: ", notification);

        // discard notification if not carrying a room
        if (!room) {
          return;
        }

        this.decideState(
          customer,
          agent,
          conversationId,
          ConversationChannelEnum.genesysCallback,
          room
        );
        break;

      case this.messageTopic.toLowerCase():
        debug("web messenger notification: ", notification);
        this.decideState(
          customer,
          agent,
          conversationId,
          PureCloudService.getConversationChannel(customer),
          room
        );
        break;

      case this.phoneTopic.toLowerCase():
        debug("call(phone) notification: ", notification);

        this.decideState(
          customer,
          agent,
          conversationId,
          ConversationChannelEnum.genesysCall,
          room
        );

        break;
    }
  }

  private canNotify(conversationId: string, state: conversationState): boolean {
    if (!this.eventState.has(conversationId)) {
      this.eventState.set(conversationId, state);
      return true;
    }
    if (
      this.eventState.has(conversationId) &&
      this.eventState.get(conversationId) !== state
    ) {
      this.eventState.set(conversationId, state);
      return true;
    }
    return false;
  }

  private decideState(
    customer,
    agent,
    conversationId,
    channel: ConversationChannelEnum,
    room?: string
  ) {
    if (
      (customer.state === GenesysCloudMemberState.CONNECTED ||
        customer.state === GenesysCloudMemberState.DIALING) &&
      agent.state === GenesysCloudMemberState.ALERTING
    ) {
      this.canNotify(conversationId, "alerted") &&
        this.handlers.alerted?.(conversationId);
    } else if (
      ((customer.state === GenesysCloudMemberState.DIALING ||
        customer.state === GenesysCloudMemberState.CONNECTED) &&
        agent.state === GenesysCloudMemberState.DISCONNECTED) ||
      (customer.state === GenesysCloudMemberState.DISCONNECTED &&
        agent.state === GenesysCloudMemberState.ALERTING)
    ) {
      if ("wrapup" in agent) {
        this.canNotify(conversationId, "ended") &&
          this.handlers.ended?.(conversationId);
      } else {
        this.canNotify(conversationId, "ending") &&
          this.handlers.ending?.(conversationId);
      }
    } else if (
      (customer.state === GenesysCloudMemberState.DIALING ||
        customer.state === GenesysCloudMemberState.DISCONNECTED) &&
      agent.state === GenesysCloudMemberState.TERMINATED
    ) {
      return (
        this.canNotify(conversationId, "ended") &&
        this.handlers.ended?.(conversationId)
      );
    } else if (
      (customer.state === GenesysCloudMemberState.DISCONNECTED &&
        agent.state === GenesysCloudMemberState.DISCONNECTED) ||
      (customer.state === GenesysCloudMemberState.TERMINATED &&
        agent.state === GenesysCloudMemberState.TERMINATED)
    ) {
      if ("wrapup" in agent) {
        debug("conversation wraped-up");
        this.canNotify(conversationId, "ended") &&
          this.handlers.ended?.(conversationId);
      } else {
        debug("conversation ended");
        this.canNotify(conversationId, "ending") &&
          this.handlers.ending?.(conversationId);
      }
    } else if (
      customer.state === GenesysCloudMemberState.CONNECTED &&
      agent.state === GenesysCloudMemberState.CONNECTED
    ) {
      if (!agent.held) {
        this.handlers.agentJoined?.(
          [
            ConversationChannelEnum.genesysWebMessaging,
            ConversationChannelEnum.genesysFacebookMessenger,
            ConversationChannelEnum.genesysWhatsApp,
            ConversationChannelEnum.genesysMessaging,
          ].includes(channel)
            ? undefined // this event for web messaging carries the web messaging config name, not the agent name
            : agent.name
        );
        this.handlers.customerJoined?.(customer.name);

        // discover origin
        const origin = !!customer.attributes[`context.${KEY_APPOINTMENT_ID}`]
          ? ConversationOriginEnum.APPOINTMENT
          : !!customer.attributes[`context.${WIDGET_REFERRER_ID}`]
          ? ConversationOriginEnum.WIDGET
          : channel === ConversationChannelEnum.genesysCall
          ? ConversationOriginEnum.PHONE
          : ConversationOriginEnum.CHAT;

        const cTypeKey = `context.${WIDGET_CONVERSATION_TYPE}`;

        const type =
          customer.attributes?.[cTypeKey] || ConversationTypeEnum.chat;

        debug("conversation started");
        switch (channel) {
          case ConversationChannelEnum.genesysWebMessaging:
          case ConversationChannelEnum.genesysWhatsApp:
          case ConversationChannelEnum.genesysFacebookMessenger:
          case ConversationChannelEnum.genesysMessaging:
          case ConversationChannelEnum.genesysWebChat:
          case ConversationChannelEnum.genesysCall:
            this.canNotify(conversationId, "started") &&
              this.handlers.started?.({
                conversationId,
                origin,
                type,
                room,
                channel,
              });
            break;
          case ConversationChannelEnum.genesysCallback:
            this.canNotify(conversationId, "started") &&
              this.handlers.started?.({
                conversationId,
                origin:
                  "attributes" in customer &&
                  TAG_CUSTOMER_CALLBACK_ORIGIN in customer.attributes
                    ? customer.attributes[TAG_CUSTOMER_CALLBACK_ORIGIN]
                    : ConversationOriginEnum.WIDGET,
                type: ConversationTypeEnum.callback,
                room,
                channel,
              });
            break;
        }
      } else {
        // when both agent & customer are online and the agent selects another interaction
        // the active interaction is held.
        this.handlers.agentHeld?.(conversationId);
        this.eventState.set(conversationId, "held");
      }
    }
  }
}
