import {
  Component,
  OnInit,
  Input,
  Renderer2,
  HostListener,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-magnify-glass",
  templateUrl: "./magnify-glass.component.html",
  styleUrls: ["./magnify-glass.component.scss"],
})
export class MagnifyGlassComponent implements OnInit {
  @Input() image: HTMLImageElement;
  @Input() zoom = 2;
  @Input() mirror: boolean;

  constructor(
    private renderer: Renderer2,
    private host: ElementRef<HTMLDivElement>
  ) {}

  ngOnInit(): void {
    this.renderer.setStyle(
      this.host.nativeElement,
      "background-image",
      `url(${this.image.src})`
    );
    this.renderer.setStyle(
      this.host.nativeElement,
      "backgroundSize",
      `${this.image.width * this.zoom}px ${this.image.height * this.zoom}px`
    );
    // this.zone.runOutsideAngular(() => {
    //   this.image.addEventListener('mousemove', this.move.bind(this));
    // });
  }

  // @HostBinding('class') get class() {
  //   return {
  //     'mirror': this.mirror
  //   };
  // }

  @HostListener("mousemove", ["$event"])
  @HostListener("touchmove", ["$event"])
  move(e: MouseEvent | TouchEvent) {
    e.preventDefault();

    const pos = this.getCursorPos(e);
    let x = pos.x;
    let y = pos.y;
    const w = this.host.nativeElement.offsetWidth / 2;
    const h = this.host.nativeElement.offsetHeight / 2;
    const img = this.image;
    const borderWidth = 3;

    // Prevent the magnifier glass from being positioned outside the image:
    if (x > img.width - w / this.zoom) {
      x = img.width - w / this.zoom;
    }
    if (x < w / this.zoom) {
      x = w / this.zoom;
    }
    if (y > img.height - h / this.zoom) {
      y = img.height - h / this.zoom;
    }
    if (y < h / this.zoom) {
      y = h / this.zoom;
    }

    // Set the position of the magnifier glass
    this.renderer.setStyle(this.host.nativeElement, "left", `${x - w}px`);
    this.renderer.setStyle(this.host.nativeElement, "top", `${y - h}px`);

    // Display what the magnifier glass "sees"
    const left = x * this.zoom - w + borderWidth;
    const top = y * this.zoom - h + borderWidth;
    this.renderer.setStyle(
      this.host.nativeElement,
      "backgroundPosition",
      `-${left}px -${top}px`
    );
  }

  getCursorPos(e): { x; y } {
    // Get the x and y positions of the image
    const a = this.image.getBoundingClientRect();
    // Calculate the cursor's x and y coordinates, relative to the image:
    let x = e.pageX - a.left;
    let y = e.pageY - a.top;
    // Consider any page scrolling
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x, y };
  }
}
