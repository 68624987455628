import {
  ConversationChannelEnum,
  ConversationTypeEnum,
  IConversationEventHandlers,
  ITranscript,
  ProtectedTicketService,
} from "../../../../core-ui";
import {
  IInteraction,
  IInteractionDetails,
  IInteractionMessageOptions,
} from "../../../../core-ui/models/IInteraction";
import { IInteractionStrategy } from "../../../../core-ui/models/strategies";

import { DigitalConnectService } from "../../../services";
import { PARAM_CALL_TYPE } from "../../../app.enums";

export class TalkdeskInteractionStrategy implements IInteractionStrategy {
  private activeInteraction: IInteraction;

  constructor(
    private digitalConnect: DigitalConnectService,
    private ticketService: ProtectedTicketService
  ) {}

  isActiveInteractionAvailable(): boolean {
    return !!this.activeInteraction;
  }
  getActiveInteraction(): IInteraction {
    return this.activeInteraction;
  }
  setActiveInteraction(interaction: IInteraction) {
    this.activeInteraction = interaction;
  }
  clearActiveInteraction() {
    this.activeInteraction = null;
  }
  async discoverActiveInteraction(): Promise<IInteraction> {
    const interaction = await this.digitalConnect.discoverActiveInteraction();
    if (interaction) {
      this.setActiveInteraction(interaction);
    }
    return interaction;
  }

  async retrieveInteractionData(id: string) {
    return this.digitalConnect.retrieveInteractionData(id);
  }

  registerConversationEventHandlers(handlers: IConversationEventHandlers) {
    this.digitalConnect.registerEventHandlers(handlers);
  }

  prepareInteractionScheduleRequest() {
    return null;
  }
  scheduleInteraction() {
    return null;
  }
  createEmailInteraction() {
    return null;
  }

  invite(
    toConversationType: ConversationTypeEnum,
    interaction: IInteraction,
    ticket: string,
    options?: IInteractionMessageOptions
  ) {
    switch (toConversationType) {
      case ConversationTypeEnum.cobrowse:
        return this.digitalConnect.cobrowseRequested(
          interaction.getIntegrationId(),
          ticket,
          options.requestType
        );
      case ConversationTypeEnum.chat:
      case ConversationTypeEnum.videoCall:
      case ConversationTypeEnum.voiceCall:
        // const url = this.ticketService.getTicketWithDomain(ticket);
        let link: URL | string = "";
        const customerLink = this.ticketService.getTicketWithDomain(ticket);
        try {
          link = new URL(customerLink);
          link.searchParams.set(PARAM_CALL_TYPE, interaction.getType());
        } catch (ex) {
          // this.errorHandler.handleError(ex.message + " -> " + customerLink);
          link =
            customerLink.indexOf("?") > -1
              ? `${link}&${PARAM_CALL_TYPE}=${interaction.getType()}`
              : `${link}?${PARAM_CALL_TYPE}=${interaction.getType()}`;
        }
        const url = link.toString();
        return this.digitalConnect.callCreated(
          interaction.getIntegrationId(),
          url
        );
    }
  }

  getInvitation(
    forConversationType: ConversationTypeEnum,
    ticket: string,
    options?: IInteractionMessageOptions
  ): string {
    return null;
  }

  postMessageToInteraction(
    interaction: IInteraction,
    message: string,
    options?: IInteractionMessageOptions
  ): Promise<void> {
    return null;
  }
  createSMSInteraction() {
    return null;
  }
  async getChatTranscript(
    interactionId: string,
    channel: ConversationChannelEnum,
    fromStorageProvider: boolean
  ): Promise<ITranscript> {
    return this.digitalConnect.getChatTranscript(
      interactionId,
      channel,
      fromStorageProvider
    );
  }
  getInteractionDetails(): Promise<IInteractionDetails> {
    return null;
  }
}
