import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { IMessage } from "@auvious/integrations";
import {} from "@auvious/rtc";
import { ConversationService } from "../../../app/services/conversation.service";
import { Subscription } from "rxjs";
import { ChangeDetectorRef, ViewChild } from "@angular/core";
import { CardBodyComponent } from "../../../modules/shared/components/card-body/card-body.component";

@Component({
  selector: "app-conversation",
  templateUrl: "./conversation.component.html",
  styleUrls: ["./conversation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationComponent implements OnInit, OnDestroy {
  messages: IMessage[] = [];

  subscription: Subscription = new Subscription();

  @ViewChild("bodyRef") bodyRef: CardBodyComponent;

  constructor(
    private conversation: ConversationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.conversation.messageReceived$.subscribe((message) => {
        let filter;
        const localSenderFilter = (m: IMessage) =>
          m.status === "sent" && m.isLocal && m.senderId === message.senderId;
        switch (message.type) {
          case "file":
            filter = (m: IMessage) => m.file.name === message.file.name;
            break;
          case "text":
          case "bot":
            filter = (m: IMessage) => m.text?.trim() === message.text;
            break;
        }
        const exists = this.messages.filter(localSenderFilter).filter(filter);

        if (exists?.length > 0) {
          // get the oldest on the existing list and mark that as 'ack'
          exists.sort((a, b) => a.timestamp.valueOf() - b.timestamp.valueOf());
          const oldest = exists[0];
          this.messages = this.messages.map((m) =>
            // replace the oldest one
            oldest.id === m.id ? message : m
          );
        } else {
          this.messages = [...this.messages, message];
        }
        this.cd.detectChanges();
        this.scrollToBottom();
      })
    );
    this.subscription.add(
      this.conversation.messageSent$.subscribe((m) => {
        this.messages = [...this.messages, m];
        this.cd.detectChanges();
        this.scrollToBottom();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  scrollToBottom() {
    this.bodyRef.host.nativeElement.scrollTo({
      top: this.bodyRef.host.nativeElement.scrollHeight,
      behavior: "smooth",
    });
  }
}
