<div
  class="text-muted"
  *ngIf="!historyLoading && history.length === 0"
  translate
>
  No previous exports found
</div>

<av-card [backdrop]="false" *ngIf="history.length > 0">
  <av-card-row *ngFor="let composition of history; trackBy: trackByFn">
    <app-composition-row
      [composition]="composition"
      (navigate)="go($event)"
      (cancelRequested)="cancelExport($event)"
      (removed)="removed($event)"
    >
    </app-composition-row>
  </av-card-row>
  <av-card-row class="pager text-center" *ngIf="historyPager?.totalPages > 1">
    <av-pager
      [totalPages]="historyPager?.totalPages"
      [pageIndex]="historyPager?.number"
      (pageChange)="nextPageLoading($event)"
    ></av-pager>
  </av-card-row>
</av-card>
