import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { IFilterSelection } from "../../../../../core-ui/models/interfaces";

@Component({
  selector: "app-duration-filter",
  templateUrl: "./duration-filter.component.html",
  styleUrls: ["./duration-filter.component.scss"],
})
export class DurationFilterComponent implements OnInit {
  @Output() changed: EventEmitter<string[]> = new EventEmitter();

  durations = [
    moment.duration(5, "minutes"),
    moment.duration(15, "minutes"),
    moment.duration(30, "minutes"),
  ];

  selection: string[] = [];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  selectionChanged(filter: IFilterSelection<moment.Duration>) {
    this.selection = filter.checked
      ? [...this.selection, filter.value.toISOString()]
      : this.selection.filter((s) => s !== filter.value.toISOString());
    this.changed.emit(this.selection);
  }

  getDuration(d: moment.Duration) {
    return `${d.minutes()} ${this.translate.instant("minutes")}`;
  }
}
