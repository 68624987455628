import {
  SpeechToTextProviderType,
  TranslationProviderType,
} from "@auvious/asr";
import { ApplicationProviderTypeEnum } from "../../core-ui.enums";
import { IAppointmentNotification } from "../Appointment";

export interface IApplicationServiceParams extends IASROfflineServiceParams {
  storageProvider?: ApplicationProviderTypeEnum;
  storageProviderKey?: string;
  storageProviderBucket?: string;
  callbackParameters?: IApplicationServiceCallbackParams;
  storageProviderTested?: boolean;
  asr?: IASRServiceParams & IASRGoogleServiceParams;
  translate?: ITranslateServiceParams &
    ITranslateGoogleAdvancedServiceParams &
    ITranslateGoogleBasicServiceParams;
  appointmentParameters?: IAppointmentServiceParams;
  tokenSessionStoreEnabled?: boolean;
  digitalConnect?: IDigitalConnectServiceParams;
  estimatedWaitTimeParameters?: IEstimatedWaitTimeServiceParams;
}

export interface IASROfflineServiceParams
  extends IASROfflineServiceBaseParams,
    IASROfflineGoogleServiceParams {}

export interface IASROfflineServiceBaseParams {
  speechToTextStorageProvider?: ApplicationProviderTypeEnum;
  speechToTextStorageProviderBucket?: string;
  speechToTextStorageProviderKey?: string;
}

export interface IASROfflineGoogleServiceParams {
  googleAsrOfflineConfiguration?: string;
}

export const DEFAULT_SPEECH_TO_TEXT_OFFLINE_PARAMS: IASROfflineServiceParams = {
  speechToTextStorageProvider: null,
  speechToTextStorageProviderBucket: null,
  speechToTextStorageProviderKey: null,
  googleAsrOfflineConfiguration: null,
};

export interface IEstimatedWaitTimeServiceParams {
  clientId: string;
  clientSecret: string;
}

export interface IDigitalConnectServiceParams {
  clientCredentials?: {
    clientId?: string;
    clientSecret?: string;
  };
}

export interface IAppointmentServiceParams {
  deploymentId?: string; // genesys
  touchpointId?: string; // talkdesk
  base_url?: string;
  // to be deprecated
  clientId?: string;
  clientSecret?: string;
  // ----
  smsClientId?: string;
  smsClientSecret?: string;
  smsNumber?: string;
  notifications?: IAppointmentNotification[];
  notificationsEnabled?: boolean;
  notificationChannelSMSEnabled?: boolean;
  notificationChannelEmailEnabled?: boolean;
  notificationChannelWebhooksEnabled?: boolean;
  singleUseAppointmentTicketEnabled?: boolean;
  ticketLength?: number;
  emailClientId?: string;
  emailClientSecret?: string;
  emailDomain?: string;
  emailFromName?: string;
  autoActivate?: boolean;
  webhooksSubscriptionId?: string;
}

export interface IApplicationServiceCallbackParams {
  clientId: string;
  clientSecret: string;
}

export interface IGoogleCloudApplicationServiceParams
  extends IApplicationServiceParams {
  storageProviderKey: string;
}

export interface IAmazonS3ApplicationServiceParams
  extends IApplicationServiceParams {
  storageProviderAccessKeyId: string;
  storageProviderSecretAccessKey: string;
  storageProviderRegion: string;
}

export interface IMicrosoftAzureApplicationServiceParams
  extends IApplicationServiceParams {
  storageProviderConnectionString: string;
}

export interface ISFTPApplicationServiceParams
  extends IApplicationServiceParams {
  storageProviderHost: string;
  storageProviderPort: string;
  storageProviderUser: string;
  storageProviderKeyPairId: string;
  storageProviderKeyPairSource: "new" | "existing";
}

export const DEFAULT_STORAGE_GOOGLE_CLOUD_PARAMS: IGoogleCloudApplicationServiceParams =
  {
    storageProvider: ApplicationProviderTypeEnum.GOOGLE_CLOUD,
    storageProviderBucket: null,
    storageProviderKey: null,
    storageProviderTested: false,
  };

export const DEFAULT_STORAGE_AMAZON_S3_PARAMS: IAmazonS3ApplicationServiceParams =
  {
    storageProvider: ApplicationProviderTypeEnum.AMAZON_S3,
    storageProviderBucket: null,
    storageProviderAccessKeyId: null,
    storageProviderSecretAccessKey: null,
    storageProviderRegion: null,
    storageProviderTested: false,
  };

export const DEFAULT_STORAGE_MICROSOFT_AZURE_PARAMS: IMicrosoftAzureApplicationServiceParams =
  {
    storageProvider: ApplicationProviderTypeEnum.MICROSOFT_AZURE,
    storageProviderBucket: null,
    storageProviderConnectionString: null,
    storageProviderTested: false,
  };

export const DEFAULT_STORAGE_SFTP_PARAMS: ISFTPApplicationServiceParams = {
  storageProvider: ApplicationProviderTypeEnum.SFTP,
  storageProviderBucket: null, // base directory
  storageProviderHost: null,
  storageProviderPort: "22",
  storageProviderUser: null,
  storageProviderKeyPairId: null,
  storageProviderTested: false,
  storageProviderKeyPairSource: "new",
};

// export interface

export interface IASRServiceParams {
  providers?: SpeechToTextProviderType[];
}

export interface ITranslateServiceParams {
  providers?: TranslationProviderType[];
}

export interface ITranslateGoogleBasicServiceParams
  extends ITranslateServiceParams {
  GOOGLE_BASIC?: string;
}

export const DEFAULT_TRANSLATE_GOOGLE_BASIC_PARAMS: ITranslateGoogleBasicServiceParams =
  {
    GOOGLE_BASIC: null,
  };

export interface ITranslateGoogleAdvancedServiceParams
  extends ITranslateServiceParams {
  GOOGLE_ADVANCED?: string;
  googleTranslateAdvancedLocation?: string;
}

export const DEFAULT_TRANSLATE_GOOGLE_ADVANCED_PARAMS: ITranslateGoogleAdvancedServiceParams =
  {
    GOOGLE_ADVANCED: null,
    googleTranslateAdvancedLocation: null,
  };

export interface IASRGoogleServiceParams extends IASRServiceParams {
  GOOGLE?: string;
}

export const DEFAULT_ASR_GOOGLE_PARAMS: IASRGoogleServiceParams = {
  GOOGLE: null,
};

// export enum ServiceParam {
//   STORAGE_PROVIDER = "storageProvider",
//   STORAGE_PROVIDER_BUCKET = "storageProviderBucket",
// }

/** only role: ADMIN has access */
export class ServiceOptions implements IApplicationServiceParams {
  public static OAUTH_CLIENT_ID = "clientId";
  public static OAUTH_CLIENT_SECRET = "clientSecret";
  public static STORAGE_PROVIDER = "storageProvider";
  public static STORAGE_PROVIDER_BUCKET = "storageProviderBucket";
  public static ASR = "asr";
  public static TRANSLATE = "translate";
  public static APPOINTMENT = "appointmentParameters";
  public static DIGITAL_CONNECT = "digitalConnect";
  public static QUEUE_ESTIMATED_WAIT_TIME_PARAMS =
    "estimatedWaitTimeParameters";
  public static SPEECH_TO_TEXT_STORAGE_PROVIDER = "speechToTextStorageProvider";
  public static SPEECH_TO_TEXT_STORAGE_PROVIDER_BUCKET =
    "speechToTextStorageProviderBucket";
  public static SPEECH_TO_TEXT_STORAGE_PROVIDER_KEY =
    "speechToTextStorageProviderKey";
  public static GOOGLE_ASR_OFFLINE_CONFIGURATION =
    "googleAsrOfflineConfiguration";

  private _options: IApplicationServiceParams;

  constructor(options: IApplicationServiceParams) {
    this._options = options;
  }

  public get values(): IApplicationServiceParams {
    return {
      storageProvider: null,
      storageProviderBucket: null,
      storageProviderTested: false,
      speechToTextStorageProvider: null,
      speechToTextStorageProviderBucket: null,
      speechToTextStorageProviderKey: null,
      googleAsrOfflineConfiguration: null,
      ...this._options,
      callbackParameters: {
        clientId: null,
        clientSecret: null,
        ...this._options?.callbackParameters,
      },
      asr: {
        providers: [],
        ...this._options?.asr,
      },
      translate: {
        providers: [],
        ...this._options?.translate,
      },
      appointmentParameters: {
        base_url: "",
        deploymentId: null,
        clientId: null,
        clientSecret: null,
        notificationsEnabled: true,
        notificationChannelSMSEnabled: false,
        notificationChannelEmailEnabled: false,
        notificationChannelWebhooksEnabled: false,
        singleUseAppointmentTicketEnabled: false,
        ticketLength: 6,
        notifications: [],
        autoActivate: false,
        webhooksSubscriptionId: null,
        ...this._options?.appointmentParameters,
      },
      digitalConnect: {
        clientCredentials: {
          clientId: null,
          clientSecret: null,
          ...this._options?.digitalConnect?.clientCredentials,
        },
        ...this._options?.digitalConnect,
      },
      estimatedWaitTimeParameters: {
        clientId: null,
        clientSecret: null,
        ...this._options?.estimatedWaitTimeParameters,
      },
      // all first-level props should go at the top, before ...this._options
    };
  }
  public get oAuthClientId() {
    return this._options?.callbackParameters?.clientId;
  }
  public get oAuthClientSecret() {
    return this._options?.callbackParameters?.clientSecret;
  }
  public get storageProvider() {
    return this._options?.storageProvider;
  }
  public get storageProviderBucket() {
    return this._options?.storageProviderBucket;
  }
  public get asrProvider() {
    return this._options?.asr || { providers: [] };
  }
  public get translateProvider() {
    return this._options?.translate || { providers: [] };
  }
  public get appointmentParameters() {
    return this._options?.appointmentParameters;
  }
  public get appointmentDeploymentId() {
    return this._options?.appointmentParameters?.deploymentId;
  }
  public get appointmentBaseUrl() {
    return this._options?.appointmentParameters?.base_url;
  }
}
