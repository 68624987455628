import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
@Component({
  selector: "app-qr",
  templateUrl: "./qr.component.html",
  styleUrls: ["./qr.component.scss"],
  animations: [
    trigger("centerInOut", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(-45%)" }),
        animate(100),
      ]),
      transition(":leave", [
        animate(100, style({ opacity: 0, transform: "translateY(-45%)" })),
      ]),
      state("*", style({ opacity: 1, transform: "translateY(-50%)" })),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QRComponent implements OnInit {
  @Input() data: string;

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    if (this._visible) {
      this.animate = true;
      this.animateDestroy = setTimeout(() => {
        this.animate = false;
        this.cdr.detectChanges();
      }, 2400);
    } else {
      if (!!this.animateDestroy) {
        clearTimeout(this.animateDestroy);
      }
    }
  }
  get visible() {
    return this._visible;
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  animate: boolean;
  _visible = false;
  animateDestroy: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  cancel() {
    this.close.emit();
  }
}
