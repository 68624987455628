import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Device } from "@auvious/compatibility";
import { IDevice, MediaDevices } from "@auvious/media-tools";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import {
  LocalMediaService,
  MediaRulesService,
  NotificationService,
} from "../../services";

@Component({
  selector: "app-device-settings",
  templateUrl: "./device-settings.component.html",
  styleUrls: ["./device-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceSettingsComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  micOn = true;
  camOn = true;

  audioDeviceId = this.local.audioDevice?.deviceId;
  videoDeviceId = this.local.videoDevice?.deviceId;
  speakerDeviceId = this.local.speakerDevice?.deviceId;

  subscription: Subscription = new Subscription();

  constructor(
    private cd: ChangeDetectorRef,
    private mediaRules: MediaRulesService,
    private local: LocalMediaService,
    private alert: NotificationService,
    private translate: TranslateService
  ) {
    this.camOn = this.mediaRules.isVideoControlAvailable;
    this.micOn = this.mediaRules.isAudioControlAvailable;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.local.tracksChanged$.subscribe(() => {
        this.audioDeviceId = this.local.audioDevice?.deviceId;
        this.videoDeviceId = this.local.videoDevice?.deviceId;
        this.speakerDeviceId = this.local.speakerDevice?.deviceId;

        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  dismiss() {
    this.close.emit();
  }

  trackByFn(index: number, el: IDevice) {
    return el.deviceId;
  }

  async deviceChange(kind: MediaDeviceKind, id: string) {
    try {
      await this.local.openDeviceStream(kind, id);
    } catch {
      this.alert.error(
        this.translate.instant("Could not connect to camera and/or mic")
      );
    }
  }

  get isAudioDisabled() {
    return !(
      MediaDevices.has.audioinput &&
      MediaDevices.getDeviceList("audioinput").length > 0 &&
      this.micOn &&
      this.mediaRules.isAudioAvailable
    );
  }

  get isSpeakerDisabled() {
    return !(
      (
        MediaDevices.has.audiooutput &&
        MediaDevices.getDeviceList("audiooutput").length > 0
      )
      // this.micOn &&
      // this.mediaRules.isAudioAvailable
    );
  }

  get isSpeakerSupported() {
    return Device.isSpeakerSelectionSupported && MediaDevices.has.audiooutput;
  }

  get audioMediaDevices(): IDevice[] {
    return MediaDevices.getDeviceList("audioinput");
  }

  get videoMediaDevices(): IDevice[] {
    return MediaDevices.getDeviceList("videoinput");
  }

  get speakerMediaDevices(): IDevice[] {
    return MediaDevices.getDeviceList("audiooutput");
  }
}
