import {
  Component,
  HostBinding,
  OnInit,
  forwardRef,
  HostListener,
  Input,
  ElementRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "av-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements OnInit, ControlValueAccessor {
  @Input() type: "control" | "icon" = "control";

  // @Input()
  // set checked(value: boolean) {
  //   this.on = value;
  // }
  // get checked() {
  //   return this.on;
  // }

  @Input() disabled: boolean;

  // @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected on = false;
  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};

  constructor(private host: ElementRef<HTMLDivElement>) {}

  ngOnInit(): void {
    this.host.nativeElement.setAttribute("role", "checkbox");
    this.host.nativeElement.setAttribute("tabindex", "0");
  }

  writeValue(obj: any): void {
    this.on = !!obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  // public setChecked(value: boolean) {
  //   this.on = value;
  //   this.propagateTouched(value);
  //   this.propagateChange(this.on);
  // }

  @HostBinding("class") get class() {
    return {
      on: this.on,
      disabled: this.disabled,
      icon: this.type === "icon",
      control: this.type === "control",
    };
  }

  @HostListener("click", ["$event"])
  onClick(e) {
    // if (this.disabled) {
    //   return;
    // }
    // this.on = !this.on;
    // this.propagateTouched(this.on);
    // this.propagateChange(this.on);
    // this.checkedChange.emit(this.on);
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(event: KeyboardEvent): void {
    if (!this.disabled && (event.code === "Enter" || event.code === "Space")) {
      this.host.nativeElement
        .querySelector("input")
        .dispatchEvent(new MouseEvent("click"));
    }
  }

  // space should not scroll page when switch focused
  @HostListener("keydown.space", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  change(on) {
    this.host.nativeElement.setAttribute("aria-checked", String(on));
    this.propagateTouched(on);
    this.propagateChange(on);
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
