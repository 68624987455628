import { Injector } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { IJwtTokenResponse, IOAuth2Token } from "@auvious/common";

import { IUser } from "../IUser";
import { IAuthenticationStrategy } from "./IAuthenticationStrategy";
import { AuviousRtcService } from "../../services/rtc.service";
import { User } from "../User";
import { OAuth2TokenHelper } from "../OAuth2TokenHelper";
import { ITokenResponse } from "../ITokenResponse";
import { delay } from "../../services/utils";
import { PARAM_TICKET_ID } from "../../core-ui.enums";
import { firstValueFrom } from "rxjs";

export class TicketAuthenticationStrategy implements IAuthenticationStrategy {
  private rtcService: AuviousRtcService;
  private httpClient: HttpClient;

  constructor(
    injector: Injector,
    private authClientId: string,
    private persistAccessToken: boolean
  ) {
    this.rtcService = injector.get(AuviousRtcService);
    this.httpClient = injector.get(HttpClient);
  }

  async authenticate(route: ActivatedRouteSnapshot): Promise<IUser> {
    const ticketId = route.params[PARAM_TICKET_ID];
    const auviousCommonClient = await this.rtcService.createAuviousCommonClient();

    const token = OAuth2TokenHelper.getToken(ticketId);
    let userToken: IOAuth2Token;

    if (!!token) {
      if (OAuth2TokenHelper.isTokenExpired(token)) {
        try {
          sessionStorage.clear();
        } catch (ignored) {}

        window.location.reload();
        // delay now, before anyone who catches the error routes us elsewhere
        await delay(5000);
        throw new Error("redirecting");
      } else {
        // if we have a refresh token, let's try it now, because if it fails later
        // we're screwed
        if (
          !!token.refresh_token &&
          !OAuth2TokenHelper.isRefreshTokenExpired(token)
        ) {
          try {
            await this.refreshTokenFn(ticketId)(token.refresh_token);
          } catch (error) {
            // refresh-token failed so delete cached token (clear sessionStorage) and reload
            try {
              sessionStorage.clear();
            } catch (ex) {}
            window.location.reload();
            await delay(5000);
            throw error;
          }
          const newToken = OAuth2TokenHelper.getToken(ticketId);
          userToken = await auviousCommonClient.loginTemplate(
            () => Promise.resolve(newToken),
            this.refreshTokenFn(ticketId)
          );
        } else {
          userToken = await auviousCommonClient.loginTemplate(
            () => Promise.resolve(token),
            this.refreshTokenFn(ticketId)
          );
        }
      }
    } else {
      userToken = await auviousCommonClient.loginTemplate(async () => {
        const params = new URLSearchParams();
        params.set("client_id", this.authClientId);
        params.set("grant_type", "password");
        params.set("ticket", ticketId);

        const options = {
          headers: new HttpHeaders().set(
            "Content-Type",
            "application/x-www-form-urlencoded"
          ),
        };

        const tokenResponse = await firstValueFrom(
          this.httpClient.post<ITokenResponse>(
            "/security/oauth/token",
            params.toString(),
            options
          )
        );

        if (this.persistAccessToken) {
          // Save access token
          OAuth2TokenHelper.saveToken(ticketId, tokenResponse);
        }
        return tokenResponse;
      }, this.refreshTokenFn(ticketId));
    }

    return new User(userToken);
  }

  private refreshTokenFn(
    ticketId: string
  ): (refreshToken: string) => Promise<IJwtTokenResponse> {
    return async (refreshToken): Promise<IJwtTokenResponse> => {
      const params = new URLSearchParams();
      params.set("client_id", this.authClientId);
      params.set("grant_type", "refresh_token");
      params.set("refresh_token", refreshToken);

      const options = {
        headers: new HttpHeaders().set(
          "Content-Type",
          "application/x-www-form-urlencoded"
        ),
      };

      const tokenResponse = await firstValueFrom(
        this.httpClient.post<ITokenResponse>(
          "/security/oauth/token",
          params.toString(),
          options
        )
      );

      if (this.persistAccessToken) {
        // Save access token
        OAuth2TokenHelper.saveToken(ticketId, tokenResponse);
      }
      return tokenResponse;
    };
  }
}
