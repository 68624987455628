import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const fadeInOut = trigger("fadeInOut", [
  transition(":enter", [style({ opacity: 0 }), animate(300)]),
  transition(":leave", [animate(500, style({ opacity: 0 }))]),
  state("*", style({ opacity: 1 })),
]);

export const fadeOut = trigger("fadeOut", [
  transition(":leave", [animate(500, style({ opacity: 0 }))]),
  state("*", style({ opacity: 1 })),
]);

export const slideInOut = trigger("slideInOut", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateY(50px)" }),
    animate(100),
  ]),
  transition(":leave", [
    animate(100, style({ opacity: 0, transform: "translateY(-50px)" })),
  ]),
  state("*", style({ opacity: 1, transform: "translateY(0)" })),
]);

export const slideIn = trigger("slideIn", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateY(50px)" }),
    animate(100),
  ]),
  transition(":leave", [
    animate(100, style({ opacity: 0, transform: "translateY(50px)" })),
  ]),
  state("*", style({ opacity: 1, transform: "translateY(0)" })),
]);

export const slideFromUp = trigger("slideFromUp", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateY(-50px)" }),
    animate(100),
  ]),
  transition(":leave", [
    animate(100, style({ opacity: 0, transform: "translateY(-50px)" })),
  ]),
  state("*", style({ opacity: 1, transform: "translateY(0)" })),
]);

export const slideFromLeft = trigger("slideFromLeft", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateX(-60px)" }),
    animate(200),
  ]),
  transition(":leave", [
    animate(200, style({ opacity: 0, transform: "translateX(-60px)" })),
  ]),
  state("*", style({ opacity: 1, transform: "translateX(0)" })),
]);

export const slideFromRight = trigger("slideFromRight", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateX(80px)" }),
    animate(140),
  ]),
  transition(":leave", [
    animate(140, style({ opacity: 0, transform: "translateX(80px)" })),
  ]),
  state("*", style({ opacity: 1, transform: "translateX(0)" })),
]);

export const detailsSlideFromRight = trigger("detailsSlideFromRight", [
  transition(":enter", [style({ opacity: 0, width: 0 }), animate(200)]),
  transition(":leave", [animate(200, style({ opacity: 0, width: 0 }))]),
  state("*", style({ opacity: 1, width: "50%" })),
]);

export const slideInToaster = trigger("slideInToaster", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateY(100%)" }),
    animate(200),
  ]),
  transition(":leave", [
    animate(200, style({ opacity: 0, transform: "translateY(100%)" })),
  ]),
  state("*", style({ opacity: 1, transform: "translateY(0)" })),
]);

export const zoomInOut = trigger("zoomInOut", [
  transition(":enter", [
    style({ opacity: 0, transform: "scale(1.4)" }),
    animate(100),
  ]),
  transition(":leave", [
    animate(100, style({ opacity: 0, transform: "scale(0.7)" })),
  ]),
  state("*", style({ opacity: 1, transform: "scale(1)" })),
]);

export const zoomOutIn = trigger("zoomOutIn", [
  transition(":enter", [
    style({ opacity: 0, transform: "scale(0.3)" }),
    animate(100),
  ]),
  transition(":leave", [
    animate(100, style({ opacity: 0, transform: "scale(0.3)" })),
  ]),
  state("*", style({ opacity: 1, transform: "scale(1)" })),
]);

export const snapshotSlide = trigger("snapshotSlide", [
  transition("void => empty", [
    style({ transform: "translateY(-100px)" }),
    animate(400),
  ]),
  state("*", style({ transform: "translateY(0)" })),
]);

export const tileSlideIn = trigger("tileSlideIn", [
  transition(":enter", [
    style({ opacity: 0, transform: "translate(-50%, 0)" }),
    animate(100),
  ]),
  transition(":leave", [
    animate(100, style({ opacity: 0, transform: "translate(-50%, 0)" })),
  ]),
  state("*", style({ opacity: 1, transform: "translate(-50%, -50%)" })),
]);

