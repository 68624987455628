import { Injectable, NgZone } from "@angular/core";
import {
  Subject,
  Observable,
  ReplaySubject,
  from,
  BehaviorSubject,
} from "rxjs";
import { IConversationStrategy } from "../../core-ui/models/strategies/IConversationStrategy";
import { IApplication } from "../../core-ui/models/IApplication";
import { ApplicationService } from "../../core-ui/services/application.service";
import {
  ConversationFactory,
  IContactInfo,
  IMessage,
  IMember,
  IConversation,
  IConversationEventHandlers,
  IConfigOptions,
  IConversationAuthClient,
  IConversationAuthCode,
} from "@auvious/integrations";
import {
  FileTransferNotification,
  IScheduledAppointmentContact,
  ITicket,
} from "../../core-ui/models";
import {
  ConversationTypeEnum,
  NotificationTypeEnum,
} from "../../core-ui/core-ui.enums";
import {
  IAppointment,
  IAppointmentRouting,
} from "../../core-ui/models/Appointment";
import { debugError } from "../app.utils";
import { NotificationService } from "../../core-ui/services/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { ConversationNotification } from "../../core-ui/models/notifications/ConversationNotification";
import {
  CUSTOMER_CALL_ACCEPTED_ACK,
  KEY_APPOINTMENT_CONVERSATION,
  QUEUE_TIMEOUT_MESSAGE,
} from "../app.enums";
import { sessionStore } from "@auvious/utils";
import { IInteraction } from "../../core-ui/models/IInteraction";
import {
  AuviousRtcService,
  GenericErrorHandler,
  UserService,
} from "../../core-ui";
import { DigitalConnectAuthClient, NoAuthClient } from "../models/";

const KEY_AGENT_NOTIFIED = "auvious.appointment.conversation.notified";

interface ICXoneAuthCode extends IConversationAuthCode {
  code: string;
}

@Injectable()
export class ConversationService implements IConversationStrategy {
  private readySubject: Subject<void> = new Subject();
  private connectingSubject: ReplaySubject<void> = new ReplaySubject();
  private connectedSubject: ReplaySubject<void> = new ReplaySubject();
  private disconnectedSubject: Subject<void> = new Subject();
  private reconnectedSubject: Subject<void> = new Subject();
  private startedSubject: BehaviorSubject<IConversation> = new BehaviorSubject(
    undefined
  );
  private endedSubject: ReplaySubject<void> = new ReplaySubject();
  private messageSentSubject: Subject<IMessage> = new Subject();
  private messageReceivedSubject: ReplaySubject<IMessage> = new ReplaySubject();
  private agentNotififiedSubject: Subject<void> = new Subject();
  private agentJoinedSubject: Subject<IMember> = new Subject();
  private agentLeftSubject: Subject<void> = new Subject();
  private customerLeftSubject: Subject<void> = new Subject();
  private roomCreatedSubject: Subject<string> = new Subject();
  private agentTypingStartedSubject: Subject<void> = new Subject();
  private agentTypingStoppedSubject: Subject<void> = new Subject();
  private errorSubject: Subject<any> = new Subject();

  private conversationPanelToggledSubject: Subject<boolean> = new Subject();

  private keepConversationAliveInterval = 1000 * 60 * 5; // 5 minutes
  private keepaliveInterval: NodeJS.Timeout;
  private agent: IMember;
  private authClient: IConversationAuthClient;
  private isPanelOpen: boolean;
  private config: IConfigOptions;
  private reconnectTimeout: NodeJS.Timeout | number;
  private conversationData: IConversation;

  constructor(
    private applicationService: ApplicationService,
    private notification: NotificationService,
    private rtc: AuviousRtcService,
    private zone: NgZone,
    private translate: TranslateService,
    private errorHandler: GenericErrorHandler,
    private userService: UserService
  ) {}

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private get impl(): IConversationStrategy {
    return this.application.conversationStrategy();
  }

  public get ready$(): Observable<void> {
    return this.readySubject.asObservable();
  }
  public get connecting$(): Observable<void> {
    return this.connectingSubject.asObservable();
  }
  public get connected$(): Observable<void> {
    return this.connectedSubject.asObservable();
  }
  public get disconnected$(): Observable<void> {
    return this.disconnectedSubject.asObservable();
  }
  public get reconnected$(): Observable<void> {
    return this.reconnectedSubject.asObservable();
  }
  public get started$(): Observable<IConversation> {
    return this.startedSubject.asObservable();
  }
  public get ended$(): Observable<void> {
    return this.endedSubject.asObservable();
  }
  public get messageSent$(): Observable<IMessage> {
    return this.messageSentSubject.asObservable();
  }
  public get messageReceived$(): Observable<IMessage> {
    return this.messageReceivedSubject.asObservable();
  }
  public get agentNotifified$(): Observable<void> {
    return this.agentNotififiedSubject.asObservable();
  }
  public get agentJoined$(): Observable<IMember> {
    return this.agentJoinedSubject.asObservable();
  }
  public get agentLeft$(): Observable<void> {
    return this.agentLeftSubject.asObservable();
  }
  public get customerLeft$(): Observable<void> {
    return this.customerLeftSubject.asObservable();
  }
  public get roomCreated$(): Observable<string> {
    return this.roomCreatedSubject.asObservable();
  }
  public get agentTypingStarted$(): Observable<void> {
    return this.agentTypingStartedSubject.asObservable();
  }
  public get agentTypingStopped$(): Observable<void> {
    return this.agentTypingStoppedSubject.asObservable();
  }
  public get error$(): Observable<void> {
    return this.errorSubject.asObservable();
  }
  public get conversationPanelToggled$(): Observable<boolean> {
    return this.conversationPanelToggledSubject.asObservable();
  }

  /**
   *
   * @param config: Configuration containing the properties needed to start a chat
   * @returns
   */
  public init(config: IConfigOptions): Promise<void> {
    this.config = config;
    return new Promise((resolve) => {
      const handlers: IConversationEventHandlers = {
        ready: () => {
          this.zone.run((_) => resolve());
        },
        connecting: (event: any) => {
          this.zone.run((_) => this.connectingSubject.next());
        },
        connected: (event: any) => {
          this.zone.run((_) => this.connectedSubject.next());
        },
        disconnected: (event: any) => {
          this.zone.run((_) => this.disconnectedSubject.next());
        },
        reconnected: (event: any) => {
          this.zone.run((_) => this.reconnectedSubject.next());
        },
        started: (conversation: IConversation) => {
          // this.clearReconnectTimeout();
          this.conversationData = conversation;
          sessionStore.setItem(
            KEY_APPOINTMENT_CONVERSATION,
            JSON.stringify(conversation)
          );
          this.zone.run((_) => {
            ConversationFactory.instance.$conversation = conversation;
            // ConversationFactory.instance.sendMessage(
            //   this.translate.instant("appointment-notice-message"),
            //   "notice"
            // );
            this.startedSubject.next(conversation);
          });
        },
        ended: (event: any) => {
          this.zone.run((_) => {
            // this.notification.info("Conversation ended", {
            //   body: "You can no longer send or receive chat messages.",
            //   ttl: 5,
            // });
            this.resetConversation();
            this.endedSubject.next();
          });
        },
        messageSent: (message: IMessage) => {
          this.zone.run((_) => this.messageSentSubject.next(message));
        },
        messageReceived: (message: IMessage) => {
          this.zone.run((_) => {
            // discard auvious notices
            if (
              message.text &&
              (message.text.includes(
                this.translate.instant("appointment-notice-message")
              ) ||
                message.text.includes(this.translate.instant("chat-notice")) ||
                message.text.includes(
                  this.translate.instant("chat-notice-voice")
                ) ||
                message.text.includes(
                  this.translate.instant("chat-customer-rejected")
                ) ||
                // this is a substring. we don't know the actual customer's language
                // not sent for now
                message.text.includes(
                  this.translate.instant("Customer's language is")
                ) ||
                // discard ACK message
                message.text.includes(CUSTOMER_CALL_ACCEPTED_ACK) ||
                message.text.includes(QUEUE_TIMEOUT_MESSAGE) ||
                message.text.includes(
                  this.translate.instant("Customer's name is")
                ) ||
                message.text.includes(
                  this.translate.instant("Consent not given")
                ))
            ) {
              return;
            }

            this.handleMessageReceived(message);
            this.messageReceivedSubject.next(message);
          });
        },
        agentNotified: () => {
          this.zone.run((_) => {
            if (!sessionStore.getItem(KEY_AGENT_NOTIFIED)) {
              ConversationFactory.instance.sendMessage(
                this.translate.instant("appointment-notice-message"),
                "notice"
              );
              sessionStore.setItem(
                KEY_AGENT_NOTIFIED,
                new Date().toISOString()
              );
            }
            this.agentNotififiedSubject.next();
          });
        },
        agentJoined: (member: IMember) => {
          this.zone.run((_) => {
            this.agent = member;
            this.keepAliveConversation();
            this.agentJoinedSubject.next(member);
          });
        },
        agentLeft: () => {
          this.zone.run((_) => this.agentLeftSubject.next());
        },
        customerLeft: () => {
          this.zone.run((_) => this.customerLeftSubject.next());
        },
        roomCreated: (url: string) => {
          this.zone.run((_) => this.roomCreatedSubject.next(url));
        },
        cobrowseRequested: (ticket: string) => {},
        agentTypingStarted: () => {
          this.zone.run((_) => this.agentTypingStartedSubject.next());
        },
        agentTypingStopped: () => {
          this.zone.run((_) => this.agentTypingStoppedSubject.next());
        },
        error: (event: any) => {
          this.zone.run((_) => this.errorSubject.next(event));
        },
        messageError: (event: any) => {},
        memberSet: (member: IMember) => {},
        memberRemoved: (member: IMember) => {},
      };

      switch (config.chatMode) {
        case "talkdesk-digital-connect":
          this.authClient = new DigitalConnectAuthClient(
            this.rtc,
            this.userService
          );
          break;
        default:
          this.authClient = new NoAuthClient();
          break;
      }

      ConversationFactory.create(
        config,
        handlers,
        this.rtc.getAuviousCommonClient(),
        this.authClient
      );
    });
  }

  private keepAliveConversation() {
    this.keepaliveInterval = setInterval(async () => {
      try {
        await ConversationFactory.instance.sendTyping();
      } catch (ex) {
        debugError(ex);
      }
    }, this.keepConversationAliveInterval);
  }

  private clearKeepAlive() {
    if (!!this.keepaliveInterval) clearInterval(this.keepaliveInterval);
  }

  private resetConversation() {
    this.agent = null;
    this.notification.dismissAll();
    this.clearKeepAlive();
    this.clearSession();
    ConversationFactory.instance.destroy();
  }

  private clearSession() {
    this.conversationData = undefined;
    sessionStore.removeItem(KEY_APPOINTMENT_CONVERSATION);
    sessionStore.removeItem(KEY_AGENT_NOTIFIED);
  }

  private clearReconnectTimeout() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
  }

  private handleMessageReceived(message: IMessage) {
    if (this.isPanelOpen) {
      return;
    }

    let notif: ConversationNotification;
    let title: string;

    switch (message.type) {
      case "file":
        title = "File received";
        // notif = new FileTransferNotification({
        //   id: message.id,
        //   targetId: "",
        //   targetType: "conference",
        //   mimeType: "",
        //   userId: "",
        //   filename: message.file.name,
        //   sentAt: message.timestamp,
        //   signedUrl: new Observable((obs) => {
        //     ConversationFactory.instance.downloadFile(message.file);
        //     obs.next("");
        //   }),
        // });
        notif = new ConversationNotification(
          title,
          message.file?.name,
          message.id
        );
        break;
      case "text":
        title = this.agent?.displayName || "Message";
        notif = new ConversationNotification(title, message.text, message.id);
        break;
      case "bot":
        title = this.agent?.displayName || "Bot";
        notif = new ConversationNotification(title, message.text, message.id);
        break;
    }

    if (notif) {
      notif.onClicked(() => {
        this.toggleConversationPanel(true);
      });
      this.notification.notify(notif);
    }
  }

  public toggleConversationPanel(open: boolean) {
    this.isPanelOpen = open;
    if (this.isPanelOpen) {
      this.notification.dismissAll(NotificationTypeEnum.conversation);
    }
    this.conversationPanelToggledSubject.next(open);
  }

  /** check if conversation has started (we got conversation data) */
  public get isConversationStarted(): boolean {
    return !!ConversationFactory.instance?.$conversation;
  }

  /** check if the instance has been initialised */
  public get isConversationInitialised(): boolean {
    try {
      return !!ConversationFactory.instance;
    } catch (ex) {
      return false;
    }
  }

  /** Applies only to customer */

  private isReconnect() {
    return !!sessionStore.getItem(KEY_APPOINTMENT_CONVERSATION);
    // const str = sessionStore.getItem(KEY_APPOINTMENT_CONVERSATION);
    // if (!str) {
    //   return false;
    // }
    // try {
    //   const conversation: IConversation = JSON.parse(str);
    //   return (
    //     !!conversation.id && conversation?.id === this.conversationData?.id
    //   );
    // } catch (ex) {
    //   return false;
    // }
  }

  public async connect(contact: IContactInfo): Promise<void> {
    if (this.isReconnect()) {
      return ConversationFactory.instance.reconnect(
        contact,
        ConversationTypeEnum.videoCall
      );
    }
    this.clearSession();
    let code: IConversationAuthCode;
    switch (this.config.chatMode) {
      case "talkdesk-digital-connect":
        code = (this.authClient as DigitalConnectAuthClient).getAuthCode(
          contact,
          this.config.dcTouchpointId
        );
        break;
      case "genesys-web-messaging":
        code = { applicationId: this.application.getId() };
        break;
      case "cxone-dfo-chat":
        code = {
          applicationId: this.application.getId(),
          code: this.config.cxAuthorizationCode,
        } as ICXoneAuthCode;
        break;
    }
    return ConversationFactory.instance.createChat(
      contact,
      ConversationTypeEnum.videoCall,
      code
    );
  }

  /** Applies only to customer */
  public endConversation(): Promise<void> {
    try {
      return ConversationFactory.instance.endChat();
    } catch (ex) {
      // do nothing
    }
  }

  public leaveConversation(interaction: IInteraction): Promise<void> {
    return this.impl.leaveConversation(interaction);
  }

  public getMessages(interaction: IInteraction): Promise<IMessage[]> {
    return this.impl.getMessages(interaction);
  }

  /** Applies only to customer */
  public sendMessage(
    message: string,
    bodyType?: "notice" | "standard"
  ): Promise<void> {
    return ConversationFactory.instance.sendMessage(message, bodyType);
  }

  public prepareConversationRequest(
    ticket: ITicket,
    appointment: IAppointment<IAppointmentRouting>,
    contact: IScheduledAppointmentContact
  ): IContactInfo {
    return this.impl.prepareConversationRequest(ticket, appointment, contact);
  }

  public prepareConversationConfig(
    ticket: ITicket,
    appointment: IAppointment<IAppointmentRouting>
  ): IConfigOptions {
    return this.impl.prepareConversationConfig(ticket, appointment);
  }
}
