import {
  Input,
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ConversationService } from "../../services/conversation.service";
import { Subscription, filter } from "rxjs";
import { slideFromLeft } from "../../../core-ui/core-ui.animations";
import { ChangeDetectorRef } from "@angular/core";
import {
  ConversationOriginEnum,
  DeviceService,
  IInteraction,
  ToggleConversationPanelAction,
  UserService,
  WindowEventService,
  windowActionType,
} from "../../../core-ui";

@Component({
  selector: "app-conversation-launcher",
  templateUrl: "./conversation-launcher.component.html",
  styleUrls: ["./conversation-launcher.component.scss"],
  animations: [slideFromLeft],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationLauncherComponent implements OnInit, OnDestroy {
  @Input() interaction: IInteraction;

  isChatOpen = false;
  isLoading = false;
  isStarted = false;
  isCustomer = false;
  subscription = new Subscription();

  constructor(
    private conversation: ConversationService,
    private userService: UserService,
    private windowEventService: WindowEventService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isCustomer = this.userService.isCustomer;

    if (this.isWidget) {
      this.isStarted = true;
      // listen to widget events that show/hide the widget chat
      this.windowEventService
        .init(this.interaction?.getParentFrameUrl())
        .pipe(
          filter(
            (action) =>
              action.type === windowActionType.CONVERSATION_PANEL_TOGGLE
          )
        )
        .subscribe((action) => {
          this.isChatOpen = action.payload;
          this.cd.detectChanges();
        });
    } else {
      // we own the widget chat, not the widget
      this.subscription.add(
        this.conversation.connected$.subscribe(() => {
          this.isLoading = true;
          this.cd.detectChanges();
        })
      );
      this.subscription.add(
        this.conversation.started$.subscribe(() => {
          this.isLoading = false;
          this.isStarted = true;
          this.cd.detectChanges();
        })
      );
      this.subscription.add(
        this.conversation.ended$.subscribe(() => {
          this.isStarted = false;
          this.cd.detectChanges();
        })
      );
      this.subscription.add(
        this.conversation.conversationPanelToggled$.subscribe((open) => {
          this.isChatOpen = open;
          this.cd.detectChanges();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleChat() {
    if (this.isLoading) {
      return;
    }
    if (this.isWidget) {
      this.isChatOpen = !this.isChatOpen;
      this.windowEventService.sendMessage(
        new ToggleConversationPanelAction(this.isChatOpen)
      );
    } else {
      this.conversation.toggleConversationPanel(!this.isChatOpen);
    }
  }

  get isConversationAvailable() {
    return !this.isWidget;
  }

  get isOriginWidget() {
    return this.interaction?.getOrigin() === ConversationOriginEnum.WIDGET;
  }

  get isWidget() {
    return DeviceService.isIFrame && this.isCustomer && this.isOriginWidget;
  }
}
