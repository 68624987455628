import { Injectable } from "@angular/core";
import {
  IAppointment,
  IAppointmentRouting,
  IScheduledAppointmentContact,
  ITicket,
} from "../../../core-ui/models";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import {
  ActivityIndicatorService,
  AppointmentService,
  PublicTicketService,
  ThemeService,
} from "../../../core-ui";
import {
  ErrorPageCode,
  ErrorPageSubCode,
} from "../../app.enums";
import { PARAM_TICKET_ID } from "../../../core-ui/core-ui.enums";
import {
  IConfigOptions,
  IContactInfo,
} from "@auvious/integrations";
import { ConversationService } from "../../../app/services/conversation.service";
import { ISyncable } from "./ISyncable";

class HandledError extends Error {
  subcode: string;

  constructor(code: ErrorPageCode, subcode?: ErrorPageSubCode) {
    super(code);
    this.subcode = subcode;
  }
}

@Injectable({ providedIn: "root" })
export class AppointmentConversationGuard implements ISyncable {
  constructor(
    private themeService: ThemeService,
    private ticketService: PublicTicketService,
    private appointmentService: AppointmentService,
    private conversation: ConversationService,
    private router: Router,
    private activity: ActivityIndicatorService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    this.activity.loading(true, "Connecting...");

    if (
      this.conversation.isConversationInitialised &&
      this.conversation.isConversationStarted
    ) {
      return true;
    }

    let ticket: ITicket,
      appointment: IAppointment<IAppointmentRouting>,
      config: IConfigOptions,
      request: IContactInfo,
      appointmentId: string;

    // 1. get the ticket data
    try {
      ticket = await this.getTicket(route.paramMap.get(PARAM_TICKET_ID));
      if (!ticket.properties.appointmentId) {
        return true;
      }
      appointmentId = ticket.properties.appointmentId;
    } catch (ex) {
      return this.onError(ex);
    }

    // 2. get the appointment details
    try {
      appointment = await this.getAppointment(appointmentId);
    } catch (ex) {
      return this.onError(ex);
    }

    // 3. start a conversation (or reconnect to existing one)
    try {
      config = this.conversation.prepareConversationConfig(ticket, appointment);

      const response = await Promise.all([
        this.conversation.init(config),
        this.getConversationRequest(ticket, appointment),
      ]);

      request = response[1];

      await this.conversation.connect(request);

      this.activity.loading(false);
      return true;
    } catch (ex) {
      return this.onError(ex);
    }
  }

  private async getTicket(ticket: string): Promise<ITicket> {
    try {
      if (!ticket) {
        throw new HandledError(ErrorPageCode.TICKET_NOT_FOUND);
      }

      const ticketData = await this.ticketService.getTicket(ticket);

      if (ticketData.properties.theme) {
        this.themeService.setOptions(ticketData.properties.theme);
      }

      return ticketData;
    } catch (ex) {
      throw new HandledError(ErrorPageCode.TICKET_NOT_FOUND);
    }
  }

  private async getAppointment(
    appointmentId: string
  ): Promise<IAppointment<IAppointmentRouting>> {
    try {
      const appointment = await this.appointmentService.get(appointmentId);

      if (!appointment) {
        throw new HandledError(ErrorPageCode.APPOINTMENT_NOT_FOUND);
      }

      if (appointment.interaction.state === "COMPLETE") {
        throw new HandledError(ErrorPageCode.APPOINTMENT_COMPLETED);
      }
      this.appointmentService.notifyChange(appointment);
      return appointment;
    } catch (ex) {
      throw new HandledError(ErrorPageCode.APPOINTMENT_NOT_FOUND);
    }
  }

  private async getConversationRequest(
    ticket: ITicket,
    appointment: IAppointment<IAppointmentRouting>
  ): Promise<IContactInfo> {
    // get customer
    let participant: IScheduledAppointmentContact;
    try {
      participant = await this.appointmentService.getParticipantDetails(
        ticket.properties.appointmentId,
        ticket.properties.customer_id
      );
    } catch (ex) {
      throw new HandledError(ErrorPageCode.CONVERSATION_FAILED);
    }

    try {
      const request = this.conversation.prepareConversationRequest(
        ticket,
        appointment,
        participant
      );
      return request;
    } catch (ex) {
      throw new HandledError(ErrorPageCode.CONVERSATION_FAILED);
    }
  }

  private onError(error: Error): UrlTree {
    this.activity.loading(false);
    let code, message;
    if (error instanceof HandledError) {
      code = error.message;
    } else {
      code = ErrorPageCode.GENERIC_ERROR;
      message = error.message;
    }
    return this.router.createUrlTree(["/error", code], {
      queryParams: { message },
    });
  }
}
