import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ISettingsData } from "../../models";
import { ActivityIndicatorService, IUserDetails } from "../../../core-ui";
import { IntegrationService } from "../../services";

@Injectable({ providedIn: "root" })
export class SettingsResolver  {
  constructor(
    private integrationService: IntegrationService,
    private activity: ActivityIndicatorService
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ISettingsData> {
    if (this.integrationService.supportsMemberRoles()) {
      let members = [];
      try {
        this.activity.loading(true);
        // change with Promise.all in the future when we load more data
        members = await this.getAllUsers();
      } catch (ex) {
      } finally {
        this.activity.loading(false);
      }
      return {
        members,
      };
    }
    return { members: [] };
  }

  private async getAllUsers(page = 1): Promise<IUserDetails[]> {
    try {
      const pager = await this.integrationService.getMembers(page, 200);
      if (!pager) {
        return [];
      }
      if (pager.pageCount > pager.pageNumber) {
        return pager.entities.concat(await this.getAllUsers(page + 1));
      }
      return pager.entities;
    } catch (ex) {
      return [];
    }
  }
}
