<img aria-hidden="true" class="frame" [class.frame-blur]="blur" #streamImg />
<!-- [class.mirror]="mirror" -->
<canvas #testCanvas></canvas>

<ng-container *ngIf="isZoomOn">
  <app-magnify-glass
    [image]="streamImg"
    [mirror]="mirror"
    [zoom]="2"
  ></app-magnify-glass>
</ng-container>
