import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Input,
  Optional,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { UserRoleEnum, ConferenceMetadataKeyEnum } from "../../core-ui.enums";
import {
  AgentParam,
  IInteraction,
  IRecorderInfo,
  IRecorderOptions,
  RecorderStateEnum,
  PublicParam,
} from "../../models";
import { AppConfigService, FEATURES } from "../../services/app.config.service";
import { GenericErrorHandler } from "../../services/error-handlers.service";
import { RecorderService } from "../../services/recorder.service";
import { UserService } from "../../services/user.service";
import { NotificationService } from "../../services/notification.service";
import { debug } from "../../services/utils";
import { RoomComponent } from "../room/room.component";
import { filter } from "rxjs/operators";
import { BaseMetadata, RecorderMetadata } from "../../models/Metadata";
import {
  ConferenceService,
  RecorderEndedAction,
  RecorderStartedAction,
  WindowEventService,
} from "../../services";
import { CobrowseService } from "../../services/cobrowse.service";

@Component({
  selector: "app-recorder",
  templateUrl: "./recorder.component.html",
  styleUrls: ["./recorder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecorderComponent implements OnInit, OnDestroy {
  @Input() options: IRecorderOptions;
  @Input() interaction: IInteraction;

  @Output() started = new EventEmitter<IRecorderInfo>();
  @Output() stopped = new EventEmitter<IRecorderInfo>();

  recorder: IRecorderInfo;
  recorderState: RecorderStateEnum;
  recorderAbortedTimeout;
  recorderAbortedRetryWait: number;
  recorderRetry = false;

  isAgent: boolean;
  isCustomer: boolean;
  isGuestAgent: boolean;
  isRecordingRemotely: boolean;
  isRecorderEnabled: boolean;

  recorderSubscription: Subscription;
  subscription: Subscription = new Subscription();

  constructor(
    private config: AppConfigService,
    private recorderService: RecorderService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private conferenceService: ConferenceService,
    private notification: NotificationService,
    private cobrowser: CobrowseService,
    private windowEventService: WindowEventService,
    @Optional() private room: RoomComponent
  ) {
    this.recorderAbortedRetryWait = config.recorderAbortWaitTime;
    this.isAgent = this.userService.getActiveUser().hasRole(UserRoleEnum.agent);
    this.isGuestAgent = this.userService.isGuestAgent;
    this.isCustomer = this.userService
      .getActiveUser()
      .hasRole(UserRoleEnum.customer);
    this.isRecorderEnabled = this.config.publicParam(
      PublicParam.RECORDER_ENABLED
    ) as boolean;
  }

  ngOnInit(): void {
    if (!this.isRecorderEnabled) {
      return;
    }
    this.getActiveRecorder();
    this.subscription.add(
      this.conferenceService.conferenceMetadataSet$
        .pipe(filter((data) => data instanceof RecorderMetadata))
        .subscribe((data: RecorderMetadata) => {
          this.isRecordingRemotely = true;
          this.cdr.detectChanges();
          this.notification.info("Recording", {
            body: "Session is being recorded",
          });
          if (this.room?.isChildWindow) {
            this.windowEventService.sendMessage(new RecorderStartedAction());
          }
        })
    );
    this.subscription.add(
      this.conferenceService.conferenceMetadataRemoved$
        .pipe(filter((data) => data instanceof RecorderMetadata))
        .subscribe((data: RecorderMetadata) => {
          this.isRecordingRemotely = false;
          // covers the case where we have more than one agents (not guests)
          // 1st has started recording, 2nd joins, 1st stops recording.
          if (this.isAgent && this.isRecorderActive) {
            this.recorder = null;
            this.recorderState = RecorderStateEnum.stopped;
          }
          this.cdr.detectChanges();
          this.notification.info("Recording", {
            body: "Session is no longer recorded",
          });
          if (this.room?.isChildWindow) {
            this.windowEventService.sendMessage(new RecorderEndedAction());
          }
        })
    );
    this.subscribeToRecorder();
  }

  ngOnDestroy() {
    this.recorderSubscription?.unsubscribe();
    this.subscription.unsubscribe();
    this.recorderService.reset();
  }

  toggleRecorder() {
    if (this.isRecorderActive) {
      this.stopRecorder();
    } else {
      this.startRecorder();
    }
  }

  async startRecorder() {
    this.setRecorderLoading();
    if (!this.options || (!this.options.audio && !this.options.video)) {
      return;
    }
    if (this.options.audio === undefined || this.options.audio === null) {
      this.options.audio = this.options.audio;
    }
    if (this.options.video === undefined || this.options.video === null) {
      this.options.video = !this.config.agentParamEnabled(
        AgentParam.RECORDER_AUDIO_ONLY
      );
    }

    try {
      const recorder = await this.recorderService.start(this.options);
      // we may get a recorder object from the event faster than the response.
      if (!this.recorder) {
        this.recorder = recorder;
      }
      this.recorderService.storeRecorderInfo(
        this.options.conversationId,
        this.recorder
      );
    } catch (ex) {
      this.errorHandler.handleError(ex);
      this.notification.error(
        this.translate.instant(
          "Could not start recording. Please try again or contact your administrator."
        )
      );
      this.recorder = null;
    } finally {
      this.cdr.detectChanges();
      // this.activityService.loading(false);
    }
  }

  async stopRecorder() {
    if (this.isRecorderLocked) {
      return;
    }
    this.recorder.state = RecorderStateEnum.loading;
    try {
      await this.recorderService.stop(
        this.recorder.recorderId,
        this.recorder.conversationId,
        this.recorder.instanceId
      );
      await this.cobrowser.disableRecording();
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
    this.recorderService.clearRecorderInfo(this.options.conversationId);
    this.cdr.detectChanges();
  }

  setRecorderLoading() {
    this.recorder = {
      state: RecorderStateEnum.loading,
      recorderId: this.recorder?.recorderId,
      instanceId: this.recorder?.instanceId,
      conversationId: this.recorder?.conversationId,
    };
    this.cdr.detectChanges();
  }

  async getActiveRecorder() {
    if (this.isAgent && this.isRecorderAvailable) {
      const recorder = await this.recorderService.getStoredRecorderInfo(
        this.options.conversationId
      );
      if (!recorder) {
        // no active recorder. check if we need to start recording
        if (
          !this.isGuestAgent &&
          this.isRecorderConfigured &&
          this.config.agentParamEnabled(AgentParam.RECORDER_AUTO_START)
        ) {
          this.startRecorder();
        }
      } else {
        this.setRecorderLoading();
        try {
          const data = await this.recorderService.getInfo(
            recorder.recorderId,
            recorder.conversationId,
            recorder.instanceId
          );

          this.recorder = data;
          if (this.recorder.state !== RecorderStateEnum.active) {
            // if not 'active' start again. We have saved recorder info, so something happened.
            this.startRecorder();
          }
        } catch (ex) {
          // do nothing if we don't find an active recorder
          debug(ex);
          this.recorder = null;
        } finally {
          this.cdr.detectChanges();
        }
      }
    }
  }

  subscribeToRecorder() {
    this.recorderSubscription = this.recorderService.eventReceived$.subscribe(
      (data) => {
        debug("recorder state changed", data);
        let metadata: BaseMetadata;
        switch (data.state) {
          case RecorderStateEnum.active:
            // we may receive 'active' from an aborted stream
            this.recorder = data;
            this.recorderService.storeRecorderInfo(
              this.options.conversationId,
              this.recorder
            );
            if (!!this.recorderAbortedTimeout) {
              clearTimeout(this.recorderAbortedTimeout);
              this.recorderAbortedTimeout = null;
            }
            this.cdr.detectChanges();

            this.started.emit({ ...this.recorder });

            // notify others
            metadata = new RecorderMetadata(
              this.conferenceService.myself,
              true
            );
            this.conferenceService.setConferenceMetadata(metadata);

            break;
          case RecorderStateEnum.stopped:
            this.stopped.emit({
              ...this.recorder,
              state: RecorderStateEnum.stopped,
            });

            this.recorder = null;
            if (!!this.recorderAbortedTimeout) {
              clearTimeout(this.recorderAbortedTimeout);
              this.recorderAbortedTimeout = null;
            }
            this.cdr.detectChanges();

            this.conferenceService.removeConferenceMetadata(
              ConferenceMetadataKeyEnum.recorder
            );
            break;
          case RecorderStateEnum.failed:
            this.errorHandler.handleError(data.error);
            if (!!this.recorderAbortedTimeout) {
              clearTimeout(this.recorderAbortedTimeout);
              this.recorderAbortedTimeout = null;
            }
            this.recorderNotifyFailure();

            this.conferenceService.removeConferenceMetadata(
              ConferenceMetadataKeyEnum.recorder
            );
            break;
          case RecorderStateEnum.aborted:
            this.errorHandler.handleError(data.error);
            this.setRecorderLoading();
            // temporarily stopped because of a server/cluster error. will reconnect.
            // wait for some seconds to receive 'active' or 'failed' again.
            // 'active' and 'failed' will cancel this timeout
            this.recorderAbortedTimeout = setTimeout(() => {
              this.recorderNotifyFailure();
              // this.handleRecorderFailRetry(recorderSubscription);
            }, this.recorderAbortedRetryWait);

            this.conferenceService.removeConferenceMetadata(
              ConferenceMetadataKeyEnum.recorder
            );
            break;
        }
      }
    );
  }

  private recorderNotifyFailure() {
    this.notification.error(
      this.translate.instant(
        "Recording has stopped. Please contact your administrator."
      )
    );
    this.recorder = null;
    this.cdr.detectChanges();
  }

  /**
   * Deprecated for now. Kept recording abort-retry simple and let server do all the handling
   */
  async handleRecorderFailRetry(recorderSubscription: Subscription) {
    // clear previous recorder and subscription
    this.recorder = null;
    this.cdr.detectChanges();
    // retry once
    if (!this.recorderRetry) {
      debug("retrying recorder to start");
      this.recorderRetry = true;
      const existingRecorder = await this.recorderService.getStoredRecorderInfo(
        this.options.conversationId
      );
      // check if we have an existing active recorder before retrying.
      if (!!existingRecorder) {
        // get recorder info from server
        const zombieRecorder = await this.recorderService.getInfo(
          existingRecorder.recorderId,
          existingRecorder.conversationId,
          existingRecorder.instanceId
        );

        // clear previous recorder info
        this.recorderService.clearRecorderInfo(this.options.conversationId);

        // if the existing recorder is for some reason active, then stop before re-start.
        if (
          !!zombieRecorder &&
          zombieRecorder.state === RecorderStateEnum.active
        ) {
          await this.recorderService.stop(
            zombieRecorder.recorderId,
            zombieRecorder.conversationId,
            zombieRecorder.instanceId
          );
          this.startRecorder();
        } else {
          this.startRecorder();
        }
      } else {
        this.startRecorder();
      }
    } else {
      this.notification.error(
        this.translate.instant(
          "Recording has stopped and cannot be started again. Please contact your administrator."
        )
      );
    }
  }

  /* view accessors */

  get isActionable() {
    return (
      this.isRecorderEnabled &&
      this.isRecorderConfigured &&
      this.isAgent &&
      !this.isGuestAgent
    );
  }

  get isReadOnly() {
    return this.isRecorderEnabled && (this.isCustomer || this.isGuestAgent);
  }

  get isRecorderAvailable(): boolean {
    return this.config.featureEnabled(FEATURES.RECORDER);
  }

  get isRecorderActive(): boolean {
    return this.recorder && this.recorder.state === RecorderStateEnum.active;
  }

  get isRecorderStarting(): boolean {
    // this.recorder.state === IRecorderState.initialised ||
    return this.recorder && this.recorder.state === RecorderStateEnum.loading;
  }

  get isRecorderConfigured(): boolean {
    return this.config.agentParamEnabled(AgentParam.RECORDER_CONFIGURED);
  }

  get isRecorderButtonDisabled() {
    return (
      (this.isRecorderStarting || !this.isRecorderConfigured) &&
      !this.isRecorderActive
    );
  }

  get isRecorderLocked() {
    return (
      this.config.agentParam(AgentParam.RECORDER_AUTO_START) &&
      this.config.agentParam(AgentParam.RECORDER_LOCK)
    );
  }
}
