export interface IEvent {
  type: string;
  timestamp: Date;
}

export class BaseEvent implements IEvent {
  constructor(public type: string) {}
  // type = this.constructor.name; // not working for minifed JS
  timestamp = new Date();
}
