import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConversationTypeEnum } from "@auvious/integrations";
import { PARAM_CALL_TYPE, PARAM_WIDGET_ORIGIN_URL } from "../../app.enums";
import {
  TestDevicePermissionsError,
  TestDevicePermissionsSuccess,
  WindowEventService,
} from "../../../core-ui";

@Component({
  selector: "app-page-permissions",
  templateUrl: "./permissions.component.html",
  styleUrls: ["./permissions.component.scss"],
})
export class PermissionsPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private windowEventService: WindowEventService
  ) {}

  async ngOnInit() {
    const topic = this.route.snapshot.queryParamMap.get(PARAM_WIDGET_ORIGIN_URL);
    const conversationType = this.route.snapshot.queryParamMap.get(
      PARAM_CALL_TYPE
    ) as ConversationTypeEnum;

    if (
      !topic ||
      ![
        ConversationTypeEnum.videoCall,
        ConversationTypeEnum.voiceCall,
      ].includes(conversationType)
    ) {
      return;
    }

    this.windowEventService.init(topic).subscribe((action) => {
      switch (action.type) {
      }
    });

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const constraints = {
        video:
          conversationType === ConversationTypeEnum.videoCall &&
          devices.some((device) => device.kind === "videoinput"),
        audio:
          [
            ConversationTypeEnum.videoCall,
            ConversationTypeEnum.voiceCall,
          ].includes(conversationType) &&
          devices.some((device) => device.kind === "audioinput"),
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      this.windowEventService.sendMessage(new TestDevicePermissionsSuccess());

      // parent.postMessage(
      //   { type: "AuviousPermissionsResult", error: false },
      //   "*"
      // );

      stream.getTracks().forEach((track) => track.stop());
    } catch (ex) {
      this.windowEventService.sendMessage(
        new TestDevicePermissionsError(ex as Error)
      );

      // parent.postMessage(
      //   {
      //     type: "AuviousPermissionsResult",
      //     error: true,
      //     message: (ex as Error)?.message,
      //   },
      //   "*"
      // );
    }
  }
}
