import { AuditActionEnum, AuditApplicationEnum, AuditOutcomeEnum } from "..";
import { UserRoleEnum } from "../core-ui.enums";
import { Duration } from "moment";

export interface IPagedResponse<T> {
  entities: T[];
  pageCount: number;
  pageNumber: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: "asc" | "desc";
  total: number;
  content?: any;
}

export class PageableRequest {
  private properties = {};
  set(property, value) {
    this.properties[property] = value;
    return this;
  }

  remove(property) {
    delete this.properties[property];
    return this;
  }

  getParams() {
    return this.properties;
  }
}

export class PageableRequestBuilder {
  protected _request: PageableRequest;

  constructor() {
    this._request = new PageableRequest();
  }

  page(page: number) {
    this._request = this._request.set("page", page.toString());
    return this;
  }

  pageNumber(page: number) {
    this._request = this._request.set("pageNumber", page.toString());
    return this;
  }

  size(size: number) {
    this._request = this._request.set("size", size.toString());
    return this;
  }

  pageSize(size: number) {
    this._request = this._request.set("pageSize", size.toString());
    return this;
  }

  sort(sort: string) {
    this._request = this._request.set("sort", sort);
    return this;
  }

  sortField(field: string) {
    this._request = this._request.set("sortField", field);
    return this;
  }

  sortOrder(sort: string) {
    this._request = this._request.set("sortOrder", sort);
    return this;
  }

  build() {
    return this._request;
  }
}

export class AppointmentRequestBuilder extends PageableRequestBuilder {
  constructor() {
    super();
  }

  duration(duration: string[]) {
    this._request =
      duration.length > 0
        ? this._request.set("duration", duration.join(",")) // .map(d => d.toISOString())
        : this._request.remove("duration");
    return this;
  }

  organizers(organizers: string[]) {
    this._request =
      organizers.length > 0
        ? this._request.set("organizerId", organizers.join(","))
        : this._request.remove("organizerId");
    return this;
  }

  dateRange(startAt: Date, endAt: Date) {
    this._request = this._request.set(
      "range",
      [startAt.toISOString(), endAt.toISOString()].join(",")
    );
    return this;
  }

  // todo: sortOrder, sortField
}

export class AuditRequestBuilder extends PageableRequestBuilder {
  constructor() {
    super();
  }

  dateRange(startAt: Date, endAt: Date) {
    this._request = this._request.set(
      "timestamp",
      [startAt.toISOString(), endAt.toISOString()].join(",")
    );
  }

  roles(value: UserRoleEnum[]) {
    this._request = this._request.set("roles", value.join(","));
    return this;
  }

  application(value: AuditApplicationEnum[]) {
    this._request = this._request.set("application", value.join(","));
    return this;
  }

  applicationInstanceId(value: string) {
    this._request = this._request.set("applicationId", value);
    return this;
  }

  outcome(value: AuditOutcomeEnum[]) {
    this._request = this._request.set("outcome", value.join(","));
    return this;
  }

  action(value: AuditActionEnum[]) {
    this._request = this._request.set("action", value.join(","));
    return this;
  }

  userId(value: string) {
    this._request = this._request.set("userId", value);
    return this;
  }

  interactionId(value: string) {
    this._request = this._request.set("interactionId", value);
    return this;
  }
}

export class TalkdeskRequestBuilder extends PageableRequestBuilder {
  constructor() {
    super();
  }

  size(size: number) {
    this._request = this._request.set("per_page", size);
    return this;
  }
}
