import { Injectable } from "@angular/core";
import {
  AuviousRtcService,
  ConversationChannelEnum,
  ConversationOriginEnum,
  GenericErrorHandler,
  IConversationBase,
  IConversationEventHandlers,
  IInteraction,
  ITranscript,
  RecorderService,
  UserService,
} from "../../core-ui";
import {
  ConversationTypeEnum,
  DCEventTypeEnum,
  IDCEndingEvent,
  IDCEvent,
  IDCMessageHistoryResponse,
  IDCStartedEvent,
} from "@auvious/integrations";
import { ApiResource, Event } from "@auvious/common";
import { firstValueFrom } from "rxjs";
import {
  DigitalConnectInteraction,
  IDigitalConnectInteraction,
} from "../models/DigitalConnectInteraction";
import { debugError } from "../app.utils";
import { IntegrationService } from "./integration.service";

@Injectable()
export class DigitalConnectService {
  private conversationResource: ApiResource;

  constructor(
    private rtc: AuviousRtcService,
    private recorderService: RecorderService,
    private userService: UserService,
    private integration: IntegrationService,
    private errorHandler: GenericErrorHandler
  ) {
    this.rtc.common$.subscribe((common) => {
      this.conversationResource = common.apiResourceFactory(
        "api/digital-connect/talkdesk/conversations"
      );
    });

    firstValueFrom(this.rtc.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable.subscribe(this.handleEvent.bind(this));
      }
    );
  }

  private handlers: IConversationEventHandlers = {};

  public registerEventHandlers(handlers: IConversationEventHandlers) {
    this.handlers = handlers || {};
  }

  private handleEvent(event: Event) {
    let typedPayload;

    // interactionId: talkdesk interaction id
    // conversationId: Auvious digital connect id

    switch (event.payload.type) {
      case DCEventTypeEnum.started:
        typedPayload = event.payload as IDCStartedEvent;
        const conv: IConversationBase = {
          conversationId: typedPayload.interactionId,
          room: null,
          origin: ConversationOriginEnum.WIDGET,
          type: typedPayload.properties
            ?.conversationType as ConversationTypeEnum,
          channel: ConversationChannelEnum.digitalConnect,
        };
        this.handlers?.started(conv);
        break;
      case DCEventTypeEnum.ending:
        this.handlers?.ending((event.payload as IDCEndingEvent).interactionId);
        break;
      case DCEventTypeEnum.ended:
        this.handlers?.ended((event.payload as IDCEvent).interactionId);
        break;
      case DCEventTypeEnum.messageReceived:
        break;
    }
  }

  async discoverActiveInteraction(): Promise<IInteraction> {
    try {
      const conversations = await this.getActiveConversations();
      let activeConversation: IDigitalConnectInteraction;
      if (conversations?.length === 1) {
        activeConversation = conversations[0];
      } else if (conversations?.length > 1) {
        // find most recent conversation
        const cd = conversations.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
        activeConversation = cd[0];
      } else {
        return null;
      }

      const interaction = await this.retrieveInteractionData(
        activeConversation.interactionId
      );
      return interaction;
    } catch (ex) {
      debugError(ex);
    }
    return null;
  }

  async retrieveInteractionData(id: string) {
    try {
      const conversation = await this.getTalkdeskInteraction(id);
      return new DigitalConnectInteraction(conversation);
    } catch (ex) {
      debugError(ex);
    }
    return null;
  }

  async getChatTranscript(
    interactionId: string,
    channel: ConversationChannelEnum,
    fromStorageProvider: boolean
  ): Promise<ITranscript> {
    let transcript = {
      chatTranscriptList: [],
      conversationId: interactionId,
      instanceId: undefined,
      recorderId: undefined,
    };
    try {
      if (fromStorageProvider) {
        transcript = await this.recorderService.getTranscript(interactionId);
      } else {
        const chatHistory: IDCMessageHistoryResponse[] =
          await this.conversationResource
            .get({
              urlPostfix: interactionId + "/messages",
            })
            .catch((ex) => {
              this.errorHandler.handleNotAuthenticatedError(ex);
            });

        transcript.chatTranscriptList = chatHistory.map((message) => ({
          id: message.messageId,
          timestamp: message.created,
          transcript: message.content,
          userDisplayName: message.author,
          userId: message.name,
        }));
      }
      return transcript;
    } catch (ex) {
      return transcript;
    }
  }

  /** private methods */

  private async getActiveConversations(): Promise<
    IDigitalConnectInteraction[]
  > {
    return this.conversationResource
      .get({
        urlPostfix: "active",
        params: {
          agentId: this.integration.getIntegrationUserId(
            this.userService.getActiveUser()
          ),
        },
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param id auvious digital connect id
   * @returns DigitalConnectInteraction
   */
  private async getDigitalConnectConversation(
    id: string
  ): Promise<IDigitalConnectInteraction> {
    return this.conversationResource
      .get({
        urlPostfix: id,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param id talkdesk interaction id
   * @returns DigitalConnectInteraction
   */
  private async getTalkdeskInteraction(
    id: string
  ): Promise<IDigitalConnectInteraction> {
    return this.conversationResource
      .get({
        urlPostfix: `interaction/${id}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param interactionId talkdesk interaction id
   * @param url
   * @returns
   */
  public callCreated(interactionId, url) {
    return this.conversationResource
      .create(
        {
          interactionId,
          url,
        },
        {
          urlPostfix: "call",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param interactionId talkdesk interaction id
   * @param ticket
   * @returns
   */
  public cobrowseRequested(interactionId, ticket, requestType) {
    return this.conversationResource
      .create(
        {
          interactionId,
          ticket,
          requestType,
        },
        {
          urlPostfix: "cobrowse",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  // Talkdesk does not have an API (for now) to send messages as agent
  // private sendConversationMessage(conversationId: string, content: string){
  //   return this.apiResource.create(
  //     {
  //       conversationId,
  //       content,
  //     },
  //     {
  //       urlPostfix: "messages",
  //     }
  //   );
  // }
}
