import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

import { GenericErrorHandler } from "../../services/error-handlers.service";
import { ErrorPageCode } from "../../app.enums";

import { ActivityIndicatorService, AuviousRtcService } from "../../../core-ui";
import { InteractionService } from "../../services";

@Injectable()
export class RtcRegistrationGuard {
  constructor(
    private rtcService: AuviousRtcService,
    private errorHandler: GenericErrorHandler,
    private activityService: ActivityIndicatorService,
    private interactionService: InteractionService,
    private router: Router
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (this.rtcService.registered()) {
      return true;
    }
    try {
      this.activityService.loading(true, "connecting");
      await this.rtcService.register();
      return true;
    } catch (error) {
      this.errorHandler.handleError(error);
      return this.router.createUrlTree(
        ["/error", ErrorPageCode.RTC_CONNECTION_FAILURE],
        { queryParamsHandling: "preserve" }
      );
    } finally {
      this.activityService.loading(false);
    }
  }
}
