<av-card class="qr" *ngIf="visible" [@centerInOut]="true">
  <av-card-header
    subtitle="Scan with your phone's camera or QR code app to join the call."
    (close)="cancel()"
  >
    <span translate>Join the call</span>
  </av-card-header>
  <av-card-body type="spotlight">
    <div class="laser" *ngIf="animate"></div>
    <qrcode [qrdata]="data" [width]="160"></qrcode>
  </av-card-body>
  <av-card-footer>
    <div class="fx-row">
      <div class="fx-column qr-link">
        <small class="text-muted" translate>Video link</small>
        <b>{{ data }}</b>
      </div>
      <av-copy-clipboard [url]="data" tooltipLabel="Copy to clipboard" size="sm"></av-copy-clipboard>
    </div>
  </av-card-footer>
</av-card>
