import { Injectable } from "@angular/core";
import { Event } from "@auvious/common";
import { AuviousRtcService } from "./rtc.service";
import { Subject, Observable, take, firstValueFrom } from "rxjs";

export interface IBandwidthPublisherBitrateChangedEvent {
  userEndpointId: string;
  newBitrate: number;
}

export interface IBandwidthViewerChangedEvent {
  userEndpointId: string;
  streamId: string;
}

@Injectable()
export class BandwidthAdaptationService {
  private readonly _bitrateDecreased: Subject<IBandwidthPublisherBitrateChangedEvent> =
    new Subject();
  public readonly bitrateDecreased$: Observable<IBandwidthPublisherBitrateChangedEvent> =
    this._bitrateDecreased.asObservable();

  private readonly _bitrateIncreased: Subject<IBandwidthPublisherBitrateChangedEvent> =
    new Subject();
  public readonly bitrateIncreased$: Observable<IBandwidthPublisherBitrateChangedEvent> =
    this._bitrateIncreased.asObservable();

  private readonly _viewerDisabled: Subject<IBandwidthViewerChangedEvent> =
    new Subject();
  public readonly viewerDisabled$: Observable<IBandwidthViewerChangedEvent> =
    this._viewerDisabled.asObservable();

  private readonly _viewerEnabled: Subject<IBandwidthViewerChangedEvent> =
    new Subject();
  public readonly viewerEnabled$: Observable<IBandwidthViewerChangedEvent> =
    this._viewerEnabled.asObservable();

  constructor(private rtc: AuviousRtcService) {
    firstValueFrom(rtc.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable.subscribe(this.handleEvent.bind(this));
      }
    );
  }

  private handleEvent(e: Event) {
    switch (e?.payload?.type) {
      case "BandwidthManagerDecreasedPublisherVideoBitrateEvent":
        this._bitrateDecreased.next({
          userEndpointId: e.payload.userEndpointId,
          newBitrate: e.payload.newBitrate,
        });
        break;
      case "BandwidthManagerIncreasedPublisherVideoBitrateEvent":
        this._bitrateIncreased.next({
          userEndpointId: e.payload.userEndpointId,
          newBitrate: e.payload.newBitrate,
        });
        break;
      case "BandwidthManagerDisabledViewerVideoEvent":
        // ignore messages for other viewers
        if (e.payload.userEndpointId !== this.rtc.myself.endpoint) {
          break;
        }
        this._viewerDisabled.next({
          userEndpointId: e.payload.userEndpointId,
          streamId: e.payload.streamId,
        });

        break;
      case "BandwidthManagerEnabledViewerVideoEvent":
        // ignore messages for other viewers
        if (e.payload.userEndpointId !== this.rtc.myself.endpoint) {
          break;
        }
        this._viewerEnabled.next({
          userEndpointId: e.payload.userEndpointId,
          streamId: e.payload.streamId,
        });
        break;
    }
  }
}
