import type {
  AssignedAgentChangedData,
  ContactStatusChangedData,
} from "@nice-devone/nice-cxone-chat-web-sdk";
import { Brand, Contact, User } from "./types";

export enum PushUpdateEventObject {
  CASE = "Case",
  CHANNEL = "Channel",
  MESSAGE = "Message",
  PAGE_VIEW = "PageView",
  ROUTING_QUEUE = "RoutingQueue",
  SENDER_ACTION = "SenderAction",
  USER_STATUS = "UserStatus",
  CHAT_WINDOW = "ChatWindow",
  CUSTOMER_CONTACT = "CustomerContact",
  CONSUMER_CONTACT = "ConsumerContact",
  CONTACT = "Contact",
  MESSAGE_NOTE = "MessageNote",
  MESSAGE_PREVIEW = "MessagePreview",
  S3_OBJECT = "S3Object",
}

export enum PushUpdateEventType {
  AUTHORIZE_CONSUMER = "AuthorizeConsumer",
  /** @deprecated use CONTACT_CREATED */
  CASE_CREATED = "CaseCreated",
  /** @deprecated use ASSIGNED_AGENT_CHANGED */
  CASE_INBOX_ASSIGNEE_CHANGED = "CaseInboxAssigneeChanged",
  /** @deprecated use CONTACT_STATUS_CHANGED */
  CASE_STATUS_CHANGED = "CaseStatusChanged",
  /** @deprecated use CONTACT_TO_ROUTING_QUEUE_ASSIGNMENT_CHANGED */
  CASE_TO_ROUTING_QUEUE_ASSIGNMENT_CHANGED = "CaseToRoutingQueueAssignmentChanged",
  CONTACT_CREATED = "CaseCreated",
  ASSIGNED_AGENT_CHANGED = "CaseInboxAssigneeChanged",
  CONTACT_STATUS_CHANGED = "CaseStatusChanged",
  CONTACT_TO_ROUTING_QUEUE_ASSIGNMENT_CHANGED = "CaseToRoutingQueueAssignmentChanged",
  CONTACT_PREFERRED_USER_CHANGED = "ContactPreferredUserChanged",
  CONTACT_PROFICIENCY_CHANGED = "ContactProficiencyChanged",
  CONTACT_PRIORITY_CHANGED = "ContactPriorityChanged",
  CONTACT_SYNC = "ContactSync",
  CHANNEL_CREATED = "ChannelCreated",
  CHANNEL_DELETED = "ChannelDeleted",
  CHANNEL_UPDATED = "ChannelUpdated",
  MESSAGE_ADDED_INTO_CASE = "MessageAddedIntoCase",
  MESSAGE_CREATED = "MessageCreated",
  MESSAGE_DELIVERED_TO_END_USER = "MessageDeliveredToEndUser",
  MESSAGE_DELIVERED_TO_USER = "MessageDeliveredToUser",
  MESSAGE_NOTE_CREATED = "MessageNoteCreated",
  MESSAGE_NOTE_UPDATED = "MessageNoteUpdated",
  MESSAGE_NOTE_DELETED = "MessageNoteDeleted",
  MESSAGE_READ_CHANGED = "MessageReadChanged",
  MESSAGE_SEEN_BY_END_USER = "MessageSeenByEndUser",
  MESSAGE_SEEN_BY_USER = "MessageSeenByUser",
  MESSAGE_SENT = "MessageSent",
  MESSAGE_UPDATED = "MessageUpdated",
  PAGE_VIEW_CREATED = "PageViewCreated",
  ROUTING_QUEUE_CREATED = "RoutingQueueCreated",
  ROUTING_QUEUE_DELETED = "RoutingQueueDeleted",
  ROUTING_QUEUE_UPDATED = "RoutingQueueUpdated",
  SUBQUEUE_ASSIGNED_TO_ROUTING_QUEUE = "SubqueueAssignedToRoutingQueue",
  SUBQUEUE_UNASSIGNED_TO_ROUTING_QUEUE = "SubqueueUnassignedFromRoutingQueue",
  USER_ASSIGNED_TO_ROUTING_QUEUE = "UserAssignedToRoutingQueue",
  USER_STATUS_CHANGED = "UserStatusChanged",
  USER_UNASSIGNED_FROM_ROUTING_QUEUE = "UserUnassignedFromRoutingQueue",
  AGENT_CONTACT_STARTED = "AgentContactStarted",
  AGENT_CONTACT_ENDED = "AgentContactEnded",
  SENDER_TYPING_STARTED = "SenderTypingStarted",
  SENDER_TYPING_ENDED = "SenderTypingEnded",
  FIRE_PROACTIVE = "FireProactiveAction",
  CONTACT_INBOX_PRE_ASSIGNEE_CHANGED = "ConsumerContactInboxPreAssigneeChanged",
  CONTACT_RECIPIENTS_CHANGED = "ContactRecipientsChanged",
  MESSAGE_PREVIEW_CREATED = "MessagePreviewCreated",
  EVENT_IN_S3 = "EventInS3",
}

declare enum PushUpdateContextInitiatorType {
  SYSTEM = "system",
  USER = "user",
  API = "api",
  EXTERNAL = "external",
  WORKFLOW = "workflow",
  WORKFLOW_JOB = "workflowJob",
  ROUTING = "routing",
}

interface PushUpdateContextInitiator {
  type: PushUpdateContextInitiatorType;
  id: string;
}

interface PushUpdateContext {
  initiator?: PushUpdateContextInitiator;
}

interface PushUpdateEventFields {
  eventId: string;
  eventObject: PushUpdateEventObject;
  eventType: PushUpdateEventType;
  context?: PushUpdateContext | [];
}

export interface Message<T> extends PushUpdateEventFields {
  createdAt: string;
  createdAtWithMilliseconds: string;
  traceId: string;
  data: T;
}

enum ChannelAvailability {
  ONLINE = "online",
  OFFLINE = "offline",
}

interface UserStatus {
  id: string;
  userStatusId: string;
  name: "Online" | "Offline";
  type: ChannelAvailability;
  timeElapsedInSeconds: number;
}

interface UserStatusChangedData {
  brand: Brand;
  user: User;
  userStatus: UserStatus;
}

export interface UserStatusChanged extends Message<UserStatusChangedData> {
  eventObject: PushUpdateEventObject.USER_STATUS;
  eventType: PushUpdateEventType.USER_STATUS_CHANGED;
}

export interface AssignedAgentChanged
  extends Message<AssignedAgentChangedData> {
  eventObject: PushUpdateEventObject.CASE;
  eventType: PushUpdateEventType.ASSIGNED_AGENT_CHANGED;
}

export interface CaseStatusChanged extends Message<ContactStatusChangedData> {
  eventObject: PushUpdateEventObject.CASE;
  eventType: PushUpdateEventType.CONTACT_STATUS_CHANGED;
}

export type NiceWSEvents =
  | UserStatusChanged
  | AssignedAgentChanged
  | CaseStatusChanged;
