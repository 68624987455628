import { IConfigOptions, IContactInfo } from "@auvious/integrations";
import { IConversationStrategy } from "../../../core-ui/models/strategies/IConversationStrategy";

export class NoConversationStrategy implements IConversationStrategy {
  prepareConversationRequest(): IContactInfo {
    return null;
  }

  prepareConversationConfig(): IConfigOptions {
    return null;
  }

  leaveConversation() {
    return null;
  }

  getMessages() {
    return null;
  }
}
