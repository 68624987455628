import { IUserDetails } from "../../core-ui";

export interface ITalkdeskPagedResponse {
  count: number;
  total: number;
  page: number;
  per_page: number;
  total_pages: number;
}

export interface ITalkdeskUsersPagedResponse extends ITalkdeskPagedResponse {
  _embedded: { users: ITalkdeskUser[] };
  _links?: ITalkdeskUserLink;
}

export interface ITalkdeskUser {
  id: string;
  email: string;
  name: string;
  active: boolean;
  gender: string;
  extension: number;
  external_phone_number: string;
  created_at: string;
  _links: {
    self: any;
    href: string;
  };
}

export interface ITalkdeskUserLink {
  self: {
    href: string;
  };
  next: {
    href: string;
  };
  first: {
    href: string;
  };
  last: {
    href: string;
  };
  show: {
    templated: boolean;
    href: string;
  };
  index: {
    templated: boolean;
    href: string;
  };
}

export class TalkdeskUser implements IUserDetails {
  private _id: string;
  private _integrationUserId: string;
  private _name: string;
  private _email: string;
  private _avatarUrl: string;
  private _displayName: string;

  constructor(data: ITalkdeskUser) {
    this._id = data.id;
    this._name = data.name;
    this._email = data.email;
    this._displayName = data.name;
  }

  get id(): string {
    return this._id;
  }
  // get this._integrationUserId(): string {

  // }
  get name(): string {
    return this._name;
  }
  get email(): string {
    return this._email;
  }
  get avatarUrl(): string {
    return this._avatarUrl;
  }
  get displayName(): string {
    return this._displayName;
  }
}
