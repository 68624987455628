import { Util } from "@auvious/common";
import { INotificationEvent } from "../INotificationEvent";
import { NotificationTypeEnum } from "../../core-ui.enums";
import { INotificationOptions } from "../INotificationOptions";

export class ToastNotification implements INotificationEvent {
  private _dismissedCb: () => void;

  constructor(
    private _title: string,
    private _options?: INotificationOptions,
    private _id?: number
  ) {
    if (!_id) {
      this._id = Util.uuidgen();
    }
  }

  getColor() {
    return this._options?.color || "container";
  }

  getId() {
    return this._id;
  }

  getTitle() {
    return this._title;
  }

  getType() {
    return NotificationTypeEnum.toast;
  }

  getSentAt() {
    return new Date();
  }

  getBody() {
    return this._options?.body;
  }

  getTimeToLive(): number {
    return this._options?.ttl;
  }

  canShow() {
    return true;
  }

  dismiss() {
    this._dismissedCb?.();
  }

  onDismissed(callback: () => void) {
    this._dismissedCb = callback;
  }
}
