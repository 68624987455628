import { Injectable } from "@angular/core";
import { AuviousRtcService } from "./rtc.service";
import { ApiResource } from "@auvious/common";
import { IWebhookSubscription } from "../models/Webhooks";
import {
  WebhookSubscriptionTargetEnum,
  WebhookSubscriptionTypeEnum,
} from "../core-ui.enums";
import { IPagedResponse } from "../models";
import { GenericErrorHandler } from "./error-handlers.service";

@Injectable()
export class WebhookService {
  private apiResource: ApiResource;

  constructor(
    private rtcService: AuviousRtcService,
    private errorHandler: GenericErrorHandler
  ) {
    this.apiResource =
      this.rtcService.getAuviousCommonClient().apiResourceFactory("api/webhooks");
  }

  public createSecretKey(): Promise<string> {
    return this.apiResource
      .create(
        {},
        {
          urlPostfix: "subscriptions/hmackey",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public createSubscription(
    subscription: IWebhookSubscription
  ): Promise<IWebhookSubscription> {
    return this.apiResource
      .create(subscription, {
        urlPostfix: "subscriptions/",
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public updateSubscription(
    subscription: IWebhookSubscription
  ): Promise<IWebhookSubscription> {
    return this.apiResource
      .update(subscription, {
        urlPostfix: "subscriptions/",
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public deleteSubscription(id: string): Promise<void> {
    return this.apiResource
      .delete({
        urlPostfix: "subscriptions/" + id,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getSubscription(id: string): Promise<IWebhookSubscription> {
    return this.apiResource
      .get({
        urlPostfix: "subscriptions" + id,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getSubscriptionsByApplication(
    id: string
  ): Promise<IPagedResponse<IWebhookSubscription>> {
    return this.apiResource
      .get({
        urlPostfix: `subscriptions/${id}/all`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getSubscriptionByApplication(
    id: string,
    target?: WebhookSubscriptionTargetEnum,
    type?: WebhookSubscriptionTypeEnum
  ): Promise<IWebhookSubscription> {
    return this.apiResource
      .get({
        urlPostfix: "subscriptions/applications/" + id,
        params: { target, type },
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }
}
