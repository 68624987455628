import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { IDateRange } from "../../../../../core-ui/models/interfaces";
import moment from "moment";
import {
  DayTimeCalendarComponent,
  IDate,
  IDatePickerConfig,
} from "ng2-date-picker";
import dayjs from "dayjs";

@Component({
  selector: "app-date-range-picker-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
})
export class DateRangePickerPopupComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  set startDate(value: moment.Moment) {
    this._startDate = value;
    this.dpStartAt = dayjs(value.toDate());
  }
  get startDate() {
    return this._startDate;
  }
  @Input()
  set endDate(value: moment.Moment) {
    this._endDate = value;
    this.dpEndAt = dayjs(value.toDate());
  }
  get endDate() {
    return this._endDate;
  }
  @Input() direction: "row" | "column" | "responsive" = "row";

  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() rangeChanged: EventEmitter<IDateRange> = new EventEmitter();

  @ViewChild("startPicker") startCalendarRef: DayTimeCalendarComponent;
  @ViewChild("endPicker") endCalendarRef: DayTimeCalendarComponent;

  subscriptions: Subscription = new Subscription();
  dateConfig: IDatePickerConfig = {
    format: "YYYY-MM-DD",
    firstDayOfWeek: "mo",
  };

  rangeToday: IDateRange = {
    startAt: this.startOfDay(moment()),
    endAt: this.endOfDay(moment()),
  };
  rangeYesterday: IDateRange = {
    startAt: this.startOfDay(moment().subtract(1, "days")),
    endAt: this.endOfDay(moment().subtract(1, "days")),
  };
  rangeThisWeek: IDateRange = {
    startAt: this.startOfDay(moment().day(1)),
    endAt: this.endOfDay(moment().set("hours", 23)),
  };
  rangeLastWeek: IDateRange = {
    startAt: this.startOfDay(moment().day(-6)),
    endAt: this.endOfDay(moment().day(0)),
  };
  rangeThisMonth: IDateRange = {
    startAt: this.startOfDay(moment().date(1)),
    endAt: this.endOfDay(moment()),
  };
  rangeLastMonth: IDateRange = {
    startAt: this.startOfDay(moment().subtract(1, "months").date(1)),
    endAt: this.endOfDay(
      this.startOfDay(moment().date(1)).subtract(1, "seconds")
    ),
  };
  _startDate: moment.Moment;
  _endDate: moment.Moment;
  dpStartAt: dayjs.Dayjs;
  dpEndAt: dayjs.Dayjs;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.startCalendarRef.api.moveCalendarTo(this.startDate);
    this.endCalendarRef.api.moveCalendarTo(this.endDate);
  }

  closeRequest() {
    this.closed.emit();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  startOfDay(day: moment.Moment): moment.Moment {
    return day.set("hours", 0).set("minutes", 0).set("seconds", 0);
  }

  endOfDay(day: moment.Moment): moment.Moment {
    return day.set("hours", 23).set("minutes", 59).set("seconds", 59);
  }

  get arrowPos() {
    return this.direction === "column" ? "top-left" : "top";
  }

  startChange(entry: IDate) {
    if (!entry) {
      return;
    }
    this._startDate = moment(entry.date.toDate());
    this.rangeChanged.emit({
      startAt: this.startDate,
      endAt: this.endDate,
    });
  }

  endChange(entry: IDate) {
    if (!entry) {
      return;
    }
    this._endDate = moment(entry.date.toDate());
    this.rangeChanged.emit({
      startAt: this.startDate,
      endAt: this.endDate,
    });
  }

  rangeChange(range: IDateRange) {
    this.startCalendarRef.api.moveCalendarTo(range.startAt);
    this.endCalendarRef.api.moveCalendarTo(range.endAt);
    this.rangeChanged.emit(range);
  }
}
