import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

import { ErrorPageCode, PARAM_APPLICATION_ID } from "../../../app/app.enums";
import { IIntegration } from "../../../app/models";
import { IntegrationService } from "../../../app/services";
import {
  ApplicationService,
  ThemeService,
  ActivityIndicatorService,
} from "../../../core-ui/services";
import { ApplicationFactory } from "../../factories/ApplicationFactory";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "../../../core-ui/services/notification.service";

@Component({
  selector: "app-page-applications",
  templateUrl: "./applications.component.html",
  styleUrls: ["./applications.component.scss"],
})
export class ApplicationsPageComponent implements OnInit {
  apps: IIntegration[] = [];

  constructor(
    private integrationService: IntegrationService,
    private applicationService: ApplicationService,
    private locationService: Location,
    private alertService: NotificationService,
    private applicationFactory: ApplicationFactory,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private activity: ActivityIndicatorService,
    private router: Router
  ) {}

  async ngOnInit() {
    try {
      this.activity.loading(true);
      this.apps = await this.integrationService.getIntegrations();
      if (this.apps.length === 0) {
        return this.router.navigate([
          "/error",
          ErrorPageCode.APPLICATION_NOT_FOUND,
        ]);
      } else if (this.apps.length === 1) {
        return this.pickInstance(this.apps[0]);
      }
    } catch (ex) {
      return this.router.navigate([
        "/error",
        ErrorPageCode.APPLICATION_NOT_FOUND,
      ]);
    } finally {
      this.activity.loading(false);
    }
  }

  get applications() {
    return this.apps || [];
  }

  async pickInstance(app: IIntegration) {
    const applicationId = new URL(app.getUrl()).searchParams.get(
      PARAM_APPLICATION_ID
    );
    const application = await this.applicationFactory.createAgentApplication(
      applicationId,
      this.route.snapshot
    );
    this.applicationService.setActiveApplication(application);
    this.themeService.parseApplicationOptions(application);

    // go back where you came from
    this.locationService.back();
  }
}
