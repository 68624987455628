import { Util } from "@auvious/common";
import { INotificationEvent } from "../INotificationEvent";
import { NotificationTypeEnum } from "../../core-ui.enums";

export class ConfirmNotification implements INotificationEvent {
  private _id: string;
  private _confirmedCb: () => void;
  private _canceledCb: () => void;

  constructor(private title: string, private body: string) {
    this._id = Util.uuidgen();
  }

  getId() {
    return this._id;
  }

  getTitle() {
    return this.title;
  }

  getType() {
    return NotificationTypeEnum.confirm;
  }

  getSentAt() {
    return new Date();
  }

  getBody() {
    return this.body;
  }

  getColor(): "danger" | "warning" | "info" | "success" | "container" {
    return "container";
  }

  getTimeToLive(): number {
    return 0;
  }

  canShow() {
    return true;
  }

  onConfirmed(callback: () => void) {
    this._confirmedCb = callback;
  }

  onCanceled(callback: () => void) {
    this._canceledCb = callback;
  }

  confirm() {
    this._confirmedCb?.();
  }

  cancel() {
    this._canceledCb?.();
  }
}
