import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { ISyncable } from "./ISyncable";
import { IntegrationService } from "../../../app/services";
import { ErrorPageCode } from "../../../app/app.enums";

@Injectable()
export class LicenseAgentGuard  implements ISyncable {
  constructor(
    private router: Router,
    private integration: IntegrationService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    switch (route.url[0].path) {
      case "a":
        if (this.integration.hasLicense("video")) {
          return true;
        }
        return this.router.createUrlTree(
          ["/error", ErrorPageCode.LICENSE_REQUIRED],
          { queryParamsHandling: "preserve" }
        );
      default:
        return true;
    }
  }
}
