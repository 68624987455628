import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { GoogleTagManagerService } from "../../modules/google-tag-manager";
import { AppConfigService } from "./app.config.service";

export enum TagEventsEnum {
  PAGE = "page",
  AGENT_ROOM_REQUEST = "agent-room-request",
  JOIN_ROOM = "join-room",
}

@Injectable()
export class TagManagerService {
  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private config: AppConfigService
  ) {}

  public async trackPages() {
    if (this.config.isGoogleTagManagerEnabled) {
      await this.gtmService.addGtmToDom();

      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const tag = {
            event: TagEventsEnum.PAGE,
            pageName: item.url,
          };
          this.sendEvent(tag);
        }
      });
    }
  }

  public trackEvent(event: TagEventsEnum, data: string) {
    this.sendEvent({ event, data });
  }

  private sendEvent(tag) {
    this.gtmService.pushTag(tag);
  }
}
