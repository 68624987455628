import { Injectable } from "@angular/core";
import { IRating } from "../models";
import { ApiResource } from "@auvious/common";
import { AuviousRtcService } from "./rtc.service";
import { Observable, Subject } from "rxjs";
import { Rating } from "@auvious/rating";
import { RatingService as AVRatingService } from "@auvious/rating";
import { GenericErrorHandler } from "./error-handlers.service";

@Injectable()
export class RatingService {
  private sessionId: string;
  private popupSubject: Subject<boolean>;
  private resolver: any;

  constructor(private avRating: AVRatingService) {
    this.popupSubject = new Subject<boolean>();
  }

  public get popupVisibility$(): Observable<boolean> {
    return this.popupSubject.asObservable();
  }

  public showPopup(conferenceId): Promise<void> {
    return new Promise((resolve) => {
      this.resolver = resolve;
      this.sessionId = conferenceId;
      this.popupSubject.next(true);
    });
  }

  public hidePopup() {
    if (!!this.resolver) {
      this.resolver();
    }
    this.popupSubject.next(false);
  }

  public rate(feedback: IRating): Promise<string> {
    return this.avRating.create(
      feedback.score,
      feedback.comment,
      feedback.interactionId,
      this.sessionId,
      feedback.sessionType
    );
    // return this.rtcResource.create({ ...feedback, sessionId: this.sessionId, });
  }

  public getRatingForInteractionId(id: string): Promise<Rating[]> {
    return this.avRating.findByInteractionId(id);
  }
  public getRatingForConferenceId(id: string): Promise<Rating[]> {
    return this.avRating.findByConferenceId(id);
  }
}
