import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { ConversationTypeEnum, SketchToolEnum } from "../../core-ui.enums";
import { IInteraction } from "../../models/IInteraction";
import {
  PointerService,
  SketchService,
  SnapshotService,
  StreamState,
} from "../../services";
import { ConferenceService } from "../../services/conference.service";
import { NotificationService } from "../../services/notification.service";
import { UserService } from "../../services/user.service";
import { AppConfigService } from "../../services/app.config.service";
import { AgentParam } from "../../models/application-options/AgentOptions";

@Component({
  selector: "app-point-controls",
  templateUrl: "./point-controls.component.html",
  styleUrls: ["./point-controls.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointControlsComponent implements OnInit, OnDestroy {
  @Input() stream: StreamState;
  @Input() interaction: IInteraction;
  @Input() column: boolean;

  @Output() controlSelected: EventEmitter<SketchToolEnum> = new EventEmitter();

  private subscriptions: Subscription = new Subscription();

  activeTool: SketchToolEnum = SketchToolEnum.pointer;
  isFrameFrozen = false;
  isZoomOn = false;

  constructor(
    private sketchService: SketchService,
    private pointerService: PointerService,
    private snapshotService: SnapshotService,
    private notification: NotificationService,
    private conference: ConferenceService,
    private user: UserService,
    private config: AppConfigService,
    private cd: ChangeDetectorRef
  ) {}

  @HostBinding("class") get class() {
    return {
      column: this.column,
    };
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.sketchService.toolSelected$.subscribe((tool) => {
        this.activeTool = tool || SketchToolEnum.pointer;
        this.cd.detectChanges();
      })
    );
    this.subscriptions.add(
      this.pointerService.freezeFrameChange$
        .pipe(
          filter(
            (e) =>
              e.target.endpoint === this.stream.originator.endpoint &&
              e.type === this.stream.type
          )
        )
        .subscribe((e) => {
          this.isFrameFrozen = e.on;
          // stop zoom if on
          if (!e.on && this.isZoomOn) {
            this.zoom();
          }
          // reset UI
          if (!e.on && this.activeTool === SketchToolEnum.magnify) {
            this.pointer();
          }
          this.cd.detectChanges();
        })
    );
    this.subscriptions.add(
      this.pointerService.zoomFrameChange$
        .pipe(
          filter(
            (e) =>
              e.target.endpoint === this.stream.originator.endpoint &&
              e.type === this.stream.type
          )
        )
        .subscribe((e) => {
          this.isZoomOn = e.on;
          if (e.on) {
            this.activeTool = SketchToolEnum.magnify;
          }
          this.cd.detectChanges();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  snapshot() {
    this.snapshotService.requestSnapshot(
      this.stream.originator,
      this.interaction
    );
    // acquireSnapshot on snapshotService will also notify target of snapshot taken
    //  so if it's myself, don't notify twice
    if (this.stream.originator.username !== this.conference.myself.username) {
      this.notification.info("Click!", { body: "Snapshot taken" });
    }
  }

  freezeFrame() {
    this.pointerService.freezeFrame(this.stream.originator, this.stream.type);
  }

  zoom() {
    const activateControl = this.isZoomOn
      ? SketchToolEnum.pointer
      : SketchToolEnum.magnify;
    this.sketchService.selectTool(activateControl);
    this.controlSelected.emit(activateControl);
    this.pointerService.zoomFrame(this.stream.originator, this.stream.type);
  }

  pointer() {
    if (this.isZoomOn) {
      this.pointerService.zoomFrame(this.stream.originator, this.stream.type);
    }
    this.sketchService.selectTool(SketchToolEnum.pointer);
    this.controlSelected.emit(SketchToolEnum.pointer);
  }

  get tooltipPosition() {
    return this.column ? "left" : "bottom";
  }

  get isPointerActive() {
    return this.activeTool === SketchToolEnum.pointer;
  }

  get isSnapshotAvailable() {
    return (
      this.stream.type !== "screen" &&
      this.user.isAgent &&
      this.config.agentParamEnabled(AgentParam.SNAPSHOT_ENABLED) &&
      this.snapshotService.supported(this.stream.originator) &&
      this.interaction.getType() !== ConversationTypeEnum.voiceCall
    );
  }

  get isFreezeFrameAvailable() {
    return this.stream.type !== "screen" && this.user.isAgent;
  }
}
