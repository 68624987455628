import {
  Input,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { VgApiService } from "@videogular/ngx-videogular/core";
import {
  IQueueAsset,
  IThemeOptions,
  ThemeParam,
} from "../../models";
import moment from "moment";
import { DeviceService } from "../../services/device.service";
import { AssetTypeEnum } from "../../core-ui.enums";

@Component({
  selector: "app-waiting",
  templateUrl: "./waiting.component.html",
  styleUrls: ["./waiting.component.scss"],
})
export class WaitingComponent implements OnInit, OnDestroy {
  @Input() startAt: Date;
  @Input() theme: IThemeOptions;
  @Input() timezone: string;
  @Input() queueName: string;
  @Input() queueId: string;

  @ViewChild("frame") iFrameRef: ElementRef<HTMLIFrameElement>;

  isNotPlaying = false;
  timezoneOffset: string;
  videoSrc: string;
  imageSrc: string;
  isVideoAvailable = false;
  isImageAvailable = false;

  private startedPlaying = false;

  constructor() {}

  ngOnInit() {
    if (this.timezone) {
      this.timezoneOffset = moment
        .tz(this.startAt, this.timezone)
        .format("Z z");
    }

    if (this.isAssetPerQueue) {
      let asset: IQueueAsset;
      if (this.queueId) {
        asset = this.theme?.[ThemeParam.ASSET_PER_QUEUE]?.find(
          (a) => a.queueId === this.queueId
        );
      } else if (this.queueName) {
        asset = this.theme?.[ThemeParam.ASSET_PER_QUEUE]?.find(
          (a) => a.queueName === this.queueName
        );
      }

      if (asset) {
        switch (asset.type) {
          case AssetTypeEnum.video:
            this.isVideoAvailable = !!asset.url;
            this.videoSrc = asset.url;
            break;
          case AssetTypeEnum.image:
            this.isImageAvailable = !!asset.url;
            this.imageSrc = asset.url;
        }
      }
    } else {
      switch (this.theme?.[ThemeParam.QUEUE_ASSET_TYPE]) {
        case AssetTypeEnum.video:
          this.isVideoAvailable = !!this.theme?.[ThemeParam.QUEUE_VIDEO_URL];
          this.videoSrc = this.theme[ThemeParam.QUEUE_VIDEO_URL];
          break;
        case AssetTypeEnum.image:
          this.isImageAvailable = !!this.theme?.[ThemeParam.QUEUE_VIDEO_URL];
          this.imageSrc = this.theme[ThemeParam.QUEUE_VIDEO_URL];
          break;
      }
    }
  }

  ngOnDestroy() {
    // this.clearTimers();
  }

  get isAssetPerQueue() {
    return this.theme?.[ThemeParam.ASSET_PER_QUEUE_ENABLED];
  }

  get isEmbeddedVideo(): boolean {
    return !!this.theme?.embeddableVideoUrl;
  }

  get scheduledDate() {
    return this.startAt;
  }

  get isMobile() {
    return DeviceService.isMobile;
  }

  imgLoadFailed(e){
      this.isImageAvailable = false;
  }

  onPlayerReady(api: VgApiService) {
    api.getDefaultMedia().subscriptions.canPlay.subscribe(() => {
      // first it was Safari, then others (Google, and Firefox) followed,
      // thus we need to mute at this point
      api.volume = 0;
      // if it hasn't started playing, show the play overlay and make it manual
      setTimeout(() => {
        if (!this.startedPlaying) {
          this.isNotPlaying = true;
        }
      }, 300);
      api.play();
    });
    api.getDefaultMedia().subscriptions.playing.subscribe(() => {
      this.startedPlaying = true;
    });
  }
}
