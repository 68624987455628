import { Injectable } from "@angular/core";

import { ISyncable } from "./ISyncable";

import { LocaleService } from "../../services/locale.service";
import { ActivityIndicatorService } from "../../../core-ui/services/activity-indicator.service";

@Injectable()
export class LocaleGuard  implements ISyncable {
  constructor(
    private locale: LocaleService,
    private activity: ActivityIndicatorService
  ) {}

  async canActivate(): Promise<boolean> {
    this.activity.loading(true);
    try {
      await this.locale.loadInstanceTranslationsForLocale();
    } catch (ex) {
    } finally {
      this.activity.loading(false);
    }
    return true;
  }
}
