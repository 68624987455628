import { AuviousRtcService } from "./rtc.service";
import { IAuditLog, PageableRequest } from "../models";
import { ApiResource, PagedCollection } from "@auvious/common";
import { Injectable } from "@angular/core";
import { AuditActionEnum, AuditApplicationEnum, GenericErrorHandler } from "..";

@Injectable()
export class AuditService {
  private auditResource: ApiResource;

  constructor(
    private rtcService: AuviousRtcService,
    private errorHandler: GenericErrorHandler
  ) {
    this.rtcService.common$.subscribe((common) => {
      this.auditResource = common.apiResourceFactory("api/reporting/audits");
    });
  }

  public query(request: PageableRequest): Promise<PagedCollection<IAuditLog>> {
    return this.auditResource
      .get({ params: request.getParams() })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getActionTypes(): Promise<AuditActionEnum[]> {
    return this.auditResource
      .get({
        urlPostfix: "actions",
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getApplicationTypes(): Promise<AuditApplicationEnum[]> {
    return this.auditResource
      .get({
        urlPostfix: "applications",
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }
}
