import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { IInteraction, IRating } from "../../models";
import { RatingService, ThemeService } from "../../services";
import { slideInOut } from "../../core-ui.animations";
@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
  animations: [slideInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent implements OnInit, OnDestroy {
  @Input() interaction: IInteraction;

  visible: boolean;
  feedback: IRating;
  hoverRating = 0;
  points = [1, 2, 3, 4, 5];
  maxChars = 500;
  isRated = false;
  isDarkMode = false;
  subscriptions: Subscription;

  constructor(
    private rateService: RatingService,
    private themeService: ThemeService,
    private cd: ChangeDetectorRef
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.rateService.popupVisibility$.subscribe((visible) => {
        this.reset();
        this.visible = visible;
        this.cd.detectChanges();
        (
          document.querySelector(
            "[data-tid='rating/skip']"
          ) as HTMLButtonElement
        )?.focus();
      })
    );
    this.subscriptions.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        this.isDarkMode = theme.darkMode;
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  reset() {
    // sessionId is populated onShow, inside the service
    this.feedback = {
      sessionId: null,
      interactionId: this.interaction.getId(),
      sessionType: "CONFERENCE",
      score: 0,
      comment: "",
    };
    this.isRated = false;
  }

  get isLowRating() {
    return this.feedback.score < 5 && this.feedback.score > 0;
  }

  isActive(point) {
    return this.hoverRating >= point || this.feedback.score >= point;
  }

  rate(point) {
    this.feedback.score = point;
    if (point === 5) {
      this.send();
    }
  }

  empty() {
    this.hoverRating = 0;
  }

  fill(point) {
    this.hoverRating = point;
    // this.feedback.rating = 0;
  }

  send() {
    this.rateService.rate(this.feedback);
    this.isRated = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.rateService.hidePopup();
    }, 1500);
  }

  cancel() {
    this.rateService.hidePopup();
  }
}
