import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import moment from "moment";
import { AppConfigService } from "../../../../core-ui/services";
import { slideFromUp } from "../../../../core-ui/core-ui.animations";
import { IDateRange } from "../../../../core-ui/models";

@Component({
  selector: "app-date-range-picker",
  templateUrl: "./date-range-picker.component.html",
  styleUrls: ["./date-range-picker.component.scss"],
  animations: [slideFromUp],
})
export class DateRangePickerComponent implements OnInit {
  @Input() range: IDateRange;
  @Input() direction: "row" | "column" | "responsive" = "row";

  @Output() ready: EventEmitter<IDateRange> = new EventEmitter();
  @Output() changed: EventEmitter<IDateRange> = new EventEmitter();

  open = false;
  lang: string;


  startDate: moment.Moment;
  endDate: moment.Moment;

  rangeToday: IDateRange = {
    startAt: this.startOfDay(moment()),
    endAt: this.endOfDay(moment()),
  };

  constructor(config: AppConfigService) {
    this.lang = config.language;
  }

  ngOnInit(): void {
    if (!this.range) {
      this.startDate = this.rangeToday.startAt;
      this.endDate = this.rangeToday.endAt;
    } else {
      this.startDate = this.range.startAt;
      this.endDate = this.range.endAt;
    }
    this.ready.emit({
      startAt: this.startDate,
      endAt: this.endDate,
    });

  }

  rangeChange(range: IDateRange) {
    this.startDate = range.startAt;
    this.endDate = range.endAt;
    this.changed.emit(range);
  }

  startOfDay(day: moment.Moment): moment.Moment {
    return day.set("hours", 0).set("minutes", 0).set("seconds", 0);
  }

  endOfDay(day: moment.Moment): moment.Moment {
    return day.set("hours", 23).set("minutes", 59).set("seconds", 59);
  }

}
