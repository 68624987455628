import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { UserRoleEnum } from "../core-ui.enums";

import { IUser, IUserDetails } from "../models/IUser";
import { ApiResource } from "@auvious/common";
import { AuviousRtcService } from "./rtc.service";
import { PageableRequest, IPagedResponse } from "../models/Pageable";
import { IContact } from "../models/Appointment";
import { GenericErrorHandler } from "./error-handlers.service";

@Injectable()
export class UserService {
  private activeUser: IUser;
  private userDetails: IUserDetails;
  private userSubject: BehaviorSubject<{ user: IUser; details: IUserDetails }> =
    new BehaviorSubject({ user: null, details: null });
  private userResource: ApiResource;

  constructor(
    rtcService: AuviousRtcService,
    private errorHandler: GenericErrorHandler
  ) {
    rtcService.common$.subscribe((common) => {
      this.userResource = rtcService
        .getAuviousCommonClient()
        .apiResourceFactory("api/users");
    });
  }

  queryContacts(query: PageableRequest): Promise<IPagedResponse<IContact>> {
    return this.userResource
      .get({
        params: query.getParams(),
        urlPostfix: "/",
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  getContactById(id: string): Promise<IContact> {
    return this.userResource.getById(id).catch((ex) => {
      this.errorHandler.handleNotAuthenticatedError(ex);
      throw ex;
    });
  }

  isAuthenticated(): boolean {
    return this.activeUser && this.activeUser.isAuthenticated();
  }

  getActiveUser(): IUser {
    return this.activeUser;
  }

  get userChanged$() {
    return this.userSubject.asObservable();
  }

  getUserDetails(): IUserDetails {
    return this.userDetails;
  }

  setActiveUser(user: IUser) {
    this.activeUser = user;
    this.userSubject.next({ user: this.activeUser, details: this.userDetails });
  }

  setUserDetails(details: IUserDetails) {
    this.userDetails = details;
    this.userSubject.next({ user: this.activeUser, details: this.userDetails });
  }

  get isCustomer() {
    return this.activeUser?.hasRole(UserRoleEnum.customer);
  }

  get isAgent() {
    return this.activeUser?.hasRole(UserRoleEnum.agent);
  }

  get isGuestAgent() {
    return this.activeUser?.hasRole(UserRoleEnum.guestAgent);
  }

  get isSupervisor() {
    return this.activeUser?.hasRole(UserRoleEnum.supervisor);
  }

  get isAdmin() {
    return this.activeUser?.hasRole(UserRoleEnum.admin);
  }
}
