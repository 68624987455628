import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from "@angular/core";

@Component({
  selector: "av-card-body",
  templateUrl: "./card-body.component.html",
  styleUrls: ["./card-body.component.scss"],
})
export class CardBodyComponent implements OnInit {
  @Input() type: "spotlight" | "container" | "slim" | "thin" = "container";
  @Input() noHeader = false;

  classes = {};
  constructor(public host: ElementRef<HTMLDivElement>) {}

  ngOnInit(): void {
    // this.classes = ;
  }

  @HostBinding("class") get class() {
    return {
      "panel-body-spotlight": this.type === "spotlight",
      "panel-body-no-header": this.noHeader,
      "panel-body-slim": this.type === "slim",
      "panel-body-thin": this.type === "thin",
      "panel-body-container": this.type === "container",
    };
  }

  get nativeElement(): HTMLDivElement {
    return this.host.nativeElement;
  }
}
