import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ErrorHandler,
  HostBinding,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  EventSummary,
  IPageEventHandlers,
  IReceiverEventMap,
  MirrorPage,
  Player,
} from "@auvious/cobrowser";
import { PlayerBuffer } from "@auvious/cobrowser/dist/player/buffer";
import { TranslateService } from "@ngx-translate/core";
import { fadeInOut } from "../../core-ui.animations";
import { ColorEnum, UserRoleEnum } from "../../core-ui.enums";
import { IArea, IMediaPlayerPlayRequest } from "../../models";
import {
  ApplicationService,
  debugError,
  MediaPlayerService,
} from "../../services";
import { NotificationService } from "../../services/notification.service";
import { CobrowseFrameComponent } from "../cobrowse-frame/cobrowse-frame.component";
import { VgApiService } from "@videogular/ngx-videogular/core";

@Component({
  selector: "app-media-player",
  templateUrl: "./media-player.component.html",
  styleUrls: ["./media-player.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class MediaPlayerComponent implements OnInit {
  request: IMediaPlayerPlayRequest;

  @ViewChild("cobrowseView") cobrowseRef: CobrowseFrameComponent;

  private coBrowsePlayer: Player;
  coBrowseTime = "0";
  coBrowseLabel;
  isCoBrowseLoading = false;
  isCoBrowsePlaying = false;
  isCoBrowseDrawerOpen = false;
  pointers = new Map<string, Parameters<IPageEventHandlers["mousemove"]>[0]>();
  remoteFrameSize: IArea = {
    width: 0,
    height: 0,
  };
  mouseRatio = 1;
  coBrowseSummary: EventSummary[] = [];

  constructor(
    private mediaPlayerService: MediaPlayerService,
    private notification: NotificationService,
    private cd: ChangeDetectorRef,
    private handler: ErrorHandler,
    private t: TranslateService,
    private applicationService: ApplicationService
  ) {}

  @HostBinding("class") get class() {
    return {
      open: !!this.request,
      "player-talkdesk-offset":
        !!this.request && this.applicationService.isTalkdeskApp,
    };
  }

  @HostListener("click")
  click() {
    this.cancel();
  }

  copied(event) {
    event.stopPropagation();
  }

  ngOnInit() {
    this.mediaPlayerService.playRequest$.subscribe(async (request) => {
      this.request = request;

      if (this.isCoBrowse) {
        try {
          this.resetCobrowse();
          this.isCoBrowseLoading = true;
          this.cd.detectChanges();

          this.coBrowsePlayer = new Player(
            await MirrorPage.create(
              { target: this.cobrowseRef.iFrame, ignorePermissions: true },
              null,
              {
                resize: (event: IArea) => {
                  this.remoteFrameSize = event;
                  this.cd.detectChanges();
                },
                mousemove: (event) => {
                  this.pointers.set(event.user?.endpoint, {
                    ...event,
                    top: event.top * this.mouseRatio,
                    left: event.left * this.mouseRatio,
                  });

                  this.cd.detectChanges();
                },
              }
            ),
            // TODO: handle null in new version
            new PlayerBuffer(),
            {
              progress: (time) => {
                // this is how to calculate percentage for the range
                this.coBrowseTime =
                  Math.round(
                    (100 * time) / this.coBrowsePlayer?.getDuration()
                  ) + "";
                // this is seconds for the label
                this.coBrowseLabel = this.getTime(Math.round(time / 1000));

                this.cd.detectChanges();
              },
              ended: async () => {
                this.isCoBrowsePlaying = false;
                await this.goto(0);

                this.cd.detectChanges();
              },
            }
          );

          await this.coBrowsePlayer.load(this.source);
          this.coBrowseSummary = this.coBrowsePlayer.getEventSummary();

          this.play();
        } catch (ex) {
          this.notification.error("Could not play recording", {
            body: ex.message || ex,
          });

          await this.cancel();
        } finally {
          this.isCoBrowseLoading = false;
          this.cd.detectChanges();
        }
      } else {
        this.cd.detectChanges();
      }
    });
  }

  cardClick(e) {
    e.stopPropagation();
  }

  async goto(time) {
    try {
      await this.coBrowsePlayer.goto(
        (time / 100) * this.coBrowsePlayer.getDuration()
      );
    } catch (ex) {
      this.handler.handleError(ex);
    }
  }

  gotoTimestamp(stamp: number) {
    try {
      this.coBrowsePlayer.goto(stamp);
    } catch (ex) {
      this.handler.handleError(ex);
    }
  }

  async play() {
    try {
      this.isCoBrowsePlaying = true;
      await this.coBrowsePlayer.play();
    } catch (ex) {
      debugError(ex);
    }
  }

  pause() {
    this.coBrowsePlayer.pause();
    this.isCoBrowsePlaying = false;
  }

  toggle() {
    this.isCoBrowsePlaying ? this.pause() : this.play();
  }

  onPlayerReady(api: VgApiService) {
    api.getDefaultMedia().subscriptions.error.subscribe((e) => {
      // we get error code 4 when we close the window, MEDIA_ELEMENT_ERROR: Empty src attribute
      if (e.target.error?.code !== 4) {
        this.notification.error("Media Player error", {
          body: e.target.error.message,
        });
      }
    });
  }

  async cancel() {
    if (this.isCoBrowse) {
      if (this.isCoBrowsePlaying) {
        this.pause();
      }

      this.resetCobrowse();
      this.isCoBrowseDrawerOpen = false;
    }

    this.request = null;
    this.cd.detectChanges();
  }

  toggleDrawer(event) {
    event.stopPropagation();
    this.isCoBrowseDrawerOpen = !this.isCoBrowseDrawerOpen;
    if (this.isCoBrowseDrawerOpen) {
      this.pause();
    }
  }

  mouseRatioChanged(ratio) {
    this.pointers.forEach((pointer) => {
      pointer.top = (pointer.top / this.mouseRatio) * ratio;
      pointer.left = (pointer.left / this.mouseRatio) * ratio;
    });

    this.mouseRatio = ratio;
  }

  cobrowseUser(pointer: Partial<IReceiverEventMap["mousemove"]>) {
    /*
    return pointer.user?.['metadata']?.name ||
      (!!pointer.user?.['metadata']?.role
        ? this.t.instant(pointer.user?.['metadata']?.role)
        : pointer.user?.endpoint.substr(0, 8) + '...'
      );
    */
    // TODO: add metadata on cobrowser user interface
    const user = this.coBrowsePlayer.renderer.users.getUser(pointer.user.id);

    return user?.metadata?.role
      ? this.t.instant(user.metadata.role)
      : pointer.user?.endpoint.slice(0, 8) + "...";
  }

  cobrowseUserColor(pointer: Partial<IReceiverEventMap["mousemove"]>) {
    const user = this.coBrowsePlayer.renderer.users.getUser(pointer.user.id);

    return user?.metadata?.role === UserRoleEnum.customer
      ? ColorEnum.green
      : ColorEnum.blue;
  }

  /** private helpers **/

  private resetCobrowse() {
    this.coBrowseTime = "0";
    this.coBrowseLabel = null;
    this.remoteFrameSize = { width: 0, height: 0 };
    this.mouseRatio = 1;
    this.pointers.clear();
    this.coBrowseSummary = [];
    this.coBrowsePlayer = null;
    // this.resizeRatio = 1;
  }

  private getTime(totalSeconds) {
    const seconds = this.pad(totalSeconds % 60);
    let minutes = this.pad(Math.floor(Number(totalSeconds / 60)));
    const hoursNum = Math.floor(Math.floor(Number(totalSeconds / 60)) / 60);
    if (hoursNum > 0) {
      const hours = this.pad(hoursNum);
      minutes = this.pad(Math.floor(Number(totalSeconds / 60) % 60));
      return `${hours}:${minutes}:${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  private pad(val) {
    const valString = val + "";
    return valString.length < 2 ? "0" + valString : valString;
  }

  /** view getters */

  get isAudio() {
    return this.request?.sourceType === "AUDIO";
  }
  get isVideo() {
    return this.request?.sourceType === "VIDEO";
  }
  get isImage() {
    return this.request?.sourceType === "IMAGE";
  }
  get isCoBrowse() {
    return this.request?.sourceType === "CO-BROWSE";
  }
  get source() {
    return this.request?.sourceUrl;
  }
  get filename() {
    return this.request?.sourceFileName;
  }
}
