import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuviousRtcService, GenericErrorHandler } from "../../../core-ui";
import { ErrorPageSubCode, ErrorPageCode } from "../../app.enums";
import { ISyncable } from "./ISyncable";

import { isAuviousCoreSupported } from "@auvious/compatibility";

@Injectable()
export class CompatibilityGuard  implements ISyncable {
  constructor(
    private router: Router,
    private errorHandler: GenericErrorHandler,
    private rtcService: AuviousRtcService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): UrlTree | boolean {
    if (isAuviousCoreSupported()) {
      return true;
    } else {
      return this.router.createUrlTree(
        ["/error", ErrorPageCode.NOT_SUPPORTED],
        {
          queryParams: {
            code: ErrorPageSubCode.UNSUPPORTED_BROWSER,
            url: escape(state.url),
          },
        }
      );
    }
  }
}
