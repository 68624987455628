import { IIntegration } from "./IIntegration";

export interface IGenesysIntegrationInstance {
  id: string;
  name: string;
  intendedState: string;
  integrationType: {
    id: string;
  };
}

export interface IGenesysIntegrationConfig {
  properties: {
    url: string;
  };
  version: number;
  name?: string;
}

export class GenesysIntegration implements IIntegration {
  constructor(
    private instance: IGenesysIntegrationInstance,
    private config: IGenesysIntegrationConfig
  ) {}

  getId() {
    return this.instance.id;
  }
  getName() {
    return this.config.name || this.instance.name;
  }
  getUrl() {
    return this.config.properties.url;
  }
  getVersion() {
    return this.config.version;
  }
  isEnabled() {
    return this.instance.intendedState === "ENABLED";
  }
  getIdentifier() {
    return this.instance.integrationType.id.toLowerCase().trim();
  }
}
