<!-- <div class="spinner spinner-{{size}}">
  <div class="double-bounce1"></div>
  <div class="double-bounce2"></div>
</div> -->

<div class="av-spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
