import { IQueueStrategy } from "../../../core-ui/models/strategies";

export class NoQueueStrategy implements IQueueStrategy {
  async getAllQueues() {
    return [];
  }
  async getEmailQueues() {
    return [];
  }
  async getSMSQueues() {
    return [];
  }
  async getMyQueues() {
    return [];
  }
}
