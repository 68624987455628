import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IPosition } from "../models";

@Injectable()
export class WindowService {
  public isWindowPopedOut;

  private _streamControlsContainerPositionChange: Subject<IPosition> =
    new Subject();
  public streamControlsContainerPositionChange$: Observable<IPosition> =
    this._streamControlsContainerPositionChange.asObservable();

  private onClickElements = new Set<{
    elm: HTMLElement;
    resolve: () => void;
  }>();

  constructor() {}

  public notifyStreamControlsContainerPositionChange(position: IPosition) {
    this._streamControlsContainerPositionChange.next(position);
  }

  private onClickedHandler = (event: PointerEvent) => {
    let clickedOutside = 0;

    for (const entry of this.onClickElements) {
      if (!entry.elm.isConnected) {
        this.onClickElements.delete(entry);
      } else if (event.composedPath().includes(entry.elm)) {
        break;
      } else {
        clickedOutside++;
      }
    }

    if (clickedOutside === this.onClickElements.size) {
      for (const entry of this.onClickElements) {
        entry.resolve();
      }

      this.onClickElements.clear();

      document.body.removeEventListener("click", this.onClickedHandler, {
        capture: true,
      });
    }
  };

  public onClickedOutside(...elms: HTMLElement[]) {
    document.body.removeEventListener("click", this.onClickedHandler, {
      capture: true,
    });

    document.body.addEventListener("click", this.onClickedHandler, {
      capture: true,
    });

    return new Promise<void>((resolve) => {
      for (const elm of elms) this.onClickElements.add({ elm, resolve });
    });
  }
}
