import { IThemeOptions } from "./application-options";

export interface ITicketRequest {
  disabled?: boolean;

  properties: {
    /*
     * Included in jwt claims available to the user, an id specific to the user can be used here,
     * to help identify the user from the jwt
     */
    customer_id: string;

    /**
     * optional, carry the customer's name
     */
    customer_name?: string;

    /*
     * Conference id which the ticket user is allowed to join.
     */
    conference_id: string;

    /*
     * Genesys organizationId of the agent that creates the ticket (will be validated
     * against the agent's organizationId)
     */
    organizationId?: string;

    /*
     * Genesys pcEnvironment of the agent that creates the ticket (will be validated
     * against the agent's pcEnvironment.
     */
    pcEnvironment?: string;

    /*
     * Application identifier, which allows the customer to retrieve the application instance
     * parameters that are specific to the customer UI.
     */
    application_id: string;

    /** @deprecated old application id, keeping it for backwards compatibility */
    applicationId?: string;

    /*
     * Time-to-live in seconds. If scheduled_date is provided it is taken into account, so that the ticket
     * will live until schedule_date + ttl.
     */
    ttl?: number;

    /*
     * Scheduled date of ticket. Only valid for GENESYS_SCHEDULE_TICKET. If not given the ticket will
     * be valid for 7 days plus ttl, and the customer can join anytime. Format is ISO_INSTANT
     * e.g. "2011-12-03T10:15:30Z"
     */
    scheduled_date?: string;

    /**
     * Theme options as used in the publicOptions of the application config
     */
    theme?: IThemeOptions;

    /**
     * Base url for join. Necessary for scheduled calls.
     */
    join_base_url?: string;

    interaction_id?: string;

    appointmentId?: string;

    timezone?: string;

    queueId?: string;

    queueName?: string;
  };
  type: TicketTypeEnum;
  length?: number;
}

export interface ITicket extends ITicketRequest {
  id: string;
  createdAt: Date;
  expired: boolean;
  expiredAt: Date;
  revoked: Date;
  userId: string;
  // auvious org id. Prefixed with the environment
  organizationId?: string;
  version: number;
}

// eslint-disable-next-line no-shadow
export enum TicketTypeEnum {
  SingleUseTicket = "SINGLE_USE_TICKET",
  MultiUseTicket = "MULTI_USE_TICKET",
  ScheduleTicket = "SCHEDULE_TICKET",
  SingleScheduleTicket = "SINGLE_USE_SCHEDULE_TICKET",
  AppointmentScheduleTicket = "APPOINTMENTS_SCHEDULE_TICKET",
  AppointmentSingleScheduleTicket = "SINGLE_USE_APPOINTMENTS_SCHEDULE_TICKET",
}

type excludeScheduleOptions =
  | typeof TicketTypeEnum.SingleUseTicket
  | typeof TicketTypeEnum.MultiUseTicket;

export type ScheduleTicketTypeEnum = Exclude<
  TicketTypeEnum,
  excludeScheduleOptions
>;

export interface ISSHKey {
  id?: string;
  publicKey?: string;
  privateKey?: string;
  type: "SSH_RSA";
}
