import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ColorEvent } from "ngx-color";
import { slideFromUp } from "../../../../core-ui/core-ui.animations";

@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
  animations: [slideFromUp],
})
export class ColorPickerComponent {
  EMPTY_COLOR = "n/a";

  colorLabel: string;
  private _color: string;

  @Input()
  set color(value: string) {
    this._color = value;
    this.colorLabel = this._color || this.EMPTY_COLOR;
  }
  get color() {
    return this._color;
  }

  @Input() colors: string[];

  @Input() canReset = true;

  @Input() error: boolean;

  @Input() disabled: boolean;

  @Output() changeComplete: EventEmitter<ColorEvent> = new EventEmitter();

  iscolorPickerVisible = false;

  constructor() {}

  colorChangeComplete(event: ColorEvent) {
    this.iscolorPickerVisible = false;
    this.changeComplete.emit(event);
  }

  clear() {
    this.changeComplete.emit(null);
  }

  toggle() {
    if (this.disabled) {
      return;
    }
    if (!this._color) {
      this._color = this.defeaultColors[0];
    }
    this.iscolorPickerVisible = !this.iscolorPickerVisible;
  }

  get isEmptyColor() {
    return this.colorLabel === this.EMPTY_COLOR;
  }

  get bkgColor() {
    return this.colorLabel !== this.EMPTY_COLOR ? this.color : null;
  }

  get defeaultColors() {
    return (
      this.colors || [
        "#FF6900",
        "#FCB900",
        "#7BDCB5",
        "#00D084",
        "#8ED1FC",
        "#0693E3",
        "#ABB8C3",
        "#EB144C",
        "#F78DA7",
        "#9900EF",
      ]
    );
  }
}
