import { Injectable } from "@angular/core";
import { IEndpoint, StreamType } from "@auvious/rtc";
import { ISketch, Sketch } from "@auvious/sketch";
import { Observable, Subject, Subscriber } from "rxjs";

import { SketchToolEnum } from "../core-ui.enums";
import { IArea } from "../models";
import { BaseEvent } from "../models/IEvent";
import { NotificationService } from "./notification.service";
import { AuviousRtcService } from "./rtc.service";
import { debugError } from "./utils";
import { SketchMetadata } from "../models/Metadata";
import { ConferenceService } from "./conference.service";
import { filter } from "rxjs/operators";

export class SketchToolChangedEvent extends BaseEvent {
  public static type = "SketchToolChangedEvent";
  constructor(
    public connectionId: string,
    public tool: SketchToolEnum,
    public senderEndpointId: string,
    public senderUserId: string,
    public targetEndpointId: string,
    public targetMediaType: StreamType
  ) {
    super("SketchToolChangedEvent");
  }
}

export class SketchAvailabilityChangedEvent extends BaseEvent {
  public static type = "SketchAvailabilityChangedEvent";
  constructor(
    public connectionId: string,
    public available: boolean,
    public senderEndpointId: string,
    public senderUserId: string,
    public targetEndpointId: string,
    public targetMediaType: StreamType
  ) {
    super("SketchAvailabilityChangedEvent");
  }
}

@Injectable()
export class SketchService {
  private sketchMap: Map<string, ISketch> = new Map();

  /** what the sketch annotates */
  private targets: { [key: string]: ISketch } = {};

  private toolSelectedSubject: Subject<SketchToolEnum> = new Subject();

  private _conferenceMetadata: SketchMetadata;

  constructor(
    private rtcService: AuviousRtcService,
    private conference: ConferenceService,
    private notification: NotificationService
  ) {
    this.conference.conferenceMetadataSet$
      .pipe(filter((m) => m instanceof SketchMetadata))
      .subscribe((m) => {
        this.setConferenceMetadata(m as SketchMetadata);
      });
  }

  /**
   * Create a new sketch area and connect to it or connect to an existing one
   *
   * @param canvas where to draw
   * @param sync
   * @param targetId the optional id of the thing to associate the sketch with
   * @param sketchId creator should leave it null, connected clients should get it from creator
   * @returns sketch id for clients to connect
   */
  public async connect(
    canvas: HTMLCanvasElement,
    sync = false,
    targetId: string | null,
    sketchId?: string
  ): Promise<string> {
    try {
      if (this.sketchMap.has(sketchId)) {
        throw new Error("id exists");
      }

      // store it until it is created
      if (sketchId) {
        this.sketchMap.set(sketchId, null);
      }

      const sketchInstance = Sketch.create(
        canvas,
        this.rtcService.getAuviousCommonClient()
      );
      const sketchInstanceId = await sketchInstance.connect({
        sketchId,
        userId: this.rtcService.identity(),
        userEndpointId: this.rtcService.endpoint(),
      });

      if (sync) {
        sketchInstance.lockRatio(true);
      }

      sketchInstance.events.on("error", this.onError);
      this.sketchMap.set(sketchInstanceId, sketchInstance);

      if (targetId) {
        this.targets[targetId] = sketchInstance;
      }

      return sketchInstanceId;
    } catch (ex) {
      debugError(ex);
      throw ex;
    }
  }

  onError = (err) => {
    debugError(err);
    this.notification.error("Sketch error", { body: err.message || err });
  };

  public getInstanceByTarget(targetId: string) {
    return this.targets[targetId];
  }

  public get toolSelected$(): Observable<SketchToolEnum> {
    return this.toolSelectedSubject.asObservable();
  }

  public selectTool(tool: SketchToolEnum) {
    this.toolSelectedSubject.next(tool);
  }

  public notifyAvailabilityChanged(
    eventReceiver: IEndpoint,
    sketchTarget: IEndpoint,
    connectionId: string,
    availability: boolean,
    streamType: StreamType
  ) {
    const changedEvent = new SketchAvailabilityChangedEvent(
      connectionId,
      availability,
      this.rtcService.endpoint(),
      this.rtcService.identity(),
      sketchTarget?.endpoint,
      streamType
    );
    this.rtcService.sendEventMessage(
      eventReceiver.username,
      eventReceiver.endpoint,
      changedEvent
    );
  }

  public notifyToolChanged(
    target: IEndpoint,
    connectionId: string,
    tool: SketchToolEnum,
    streamType: StreamType
  ) {
    const changedEvent = new SketchToolChangedEvent(
      connectionId,
      tool,
      this.rtcService.endpoint(),
      this.rtcService.identity(),
      target.endpoint,
      streamType
    );
    this.rtcService.sendEventMessage(
      target.username,
      target.endpoint,
      changedEvent
    );
  }

  public marker(on: boolean, id: string) {
    this.selectTool(on ? SketchToolEnum.marker : null);
    return this.toggleTool("marker", on, id);
  }

  public setMarkerColor(color: string, id: string) {
    this.ifExists(id);
    this.sketchMap.get(id).marker.options.strokeColor = color;
    this.sketchMap.get(id).marker.options.strokeWidth = 3;
    this.sketchMap.get(id).marker.options.shadowBlur = 1;
  }

  public arrow(on: boolean, id: string) {
    this.selectTool(on ? SketchToolEnum.arrow : null);
    return this.toggleTool("arrow", on, id);
  }

  public setArrowColor(color: string, id: string) {
    this.ifExists(id);
    this.sketchMap.get(id).arrow.options.strokeColor = color;
    this.sketchMap.get(id).arrow.options.strokeWidth = 3;
  }

  public eraser(on: boolean, id: string) {
    this.selectTool(on ? SketchToolEnum.eraser : null);
    return this.toggleTool("eraser", on, id);
  }

  public clear(id: string) {
    this.ifExists(id);
    this.sketchMap.get(id).clear();
  }

  public terminate(id: string) {
    try {
      this.clear(id);
    } catch {}

    const instance = this.sketchMap.get(id);
    instance?.events.off("error", this.onError);
    this.sketchMap.delete(id);

    for (const target in this.targets) {
      if (this.targets[target] === instance) {
        delete this.targets[target];
        break;
      }
    }
  }

  public getConferenceMetadata(): SketchMetadata {
    if (!this._conferenceMetadata) {
      this._conferenceMetadata = new SketchMetadata(this.rtcService.myself);
    }
    return this._conferenceMetadata;
  }

  public setConferenceMetadata(value: SketchMetadata) {
    this._conferenceMetadata = value;
  }

  public scrollTo(top: number, left: number, id: string) {
    this.ifExists(id);
    this.sketchMap.get(id).scrollTo(top, left);
  }

  public resize(size: IArea, id: string) {
    this.ifExists(id);
    this.sketchMap.get(id).resize(size.width, size.height);
  }

  public scale(ratio: number, id: string) {
    this.ifExists(id);
    this.sketchMap.get(id).scale(ratio, { x: 0, y: 0 });
  }

  public mirror(id: string) {
    this.ifExists(id);
    this.sketchMap.get(id).mirror();
  }

  private toggleTool(tool: string, on: boolean, id: string) {
    this.ifExists(id);
    on
      ? this.sketchMap.get(id)[tool].enable()
      : this.sketchMap.get(id)[tool].disable();
  }

  private ifExists(id) {
    if (!this.sketchMap.has(id)) {
      throw Error("Sketch instance not created");
    }
  }
}
