import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from "@angular/core";
import { ColorEnum } from "../../core-ui.enums";

@Component({
  selector: "app-pointer",
  templateUrl: "./pointer.component.html",
  styleUrls: ["./pointer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointerComponent implements OnInit {
  @Input() sender: string;
  @Input() color: ColorEnum.blue | ColorEnum.green;

  constructor(private host: ElementRef<HTMLDivElement>) {}

  @HostBinding("class") get class() {
    return {
      "av-pointer-blue": this.color === ColorEnum.blue || !this.color,
      "av-pointer-green": this.color === ColorEnum.green,
    };
  }

  ngOnInit(): void {}

  get nativeElement(): HTMLDivElement {
    return this.host.nativeElement;
  }

  // @HostBinding('style')
  // get style() {
  //   return {
  //     left: this.left + 'px',
  //     top: this.top + 'px'
  //   };
  // }
}
