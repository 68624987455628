<div class="stream-play-panel" [@zoomInOut]="true" *ngIf="visible">
  <av-icon name="speaker" mode="dark"></av-icon>
  <div>
    <p>
      <small
        ><b translate>Allow Browser to play audio for this stream.</b></small
      >
    </p>
  </div>
  <button
    avButton
    color="accent"
    size="block"
    (click)="play()"
    data-tid="stream-play-button/allow"
  >
    Allow
  </button>
</div>
