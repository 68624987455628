import { NotificationService } from "../../../core-ui";
import {
  INotificationOptions,
  ToastNotification,
} from "../../../core-ui/models";
import { INotificationStrategy } from "../../../core-ui/models/strategies";

export class ToastNotificationStrategy implements INotificationStrategy {
  constructor(private notification: NotificationService) {}

  show(title: string, options?: INotificationOptions) {
    // const message = options?.body ? options.body : title;
    // const titl = options?.body ? title : null;
    // this.toastr.info(message, titl);
    options = {
      ...options,
      ttl: options?.ttl ?? 5000,
      color: "container",
    };

    const notif = new ToastNotification(title, options);
    this.notification.notify(notif);
  }
}
