import { Injectable } from "@angular/core";
import { IApplication, IInteraction, ITicketRequest } from "../../core-ui";
import { IInvitationStrategy } from "../../core-ui/models/strategies";
import { ApplicationService } from "../../core-ui/services/application.service";
import { ITicketAppointmentOptions } from "../../core-ui/models/interfaces";
import {
  TicketTypeEnum,
  ScheduleTicketTypeEnum,
} from "../../core-ui/models/ITicket";

@Injectable()
export class InvitationService implements IInvitationStrategy {
  constructor(private applicationService: ApplicationService) {}

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private get impl(): IInvitationStrategy {
    return this.application.ticketStrategy();
  }

  prepareScheduleTicketRequest(
    type: ScheduleTicketTypeEnum,
    length: number,
    conferenceId: string,
    customerId: string,
    interactionId: string,
    scheduledAt: string,
    customerName?: string,
    timezone?: string,
    queueId?: string
  ): ITicketRequest {
    return this.impl.prepareScheduleTicketRequest(
      type,
      length,
      conferenceId,
      customerId,
      interactionId,
      scheduledAt,
      customerName,
      timezone,
      queueId
    );
  }
  prepareTicketRequest(
    type: TicketTypeEnum,
    length: number,
    conferenceId: string,
    customerId: string,
    interactionId: string,
    customerName?: string
  ): ITicketRequest {
    return this.impl.prepareTicketRequest(
      type,
      length,
      conferenceId,
      customerId,
      interactionId,
      customerName
    );
  }

  /**
   * @deprecated The back-end will create the ticket
   */
  prepareAppointmentTicketRequest(
    agentUserId: string,
    options?: ITicketAppointmentOptions
  ): ITicketRequest {
    return this.impl.prepareAppointmentTicketRequest(agentUserId, options);
  }

  prepareAgentInvitation(interaction: IInteraction): string {
    return this.impl.prepareAgentInvitation(interaction);
  }
}
