<ng-container #streamsContainer></ng-container>
<ng-template #audioTagTpl
  ><audio
    aria-hidden="true"
    [muted]="muted"
    playsinline
    webkit-playsinline
    x5-playsinline
    autoplay
  ></audio
></ng-template>
<ng-template #videoTagTpl
  ><video
    aria-hidden="true"
    [muted]="muted"
    playsinline
    webkit-playsinline
    x5-playsinline
    autoplay
  ></video
></ng-template>
