<button
  avButton
  size="xs"
  (click)="change(pageIndex - 1)"
  [disabled]="pageIndex === 0"
>
  &larr;
</button>
<div class="pages">
  <button
    avButton
    size="xs"
    *ngFor="let page of pages"
    [color]="pageIndex === page ? 'accent' : 'basic'"
    (click)="change(page)"
  >
    {{ page + 1 }}
  </button>
</div>
<button
  avButton
  size="xs"
  (click)="change(pageIndex + 1)"
  [disabled]="totalPages === pageIndex + 1"
>
  &rarr;
</button>
