<div class="fx-column" #captions>
  <app-caption-item
    class="caption-item"
    [chunk]="u"
    *ngFor="let u of chunks; trackBy: trackChunk"
  >
    {{ u.transcript }}
  </app-caption-item>
</div>
<ng-content></ng-content>
