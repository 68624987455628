<av-card
  [backdrop]="false"
  [type]="isStandalone() ? 'ghost' : 'container'"
  class="cobrowse"
  [class.cobrowse-tile]="!isStandalone()"
>
  <!-- controls -->
  <div
    class="cobrowse-header-actions fx-row fx-align-center fx-justify-space-between"
    [class.cobrowse-header-actions-standalone]="isStandalone()"
  >
    <div class="cobrowse-header-actions-left">
      <app-sketch-controls
        *ngIf="!!sketchId"
        (controlStateChanged)="sketchControlStateChanged($event)"
        [room]="sketchId"
        [disabled]="isCapturingRemoteDisplay() || isCapturingLocalDisplay()"
      >
      </app-sketch-controls>
    </div>

    <div class="cobrowse-header-actions-right fx-row fx-align-center">
      <av-tooltip position="bottom" *ngIf="isResizeAvailable()">
        <button
          avButton
          ofType="icon"
          size="xs"
          [raised]="true"
          [disabled]="isResizeDisabled()"
          (click)="expand()"
          data-tid="cobrowse-session/toggle-expand"
        >
          <av-icon size="sm" *ngIf="!isFullWidth()" name="expand"></av-icon>
          <av-icon size="sm" *ngIf="isFullWidth()" name="collapse"></av-icon>
        </button>
        <ng-container tooltip-message>
          <ng-container *ngIf="!isFullWidth()">{{
            "Actual size" | translate
          }}</ng-container>
          <ng-container *ngIf="isFullWidth()">{{
            "Fit to view" | translate
          }}</ng-container>
        </ng-container>
      </av-tooltip>

      <av-tooltip position="bottom" *ngIf="isRemoteDisplayCaptureAvailable()">
        <button
          avButton
          ofType="icon"
          size="xs"
          [color]="isCapturingRemoteDisplay() ? 'danger' : 'basic'"
          [loading]="isRequestingDisplayCapture()"
          [disabled]="isCaptureRemoteDisplayDisabled()"
          [raised]="true"
          (click)="toggleDisplayCapture()"
          data-tid="cobrowse-session/request-screen-share"
        >
          <av-icon
            *ngIf="!isCapturingRemoteDisplay()"
            size="sm"
            name="screen-in"
          ></av-icon>
          <av-icon
            *ngIf="isCapturingRemoteDisplay()"
            size="sm"
            name="screen-stop"
          ></av-icon>
        </button>
        <ng-container tooltip-message>
          <ng-container *ngIf="!isCapturingRemoteDisplay()">{{
            "Request desktop capture" | translate
          }}</ng-container>
          <ng-container *ngIf="isCapturingRemoteDisplay()">{{
            "Stop desktop capture" | translate
          }}</ng-container>
        </ng-container>
      </av-tooltip>

      <av-tooltip position="bottom" *ngIf="isLocalDisplayCaptureAvailable()">
        <button
          avButton
          ofType="icon"
          size="xs"
          [raised]="true"
          [color]="isLocalDisplayCaptureActive() ? 'danger' : 'basic'"
          [loading]="isLocalDisplayCaptureLoading()"
          [disabled]="isLocalDisplayCaptureDisabled()"
          (click)="toggleLocalDisplayCapture()"
          data-tid="cobrowse-session/screen-share/start"
        >
          <av-icon
            *ngIf="!isLocalDisplayCaptureActive()"
            size="sm"
            name="screen-out"
          ></av-icon>
          <av-icon
            *ngIf="isLocalDisplayCaptureActive()"
            size="sm"
            name="screen-stop"
          ></av-icon>
        </button>

        <ng-container tooltip-message>
          <ng-container *ngIf="!isCapturingLocalDisplay()">
            {{ "Share your screen" | translate }}
          </ng-container>
          <ng-container *ngIf="isCapturingLocalDisplay()">
            {{ "Stop sharing screen or window" | translate }}
          </ng-container>
        </ng-container>
      </av-tooltip>

      <av-tooltip position="bottom" *ngIf="isRequestControlAvailable()">
        <button
          avButton
          ofType="icon"
          size="xs"
          [raised]="true"
          [color]="isControlGranted() ? 'accent' : 'basic'"
          [disabled]="isRequestControlDisabled()"
          [loading]="isRequestingControl()"
          (click)="requestControl()"
          data-tid="cobrowse-session/request-control"
        >
          <av-icon size="sm" name="text"></av-icon>
        </button>
        <ng-container tooltip-message>
          <ng-container *ngIf="!isControlGranted()">
            <ng-container *ngIf="!isRequestingControl()">
              {{ "Request control" | translate }}
            </ng-container>
            <ng-container *ngIf="isRequestingControl()">
              {{ "Waiting for approval" | translate }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isControlGranted()">{{
            "Editing Page" | translate
          }}</ng-container>
        </ng-container>
      </av-tooltip>

      <!--end -->
      <av-confirm
        [open]="isConfirmOpen()"
        arrow="top-right"
        (confirmed)="end()"
        (canceled)="isConfirmOpen.set(false)"
      >
        <av-tooltip
          [enabled]="!isConfirmOpen()"
          position="bottom"
          message="End session"
        >
          <button
            class="exit"
            avButton
            ofType="icon"
            size="xs"
            color="basic"
            [raised]="true"
            (click)="isConfirmOpen.set(true)"
            data-tid="cobrowse-session/end"
          >
            <av-icon name="close-big"></av-icon>
          </button>
        </av-tooltip>
        <span confirmTitle translate>End session</span>
        <span confirmBody translate>Are you sure you want to quit?</span>
      </av-confirm>
    </div>
  </div>

  <app-cobrowse-frame
    #cobrowseView
    class="cobrowse-frame"
    [class.cobrowse-frame-back]="
      isCapturingRemoteDisplay() || isCapturingLocalDisplay()
    "
    [viewSize]="remoteFrameSize()"
    [sketchActive]="sketchActive"
    [cobrowseActive]="isCobrowseActive()"
    (mouseRatioChanged)="mouseRatioChanged($event)"
    (containerRatioChanged)="containerRatioChanged($event)"
    (frameScrollChanged)="scrollChanged($event)"
    (containerScrollChanged)="scrollChanged($event)"
    (viewSizeChanged)="viewSizeChanged($event)"
  >
    <div
      class="cobrowse-info fx-column fx-align-center fx-justify-center"
      [class.cobrowse-info-standalone]="isStandalone()"
      *ngIf="!isFrameReady()"
    >
      <ng-container *ngIf="isCobrowseRequest">
        <av-icon [mode]="iconMode()" name="cobrowse"></av-icon>
        <h1 translate>Co-browse started</h1>
      </ng-container>
      <ng-container *ngIf="isDisplayCaptureRequest">
        <av-icon [mode]="iconMode()" name="cobrowse-screen"></av-icon>
        <h1 translate>Session started</h1>
      </ng-container>
      <h2 class="blink" translate *ngIf="isWaitingToAccept()">
        Waiting for customer...
      </h2>
      <h2
        class="blink"
        translate
        *ngIf="isCobrowseFrameOpen() && !isFrameReady()"
      >
        Retrieving page...
      </h2>
    </div>

    <app-sketch-area
      #sketch
      [scaleRatio]="mouseRatio"
      [viewSize]="remoteFrameSize()"
      [endpoint]="customerEndpoint"
      [class.sketch-active]="sketchActive"
      (connected)="sketchConnected($event)"
    >
    </app-sketch-area>

    <ng-container cobrowse-pointers>
      <app-pointer
        *ngIf="isViewGranted()"
        #pointer
        [sender]="customerName"
      ></app-pointer>
    </ng-container>
  </app-cobrowse-frame>

  <!-- <app-tile
    class="tile-ready"
    [stream]="screenStream"
    [interaction]="interaction"
    [@tileSlideIn]="true"
    *ngIf="!!screenStream"
  >
  </app-tile> -->
  <div
    class="tile"
    [class.tile-loading]="isPublishingLocalDisplay()"
    #tile
    [@tileSlideIn]="true"
    *ngIf="isCapturingRemoteDisplay() || isCapturingLocalDisplay()"
  >
    <app-stream-view
      *ngIf="!!screenStream()"
      [id]="screenStream().id"
      [muted]="true"
      [stream]="screenStream().mediaStream"
      [type]="screenStream().type"
      (mediaElementReady)="mediaElementReady($event)"
    ></app-stream-view>

    <av-spinner
      *ngIf="!screenStream() || isPublishingLocalDisplay()"
      mode="light"
    ></av-spinner>
  </div>
</av-card>
