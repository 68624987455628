import { debug } from "../../app.utils";

import { INotificationOptions } from "../../../core-ui/models";
import { INotificationStrategy } from "../../../core-ui/models/strategies";

export class BrowserNotificationStrategy implements INotificationStrategy {
  private static INSTANCE: BrowserNotificationStrategy;

  private constructor() {
    this.askNotificationPermission();
  }

  public static singleton() {
    if (!BrowserNotificationStrategy.INSTANCE) {
      BrowserNotificationStrategy.INSTANCE = new BrowserNotificationStrategy();
    }

    return BrowserNotificationStrategy.INSTANCE;
  }

  // initialy copied from https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
  // but found out that it didn't work well, so it was changed alot.
  private askNotificationPermission() {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      debug("This browser does not support notifications.");
    } else {
      if (Notification.permission === "default") {
        Notification.requestPermission();
      }
    }
  }

  private get isGranted(): boolean {
    if ("Notification" in window) {
      return Notification.permission === "granted";
    }

    return false;
  }

  show(title: string, options?: INotificationOptions) {
    if (this.isGranted) {
      if (!!options && !!options.body) {
        const n = new Notification(title, { body: options.body });
      } else {
        const n = new Notification(title, { body: title });
      }
    }
  }
}
