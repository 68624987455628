import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import {
  ActivityIndicatorService,
  AuviousRtcService,
  GenericErrorHandler,
  UserService,
} from "../../services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-connection-status",
  templateUrl: "./connection-status.component.html",
  styleUrls: ["./connection-status.component.scss"],
})
export class ConnectionStatusComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  isWarnOpen = false;
  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private rtcService: AuviousRtcService,
    private user: UserService,
    private activity: ActivityIndicatorService,
    private errorHandler: GenericErrorHandler
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.errorHandler.connectionError$.subscribe((_) => {
        this.isWarnOpen = true;
      })
    );
    this.subscription.add(
      this.rtcService.connectionRegistered$.subscribe((_) => {
        this.isWarnOpen = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async authenticate() {
    try {
      this.setLoading(true);
      await this.authentication.authenticate(this.route.snapshot);
      try {
        // unregister, just in case we have a messed up state
        await this.rtcService.unregister();
      } catch (ex) {
        // nothing
      }
      await this.rtcService.register();
      // we wait for the 'registered' event to hide the panel
    } catch (ex) {
      // this.
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(value: boolean) {
    this.isLoading = value;
    // this.activity.loading(value);
  }
}
