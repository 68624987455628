import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";
import { IArea, ISnapshotMetadata } from "../../../../core-ui/models";
import { CropperComponent } from "angular-cropperjs";
import {
  debugError,
  MediaEffectsService,
  SnapshotService,
} from "../../../../core-ui/services";
import { SegmentState } from "../../../../core-ui/core-ui.enums";

@Component({
  selector: "av-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnInit, OnDestroy {
  @Input()
  set src(value: string) {
    this.hasLoaded = false;
    // this.cancelSegmentation();
    this.originalSrc = value;
    this.renderImage(value);
  }
  get src(): string {
    return this.imageSrc;
  }

  @Input() id: string;
  @Input() metadata: ISnapshotMetadata;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() error: EventEmitter<void> = new EventEmitter();
  @Output() ready: EventEmitter<void> = new EventEmitter();
  @Output() metadataChanged: EventEmitter<{ id: string; metadata: any }> =
    new EventEmitter();
  @Output() cropStarted: EventEmitter<void> = new EventEmitter();
  @Output() cropEnded: EventEmitter<void> = new EventEmitter();
  @Output() segmentStateChanged: EventEmitter<SegmentState> =
    new EventEmitter();

  @ViewChild("editor") editorRef: CropperComponent;

  private segmFor: string;
  private originalSrc: string;
  private imageSrc: string;
  // private dragMode: 'none' | 'crop' | 'move' = 'move';
  private isCropOn = false;
  private hasLoaded = false;
  private segmState = SegmentState.Off;

  constructor(
    private host: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private snapshotService: SnapshotService,
    private effects: MediaEffectsService
  ) {
    // zone.runOutsideAngular(() => {
    //   window.addEventListener('resize', this.resize);
    // });
  }

  ngOnInit() {
    this.segmentStateChanged.emit(this.segmState);
  }

  ngOnDestroy() {
    // window.removeEventListener('resize', this.resize);
  }

  async loaded() {
    this.hasLoaded = true;

    this.fit();
    this.renderer.setStyle(this.host.nativeElement, "opacity", "1");

    // if (this.segmState === SegmentState.On) {
    // await this.segmentImage();
    // } else {
    this.ready.emit();
    // }
  }

  private renderImage(value: string) {
    this.renderer.setStyle(this.host.nativeElement, "opacity", "0");
    if (this.imageSrc !== value) this.imageSrc = value;
  }

  public async segmentImage() {
    switch (this.segmState) {
      // case SegmentState.Off:
      //   this.segmState = SegmentState.On;
      //   this.segmentStateChanged.emit(this.segmState);
      //   break;
      case SegmentState.Setting:
        return;
      case SegmentState.Set:
        if (this.segmFor === this.originalSrc) return;
    }

    if (!this.hasLoaded) return;

    this.segmState = SegmentState.Setting;
    this.segmentStateChanged.emit(this.segmState);

    try {
      this.segmFor = this.editorRef.imageUrl;
      const segmSrc = await this.effects.segmentImage(
        this.editorRef.imageElement
      );

      // @ts-expect-error
      if (this.segmState === SegmentState.Off) return;

      this.segmState = SegmentState.Set;
      this.segmentStateChanged.emit(this.segmState);
      this.renderImage(segmSrc);
      this.metadataChanged.emit({
        id: this.id,
        metadata: this.editor.getCanvasData(),
      });
    } catch (ex) {
      this.segmState = SegmentState.Off;
      this.segmentStateChanged.emit(this.segmState);
      debugError(ex);
    }
  }

  public cancelSegmentation() {
    this.resetSegmentState();
    this.renderImage(this.originalSrc);
  }

  public resetSegmentState() {
    this.segmState = SegmentState.Off;
    this.segmentStateChanged.emit(this.segmState);
  }

  public zoom(level: number) {
    this.editor.zoom(level / 100);
    this.metadataChanged.emit({
      id: this.id,
      metadata: this.editor.getCanvasData(),
    });
  }

  public expand() {
    this.editor.zoomTo(1);
  }

  public fit() {
    const data = this.editor.getImageData();
    const area = this.getFittedArea({
      width: data.naturalWidth,
      height: data.naturalHeight,
    });
    const scale = area.width / data.naturalWidth;
    this.editor.zoomTo(scale);
  }

  public crop() {
    this.isCropOn = !this.isCropOn;
    if (this.isCropOn) {
      this.editor.crop();
      this.cropStarted.emit();
    } else {
      this.editor.clear();
      this.cropEnded.emit();
    }
  }

  public rotate(degrees: number) {
    this.editor.rotate(degrees);
    this.metadataChanged.emit({
      id: this.id,
      metadata: this.editor.getCanvasData(),
    });
  }

  private getFittedArea(targetArea: IArea): IArea {
    let containerArea: IArea =
      this.host.nativeElement.parentElement?.getBoundingClientRect();
    if (!containerArea) {
      containerArea = this.host.nativeElement.getBoundingClientRect();
    }
    let ratio = containerArea.height / targetArea.height;
    const width = targetArea.width * ratio;

    if (width > containerArea.width) {
      // doesn't fit, go by width
      ratio = containerArea.width / targetArea.width;
      return {
        width: containerArea.width,
        height: targetArea.height * ratio,
      };
    } else {
      return {
        width: targetArea.width * ratio,
        height: containerArea.height,
      };
    }
  }

  private get editor() {
    return this.editorRef.cropper;
  }

  public get config() {
    return {
      dragMode: "move",
      autoCrop: false,
      zoomable: true,
      rotatable: true,
      movable: true,
      scalable: true,
      background: false,
      toggleDragModeOnDblclick: false,
      checkCrossOrigin: true,
    };
  }

  // public get isExpanded() {

  // }

  /*

    loaded() {
    if (!this.imageRef.nativeElement) { return; }
    const fitted = this.getFittedArea();
    const scale = fitted.width / this.imageRef.nativeElement.width;
    this.renderer.setStyle(this.imageRef.nativeElement, 'transition', 'opacity .2s ease-out, transform .2s ease-out');
    this.zoom(scale * 100);
    this._baseZoomLevel = scale * 100;
    this.renderer.setStyle(this.imageRef.nativeElement, 'opacity', `1`);
  }

  loadError() {
    this.error.emit();
  }

  public zoom(level: number): void {
    if (this.zoomLevel + level <= 10) { return; }
    this._zoomLevel += level;
    this.transformApply();
    this.zoomChanged.emit({ id: this.id, zoomLevel: this._zoomLevel });
  }

  public rotate(degrees: number) {
    this._rotationDeg += degrees;

    this.isRotated90 = Math.abs((this._rotationDeg / 90) % 2) === 1;

    // adjust scale to fit if we are in initial scale
    if (this._baseZoomLevel === this._zoomLevel) {
      const isLandscape = this.imageRef.nativeElement.width > this.imageRef.nativeElement.height;
      let scale: number;
      const containerArea = this.host.nativeElement.getBoundingClientRect();

      // odd numbers are 90 degree rotations.
      if (this.isRotated90) {
        if (isLandscape) {
          scale = containerArea.height / this.imageRef.nativeElement.width;
        } else {
          scale = containerArea.width / this.imageRef.nativeElement.height;
          const width = this.imageRef.nativeElement.width * scale;
          if (width > containerArea.height) {
            // overflows container, get scale by height
            scale = containerArea.height / this.imageRef.nativeElement.width;
          }
        }
      } else {
        const fitted = this.getFittedArea();
        scale = fitted.width / this.imageRef.nativeElement.width;
      }
      this._zoomLevel = scale * 100;
      this._baseZoomLevel = this._zoomLevel;
    }

    this.transformApply();
    this.rotationChanged.emit({ id: this.id, rotationDegrees: this._rotationDeg });
  }

  public panStart(event: MouseEvent) {
    event.preventDefault();
    this.renderer.removeStyle(this.imageRef.nativeElement, 'transition');
    this.zone.runOutsideAngular(() => {
      this.isPanning = true;

      this._panOrigin = {
        x: event.pageX - this._panLamda.x,
        y: event.pageY - this._panLamda.y
      };

      this.zone.runOutsideAngular(() => {
        window.addEventListener('mousemove', this.panMove);
        window.addEventListener('mouseup', this.panEnd);
        document.body.style.setProperty('pointer-events', 'none');

        // window.addEventListener('touchmove', this.panMove);
        // window.addEventListener('touchEnd', this.panEnd);
      });
    });
  }

  private panMove = (event: MouseEvent) => {
    if (!this._panOrigin || !this.isPanning) { return this.panEnd(); }
    const lamda = {
      x: event.pageX - this._panOrigin.x,
      y: event.pageY - this._panOrigin.y
    };
    if (lamda.x === this._panLamda.x && lamda.y === this._panLamda.y) { return; }
    this._panLamda = lamda;
    this.transformApply();
  }

  private panEnd = () => {
    this.isPanning = false;
    // this._panOrigin = null;
    this.renderer.setStyle(this.imageRef.nativeElement, 'transition', 'opacity .2s ease-out, transform .2s ease-out');

    window.removeEventListener('mousemove', this.panMove);
    window.removeEventListener('mouseup', this.panEnd);
    document.body.style.setProperty('pointer-events', null);

    // window.removeEventListener('touchmove', this.panMove.bind(this));
    // window.removeEventListener('touchEnd', this.panEnd.bind(this));
  }
*/

  /** transformations  */

  /*
  private transformScale(): string {
    if (this._zoomLevel === 0) { return; }
    return `scale(${this._zoomLevel / 100})`;
  }

  private transformRotate(): string {
    if (this._rotationDeg === 0) { return; }
    return `rotate(${this._rotationDeg}deg)`;
  }

  private transformTranslate(): string {
    if (!this._panLamda) { return; }
    const x = this.isRotated90 ? this._panLamda.y : this._panLamda.x;
    const y = this.isRotated90 ? this._panLamda.x : this._panLamda.y;
    return `translate(${x}px, ${y}px)`;
  }

  private transformApply() {
    const transform = [
      this.transformScale(),
      this.transformRotate(),
      this.transformTranslate()
    ];
    // debug('applying transform to image', transform.join(' '));
    this.renderer.setStyle(this.imageRef.nativeElement, 'transform', transform.join(' '));
  }
  */

  /** getters */

  // public get zoomLevel() {
  //   return this._zoomLevel;
  // }

  // public get rotationDegrees() {
  //   return this._rotationDeg;
  // }

  // public zoomSize(level: number): Promise<void> {
  //   return new Promise(resolve => {
  //     this._zoomLevel += level;
  //     let width, height;
  //     if (this._zoomLevel <= 0) {
  //       this._zoomLevel = 0;
  //       const fitted = this.getFittedArea();
  //       width = fitted.width;
  //       height = fitted.height;
  //     } else {
  //       height = this.imageRef.nativeElement.height * level / 100 + this.imageRef.nativeElement.height;
  //       width = this.imageRef.nativeElement.width * level / 100 + this.imageRef.nativeElement.width;
  //     }
  //     this.renderer.removeStyle(this.imageRef.nativeElement, 'max-width');
  //     this.renderer.removeStyle(this.imageRef.nativeElement, 'max-height');
  //     this.renderer.setStyle(this.imageRef.nativeElement, 'height', `${height}px`);
  //     this.renderer.setStyle(this.imageRef.nativeElement, 'width', `${width}px`);
  //     // notify
  //     this.zoomChanged.emit({ id: this.id, zoomLevel: this._zoomLevel });

  //     // wait for animation to finish
  //     setTimeout(() => resolve(), 200);
  //   });
  // }

  // loadedSize() {
  //   if (!this.imageRef.nativeElement) { return; }

  //   const fitted = this.getFittedArea();
  //   debug('Image: fitted area: ', fitted);

  //   if (fitted.width === 0 || fitted.height === 0) {
  //     // fallback to max-width / max-height in case we got a bad size
  //     this.renderer.setStyle(this.imageRef.nativeElement, 'max-width', '100%');
  //     this.renderer.setStyle(this.imageRef.nativeElement, 'max-height', '100%');
  //   } else {
  //     this.renderer.setStyle(this.imageRef.nativeElement, 'height', `${fitted.height}px`);
  //     this.renderer.setStyle(this.imageRef.nativeElement, 'width', `${fitted.width}px`);
  //   }
  //   this.renderer.setStyle(this.imageRef.nativeElement, 'opacity', `1`);
  //   this.ready.emit();
  // }
}
