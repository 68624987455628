import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  ViewChild,
  ChangeDetectorRef,
  signal,
  computed,
} from "@angular/core";
import { ISnapshot } from "@auvious/snapshot";
import { SnapshotService } from "../../../../core-ui/services";
import { IArea } from "../../../../core-ui/models";
import { catchError, timeout } from "rxjs/operators";
import { of } from "rxjs";

@Component({
  selector: "app-snapshot",
  templateUrl: "./snapshot.component.html",
  styleUrls: ["./snapshot.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotComponent implements OnInit {
  @Input() snapshot: ISnapshot;
  @Input() active: boolean;
  @Input() size: IArea;

  @Output() ready: EventEmitter<ISnapshot> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() error: EventEmitter<ISnapshot> = new EventEmitter();
  @Output() removed: EventEmitter<ISnapshot> = new EventEmitter();

  @ViewChild("snap") snapElemRef: ElementRef<HTMLImageElement>;

  private loading = signal(true);
  public width = 100;
  public url: string;
  failed = signal(false);

  constructor(
    private snapshotService: SnapshotService,
    private host: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    let size =
      this.snapshotService.getSnapshotMetadata(this.snapshot.id)?.thumbSize ||
      this.size;
    if (!size.width || !size.height) {
      size = { width: 100, height: 100 };
    }
    this.snapshotService.setSnapshotMedatata(this.snapshot, {
      thumbSize: size,
    });
    this.host.nativeElement.setAttribute("role", "group");
    this.calculateSize(size);
    if (!this.snapshot.signedUrl) {
      return;
    }
    this.snapshot.signedUrl
      .pipe(
        timeout(10000),
        catchError((err) => {
          if (!this.url) {
            this.loading.set(false);
            this.failed.set(true);
            this.error.emit(this.snapshot);
            return of(null);
          }
          return of(this.url);
        })
      )
      .subscribe((s) => {
        this.url = s;
        this.cd.markForCheck();
      });
  }

  @HostBinding("class") get class() {
    return {
      "snapshot-active": this.active,
      "snapshot-exists": !!this.url,
    };
  }

  remove() {
    this.loading.set(true);
    this.removed.emit(this.snapshot);
  }

  calculateSize(size: IArea): IArea {
    const height = this.host.nativeElement.getBoundingClientRect().height;
    const ratio = height / size.height;
    this.width = size.width * ratio;
    this.renderer.setStyle(
      this.host.nativeElement,
      "width",
      !this.width ? "auto" : `${this.width}px`
    );
    return { width: this.width, height };
  }

  get isLoading() {
    return computed(() => this.loading() || !this.snapshot.signedUrl);
  }

  imageLoaded() {
    this.loading.set(false);
    this.calculateSize(this.snapElemRef.nativeElement.getBoundingClientRect());

    this.ready.emit(this.snapshot);
    this.ready.complete();
  }

  imageError() {
    this.loading.set(false);
    this.failed.set(true);
    this.error.emit(this.snapshot);
  }

  get src() {
    return this.snapshot.signedUrl;
  }

  get hasTag() {
    return !!this.snapshot.type && this.snapshot.type !== "undefined";
  }

  get tagLabel() {
    return (
      this.hasTag &&
      this.snapshotService.snapshotTypes.find(
        (t) => this.snapshot.type === t.value
      )?.label
    );
  }

  get tagColor() {
    return (
      this.hasTag &&
      this.snapshotService.snapshotTypes.find(
        (t) => this.snapshot.type === t.value
      )?.color
    );
  }

  get isApproved() {
    return this.snapshot.state === "APPROVED";
  }
}
