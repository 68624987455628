import { IInteraction } from "../../../core-ui/models/IInteraction";
import { IAnalyticsStrategy } from "../../../core-ui/models/strategies";

export class NoAnalyticsStrategy implements IAnalyticsStrategy {
  constructor() {}

  trackCallRequested(interaction: IInteraction) {}
  trackInvitationSent(interaction: IInteraction): void {}
  trackCallEnding(interaction: IInteraction): any {}
  trackCallEnded(interaction: IInteraction): any {}
  trackCallTransferred(interaction: IInteraction): void {}
  trackConferenceRoomEnded(interaction: IInteraction): any {}
  trackRecordingStarted(): void {}
  trackRecordingStopped(): void {}
}
