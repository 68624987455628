import { NiceService } from "../../../services/nice.service";
import { IInteractionStrategy } from "../../../../core-ui/models/strategies";
import {
  ConversationChannelEnum,
  ConversationTypeEnum,
  EndpointTypeEnum,
  GenericErrorHandler,
  IConversationEventHandlers,
  IInteraction,
  IInteractionDetails,
  IInteractionMessageOptions,
  ProtectedTicketService,
} from "../../../../core-ui";
import { NiceInteraction } from "../../NiceInteraction";
import { NiceInteractionDetails } from "../../NiceInteractionDetails";
import {
  PARAM_CALL_TYPE,
  WIDGET_COBROWSE_REQUEST_PREFIX,
} from "../../../../app/app.enums";

export class NiceInteractionStrategy implements IInteractionStrategy {
  private interaction: IInteraction = null;

  constructor(
    private ticketService: ProtectedTicketService,
    private errorHandler: GenericErrorHandler,
    private niceService: NiceService
  ) {}

  isActiveInteractionAvailable(): boolean {
    return !!this.interaction;
  }

  getActiveInteraction(): IInteraction {
    return this.interaction;
  }

  setActiveInteraction(interaction: IInteraction) {
    this.interaction = interaction;
  }

  clearActiveInteraction() {
    if (this.interaction) {
      this.niceService.removeContact(this.interaction.getId());
      this.interaction = null;
    }
  }

  async discoverActiveInteraction() {
    try {
      const interaction = await this.niceService.discoverActiveConversation();

      if (interaction)
        this.setActiveInteraction(new NiceInteraction(interaction));

      return this.interaction;
    } catch (ex) {
      return null;
    }
  }

  async retrieveInteractionData(id: string, channel?: ConversationChannelEnum) {
    const info = await this.niceService.retrieveInteractionData(id);
    return info ? new NiceInteraction(info) : null;
  }

  registerConversationEventHandlers(handlers: IConversationEventHandlers) {
    this.niceService.registerConversationEventHandlers(handlers);
  }

  prepareInteractionScheduleRequest() {
    return null;
  }

  scheduleInteraction() {
    return null;
  }

  createEmailInteraction() {
    return null;
  }

  createSMSInteraction() {
    return null;
  }

  invite(
    toConversationType: ConversationTypeEnum,
    interaction: NiceInteraction,
    ticket: string,
    options?: IInteractionMessageOptions
  ) {
    let message: string;
    switch (toConversationType) {
      case ConversationTypeEnum.cobrowse:
        message = this.getInvitation(toConversationType, ticket, options);
        break;
      case ConversationTypeEnum.videoCall:
      case ConversationTypeEnum.voiceCall:
      case ConversationTypeEnum.chat:
        let link: URL | string = "";
        const customerLink = this.getInvitation(toConversationType, ticket);

        try {
          link = new URL(customerLink);
          link.searchParams.set(PARAM_CALL_TYPE, interaction.getType());
        } catch (ex) {
          this.errorHandler.handleError(ex.message + " -> " + customerLink);
          link = customerLink.includes("?")
            ? `${link}&${PARAM_CALL_TYPE}=${interaction.getType()}`
            : `${link}?${PARAM_CALL_TYPE}=${interaction.getType()}`;
        }

        message = link.toString();
        break;
    }

    return this.postMessageToInteraction(interaction, message, options);
  }

  getInvitation(
    forConversationType: ConversationTypeEnum,
    ticket: string,
    options?: IInteractionMessageOptions
  ): string {
    let message;
    switch (forConversationType) {
      case ConversationTypeEnum.cobrowse:
        message = [
          WIDGET_COBROWSE_REQUEST_PREFIX,
          options?.requestType || "view",
          ticket,
        ].join(":");
        break;
      case ConversationTypeEnum.videoCall:
      case ConversationTypeEnum.voiceCall:
      case ConversationTypeEnum.chat:
        message = this.ticketService.getTicketWithDomain(ticket);
        break;
    }
    return message;
  }

  async postMessageToInteraction(
    interaction: NiceInteraction,
    message: string,
    options: IInteractionMessageOptions
  ) {
    return this.niceService.postOutboundMessage(interaction.getId(), message);
  }

  getChatTranscript() {
    return null;
  }

  async getInteractionDetails(caseId: string): Promise<IInteractionDetails> {
    const contact = await this.niceService.retrieveContact(caseId);
    return contact ? new NiceInteractionDetails(contact) : null;
  }
}
