import { Event } from "@auvious/common";
import { Injectable } from "@angular/core";
import { AuviousRtcService } from "./rtc.service";
import { NotificationService } from "./notification.service";
import {
  CompositionNotification,
  INotificationEvent,
  TranscriptNotification,
} from "../models";
import { RecorderService } from "./recorder.service";
import { firstValueFrom } from "rxjs";
import { ASRService } from "./asr.service";
import { TranscriptStateEnum } from "../core-ui.enums";
import { AppointmentService } from "./appointment.service";

@Injectable()
export class EventService {
  constructor(
    private rtcService: AuviousRtcService,
    private notification: NotificationService,
    private recorderService: RecorderService,
    private asrService: ASRService,
    private appointmentService: AppointmentService
  ) {}

  public start() {
    firstValueFrom(this.rtcService.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable.subscribe(this.handleEvent.bind(this));
      }
    );
  }

  private handleEvent(event: Event) {
    let notification: INotificationEvent;
    switch (event.payload.type) {
      case "CompositionStateChangedEvent":
        notification = new CompositionNotification(event);
        break;
      case "RecorderStateChangedEvent":
        this.recorderService.propagateEvent(event.payload);
        break;
      case "ASROfflineTranscriptCreatedEvent":
        notification = new TranscriptNotification(
          event,
          TranscriptStateEnum.completed
        );
        this.asrService.propagateEvent(event.payload);
        break;
      case "ASROfflineTranscriptFailedEvent":
        notification = new TranscriptNotification(
          event,
          TranscriptStateEnum.failed
        );
        this.asrService.propagateEvent(event.payload);
        break;
      case "APScheduledEvent":
      case "APRescheduledEvent":
      case "APReassignedEvent":
      case "APUpdatedEvent":
      case "APInProgressEvent":
      case "APCompletedEvent":
      case "APExpiredEvent":
      case "APAgentJoinedEvent":
      case "APAgentLeftEvent":
      case "APCustomerWaitingEvent":
      case "APCustomerJoinedEvent":
      case "APCustomerLeftEvent":
      case "APDeletedEvent":
        this.appointmentService.propagateEvent(event.payload);
        break;
      // add more
    }
    if (!notification || !notification.canShow()) {
      return;
    }

    this.notification.show(notification.getTitle(), {
      body: notification.getBody(),
    });
  }
}
