import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IDatePickerConfig } from "ng2-date-picker";
// import moment from 'moment';
import moment from "moment-timezone";

import { Util } from "@auvious/common";

import {
  QueueService,
  InvitationService,
  LocaleService,
  IDialCode,
} from "../../services";
import {
  CALLBACK_NAME_PREFIX,
  KEY_EMAIL,
  KEY_ROOM_NAME,
  PHONE_REGEX,
  TAG_CUSTOMER_CALLBACK_ORIGIN,
  TAG_CUSTOMER_CALLBACK_PREFERRED_AGENT_ID,
  TAG_CUSTOMER_TICKET_URL,
} from "../../app.enums";

import {
  ActivityIndicatorService,
  ConferenceService,
  slideInOut,
  roundTime,
  ApplicationTypeEnum,
  copyTextToClipboard,
  debugError,
  ITicketRequest,
  IApplication,
  IInteraction,
  ConversationOriginEnum,
  AgentParam,
  TicketTypeEnum,
  IInteractionMessageOptions,
  AppointmentChannelEnum,
  DEFAULT_CALLBACK_NOTIFICATIONS,
} from "../../../core-ui";
import {
  ProtectedTicketService,
  AppConfigService,
  GenericErrorHandler,
} from "../../../core-ui/services";
import { ApplicationService } from "../../../core-ui/services/application.service";
import { UserService } from "../../../core-ui/services/user.service";
import { InteractionService } from "../../services/interaction.service";
import { GenesysInteraction, IGenesysAgent } from "../../../app/models";
import { NotificationService } from "../../../core-ui/services/notification.service";
import { Interaction } from "../../../../src/app/models/Interaction";
import { markFormFields } from "../../app.utils";

import dayjs from "dayjs";

@Component({
  selector: "app-callback",
  templateUrl: "./callback.component.html",
  styleUrls: ["./callback.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOut],
})
export class CallbackComponent implements OnInit, OnDestroy {
  @Input() hover: boolean;
  @Input() inCall: boolean;
  @Input() interaction: IInteraction;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  step = 1;

  dpDay: dayjs.Dayjs;
  dpTime: dayjs.Dayjs;
  callbackDate: moment.Moment;
  callbackDay: moment.Moment;
  callbackTime: moment.Moment;
  callbackToMe: boolean;
  callbackEmail: string;
  callbackName: string;
  callbackPhone: string;
  callbackQueueId: string;
  callbackEmailQueueId: string;
  callbackSMSQueueId: string;
  callbackAgentId: string;
  callbackNamePrefix: string;
  callbackTicket: string;
  callbackPhonePrefix: string;

  timeHalfHour;
  timeOneHour;
  timeTwoHours;
  timeTomorrow;

  dateNow;
  error = null;
  dateConfig: IDatePickerConfig;
  timeConfig: IDatePickerConfig;
  queues = [];
  emailQueues = [];
  smsQueues = [];
  loading = false;

  phonePattern = PHONE_REGEX;
  phoneError = false;

  notifySMS = false;
  notifyEmail = true;

  lang: string;

  isRoomSuccess = false;
  isTicketSuccess = false;
  isScheduleSuccess = false;
  isSMSSuccess = false;
  isEmailSuccess = false;

  timezone: string;

  countryCodes: IDialCode[] = [];
  defaultCountryCode: string;

  constructor(
    private conferenceService: ConferenceService,
    private activityService: ActivityIndicatorService,
    private translateService: TranslateService,
    private config: AppConfigService,
    private applicationService: ApplicationService,
    private ticketService: ProtectedTicketService,
    private userService: UserService,
    private queueService: QueueService,
    private invitationService: InvitationService,
    private interactionService: InteractionService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private locale: LocaleService,
    private erroHandler: GenericErrorHandler
  ) {
    this.lang = config.language;
    this.callbackDay = moment();
    this.callbackTime = moment();
    this.dpDay = dayjs();
    this.dpTime = dayjs();
    this.dateNow = moment();
    this.callbackToMe = false;
  }

  async ngOnInit() {
    // pre-fill times
    this.timeHalfHour = roundTime(moment().add(30, "minutes"));
    this.timeOneHour = roundTime(moment().add(60, "minutes"));
    this.timeTwoHours = roundTime(moment().add(120, "minutes"));
    this.timeTomorrow = roundTime(moment().add(60 * 24, "minutes"));

    this.populateDialCodes();

    this.step = 1;

    switch (this.application.getType()) {
      case ApplicationTypeEnum.GenesysCloud:
        if (this.interaction) {
          this.callbackName = (
            this.interaction as GenesysInteraction
          ).getCustomerName();
          this.callbackEmail = (
            this.interaction as GenesysInteraction
          ).getCustomerEmail();
          this.callbackPhone = (
            this.interaction as GenesysInteraction
          ).getCustomerPhoneNumber();
          this.callbackPhonePrefix = null;
          this.callbackQueueId = (
            this.interaction as GenesysInteraction
          ).getQueueId();
        }
        this.defaultCountryCode = (
          this.userService.getUserDetails() as IGenesysAgent
        ).organization?.defaultCountryCode;

        break;
    }

    this.dateConfig = {
      format: "YYYY-MM-DD",
    };
    this.timeConfig = {
      format: "hh:mm A",
    };
    this.callbackAgentId = this.userService.getActiveUser().getId();
    try {
      this.queues = await this.queueService.getMyQueues();
      this.emailQueues = await this.queueService.getEmailQueues();
      this.smsQueues = await this.queueService.getSMSQueues();
      // preset queue id
      this.callbackQueueId = !!this.callbackQueueId
        ? this.callbackQueueId
        : !!this.config.agentParam(AgentParam.CALLBACK_DEFAULT_QUEUE)
        ? this.config.agentParam(AgentParam.CALLBACK_DEFAULT_QUEUE)
        : this.queues.length > 0
        ? this.queues[0].id
        : null;
      this.callbackEmailQueueId = this.hasEmailQueues
        ? this.emailQueues[0].id
        : null;
      this.callbackSMSQueueId = this.hasSMSQueues ? this.smsQueues[0].id : null;

      this.callbackNamePrefix =
        this.config.agentParam(AgentParam.CALLBACK_INTERACTION_PREFIX) ||
        CALLBACK_NAME_PREFIX;
    } catch (ex) {
      this.error = ex.message || ex;
    }
    // disable in case we don't have queues
    this.notifyEmail = this.hasEmailQueues;
    this.notifySMS = this.hasSMSQueues;
  }

  ngOnDestroy() {
    this.callbackName = null;
    this.callbackEmail = null;
    this.callbackPhone = null;
    this.callbackPhonePrefix = null;
    this.callbackTicket = null;
  }

  async populateDialCodes() {
    try {
      this.countryCodes = await this.locale.getCountryDialCodes();
      // set the default country code
      const countryCode =
        this.defaultCountryCode || this.locale.getCountryCode();
      this.callbackPhonePrefix = this.countryCodes.find(
        (c) => c.code.toLowerCase() === countryCode.toLowerCase()
      )?.dial_code;
    } catch (ex) {
      this.erroHandler.handleError(ex);
    } finally {
      this.cd.detectChanges();
    }
  }

  setTime(time: moment.Moment) {
    this.callbackDate = time.tz(this.timezone);
    this.callbackDay = this.callbackDate.clone();
    this.callbackTime = this.callbackDate.clone();
    this.dpDay = dayjs(this.callbackDate.toDate());
    this.dpTime = dayjs(this.callbackDate.toDate());
  }

  dayChange(day?: dayjs.Dayjs) {
    this.error = null;
    if (day) {
      this.callbackDay
        .set("month", day.get("month"))
        .set("date", day.get("date"))
        .set("year", day.get("year"));
    }
  }
  timeChange(time?: dayjs.Dayjs) {
    this.error = null;
    if (time) {
      this.callbackTime
        .set("hours", time.get("hours"))
        .set("minutes", time.get("minutes"))
        .set("seconds", 0);
    }
  }

  private get callbackPhoneWithPrefix() {
    return !!this.callbackPhone
      ? `${this.callbackPhonePrefix ?? ""}${this.callbackPhone}`
      : undefined;
  }

  private get callbackUserName() {
    return `${this.callbackNamePrefix}: ${this.callbackName}`;
  }

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  public get hasEmailQueues() {
    return this.emailQueues.length > 0;
  }

  public get hasSMSQueues() {
    return this.smsQueues.length > 0;
  }

  get isLinkNotShared() {
    return (
      (!this.notifySMS && !this.notifyEmail) ||
      (this.notifyEmail && !this.isEmailSuccess) ||
      (this.notifySMS && !this.isSMSSuccess)
    );
  }

  confirm(form) {
    if (form.valid && !!this.callbackDay && !!this.callbackTime) {
      this.callbackDate = this.callbackDay
        .set("hours", this.callbackTime.get("hours"))
        .set("minutes", this.callbackTime.get("minutes"))
        .set("seconds", 0)
        .tz(this.timezone);

      if (this.callbackDate.isBefore(moment())) {
        this.error = this.translateService.instant(
          "The scheduled video call should be later than now"
        );
        return false;
      }
      this.step = 2;
    } else {
      markFormFields(form);
    }
  }

  async schedule(form) {
    try {
      if (form.valid) {
        this.loading = true;
        this.activityService.loading(true);

        // step 1: create room
        const roomName = Util.uuidgen();
        await this.conferenceService.createConference(roomName);

        this.isRoomSuccess = true;

        // step 2: create ticket
        const ticketRequest: ITicketRequest =
          this.invitationService.prepareScheduleTicketRequest(
            this.config.agentParamEnabled(
              AgentParam.SINGLE_USE_SCHEDULE_TICKET_ENABLED
            )
              ? TicketTypeEnum.SingleScheduleTicket
              : TicketTypeEnum.ScheduleTicket,
            this.config.agentParam(AgentParam.TICKET_LENGTH) ?? 6,
            roomName,
            this.callbackEmail ||
              this.callbackPhoneWithPrefix ||
              this.callbackName,
            this.interaction?.getId() || roomName,
            this.callbackDate.toISOString(),
            this.callbackName,
            this.timezone,
            this.callbackQueueId
          );

        const ticket = await this.ticketService.createTicket(ticketRequest);

        this.callbackTicket = this.ticketService.getTicketWithDomain(ticket);

        this.isTicketSuccess = true;

        // step 3: create callback
        const callbackRq =
          this.interactionService.prepareInteractionScheduleRequest(
            this.callbackQueueId,
            this.callbackToMe ? this.callbackAgentId : null,
            this.callbackUserName,
            this.callbackPhoneWithPrefix,
            this.callbackDate.toISOString(),
            {
              [KEY_ROOM_NAME]: roomName,
              [KEY_EMAIL]: this.callbackEmail,
              [TAG_CUSTOMER_CALLBACK_ORIGIN]: ConversationOriginEnum.EMBEDDED,
              [TAG_CUSTOMER_CALLBACK_PREFERRED_AGENT_ID]: this.callbackToMe
                ? this.callbackAgentId
                : null,
              [TAG_CUSTOMER_TICKET_URL]: this.callbackTicket,
            }
          );

        await this.interactionService.scheduleInteraction(callbackRq);

        this.isScheduleSuccess = true;

        this.step = 3;

        // step 4: show email interaction
        const options: IInteractionMessageOptions = {
          name: this.callbackName,
          roomLink: this.callbackTicket,
          scheduleAt: this.callbackDate,
          googleCalendarLink: `${window.location.origin}/security/ticket/${ticket}/event?calendar=google`,
          iCalendarLink: `${window.location.origin}/security/ticket/${ticket}/event?calendar=ical`,
          outlookCalendarLink: `${window.location.origin}/security/ticket/${ticket}/event?calendar=outlook`,
        };

        if (this.notifyEmail) {
          const emailMessage = (
            this.config.agentParam(AgentParam.CALLBACK_NOTIFICATIONS) ||
            DEFAULT_CALLBACK_NOTIFICATIONS
          ).find((n) => n.channel === AppointmentChannelEnum.EMAIL);

          await this.interactionService.createEmailInteraction(
            this.callbackEmailQueueId,
            this.callbackEmail,
            emailMessage.textBody,
            emailMessage.htmlBody,
            emailMessage.subject,
            options
          );
        }

        this.isEmailSuccess = true;

        if (this.notifySMS) {
          const smsMessage = (
            this.config.agentParam(AgentParam.CALLBACK_NOTIFICATIONS) ||
            DEFAULT_CALLBACK_NOTIFICATIONS
          ).find((n) => n.channel === AppointmentChannelEnum.SMS);

          const response = await this.interactionService.createSMSInteraction(
            this.callbackSMSQueueId,
            this.callbackPhoneWithPrefix
          );
          const interaction = new Interaction(response.id);
          await this.interactionService.postMessageToInteraction(
            interaction,
            smsMessage.textBody,
            { type: "sms", ...options }
          );

          this.isSMSSuccess = true;
        }
      } else {
        markFormFields(form);
      }
    } catch (ex) {
      this.error = (ex.body && ex.body.message) || ex.message || ex;
      debugError(ex);
    } finally {
      this.loading = false;
      this.activityService.loading(false);
      this.cd.detectChanges();
    }
  }

  copy() {
    copyTextToClipboard(this.callbackTicket);
    if (this.application.isReady()) {
      this.notificationService.show("auvious", {
        body: this.translateService.instant(
          "The video link has been copied to your clipboard"
        ),
      });
    }
  }

  cancel(event?) {
    if (!!event) {
      event.preventDefault();
    }
    this.close.emit();
  }

  getTimezone() {
    const date = this.callbackDay
      .set("hours", this.callbackTime?.get?.("hours") || 0)
      .set("minutes", this.callbackTime?.get?.("minutes") || 0)
      .set("seconds", 0);
    return moment.tz(date.toISOString(), this.timezone).format("Z z");
  }

  timezoneChange(zone: string) {
    this.timezone = zone;
    this.callbackDay.tz(this.timezone);
  }
}
