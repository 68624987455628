import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiResource } from "@auvious/common";
import { ITicket, ITicketRequest, ISSHKey, PublicParam } from "../models";
import { AuviousRtcService } from "./rtc.service";
import { AppConfigService } from "./app.config.service";
import { firstValueFrom } from "rxjs";
import { GenericErrorHandler } from "./error-handlers.service";

@Injectable()
export class PublicTicketService {
  private ticketsMap: Map<string, ITicket> = new Map();

  constructor(private http: HttpClient) {}

  public getTicket(id: string): Promise<ITicket> {
    return new Promise((resolve, reject) => {
      if (this.ticketsMap.has(id)) {
        return resolve(this.ticketsMap.get(id));
      }
      // firstValueFrom(
      this.http.get<ITicket>(`/security/ticket/${id}`).subscribe({
        next: (response) => {
          this.ticketsMap.set(id, response);
          return resolve(response);
        },
        error: reject,
      });
    });
  }
}

@Injectable()
export class ProtectedTicketService {
  private securityApiResource: ApiResource;

  constructor(
    rtcService: AuviousRtcService,
    private config: AppConfigService,
    private errorHandler: GenericErrorHandler
  ) {
    rtcService.common$.subscribe((common) => {
      this.securityApiResource = common.apiResourceFactory("security");
    });
  }

  public async createTicket(ticket: ITicketRequest): Promise<string> {
    const response = await this.securityApiResource
      .create(ticket, {
        urlPostfix: "ticket",
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
    return response.id;
  }

  public getTicketWithDomain(ticket: string) {
    const domain = this.config.publicParam(PublicParam.CUSTOM_DOMAIN_URL);
    return `${domain || window.location.origin}/t/${ticket}`;
  }

  public createSSHKeyValue(key: ISSHKey): Promise<ISSHKey> {
    return this.securityApiResource
      .create(key, { urlPostfix: "keys" })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getSSHKeyValue(id: string): Promise<ISSHKey> {
    return this.securityApiResource
      .get({ urlPostfix: `keys/${id}` })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }
}
