import { AppConfigService } from "../../core-ui/services";
import { debug } from "../app.utils";

export class PureCloudAuthService {
  private me;

  constructor(private client, private usersApi) {}

  public async tryGetUser(): Promise<any> {
    if (!this.client) {
      throw new Error("platformClient not present");
    }

    return this.getUser();
  }

  public async getUser(): Promise<any> {
    if (this.me) return this.me;
    else {
      this.me = await this.usersApi.getUsersMe({ expand: ["organization"] });
      debug("got user: ", this.me);
      return this.me;
    }
  }

  public user() {
    return this.me;
  }
}
