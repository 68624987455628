import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import {
  ErrorPageSubCode,
  ErrorPageCode,
  PARAM_ROOM_ID,
} from "../../app.enums";

import {
  AppConfigService,
  copyTextToClipboard,
  DeviceService,
  UserService,
} from "../../../core-ui";
import { NotificationService } from "../../../core-ui/services/notification.service";
import { InteractionService } from "../../services";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-error-page",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorPageComponent implements OnInit {
  error: string;
  errorTitle = "Not supported Browser";
  redirectUrl: string;
  isAgent: boolean;
  pageNotFoundMessage: string;

  private errorCode: ErrorPageCode;
  private errorSubCode: ErrorPageSubCode;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private interaction: InteractionService,
    private user: UserService,
    private notifyService: NotificationService,
    translate: TranslateService,
    config: AppConfigService
  ) {
    this.errorCode = route.snapshot.paramMap.get("code") as ErrorPageCode;
    this.errorSubCode = route.snapshot.queryParamMap.get(
      "code"
    ) as ErrorPageSubCode;
    const errorMessage = route.snapshot.queryParamMap.get("message");

    if (this.errorCode === ErrorPageCode.PAGE_NOT_FOUND) {
      this.pageNotFoundMessage = !!config.pageNotFoundMessage
        ? config.pageNotFoundMessage
        : translate.instant(
            "Oops! This page is not accessible directly. Please use the correct URL."
          );
    }

    const url = route.snapshot.queryParamMap.get("url");
    if (!!url) {
      this.redirectUrl = `${window.location.origin}${unescape(url)}`;
    }

    switch (this.errorSubCode) {
      case ErrorPageSubCode.UNSUPPORTED_BROWSER:
        this.error =
          "This browser does not support features that Auvious requires for its basic functionality. " +
          "Please update or use a more modern browser.";
        break;
      case ErrorPageSubCode.APPOINTMENT_TOKEN_EXPIRED:
        // handled by html & isAppointmentTokenExpired
        break;
      case ErrorPageSubCode.STATE_MISMATCH:
        this.error =
          "Please allow access to third party cookies in your browser or change the security settings in auvious to support blocked third party cookies.";
        break;
      default:
        this.error = errorMessage || "Something unexpected happened.";
        break;
    }
  }

  ngOnInit(): void {
    this.isAgent = this.user.isAgent;
  }

  back() {
    // we might end up with an interaction that has an ended room. Clean it
    if (this.user.isAgent && this.interaction.isActiveInteractionAvailable()) {
      const interaction = this.interaction.getActiveInteraction();
      interaction.setRoom(null);
      this.interaction.setActiveInteraction(interaction);
    }
    this.router.navigate(["/welcome"], {
      queryParams: { [PARAM_ROOM_ID]: null },
      queryParamsHandling: "merge",
    });
  }

  get isNotFound() {
    return this.errorCode === ErrorPageCode.ROOM_NOT_FOUND;
  }
  get isPageNotFound() {
    return this.errorCode === ErrorPageCode.PAGE_NOT_FOUND;
  }
  get isScheduleNotFound() {
    return this.errorCode === ErrorPageCode.SCHEDULE_NOT_FOUND;
  }
  get isNotSupported() {
    return this.errorCode === ErrorPageCode.NOT_SUPPORTED;
  }
  get isSessionExpired() {
    return this.errorCode === ErrorPageCode.SESSION_EXPIRED;
  }
  get isAccessDenied() {
    return this.errorCode === ErrorPageCode.ACCESS_DENIED;
  }
  get isPurecloudInitError() {
    return this.errorCode === ErrorPageCode.PURECLOUD_INIT_ERROR;
  }
  get isParticipantLimitReached() {
    return this.errorCode === ErrorPageCode.PARTICIPANT_LIMIT_REACHED;
  }
  get isAppointmentCompleted() {
    return this.errorCode === ErrorPageCode.APPOINTMENT_COMPLETED;
  }
  get isLicenseRequired() {
    return this.errorCode === ErrorPageCode.LICENSE_REQUIRED;
  }
  get isAuthenticationError() {
    return this.errorCode === ErrorPageCode.AUTHENTICATION_FAILURE;
  }

  get isGenericError() {
    switch (this.errorCode) {
      case ErrorPageCode.GENERIC_ERROR:
      case ErrorPageCode.APPLICATION_NOT_FOUND:
        return true;
    }
    return false;
  }

  get isIFrame() {
    return DeviceService.isIFrame;
  }

  get isScheduledLater() {
    return this.errorCode === ErrorPageCode.SCHEDULED_LATER;
  }

  get isMobileChromeRequired() {
    return DeviceService.isMobile && !DeviceService.isiOS;
  }

  get isMobileSafariRequired() {
    return DeviceService.isMobile && DeviceService.isiOS;
  }

  get isDesktopChromeRequired() {
    return !DeviceService.isMobile;
  }

  get isCookiesDisabledError() {
    return this.errorCode === ErrorPageCode.COOKIES_DISABLED;
  }

  get isAppointmentTokenExpired() {
    return this.errorSubCode === ErrorPageSubCode.APPOINTMENT_TOKEN_EXPIRED;
  }

  get isRTCConnectionError() {
    return this.errorCode === ErrorPageCode.RTC_CONNECTION_FAILURE;
  }

  get isAppointmentNotFound() {
    return this.errorCode === ErrorPageCode.APPOINTMENT_NOT_FOUND;
  }

  get isConversationFailed() {
    return this.errorCode === ErrorPageCode.CONVERSATION_FAILED;
  }

  get isTicketNotFound() {
    return this.errorCode === ErrorPageCode.TICKET_NOT_FOUND;
  }

  copy() {
    copyTextToClipboard(this.redirectUrl);
    this.notifyService.success("Copied!");
  }
}
