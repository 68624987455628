import { IConfigOptions, IContactInfo, IMessage } from "@auvious/integrations";
import { IInteraction, ITicket, PublicParam } from "../../../../core-ui/models";
import { IConversationStrategy } from "../../../../core-ui/models/strategies/IConversationStrategy";
import {
  KEY_ROOM_NAME,
  KEY_APPOINTMENT_ID,
  KEY_APPOINTMENT_AGENT_USER_ID,
} from "../../../app.enums";
import {
  AppConfigService,
  DEFAULT_APPOINTMENT_CHAT_MODE,
  IScheduledAppointmentContact,
} from "../../../../core-ui";
import {
  IAppointment,
  IAppointmentRoutingGenesysCloudFlow,
  IAppointmentRoutingGenesysCloudQueue,
} from "../../../../core-ui/models/Appointment";
import { PureCloudService } from "../../../services/purecloud.service";
import { GenesysInteraction } from "../../GenesysInteraction";

type IAppointmentRoutingGenesys =
  | IAppointmentRoutingGenesysCloudFlow
  | IAppointmentRoutingGenesysCloudQueue;
export class GenesysCloudConversationStrategy implements IConversationStrategy {
  constructor(
    private pureCloudService: PureCloudService,
    private appConfig: AppConfigService
  ) {}

  prepareConversationConfig(
    ticket: ITicket,
    appointment: IAppointment<
      IAppointmentRoutingGenesysCloudFlow | IAppointmentRoutingGenesysCloudQueue
    >
  ): IConfigOptions {
    const config: IConfigOptions = {
      pcEnvironment: appointment.interaction.routing.properties.pcEnvironment,
      pcOrganizationId: ticket.properties.organizationId,
      pcDeploymentId: appointment.interaction.routing.properties.deploymentId,
      pcQueue:
        appointment.interaction.routing.type === "GENESYS_CLOUD_QUEUE"
          ? (
              appointment.interaction
                .routing as IAppointmentRoutingGenesysCloudQueue
            ).properties.queueName
          : "",
      chatMode:
        (appointment.interaction.routing as IAppointmentRoutingGenesys)
          .properties.chatMode ||
        (appointment.metadata?.chatMode as any) ||
        DEFAULT_APPOINTMENT_CHAT_MODE,
    };
    if (config.chatMode === "genesys-web-messaging") {
      config.pcGenesysWebMessagingConfiguration = this.appConfig.publicParam(
        PublicParam.GENESYS_WEB_MESSAGING_CONFIG
      );
    }
    return config;
  }

  prepareConversationRequest(
    ticket: ITicket,
    appointment: IAppointment<
      IAppointmentRoutingGenesysCloudFlow | IAppointmentRoutingGenesysCloudQueue
    >,
    contact: IScheduledAppointmentContact
  ): IContactInfo {
    let info: IContactInfo = {
      firstName: contact?.name?.firstName,
      lastName: contact?.name?.lastName,
      email: contact?.contact?.primaryEmail,
      [KEY_ROOM_NAME]: appointment.interaction.routing.properties.conferenceId,
      [KEY_APPOINTMENT_ID]: ticket.properties.appointmentId,
      ...appointment.interaction.customFields,
      ...appointment.metadata,
    };
    if (appointment.interaction.routing.type === "GENESYS_CLOUD_FLOW") {
      info = {
        ...info,
        [KEY_APPOINTMENT_AGENT_USER_ID]: (
          appointment.interaction.routing as IAppointmentRoutingGenesysCloudFlow
        ).properties.agentId,
      };
    }
    return info;
  }

  leaveConversation(interaction: GenesysInteraction) {
    return this.pureCloudService.disconnectUserFromConversation(
      interaction.getId(),
      interaction.getAgentId()
    );
  }

  async getMessages(interaction: IInteraction): Promise<IMessage[]> {
    return this.pureCloudService.getConversationMessages(
      interaction.getId(),
      interaction.getChannel()
    );
  }
}
