import { NgModule } from "@angular/core";

import { SharedModule } from "../../../modules/shared/shared.module";
import { SketchAreaComponent } from "./sketch-area/sketch-area.component";
import { SketchControlsComponent } from "./sketch-controls/sketch-controls.component";

@NgModule({
  declarations: [SketchAreaComponent, SketchControlsComponent],
  imports: [SharedModule],
  exports: [SketchAreaComponent, SketchControlsComponent],
})
export class SketchModule {}
