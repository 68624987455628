import { Injectable } from "@angular/core";
import { AssetService, IAsset, Role } from "@auvious/asset";
import { ApplicationService } from "./application.service";

@Injectable()
export class StorageAssetService {
  constructor(
    private assetService: AssetService,
    private applicationService: ApplicationService,
  ) {}

  getAssets(): Promise<IAsset[]> {
    return this.assetService.list(
      this.applicationService.getActiveApplication()?.getId()
    );
  }

  uploadAsset(
    blob: Blob,
    rolesAllowedAccess?: Role[],
    category?: string,
    filename?: string
  ): Promise<IAsset> {
    return this.assetService.upload(
      blob,
      this.applicationService.getActiveApplication()?.getId(),
      rolesAllowedAccess,
      category,
      filename
    );
  }

  deleteAsset(assetId: string): Promise<void> {
    return this.assetService.delete(assetId);
  }
}
