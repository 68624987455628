import { Event, Util } from "@auvious/common";
import { INotificationEvent } from "../INotificationEvent";
import {
  CompositionStateEnum,
  NotificationTypeEnum,
} from "../../core-ui.enums";
import { FileTransfer } from "@auvious/filetransfer";
import { Observable } from "rxjs";

export class FileTransferNotification implements INotificationEvent {
  private _id: string;

  constructor(private file: FileTransfer) {
    this._id = Util.uuidgen();
  }

  getId() {
    return this._id;
  }

  getTitle() {
    return "File received";
  }

  getType() {
    return NotificationTypeEnum.fileTransfer;
  }

  getFileType() {
    return this.file.mimeType;
  }

  getSentAt() {
    return this.file.sentAt;
  }

  getBody() {
    return this.file.filename;
  }

  getColor(): "danger" | "warning" | "info" | "success" | "container" {
    return "container";
  }

  getTimeToLive(): number {
    return 0;
  }

  canShow() {
    return true;
  }

  getFileUrl(): Observable<string> {
    return this.file.signedUrl;
  }
}
