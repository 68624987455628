import { PureCloudService } from "../../../services/purecloud.service";

import { IQueueStrategy } from "../../../../core-ui/models/strategies";
import { IQueue } from "../../../../core-ui";

export class GenesysCloudQueueStrategy implements IQueueStrategy {
  private queues: IQueue[];

  constructor(private pureCloudService: PureCloudService) {}

  async getAllQueues(): Promise<IQueue[]> {
    return this.pureCloudService.getQueues();
  }

  async getMyQueues(): Promise<IQueue[]> {
    if (!!this.queues) {
      return this.queues;
    }
    const response = await Promise.all([
      this.pureCloudService.getQueues(),
      this.pureCloudService.getMyQueues(),
    ]);
    this.queues = response[0].reduce((queues, queue) => {
      if (!!response[1].find((q) => q.id === queue.id)) {
        return [...queues, queue];
      }
      return queues;
    }, []);

    return this.queues;
  }

  async getEmailQueues() {
    const queues = await this.getMyQueues();
    return queues.filter((q) => "outboundEmailAddress" in q);
  }

  async getSMSQueues() {
    const queues = await this.getMyQueues();
    return queues.filter((q) => "outboundMessagingAddresses" in q);
  }
}
