import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ASRService } from "../../services/asr.service";
import { IStream } from "@auvious/rtc";
import { ActivityIndicatorService } from "../../services/activity-indicator.service";
import { NotificationService } from "../../services/notification.service";
import { extractErrorMessage } from "../../services/utils";
import { ProviderOrganizationLanguage } from "../../models/ProviderLanguage";
import { GenericErrorHandler } from "../../services/error-handlers.service";
import { Subscription } from "rxjs";
import { ConferenceService } from "../../services/conference.service";
import { SpeechToTextMetadata } from "../../models/Metadata";
import { filter } from "rxjs/operators";
import {
  ConferenceMetadataKeyEnum,
  StreamTrackKindEnum,
} from "../../core-ui.enums";
import { AppConfigService } from "../../services/app.config.service";
import { PublicParam } from "../../models";
import { UserService } from "../../services/user.service";
import { AuviousRtcService } from "../../services/rtc.service";
import { MediaRulesService } from "../../services/media.rules.service";
import { ApplicationService } from "../../services/application.service";
import { TranslateService } from "@ngx-translate/core";
import { slideInOut } from "../../core-ui.animations";
import { LocalMediaService, StreamState } from "../../services";

@Component({
  selector: "app-captions-control",
  templateUrl: "./captions-control.component.html",
  styleUrls: ["./captions-control.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOut],
})
export class CaptionsControlComponent implements OnInit, OnDestroy {
  @Input() stream: StreamState;
  @Input() istream: IStream;
  @Input() conferenceId: string;

  languages: ProviderOrganizationLanguage[] = [];
  sourceLanguage: ProviderOrganizationLanguage;
  failed = false;
  loading = false;
  isOnHold = false;
  applicationId: string;

  // targetLanguage: ProviderLanguage;
  // failedTarget = false;
  // loadingTarget = false;

  subscription: Subscription = new Subscription();
  isLanguagePickerVisible = false;

  constructor(
    private asr: ASRService,
    private activity: ActivityIndicatorService,
    private cd: ChangeDetectorRef,
    private notification: NotificationService,
    private logger: GenericErrorHandler,
    private conference: ConferenceService,
    private config: AppConfigService,
    public user: UserService,
    private mediaRules: MediaRulesService,
    private application: ApplicationService,
    private translate: TranslateService,
    private mediaService: LocalMediaService
  ) {}

  async ngOnInit() {
    this.applicationId = this.application.getActiveApplication().getId();
    try {
      this.loading = true;
      const response = await this.asr.query.getOrganizationAvailableLanguages(
        0,
        20
      );
      this.languages = response.content.map(
        (l) => new ProviderOrganizationLanguage(l)
      );

      // start service and listen for ready event
      this.start();
    } catch (ex) {
      this.failed = true;
      this.logger.handleError(ex);
    } finally {
      this.loading = false;
      this.cd.detectChanges();
    }

    this.subscription.add(
      this.conference.conferenceMetadataSet$
        .pipe(
          filter(
            (m) =>
              m instanceof SpeechToTextMetadata &&
              this.mediaRules.isAgentASRModerator
          )
        )
        .subscribe(async (m: SpeechToTextMetadata) => {
          // already started
          // runs for all participants except the one who sent it (agent)
          // if (this.targetLanguage) { /* && this.mediaRules.isAgentTranslationModerator */
          //   this.disableTranslation();
          // }
          // do not disable asr if we only changed translation language
          if (this.sourceLanguage?.id !== m.sourceLanguage.id) {
            if (this.sourceLanguage) {
              await this.disable(false);
            }

            this.sourceLanguage = m.sourceLanguage;
            await this.enable(m.sourceLanguage);
          }

          // if (m.targetLanguage) { /*  && this.mediaRules.isAgentTranslationModerator */
          //   this.targetLanguage = m.targetLanguage;
          //   this.tryTranslate(this.targetLanguage);
          // }
        })
    );
    this.subscription.add(
      this.conference.conferenceMetadataRemoved$
        .pipe(
          filter(
            (m) =>
              m instanceof SpeechToTextMetadata &&
              this.mediaRules.isAgentASRModerator
          )
        )
        .subscribe((_) => {
          // runs for all participants except the one who sent it
          // if (this.targetLanguage) { /*  && this.mediaRules.isAgentTranslationModerator */
          //   this.disableTranslation();
          // }
          this.disable();
          this.cd.detectChanges();
        })
    );
    this.subscription.add(
      this.conference.callHoldStateChange$
        .pipe(filter((s) => !s.loading && this.asr.isEnabled))
        .subscribe(async (st) => {
          this.isOnHold = st.isOnHold;
          try {
            st.isOnHold || this.isMutedAudio
              ? await this.asr.setStream(null)
              : await this.asr.setStream(this.istream?.mediaStream);
          } catch (ex) {
            this.logger.handleError(ex);
            this.notification.error("Closed Captions", {
              body: "Could not start captions service",
            });
          }
        })
    );
    this.subscription.add(
      this.mediaService.tracksChanged$
        .pipe(
          filter(
            (event) =>
              this.asr.isEnabled && event.kind === StreamTrackKindEnum.audio
          )
        )
        .subscribe(async (_) => {
          try {
            this.asr.setStream(null);
            if (!this.isMutedAudio && !this.conference.isConferenceOnHold) {
              await this.asr.setStream(this.istream?.mediaStream);
            }
          } catch (ex) {
            this.logger.handleError(ex);
            this.notification.error("Closed Captions", {
              body: "Could not start captions service",
            });
          }
        })
    );

    // if me or someone else has already enabled ASR, enable it for me as well.
    const meta = this.conference.getConferenceMetadata(
      ConferenceMetadataKeyEnum.speechToText
    ) as SpeechToTextMetadata;
    if (meta) {
      // enable what is already active in the call, if we are not moderators
      // if ((this.user.isCustomer || this.user.isGuestAgent) && this.mediaRules.isAgentASRModerator) {
      this.sourceLanguage = meta.sourceLanguage;
      await this.enable(meta.sourceLanguage);
      // this.targetLanguage = meta.targetLanguage;
      // this.tryTranslate(this.targetLanguage);
      // }
    } else if (
      this.user.isAgent &&
      this.config.publicParam(PublicParam.ASR_AUTO_START)
    ) {
      // set source language as the default one or the first one
      this.sourceLanguage =
        this.languages.find(
          (l) =>
            l.id === this.config.publicParam(PublicParam.ASR_DEFAULT_LANGUAGE)
        ) || this.languages?.[0];
      // enable
      this.requestEnable();
      // we do not support autoStart for translate for now
    }
  }

  ngOnDestroy(): void {
    this.asr.destroy(this.conferenceId);
    this.subscription.unsubscribe();
  }

  /** --------------- speech-to-text ---------------  */

  toggle() {
    this.isActive ? this.requestDisable() : this.requestEnable();
  }

  async changeLanguage(language: ProviderOrganizationLanguage) {
    // if agent is moderator and we are not agents, don't do anything (we shouldn't be able to see this button)
    if (!this.user.isAgent && this.mediaRules.isAgentASRModerator) {
      return;
    }

    // already started
    if (this.sourceLanguage) {
      await this.disable(false);
    }
    this.sourceLanguage = language;
    this.cd.detectChanges();
    // notify others if agent and moderator
    if (this.user.isAgent && this.mediaRules.isAgentASRModerator) {
      this.requestEnable();
    } else {
      this.enable(this.sourceLanguage);
    }
  }

  async changeTranslationLanguage(language: ProviderOrganizationLanguage) {
    this.isLanguagePickerVisible = false;
    if (this.sourceLanguage) {
      await this.disable(false);
    }
    this.sourceLanguage = language;
    this.enable(this.sourceLanguage);
    this.cd.detectChanges();

    this.notification.info("Captions language changed", {
      body: `${this.translate.instant(
        "You are now speaking and reading captions in"
      )} ${language.name}`,
    });
  }

  requestEnable() {
    const meta = new SpeechToTextMetadata(
      this.conference.myself,
      this.sourceLanguage,
      this.sourceLanguage
    );
    this.conference.setConferenceMetadata(meta);
    this.enable(this.sourceLanguage);
  }

  requestDisable() {
    if (!this.sourceLanguage) {
      return;
    }
    this.sourceLanguage = null;
    // if (this.targetLanguage) {
    //   this.disableTranslation();
    //   this.targetLanguage = null;
    // }
    if (this.user.isAgent && this.mediaRules.isAgentASRModerator) {
      this.conference.removeConferenceMetadata(
        ConferenceMetadataKeyEnum.speechToText
      );
    }
    this.disable();
  }

  start(): void {
    try {
      if (!this.asr.isInitialised) {
        this.asr.init(this.applicationId, this.conferenceId, {
          ready: async () => {
            try {
              this.isMutedAudio || this.conference.isConferenceOnHold // .getCallHoldState()?.isOnHold
                ? await this.asr.setStream(null)
                : await this.asr.setStream(this.istream?.mediaStream);
            } catch (ex) {
              this.logger.handleError(ex);
              this.notification.error("Closed Captions", {
                body: "Could not start captions service",
              });
            } finally {
              this.loading = false;
              this.cd.detectChanges();
            }
          },
          reconnected: () => {
            // no need to set stream. show msg if necessary
            this.loading = false;
            this.cd.detectChanges();
          },
          reconnecting: (payload) => {
            // check payload, and on num of retries, terminate
            if (payload.times > 5) {
              this.loading = false;
              this.logger.handleError(payload.reason);
              this.notification.error("Closed Captions", {
                body: "Could not reconnect to speech-to-text service",
              });
              this.tryDisable();
            }
            this.loading = true;
            this.cd.detectChanges();
          },
          transcriptFailed: () => {
            // terminate
            this.logger.handleError("transcriptFailed");
            const body = this.user.isAgent
              ? "Could not retrieve transcript. Please check your organization quota"
              : "Not available at this time.";
            this.notification.error("Closed Captions", { body });
            this.tryDisable();
          },
          translationFailed: () => {
            // warn that translation failed
            this.logger.handleError("translationFailed");
          },
        });
      } else {
        this.loading = false;
        this.cd.detectChanges();
      }
      // await this.asr.enable(source.id, target?.code);
    } catch (ex) {
      this.notifyError(ex);
      this.activity.loading(false);
    }
  }

  private async enable(source: ProviderOrganizationLanguage) {
    try {
      const configured = this.config.publicParam(
        PublicParam.ASR_TRANSLATION_PROVIDER_CONFIGURED
      ) as boolean;
      this.start();
      this.loading = true;
      await this.asr.enable(source.id, false, configured);
    } catch (ex) {
      this.loading = false;
      this.notifyError(ex);
      this.logger.handleError(ex);
    } finally {
      this.cd.detectChanges();
    }
  }

  async disable(notify = true) {
    await this.asr.disable(notify);
    this.cd.detectChanges();
  }

  private tryDisable() {
    const meta = this.conference.getConferenceMetadata(
      ConferenceMetadataKeyEnum.speechToText
    ) as SpeechToTextMetadata;
    if (meta?.userId === this.conference.myself.username) {
      this.requestDisable();
    } else {
      this.disable();
    }
  }

  notifyError(ex) {
    this.notification.error(extractErrorMessage(ex));
    this.disable();
  }

  /** --------------- translation ---------------  
  changeTargetLanguage(language: ProviderLanguage) {
    // changed translation language
    if (!!this.targetLanguage && language.code !== this.targetLanguage.code) {
      this.asr.stopTranslation();
    }
    this.targetLanguage = language;
   
    // notify of change if agent
    // check if we are in mode where agent is moderator
    if (this.user.isAgent && this.mediaRules.isAgentASRModerator) {
      const meta = new SpeechToTextMetadata(
        this.conference.myself,
        this.sourceLanguage,
        this.targetLanguage
      );
      this.conference.setConferenceMetadata(meta);
    }
   
    this.tryTranslate(this.targetLanguage);
   
  }
   
  requestDisableTranslation() {
    if (this.user.isAgent && this.mediaRules.isAgentASRModerator) {
      // remove target language
      const meta = new SpeechToTextMetadata(
        this.conference.myself,
        this.sourceLanguage
      );
      this.conference.setConferenceMetadata(meta);
    }
    this.disableTranslation();
  }
   
  disableTranslation() {
    this.asr.stopTranslation();
    this.targetLanguage = null;
  }
   
  tryTranslate(language: ProviderLanguage) {
    if (!language) { return; }
    this.asr.startTranslation(language.code);
  }
   
  get isTargetDisabled() {
    return (
      this.disabled ||
      !this.isAvailable ||
      this.failedTarget ||
      this.loadingTarget ||
      this.isTranslationControlDisabled ||
      this.targetLanguages.length === 0 ||
      (this.isMutedAudio && !this.isActive)
    );
  }
   
  get targetLanguages() {
    return this.sourceLanguage?.translateLanguages || [];
  }
   
  get isTranslationControlAvailable() {
    return true;
  }
   
  // is shown to customers if moderator=participant. disable if no sourceLanguage is set by agent.
  get isTranslationControlDisabled() {
    return this.user.isCustomer && !this.sourceLanguage;
  }
   */
  /** --------------- view getters --------------- */

  get isCaptionControlAvailable() {
    return this.mediaRules.isCaptionsControlAvailable;
  }

  get isActive() {
    return this.asr.isEnabled;
  }

  get isDisabled() {
    return (
      this.isOnHold ||
      !this.isAvailable ||
      this.failed ||
      this.loading ||
      (this.isMutedAudio && !this.isActive)
    );
  }

  get isAvailable() {
    return this.languages.length > 0;
  }

  get isMutedAudio() {
    return this.stream.audio.state !== "enabled";
  }

  get isCardVisible() {
    return !this.isDisabled && !this.isActive;
  }

  get isLanguageDisabled() {
    return this.loading || this.isMutedAudio;
  }

  get isModeTranslate() {
    return this.user.isCustomer;
  }

  get isTranslationPickerAvailable() {
    return (
      this.isModeTranslate &&
      this.languages?.length > 1 &&
      this.mediaRules.isTranslationAvailable
    );
  }
}
