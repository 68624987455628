import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  AgentParam,
  AppConfigService,
  IInteraction,
  IQueue,
} from "../../../../core-ui";
import { PureCloudService, QueueService } from "../../../services";
import { IShareLinkAction } from "../share-link.component";
import { GenesysInvitationChannelEnum } from "../../../models";

@Component({
  selector: "app-share-link-sms",
  templateUrl: "./sms.component.html",
  styleUrls: ["./sms.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareLinkSmsComponent implements OnInit, IShareLinkAction {
  @Input() interaction: IInteraction;
  @Input() link: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Output() confirm: EventEmitter<{
    channel: GenesysInvitationChannelEnum;
    queue?: IQueue;
    workflow?: string;
  }> = new EventEmitter();

  smsQueues: IQueue[] = [];
  isSelectingSMSQueue = false;
  SMSQueue: IQueue;
  phoneNumber: string;

  constructor(
    private queueService: QueueService,
    private cdr: ChangeDetectorRef,
    private config: AppConfigService
  ) {}

  async ngOnInit() {
    if (!this.isWorkflowEnabled) {
      this.smsQueues = await this.queueService.getSMSQueues();
      this.cdr.detectChanges();
    }
  }

  async shareLink() {
    this.isWorkflowEnabled ? this.shareUsingWorkflow() : this.shareUsingQueue();
  }

  async shareUsingWorkflow() {
    this.openChange.emit(false);
    this.confirm.emit({
      channel: GenesysInvitationChannelEnum.workflow,
      workflow: this.config.agentParam(AgentParam.SMS_WORKFLOW_ID),
    });
  }

  shareUsingQueue() {
    if (this.isSelectingSMSQueue) {
      return (this.isSelectingSMSQueue = false);
    }

    if (this.smsQueues.length > 1) {
      this.isSelectingSMSQueue = true;
    } else {
      this.SMSQueue = this.smsQueues[0];
      // this.shareOpen = false;
      this.openChange.emit(false);

      // this.isPhoneNumberValidation = true;
      this.confirm.emit({
        channel: GenesysInvitationChannelEnum.queue,
        queue: this.SMSQueue,
      });
    }
  }

  smsQueueSelected(queue: IQueue) {
    this.SMSQueue = queue;
    // this.shareOpen = false;
    this.openChange.emit(false);
    this.isSelectingSMSQueue = false;

    // this.isPhoneNumberValidation = true;
    this.confirm.emit({
      channel: GenesysInvitationChannelEnum.queue,
      queue: this.SMSQueue,
    });

    this.cdr.detectChanges();
  }

  get isWorkflowEnabled() {
    return (
      this.config.agentParamEnabled(AgentParam.SMS_WORKFLOW_ENABLED) &&
      !!this.config.agentParam(AgentParam.SMS_WORKFLOW_ID)
    );
  }

  get isSMSAvailable() {
    return this.isWorkflowEnabled
      ? true
      : this.interaction.supportsSMS() && this.smsQueues.length > 0;
  }
}
