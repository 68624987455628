import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";

import { IMediaFilter } from "../../models";
import { MediaEffectsService } from "../../services";

@Component({
  selector: "app-background-effects",
  templateUrl: "./background-effects.component.html",
  styleUrls: ["./background-effects.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundEffectsComponent implements OnInit, OnDestroy {
  filters: IMediaFilter[] = [];

  activeFilter: IMediaFilter;
  subscription: Subscription = new Subscription();

  constructor(
    private effects: MediaEffectsService,
    private cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.subscription.add(
      this.effects.filterTypeChanged$.subscribe((filter) => {
        this.activeFilter = filter;
        this.cd.detectChanges();
      })
    );

    this.subscription.add(
      this.effects.filtersUpdated$.subscribe((filters) => {
        this.filters = filters;
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  effectError(effect: IMediaFilter) {
    // if the effect didn't load, remove it from the list
    this.filters = this.filters.filter((f) => f.id !== effect.id);

    this.cd.detectChanges();
  }

  trackByFn(index: number, el: IMediaFilter) {
    return el.id;
  }
}
