import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Renderer2,
  OnDestroy,
  ViewChild,
  HostBinding,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ISpeechToTextTranscriptChunk } from "../../../../../core-ui/models/interfaces";
import { ThemeService } from "../../../../../core-ui/services/theme.service";
import { ConferenceService } from "../../../../../core-ui/services/conference.service";
import { IEndpoint } from "@auvious/rtc";
import { IEndpointMetadata } from "../../../../../core-ui/models/IEndpointState";
import { UserRoleEnum } from "../../../../../core-ui/core-ui.enums";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../../../../core-ui/services/user.service";
import { DeviceService } from "../../../../../core-ui/services/device.service";

@Component({
  selector: "app-caption-item",
  templateUrl: "./item.component.html",
  styleUrls: ["./item.component.scss"],
})
export class CaptionItemComponent implements OnInit, OnDestroy {
  @Input() chunk: ISpeechToTextTranscriptChunk;
  // @ViewChild('transcript') transcriptRef: ElementRef<HTMLDivElement>;

  subscription = new Subscription();
  endpoint: IEndpoint;

  constructor(
    private theme: ThemeService,
    private host: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private conference: ConferenceService,
    private t: TranslateService,
    private user: UserService
  ) {}

  @HostBinding("class") get class() {
    return {
      mobile: DeviceService.isMobile,
    };
  }

  ngOnInit(): void {
    this.endpoint = this.findParticipantByUsername(this.chunk?.userId);

    this.subscription.add(
      this.theme.themeChanged$().subscribe((theme) => {
        if (!theme) {
          return;
        }
        this.renderer.setStyle(
          this.host.nativeElement,
          "background-color",
          theme.captionsFontBackgroundColor
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  trackChunk(index, el: ISpeechToTextTranscriptChunk) {
    return el.userId + el.transcript;
  }

  get username(): string {
    const metadata = this.endpoint?.metadata;
    if (!metadata) {
      return "Me";
    }
    const role = metadata.roles.includes(UserRoleEnum.agent)
      ? this.t.instant("Agent")
      : this.t.instant("Customer");
    return metadata.name || role;
  }

  get imgUrl() {
    if (this.conference.myself.username === this.chunk.userId) {
      return this.user.getUserDetails()?.avatarUrl;
    }
    return this.endpoint?.metadata?.avatarUrl;
  }

  get initials() {
    return !!this.username ? this.username[0] : "?";
  }

  private findParticipantByUsername(id: string): IEndpoint {
    const participants = this.conference.getParticipants();
    const pKey = Object.keys(participants).find(
      (key) => participants[key].username === id
    );
    if (!pKey) {
      return;
    }
    return participants[pKey];
  }
}
