import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { IComposition, IRecordingComposition } from "../../models";
import { ActivityIndicatorService } from "../../services/activity-indicator.service";
import { CompositionService } from "../../services/composition.service";
import { debugError } from "../../services/utils";
import { CompositionTypeEnum } from "../../core-ui.enums";
import { Subscription } from "rxjs";
import { NotificationService } from "../../services/notification.service";

// eslint-disable-next-line no-shadow
enum tabsEnum {
  search = "search",
  myExports = "my-exports",
}

@Component({
  selector: "app-composition-manager",
  templateUrl: "./composition-manager.component.html",
  styleUrls: ["./composition-manager.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositionManagerComponent implements OnInit, OnDestroy {
  @Input() interactionId: string;

  recording: IRecordingComposition;
  queryString: string;

  // historyLoading = false;
  queryLoading = false;
  querySubmitted = false;

  playerOpen = false;
  playerSource: string;
  playerSourceType: CompositionTypeEnum;
  playerFileName: string;

  pollInterval: any;

  activeTab: tabsEnum = tabsEnum.search;

  tabSearch = tabsEnum.search;
  tabMyExports = tabsEnum.myExports;

  subscriptions: Subscription = new Subscription();

  constructor(
    private compositionService: CompositionService,
    private activityService: ActivityIndicatorService,
    private alertService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.interactionId) {
      this.queryString = this.interactionId;
      this.activityService.loading(true);
      this.getRecording(this.interactionId);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isActiveTab(tab: tabsEnum) {
    return this.activeTab === tab;
  }

  async tabSet(tab: tabsEnum) {
    this.activeTab = tab;
  }

  async cancelExport(composition: IComposition) {
    try {
      this.activityService.loading(true);
      await this.compositionService.cancelComposition(
        composition.conversationId,
        composition.id
      );
      this.getRecording(composition.conversationId);
    } catch (ex) {
      this.alertService.error(ex.message || ex);
    } finally {
      this.activityService.loading(false);
    }
  }

  async trySearch(query, form) {
    if (form.valid) {
      try {
        this.activityService.loading(true);
        await this.getRecording(query);
      } catch (ex) {
        debugError(ex);
        this.alertService.error(ex.message || ex);
      }
    }
  }

  private async getRecording(conversationId) {
    this.querySubmitted = true;
    this.queryLoading = true;
    try {
      this.recording = await this.compositionService.findRecording(
        conversationId
      );
    } catch (ex) {
      if (
        ex.response &&
        (ex.response.status === 401 || ex.response.status === 404)
      ) {
        this.recording = null;
        // no recording found for this conversation
      } else {
        this.alertService.error(ex.message || ex);
      }
      debugError(ex);
    } finally {
      this.activityService.loading(false);
      this.queryLoading = false;
      this.cd.markForCheck();
    }
  }

  play(payload: { composition: IComposition; url: string }) {
    this.playerSource = payload.url;
    this.playerSourceType = payload.composition.type;
    this.playerFileName = payload.composition.name;
    this.playerOpen = true;
  }

  discardPlayer() {
    this.playerOpen = false;
    this.playerSource = null;
    this.playerSourceType = null;
    this.playerFileName = null;
  }
}
