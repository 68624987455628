<ng-container *ngIf="!!request">
  <div class="player-actions">
    <ng-container *ngIf="isImage">
      <av-copy-clipboard
        (click)="copied($event)"
        [url]="source"
        tooltipPosition="left"
      ></av-copy-clipboard>
      <av-tooltip position="left" message="Download">
        <a
          avButton
          class="player-action"
          download="true"
          target="_blank"
          [href]="source"
          size="sm"
          color="link"
          ofType="icon"
        >
          <av-icon name="download"></av-icon>
        </a>
      </av-tooltip>
    </ng-container>
    <ng-container *ngIf="isCoBrowse">
      <av-tooltip
        position="bottom"
        message="Timeline"
        *ngIf="coBrowseSummary.length > 0"
      >
        <button
          class="player-action"
          avButton
          color="link"
          ofType="icon"
          size="sm"
          (click)="toggleDrawer($event)"
        >
          <av-icon name="time"></av-icon>
        </button>
      </av-tooltip>
      <av-tooltip position="bottom" message="Close">
        <button
          avButton
          color="link"
          class="player-action"
          size="sm"
          ofType="icon"
          (click)="cancel()"
        >
          <av-icon name="close-big"></av-icon>
        </button>
      </av-tooltip>
    </ng-container>
  </div>

  <av-card
    [@fadeInOut]="true"
    [backdrop]="false"
    class="player"
    [class.player-media-video]="isVideo"
    [class.player-media-audio]="isAudio"
    [class.player-cobrowse]="isCoBrowse"
    [class.player-image-container]="isImage"
    (click)="cardClick($event)"
  >
    <ng-container *ngIf="isImage">
      <av-card-body class="player-image-body" type="slim">
        <img class="player-image" [src]="source" />
      </av-card-body>
    </ng-container>

    <ng-container *ngIf="isCoBrowse">
      <av-spinner
        class="cobrowse-spinner"
        mode="light"
        size="md"
        *ngIf="isCoBrowseLoading"
      ></av-spinner>

      <app-cobrowse-frame
        class="player-view"
        #cobrowseView
        [viewSize]="remoteFrameSize"
        [cobrowseActive]="true"
        (mouseRatioChanged)="mouseRatioChanged($event)"
      >
        <ng-container cobrowse-pointers>
          <app-pointer
            *ngFor="let pointer of pointers | keyvalue"
            [color]="cobrowseUserColor(pointer.value)"
            [ngStyle]="{
              'top.px': pointer.value.top,
              'left.px': pointer.value.left
            }"
            [sender]="cobrowseUser(pointer.value)"
          >
          </app-pointer>
        </ng-container>
      </app-cobrowse-frame>

      <av-card-footer>
        <div class="player-controls">
          <button
            [loading]="isCoBrowseLoading"
            class="player-control"
            avButton
            color="link"
            ofType="icon"
            size="xs"
            (click)="toggle()"
          >
            <av-icon *ngIf="isCoBrowsePlaying" name="pause"></av-icon>
            <av-icon *ngIf="!isCoBrowsePlaying" name="play-outline"></av-icon>
          </button>

          <input
            class="player-scrub-bar"
            [disabled]="isCoBrowseLoading"
            id="progress"
            type="range"
            min="0"
            max="100"
            [(ngModel)]="coBrowseTime"
            (input)="goto($event.target.value)"
          />

          <label for="progress">{{ coBrowseLabel }}</label>
        </div>
      </av-card-footer>
    </ng-container>

    <ng-container *ngIf="isAudio || isVideo">
      <av-card-header (close)="cancel()">
        <span translate>Now playing</span>: {{ filename }}
      </av-card-header>
      <av-card-body type="slim" *ngIf="!!source">
        <vg-player (onPlayerReady)="onPlayerReady($event)">
          <vg-overlay-play *ngIf="isVideo"></vg-overlay-play>
          <vg-buffering></vg-buffering>

          <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>

          <vg-controls>
            <vg-play-pause></vg-play-pause>

            <vg-time-display
              vgProperty="current"
              vgFormat="mm:ss"
            ></vg-time-display>
            <div class="control-separator">/</div>
            <vg-time-display
              vgProperty="total"
              vgFormat="mm:ss"
            ></vg-time-display>

            <div class="control-spacer"></div>
            <vg-volume></vg-volume>
          </vg-controls>

          <video
            *ngIf="isVideo"
            [vgMedia]="media"
            #media
            playsinline
            webkit-playsinline="true"
            id="media-player-video"
            preload="auto"
          >
            <source [src]="source" />
          </video>

          <audio
            *ngIf="isAudio"
            [src]="source"
            [vgMedia]="media"
            #media
            id="media-player-audio"
            preload="auto"
          ></audio>
        </vg-player>
      </av-card-body>
    </ng-container>
  </av-card>

  <div
    class="drawer drawer-right fx-column container-pad-md"
    (click)="cardClick($event)"
    [class.drawer-open]="isCoBrowseDrawerOpen"
    *ngIf="isCoBrowse"
  >
    <div class="text-right">
      <button
        class="player-action"
        avButton
        ofType="icon"
        color="link"
        size="sm"
        (click)="toggleDrawer($event)"
      >
        <av-icon name="close-big"></av-icon>
      </button>
    </div>
    <div>
      <app-cobrowse-event-item
        (timeTravel)="gotoTimestamp($event)"
        class="drawer-item"
        [item]="item"
        *ngFor="let item of coBrowseSummary"
      >
      </app-cobrowse-event-item>
    </div>
  </div>
</ng-container>
