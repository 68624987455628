<div
  role="group"
  [attr.aria-label]="'Call HUD' | translate"
  *ngIf="isControlsEnabled"
  class="room-info fx-row fx-align-center fx-justify-space-between"
  [class.room-info-agent]="isRecorderVisible"
  [class.hidden]="!isTopBarVisible"
  data-tid="conference/room-info"
>
  <app-recorder
    *ngIf="isRecorderVisible"
    [options]="recorderOptions"
    [interaction]="interaction"
    (started)="recStarted($event)"
    (stopped)="recStopped($event)"
  ></app-recorder>
  <app-timer></app-timer>
  <app-network-indicator [endpoint]="endpoint"></app-network-indicator>
  <av-tooltip position="bottom" *ngIf="isInteractionDataVisible">
    <av-icon name="info-round" size="sm" mode="light"></av-icon>
    <ng-container tooltip-message>
      <div>
        <b translate>Interaction details</b>
      </div>
      <table class="interaction-details">
        <tr>
          <td><small class="text-muted">ID</small></td>
          <td>
            <small data-tid="conference/interaction-id">{{
              interactionID
            }}</small>
          </td>
        </tr>
        <tr *ngIf="!!agentDisplayName()">
          <td><small class="text-muted" translate>Agent</small></td>
          <td>
            <small data-tid="conference/interaction-agent-name">
              {{ agentDisplayName() }}
            </small>
          </td>
        </tr>
      </table>
      <!-- <div class="fx-row fx-gap-10"></div>
      <div class="fx-row fx-gap-10"></div> -->
    </ng-container>
  </av-tooltip>
</div>

<av-card
  class="device-help device-help-middle device-help-middle-offset device-help-allow"
  *ngIf="isRequestingMedia()"
>
  <av-card-body [noHeader]="true">
    <div class="text-center">
      <img src="/modules/shared/assets/images/allow-left.png" alt="allow" />
      <h1 translate [innerHTML]="'Click Allow' | translate"></h1>
      <p translate>
        You can still turn your camera and microphone off in the call.
      </p>
    </div>
  </av-card-body>
</av-card>

<av-card
  class="device-help device-help-middle device-help-allow"
  aria-live="polite"
  *ngIf="isDeviceHelpVisible()"
>
  <av-card-header (close)="dismissHelp()"></av-card-header>
  <av-card-body>
    <div class="text-center">
      <ng-container *ngIf="notAllowedError()">
        <img
          *ngIf="isMobile"
          src="/modules/shared/assets/images/mobile-browser-media.png"
          alt="allow"
        />
        <img
          *ngIf="!isMobile"
          src="/modules/shared/assets/images/desktop-browser-media.png"
          alt="allow"
        />
        <h3 translate>access blocked</h3>
        <p translate>enable access</p>
      </ng-container>
      <ng-container *ngIf="notFoundError()">
        <h3 translate>Devices not available</h3>
        <p translate>
          You need at least a camera or a microphone to join the call.
        </p>
      </ng-container>
      <ng-container *ngIf="streamError()">
        <img
          *ngIf="isMobile"
          src="/modules/shared/assets/images/mobile-browser-media.png"
          alt="allow"
        />
        <img
          *ngIf="!isMobile"
          src="/modules/shared/assets/images/desktop-browser-media.png"
          alt="allow"
        />
        <h3 translate>Your devices are not responding</h3>
        <p translate>
          Please check your camera and/or microphone device and try again.
        </p>
      </ng-container>
      <ng-container *ngIf="isWebViewError() || isMediaRequestTimeoutError()">
        <img
          *ngIf="isMobileAndroid"
          src="/modules/shared/assets/images/mobile-browser-media-webview-android.png"
          alt="allow"
        />
        <img
          *ngIf="isMobileIOS"
          src="/modules/shared/assets/images/mobile-browser-media-webview-ios.png"
          alt="allow"
        />
        <h3 translate *ngIf="isWebViewError()">
          Limited access to media devices
        </h3>
        <h3 translate *ngIf="isMediaRequestTimeoutError()">
          Accessing your camera and microphone is taking longer than usual
        </h3>
        <p translate>
          Please open this page in a compatible web browser from a nearby menu.
        </p>
        <button
          color="accent"
          size="block"
          [raised]="true"
          avButton
          (click)="copyToClipboard(url)"
          class="fx-row fx-align-center fx-justify-center fx-gap-10"
        >
          <av-icon name="copy-document" size="xs"></av-icon>
          <span translate>Copy room link</span>
        </button>
        <small class="text-muted" translate>
          Or, copy the link above and open it in a compatible web browser.
        </small>
      </ng-container>
      <!-- <ng-container *ngIf="isMediaRequestTimeoutError()">
        <h3 translate>
          Accessing your camera and microphone is taking longer than usual
        </h3>
        <p translate>
          Please open this page in a standard web browser from a nearby menu.
        </p>
      </ng-container> -->
    </div>
  </av-card-body>
</av-card>

<div
  *ngIf="hasNoParticipants"
  class="text-muted text-center container-pad-md container-empty"
>
  <ng-container *ngIf="isCustomer">
    <span *ngIf="!isAgentPresent" translate>Waiting for agent...</span>
    <span *ngIf="isAgentPresent" translate>Agent online</span>
  </ng-container>
  <ng-container *ngIf="isAgent">
    <span *ngIf="!isCustomerPresent" translate>Waiting for customer...</span>
    <span *ngIf="isCustomerPresent" translate>Customer online</span>
  </ng-container>
</div>

<div #container class="tile-container" [ngClass]="containerCssClass">
  <ng-container *ngIf="!isDeviceSetupOpen">
    <app-tile
      #tiles
      *ngFor="let stream of streams; trackBy: trackByMediaIdFn"
      [attr.id]="stream.originator.endpoint"
      [stream]="stream"
      [interaction]="interaction"
      (ready)="tileReady($event)"
      (destroyed)="tileDestroyed($event)"
      [avFileDragDrop]="{ enabled: isFileTransferAvailable(stream) }"
      (filesDropped)="filesDropped($event, stream.originator)"
    >
      <app-point-area *ngIf="isLaserVisible" [stream]="stream">
      </app-point-area>

      <div
        [class.tile-actions-over]="isTileActionOnTop(stream)"
        [class.tile-actions-ar]="isLaserActive(stream)"
        class="tile-actions fx-row fx-align-start fx-justify-start"
      >
        <app-tile-controls
          [interaction]="interaction"
          [stream]="stream"
        ></app-tile-controls>
        <app-tile-info
          [interaction]="interaction"
          [stream]="stream"
        ></app-tile-info>
      </div>
    </app-tile>

    <app-cobrowse-session
      (ready)="tileReady($event)"
      (destroyed)="tileDestroyed($event)"
      [interaction]="interaction"
      *ngIf="isCobrowseActive && !isRating"
    >
    </app-cobrowse-session>

    <app-snapshot-session
      *ngIf="(snapshotStream || isSnapshotActive) && !isRating"
      [stream]="snapshotStream"
      [interaction]="interaction"
      [review]="isReviewingSnapshots"
      (ready)="tileReady($event)"
      (destroyed)="tileDestroyed($event)"
    >
    </app-snapshot-session>
  </ng-container>
</div>

<!-- [class.hidden]="!isControlsVisible" -->
<app-stream-controls
  *ngIf="isStreamControlsEnabled"
  [interaction]="interaction"
  [mediaControlsEnabled]="!isRequestingMedia()"
  [@slideIn]="true"
  (leave)="leaveConference()"
  (terminate)="terminateConference()"
  (exit)="exitConference()"
  (mediaError)="mediaError($event)"
>
</app-stream-controls>

<div
  class="captions-container"
  [class.captions-container-no-media-controls]="!isControlsVisible"
  [class.captions-container-mobile]="isMobile"
  *ngIf="isCaptionsEnabled && isControlsEnabled"
  [@toast]="true"
>
  <app-captions-control
    class="captions-control"
    *ngIf="isCaptionsAvailable"
    [conferenceId]="conferenceId"
    [istream]="localAudioVideoStream"
    [stream]="mystream"
  ></app-captions-control>
  <app-captions class="captions"></app-captions>
</div>

<div
  class="device-setup-container"
  *ngIf="isDeviceSetupAvailable()"
  [class.device-setup-container-taldesk]="isTalkdeskApp"
  [class.device-setup-container-mobile]="isMobile"
  [@fadeInOut]="true"
>
  <app-device-setup
    [conversationType]="interactionType"
    (canceled)="cancel()"
    (accepted)="acceptConstraints()"
  >
  </app-device-setup>
</div>

<app-hold
  *ngIf="!isRating && !isReviewingSnapshots"
  [videoReady]="!!localTile"
  [enabled]="isOnHold"
></app-hold>

<app-rating [interaction]="interaction"></app-rating>

<app-stream-play-button *ngIf="isDeviceSetup"></app-stream-play-button>

<!-- cannot check if speaker can be used without actually using it on an audio track -->
<audio src="/assets/sounds/notif_test.mp3" #testSound></audio>
<audio src="/assets/sounds/notif_in.mp3" #notificationIn></audio>
<audio src="/assets/sounds/notif_out.mp3" #notificationOut></audio>
