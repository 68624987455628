import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { Observable } from "rxjs";
import { ConversationNotification } from "../../models/notifications/ConversationNotification";
import {
  FileTransferNotification,
  INotificationEvent,
  ConfirmNotification,
  ToastNotification,
} from "../../models";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  @Input() notification: INotificationEvent;

  @Output() removed: EventEmitter<INotificationEvent> = new EventEmitter();

  constructor(private host: ElementRef<HTMLDivElement>) {}

  ngOnInit(): void {
    this.host.nativeElement.setAttribute(
      "role",
      this.isToast ? "alert" : "alertdialog"
    );
    this.host.nativeElement.setAttribute(
      "data-tid",
      "notification/" + this.notification.getType()
    );
  }

  get title() {
    return this.notification.getTitle();
  }

  get body() {
    return this.notification.getBody();
  }

  get color() {
    return this.notification.getColor();
  }

  get isFileTransfer() {
    return this.notification instanceof FileTransferNotification;
  }

  get isConfirm() {
    return this.notification instanceof ConfirmNotification;
  }

  get isConversation() {
    return this.notification instanceof ConversationNotification;
  }

  get isToast() {
    return this.notification instanceof ToastNotification;
  }

  get url(): Observable<string> {
    return (this.notification as FileTransferNotification).getFileUrl();
  }

  clicked() {
    if (this.isToast) {
      this.remove();
      return;
    }
    if (!this.isConversation) {
      return;
    }
    (this.notification as ConversationNotification).click();
  }

  remove() {
    if (this.notification instanceof ConversationNotification) {
      (this.notification as ConversationNotification).dismiss?.();
    } else if (this.notification instanceof ToastNotification) {
      (this.notification as ToastNotification).dismiss?.();
    }
    this.removed.emit(this.notification);
  }

  approve() {
    (this.notification as ConfirmNotification).confirm();
    this.remove();
  }

  deny() {
    (this.notification as ConfirmNotification).cancel();
    this.remove();
  }
}
