<ng-container [ngSwitch]="message.type">
  <ng-container *ngSwitchCase="typeText">
    <div class="message message-text">
      <ng-container *ngIf="isLink">
        <a [href]="message.text" target="_blank" class="link">{{
          message.text
        }}</a>
      </ng-container>
      <ng-container *ngIf="!isLink">
        {{ message.text }}
      </ng-container>
    </div>

    <div *ngIf="!!timestamp" class="message-hint">{{ timestamp }}</div>
  </ng-container>

  <ng-container *ngSwitchCase="typeFile">
    <div class="message-file">
      <label class="message-text message">{{ message.file.name }}</label>
      <button avButton ofType="icon" size="xs" (click)="downloadFile()">
        <av-icon
          name="download"
          [mode]="message.isLocal ? 'light' : 'dark'"
        ></av-icon>
      </button>
    </div>
    <div *ngIf="!!timestamp" class="message-hint">{{ timestamp }}</div>
  </ng-container>

  <ng-container *ngSwitchCase="typeBot">
    <div class="message-bot">
      <div class="message message-text">
        <ng-container *ngIf="isLink">
          <a [href]="message.text" target="_blank" class="link">{{
            message.text
          }}</a>
        </ng-container>
        <ng-container *ngIf="!isLink">
          {{ message.text }}
        </ng-container>
      </div>
      <div *ngIf="!!timestamp" class="message-hint">{{ timestamp }}</div>
      <div
        class="message-bot-replies"
        *ngIf="!hasReplied && message.quickReplies?.length > 0"
      >
        <button
          *ngFor="let reply of message.quickReplies"
          avButton
          (click)="quickReply(reply)"
          ofType="stroked"
          size="xs"
        >
          {{ reply.text }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
