import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IFilterSelection } from "../../../../core-ui/models/interfaces";

@Component({
  selector: "av-filter-item",
  templateUrl: "./filter-item.component.html",
  styleUrls: ["./filter-item.component.scss"],
})
export class FilterItemComponent implements OnInit {
  @Input() value: any;
  @Input() disabled: boolean;
  @Input() checked: boolean;

  @Output() changed: EventEmitter<IFilterSelection<any>> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  change(event: CustomEvent<HTMLInputElement>) {
    this.changed.emit({
      checked: (event.target as HTMLInputElement).checked,
      value: this.value,
    });
  }
}
