import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostBinding,
  ElementRef,
  signal,
  AfterViewInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { DeviceService } from "../../../../core-ui/services";
import { TinyColor } from "@ctrl/tinycolor";

@Component({
  selector: "av-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() popup = false;
  @Input() backdrop = true;
  @Input() menu = false;
  @Input() vAlign: "top" | "bottom";
  @Input() hAlign: "left" | "right" | "center";
  @Input() type:
    | "danger"
    | "warning"
    | "info"
    | "container"
    | "ghost"
    | "success"
    | "snapshot"
    | "control-options" = "container";
  @Input() arrow:
    | "bottom"
    | "bottom-left"
    | "bottom-right"
    | "top"
    | "left"
    | "right"
    | "top-left"
    | "top-right"
    | "left-top";

  private subscriptions: Subscription;
  private supportsBackdropBlur = true;
  // iconMode = signal("light");

  constructor(public host: ElementRef<HTMLDivElement>) {
    this.subscriptions = new Subscription();
    this.supportsBackdropBlur = !DeviceService.isFirefox;
  }

  @HostBinding("class") get class() {
    return {
      "panel-error": this.type === "danger",
      "panel-warn": this.type === "warning",
      "panel-success": this.type === "success",
      "panel-info": this.type === "info",
      "panel-secondary": this.isSecondary,
      "panel-control-options": this.type === "control-options",
      "panel-backdrop-blur":
        this.supportsBackdropBlur &&
        this.backdrop &&
        (this.type === "container" || this.type === "control-options"),
      "panel-popup": this.popup,
      "panel-popup-top": this.popup && this.vAlign === "top",
      "panel-popup-bottom": this.popup && this.vAlign === "bottom",
      "panel-popup-left": this.popup && this.hAlign === "left",
      "panel-popup-right": this.popup && this.hAlign === "right",
      "panel-popup-center": this.popup && this.hAlign === "center",
      "panel-popup-menu": this.menu,
      "panel-arrow": !!this.arrow,
      "panel-arrow-down": this.arrow === "bottom",
      "panel-arrow-down-left": this.arrow === "bottom-left",
      "panel-arrow-down-right": this.arrow === "bottom-right",
      "panel-arrow-up": this.arrow === "top",
      "panel-arrow-left": this.arrow === "left",
      "panel-arrow-left-top": this.arrow === "left-top",
      "panel-arrow-right": this.arrow === "right",
      "panel-arrow-up-left": this.arrow === "top-left",
      "panel-arrow-up-right": this.arrow === "top-right",
      "panel-ghost": this.type === "ghost",
    };
  }

  ngOnInit() {
    this.host.nativeElement.setAttribute(
      "role",
      this.popup ? "dialog" : "group"
    );
  }

  ngAfterViewInit(): void {
    // if (this.isSecondary) {
    //   this.iconMode.set(this.isLightBackground ? "dark" : "light");
    // }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public get isSecondary() {
    return ["info", "success", "warning", "error", "danger"].includes(
      this.type
    );
  }

  public get isLightBackground() {
    const style = getComputedStyle(this.host.nativeElement);
    return new TinyColor(style.backgroundColor).isLight();
  }
}
