import { Component, OnInit, Input } from "@angular/core";
import { NotificationService } from "../../../../core-ui/services/notification.service";
import { copyTextToClipboard } from "../../../../core-ui/services";

@Component({
  selector: "av-copy-clipboard",
  templateUrl: "./copy-clipboard.component.html",
  styleUrls: ["./copy-clipboard.component.scss"],
})
export class CopyClipboardComponent implements OnInit {
  @Input() url: string;
  @Input() tooltipLabel = "Copy download link";
  @Input() tooltipPosition: "top" | "bottom" | "left" | "right" = "top";
  @Input() size: "xs" | "sm" | "xxs" | "md" = "sm";

  constructor(private notification: NotificationService) {}

  ngOnInit(): void {}

  copy() {
    copyTextToClipboard(this.url);
    this.notification.info("Copied to clipboard");
  }
}
