import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "av-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @Output() queryChange: EventEmitter<string> = new EventEmitter();

  query: string;

  constructor() {}

  ngOnInit(): void {}

  change(value) {
    this.queryChange.emit(value);
  }
}
