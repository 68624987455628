<label class="switch">
  <input
    [(ngModel)]="on"
    (ngModelChange)="change($event)"
    [disabled]="disabled"
    type="checkbox"
  />
  <div class="switch-toggle">
    <ng-content></ng-content>
  </div>
</label>
