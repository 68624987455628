import { Injectable, NgZone } from "@angular/core";
import { MediaDevices } from "@auvious/media-tools";
import {
  AuviousRtc as Auvious,
  ConferenceMode,
  IConference,
  IConferenceMetadata,
  IConferenceMetadataUpdatedEvent,
  IConferenceSession,
  IConferenceSessionEventHandlers,
  ICreateConferenceOptions,
  IEndpoint,
  INetworkQualityReportEvent,
  IPublishedStream,
  IPublishOptions,
  IStream,
  StreamTypes,
  ViewStreamMetadataProvider,
} from "@auvious/rtc";
import { BehaviorSubject, Observable, Subject, Subscriber } from "rxjs";
import { anonymizeIStream } from "..";

import {
  CLAIM_TERMS_ACCEPTED,
  CLAIM_TERMS_ACCEPTED_AT,
  ConferenceMetadataKeyEnum,
  ConversationOriginEnum,
  EndpointTypeEnum,
  StreamTrackKindEnum,
  TerminateReasonEnum,
  VideoFacingModeEnum,
} from "../core-ui.enums";
import {
  DEFAULT_CALL_HOLD_STATE,
  ICallHoldState,
  IConferenceStream,
  IEndpointMetadata,
  IEndpointMetadataChangedEvent,
  EndpointStateEnum,
  IHook,
  IStreamMetadata,
  IStreamMetadataChangedEvent,
  IStreamMuteChangeEvent,
  PublicParam,
} from "../models";
import {
  DEFAULT_ABR_VIEWER,
  IAutomaticBitrateAdaptationViewerPublicParams,
} from "../models/application-options/PublicOptions";
import {
  BaseMetadata,
  ConferenceMetadataFactory,
  IntegrationMetadata,
  PointerMetadata,
  RecorderMetadata,
  SnapshotMetadata,
} from "../models/Metadata";
import { AppConfigService } from "./app.config.service";
import { GenericErrorHandler } from "./error-handlers.service";
import { AuviousRtcService } from "./rtc.service";
import {
  anonymizeIEndpoint,
  createSentryLogger,
  debug,
  debugError,
} from "./utils";
import { LocalMediaService } from "./local.media.service";
import { NotificationService } from "./notification.service";
import { UserService } from "./user.service";
import {
  DeviceCaptureErrorAction,
  DeviceCaptureStopSuccessAction,
  DeviceCaptureSuccessAction,
  ScreenShareAvailabilityChangedAction,
  WindowEventService,
} from "./window.event.service";
import { getCurrentScope } from "@sentry/angular";

const TRACK_KIND = "trackKind";
const sentryLog = createSentryLogger("conference.service");

@Injectable()
export class ConferenceService {
  private session: IConferenceSession;
  private streams: { [streamId: string]: IStream } = {};
  private participants: { [endpointId: string]: IEndpoint<IEndpointMetadata> };
  private auviousRtc: Auvious;
  private endpoints: {
    [endpoint: string]: { [streamType: string]: IConferenceStream };
  };
  private mutedStreams: {
    [streamId: string]: { [trackKind: string]: boolean };
  };
  private endpointState: { [endpointId: string]: EndpointStateEnum };
  private streamState: { [streamId: string]: EndpointStateEnum };
  private streamFacingMode = new WeakMap<IStream, VideoFacingModeEnum>();

  private isMutedWhileSwitchingCam: boolean;
  private callHoldState: ICallHoldState;
  private conferenceMetadata: Map<string, IConferenceMetadata> = new Map();
  private conferenceMetadataQueue: Map<string, BaseMetadata> = new Map();

  private _streamAdded = new Subject<IStream>();
  public streamAdded$ = this._streamAdded.asObservable();

  private _streamRemoved = new Subject<IStream>();
  public streamRemoved$ = this._streamRemoved.asObservable();

  private _endpointStateChanged = new Subject<{
    endpoint: string;
    state: EndpointStateEnum;
  }>();
  public endpointStateChanged$ = this._endpointStateChanged.asObservable();

  private _streamsNeedPlay = new Subject<boolean>();
  public streamsNeedToPlay$ = this._streamsNeedPlay.asObservable();

  private _playPausedStreams = new Subject();
  public playPausedStreams$ = this._playPausedStreams.asObservable();

  private _endpointState = new Subject<{
    [endpointId: string]: EndpointStateEnum;
  }>();
  public endpointState$ = this._endpointState.asObservable();

  private _streamState = new Subject<{
    [streamId: string]: EndpointStateEnum;
  }>();
  public streamState$ = this._streamState.asObservable();

  private _callHoldState = new Subject<ICallHoldState>();
  public callHoldStateChange$ = this._callHoldState.asObservable();

  private _networkQuality = new Subject<INetworkQualityReportEvent>();
  public networkQualityReport$ = this._networkQuality.asObservable();

  private _streamMuteChange = new Subject<IStreamMuteChangeEvent>();
  public streamMutedChange$ = this._streamMuteChange.asObservable();

  private _endpointJoined = new Subject<IEndpoint<IEndpointMetadata>>();
  public endpointJoined$ = this._endpointJoined.asObservable();

  private _endpointLeft = new Subject<IEndpoint<IEndpointMetadata>>();
  public endpointLeft$ = this._endpointLeft.asObservable();

  private _participantLeft = new Subject<IEndpoint<IEndpointMetadata>>();
  public participantLeft$ = this._participantLeft.asObservable();

  private _facingModeChanged = new Subject<{
    streamId: string;
    facingMode: VideoFacingModeEnum;
  }>();
  public facingModeChanged$ = this._facingModeChanged.asObservable();

  private _localStreamWillPublish = new BehaviorSubject<IPublishOptions>(null);
  public localStreamWillPublish$ = this._localStreamWillPublish.asObservable();

  private _localStreamPublished = new BehaviorSubject<IPublishedStream>(null);
  public localStreamPublished$ = this._localStreamPublished.asObservable();

  private _streamMetadataChanged = new Subject<IStreamMetadataChangedEvent>();
  public streamMetadataChanged$ = this._streamMetadataChanged.asObservable();

  private _endpointMetadataChanged =
    new BehaviorSubject<IEndpointMetadataChangedEvent>(null);
  public endpointMetadataChanged$ =
    this._endpointMetadataChanged.asObservable();

  private _conferenceMetadataSet = new Subject<IConferenceMetadata>();
  public conferenceMetadataSet$ = this._conferenceMetadataSet.asObservable();

  private _conferenceMetadataRemoved = new Subject<IConferenceMetadata>();
  public conferenceMetadataRemoved$ =
    this._conferenceMetadataRemoved.asObservable();

  private _terminateConference = new Subject<TerminateReasonEnum>();
  public terminateConference$ = this._terminateConference.asObservable();

  private _confirmTerminateConference = new Subject<void>();
  public confirmTerminateConference$ =
    this._confirmTerminateConference.asObservable();

  private streamElements: Record<string, HTMLAudioElement | HTMLVideoElement> =
    {};

  public screenShareStream: IPublishedStream;

  private streamWarningsTimeouts: { [id: string]: any };
  private endedObserver: Subscriber<TerminateReasonEnum>;

  private preTerminateHooks: IHook[] = [];

  private isPublishingStream = false;

  constructor(
    private rtcService: AuviousRtcService,
    private userService: UserService,
    private errorHandler: GenericErrorHandler,
    private config: AppConfigService,
    private alertService: NotificationService,
    private windowEventService: WindowEventService,
    private local: LocalMediaService,
    private zone: NgZone
  ) {
    this.auviousRtc = rtcService.client;
    this.reset();

    this.local.streamError$.subscribe((event) =>
      this.onLocalStreamError(event)
    );

    this.local.activeDeviceChanged$.subscribe((deviceKind) => {
      if (deviceKind === "videoinput") {
        this.setFacingModeForStream(
          this.localStream,
          this.local.mainStream.facingMode
        );
      }
    });

    this.local.localStreamReady$.subscribe((stream) => {
      if (
        stream.type === StreamTypes.SCREEN &&
        this.screenShareStream &&
        this.resolveScreenShareConflict(
          this.screenShareStream,
          stream as IPublishedStream
        ) === stream
      ) {
        // someone else streams a screen
        this.local.closeScreenStream();
      } else {
        // let it render first
        setTimeout(() => this.publish(stream), 0);
      }
    });

    // dummy stream replaced with the real deal
    this.local.localStreamReplaced$.subscribe((event) => {
      if (event.next) {
        this.streamElements[event.next.id] =
          this.streamElements[event.previous?.id];
      }

      delete this.streamElements[event.previous?.id];

      this.setFacingModeForStream(event.next, this.local.mainStream.facingMode);
      this.streamFacingMode.delete(event.previous);
    });

    this.local.streamMutedChange$.subscribe((event) => {
      if (event.stream) {
        this.mutedStreams[event.stream.id] = {
          ...this.mutedStreams[event.stream.id],
          [event.trackKind]: event.muted,
        };
      }
    });

    this.local.streamRemoved$.subscribe((stream) => {
      this.unpublishStream(stream.type);
      this.cleanStream(stream);
    });
  }

  private cleanStream(stream: IStream) {
    if (this.endpoints[stream.originator.endpoint]) {
      delete this.endpoints[stream.originator.endpoint]?.[stream.type];
    }

    if (stream === this.screenShareStream) {
      if (this.isScreenShareStreamLocal) {
        this.windowEventService.sendMessage(
          new DeviceCaptureStopSuccessAction()
        );
      } /* TODO: if (isWidget || isPopup) */ else {
        // in case of remote screen share, disable local on widget and show card on audio call
        this.windowEventService.sendMessage(
          new ScreenShareAvailabilityChangedAction(false)
        );
      }

      this.screenShareStream = null;
    }

    if (stream.originator.endpoint !== this.myself.endpoint) {
      this.local.closeMediaStream(stream.mediaStream);
    }

    delete this.streams[stream.id];
    delete this.streamState[stream.id];
  }

  public get getSession(): IConferenceSession {
    return this.session;
  }

  public registerPreTerminateHook(hook: IHook) {
    this.preTerminateHooks.push(hook);
  }

  public createConference(conferenceId?: string): Promise<IConference> {
    return new Promise((resolve, reject) => {
      this.auviousRtc.on("conferenceCreated", (conference: IConference) => {
        debug("conferenceCreated", conference);
        sentryLog(["conferenceCreated", conference]);
        resolve(conference);
      });

      this.auviousRtc.on("createConferenceFailed", (error) => {
        try {
          const msg = error.data ? error.data.message : error.message;
          sentryLog(msg, "fatal");

          if (!!error.response && error.response.status === 404) {
            // don't log error on sentry
          } else if (!!this.endedObserver) {
            this.alertService.error("Could not create call.", { ttl: 3000 });
            this.errorHandler.handleError(error);
            this.endedObserver.error(error);
            this.endedObserver = null;
          }

          debug("createConferenceFailed", msg);
          reject(error);
        } catch (ex) {
          reject(ex);
          this.errorHandler.handleError(ex);
        }
      });

      const options: ICreateConferenceOptions = {
        mode: ConferenceMode.ROUTER,
        id: conferenceId,
        metadata: {
          participant_limit: String(this.config.participantLimit),
        },
      };

      sentryLog(["Creating conference", options]);
      this.auviousRtc.createConference(options);
    });
  }

  public joinConference(
    roomName,
    origin: ConversationOriginEnum
  ): Promise<Observable<any>> {
    const endedObservable: Observable<any> = new Observable(
      (o) => (this.endedObserver = o)
    );

    this.local.freezeStreamType = true;

    return new Promise((resolve, reject) => {
      // if we are already joined, do not join again
      if (!!this.session) {
        return resolve(endedObservable);
      }

      sentryLog(["Joining conference", { roomName, origin }]);

      /*
        Stream state order of events:
        - Any event may come first, except streamRecovered which comes after streamWarning
        - Only streamAdded and streamRecovered have new mediaStreams
        - if publishing, streamAdded comes right away, before streamPublished
        - streamFailed is followed by streamRemoved
        - streamRemoved is the last event
      */

      this.auviousRtc.on("newConferenceSession", (session) => {
        this.session = session;

        getCurrentScope().setTag("conferenceId", session.conferenceId);
        sentryLog(["rtc.on.newConferenceSession", session]);
      });

      const eventHandlers: IConferenceSessionEventHandlers = {
        connecting: () => {
          sentryLog("rtc.on.connecting");
          debug(`connecting to conference ${roomName}`);
        },
        accepted: (session: IConferenceSession) => {
          this._endpointJoined.next({
            ...this.myself,
            metadata: this.getMyMetadata(),
          });

          debug("accepted", session);
          sentryLog(["rtc.on.accepted", session]);

          this.publish(this.local.mainStream.stream);

          resolve(endedObservable);

          this.participants[this.myself.endpoint] = {
            ...this.myself,
            metadata: this.getMyMetadata(),
          };
        },
        endpointJoined: (originator: IEndpoint) => {
          try {
            debug("endpoint joined", originator);
            sentryLog([
              "rtc.on.endpointJoined",
              anonymizeIEndpoint(originator),
            ]);

            this.participants[originator.endpoint] = originator;
            this.endpointState[originator.endpoint] = EndpointStateEnum.Joined;

            // check if the endpoint comes from a refresh and has hanging screen share streams available
            if (
              this.screenShareStream &&
              this.screenShareStream.originator.username ===
                originator.username &&
              this.isEndpointSick(this.screenShareStream.id)
            ) {
              this.cleanStream(this.screenShareStream);
            }

            if (!this.endpoints[originator.endpoint]) {
              this.endpoints[originator.endpoint] = {};
            }

            this._endpointState.next(this.endpointState);
            this._endpointJoined.next(originator);
          } catch (ex) {
            this.errorHandler.handleError(ex);
          }
        },
        endpointGotSick: (originator: IEndpoint<IEndpointMetadata>) => {
          debug("endpoint got sick", originator);
          sentryLog(["rtc.on.endpointGotSick", anonymizeIEndpoint(originator)]);

          // ignore for now and handle only stream sick events
          this.endpointState[originator.endpoint] = EndpointStateEnum.Sick;
          this._endpointState.next(this.endpointState);
          this._endpointStateChanged.next({
            endpoint: originator.endpoint,
            state: EndpointStateEnum.Sick,
          });
          debug("active endpoints", this.endpoints);
        },
        endpointRecovered: (originator: IEndpoint<IEndpointMetadata>) => {
          debug("endpoint recovered", originator);
          sentryLog([
            "rtc.on.endpointRecovered",
            anonymizeIEndpoint(originator),
          ]);

          // ignore for now and handle only stream sick events
          this.endpointState[originator.endpoint] = EndpointStateEnum.Joined;
          this._endpointState.next(this.endpointState);
          this._endpointStateChanged.next({
            endpoint: originator.endpoint,
            state: EndpointStateEnum.Joined,
          });
        },
        endpointLeft: (originator: IEndpoint<IEndpointMetadata>) => {
          const participant = this.participants[originator.endpoint];
          // originator.metadata.reason is available here (in case it was provided in session.leave())
          participant.metadata = {
            ...participant.metadata,
            ...originator.metadata,
          };
          try {
            debug("endpoint left", originator);
            sentryLog(["rtc.on.endpointLeft", anonymizeIEndpoint(originator)]);

            if (this.endpoints[originator.endpoint]) {
              Object.values(this.endpoints[originator.endpoint]).forEach(
                (stream) => {
                  this.local.closeMediaStream(stream.mediaStream);
                }
              );

              delete this.endpoints[originator.endpoint];
            }

            delete this.participants[originator.endpoint];

            // mark as left
            this.endpointState[originator.endpoint] = EndpointStateEnum.Left;
            this._endpointState.next(this.endpointState);

            debug("removed participant", originator);
          } catch (ex) {
            this.errorHandler.handleError(ex);
          }

          this._endpointLeft.next(participant || originator);
        },
        publishStreamFailed: (data) => {
          debugError("publishStreamFailed event", data);
          sentryLog([
            "rtc.on.publishStreamFailed",
            { ...data, stream: anonymizeIStream(data.stream) },
          ]);

          this.errorHandler.handleError(data);
          this.alertService.error(
            `publish stream failed for stream ${data.stream.type}. Error ${data.error.message}`
          );
        },
        // fires only for local stream
        streamPublished: (stream: IPublishedStream) => {
          this.streams[stream.id] = stream;
          this.handleStreamAdded(stream);
          this.isPublishingStream = false;
          this._localStreamPublished.next(stream);

          sentryLog(["rtc.on.streamPublished", anonymizeIStream(stream)]);
        },
        // fires for local and remote before streamPublished
        streamAdded: (stream: IStream) => {
          try {
            this.streams[stream.id] = stream;
            sentryLog(["rtc.on.streamAdded", anonymizeIStream(stream)]);

            this.streamState[stream.id] = EndpointStateEnum.Joined;
            this._streamState.next(this.streamState);

            this.handleStreamAdded(stream);

            if (stream.originator.endpoint !== this.myself.endpoint) {
              this.setFacingModeForStream(stream, this.findFacingMode(stream));
            }

            // TODO: rtc should populate metadata
            stream.originator.metadata =
              this.participants[stream.originator.endpoint]?.metadata;

            this._streamAdded.next(stream);
          } catch (ex) {
            this.errorHandler.handleError(ex);
          }
        },
        // could fire before streamAdded
        streamWarning: (stream: IStream) => {
          debug("stream warning", stream);
          sentryLog(["rtc.on.streamWarning", anonymizeIStream(stream)]);

          try {
            // in safari, stream warning comes just before endpoint left, showing 'connection lost'
            // for just a moment, before stream is removed. add a timeout to avoid this.
            if (!this.streamWarningsTimeouts) {
              this.streamWarningsTimeouts = {};
            }
            this.streamWarningsTimeouts[stream.id] = setTimeout(() => {
              this.streamState[stream.id] = EndpointStateEnum.Sick;
              this._streamState.next(this.streamState);
            }, 200);
          } catch (ex) {
            this.errorHandler.handleError(ex);
          }
        },
        streamRecovered: (stream: IStream) => {
          debug("stream recovered", stream);
          sentryLog(["rtc.on.streamRecovered", anonymizeIStream(stream)]);

          try {
            // if we recover before the warning timeout, clear it.
            if (
              !!this.streamWarningsTimeouts &&
              stream.id in this.streamWarningsTimeouts
            ) {
              clearTimeout(this.streamWarningsTimeouts[stream.id]);
              delete this.streamWarningsTimeouts[stream.id];
              debug("cleared timeout", stream.id);
            }
            this.streamState[stream.id] = EndpointStateEnum.Joined;
            this._streamState.next(this.streamState);
            this.handleStreamAdded(stream);
          } catch (ex) {
            this.errorHandler.handleError(ex);
          }
        },
        trackMuted: (stream: IStream) => {
          debug("remote stream muted", stream);
          sentryLog(["rtc.on.trackMuted", anonymizeIStream(stream)]);

          try {
            if (TRACK_KIND in stream) {
              this.mutedStreams[stream.id] = {
                ...this.mutedStreams[stream.id],
                [(stream as IStream)[TRACK_KIND]]: true,
              };
            }

            if (stream.type !== StreamTypes.SCREEN) {
              this.handleStreamAdded(
                this.endpoints[stream.originator.endpoint]?.[stream.type]
              );

              this._streamMuteChange.next({
                stream,
                muted: true,
                trackKind: stream[TRACK_KIND],
              });
            }
          } catch (ex) {
            this.errorHandler.handleError(ex);
          }
        },
        trackUnmuted: (stream: IStream) => {
          debug("remote stream unmuted", stream);
          sentryLog(["rtc.on.trackUnmuted", anonymizeIStream(stream)]);

          try {
            if (!!this.mutedStreams[stream.id] && TRACK_KIND in stream) {
              delete this.mutedStreams[stream.id][
                (stream as IStream)[TRACK_KIND]
              ];
            }

            this.handleStreamAdded(
              this.endpoints[stream.originator.endpoint]?.[stream.type]
            );

            if (stream.type !== StreamTypes.SCREEN) {
              this._streamMuteChange.next({
                stream,
                muted: false,
                trackKind: stream[TRACK_KIND],
              });
            }
          } catch (ex) {
            this.errorHandler.handleError(ex);
          }
        },
        streamRemoved: (stream: IStream) => {
          debug("stream removed", stream, this.endpoints);
          sentryLog(["rtc.on.streamRemoved", anonymizeIStream(stream)]);

          try {
            this._streamRemoved.next(stream);

            if (stream.type !== StreamTypes.SCREEN) {
              // in case a popup is closed and the customer has joined from both the widget and the popup,
              // we will not get an endpointLeft event immediately. So we rely on the streamRemoved to notify
              // the agent
              this._participantLeft.next(
                this.participants[stream.originator.endpoint]
              );

              if (this.endpoints[stream.originator.endpoint]?.[stream.type]) {
                delete this.endpointState[stream.originator.endpoint];
              }
            }

            this.cleanStream(stream);
          } catch (ex) {
            this.errorHandler.handleError(ex);
          }
        },
        holdUpdated: (event: {
          enabled: boolean;
          userId: string;
          userEndpointId: string;
        }) => {
          debug("rtc.on.holdUpdated", event);
          sentryLog(["rtc.on.holdUpdated", event]);

          this.setCallHoldState({
            loading: false,
            isOnHold: event.enabled,
            username: event.userId,
            endpoint: event.userEndpointId,
          });

          // temp fix for:
          // 1. call is on hold
          // 2. rtc mutes camera track
          // 3. rtc camera track is replaced with canvas track by bg filter
          // 4. unhold; canvas track is unmuted
          // 5. but camera track remains muted because state is not yet synced
          const cameraTrack: MediaStreamTrack =
            this.local.pipe.streamIn?.getVideoTracks()[0];
          if (
            !event.enabled &&
            this.localStream?.isMuted("video") === false &&
            cameraTrack?.enabled === false
          ) {
            cameraTrack.enabled = true;
          }
        },
        failed: (error) => {
          sentryLog(["rtc.on.failed", error], "error");
          getCurrentScope().setTag("conferenceId", null);

          if (
            error.response?.status === 404 ||
            (error.response?.status === 403 &&
              error.response?.data.error === "participant limit reached")
          ) {
            // don't log error on sentry
          } else if (this.endedObserver) {
            this.alertService.error("Could not join call.", { ttl: 3000 });
            this.errorHandler.handleError(error);
            this.endedObserver.error(error);
            this.endedObserver = null;
          } else {
            this.errorHandler.handleError(error);
          }

          debug("conference failed", error.data?.message ?? error.message);
          reject(error);
        },
        ended: (payload: any) => {
          debug("conference session ended");
          sentryLog(["rtc.on.ended", payload]);
          getCurrentScope().setTag("conferenceId", null);

          this.session = null;
          if (!!this.endedObserver) {
            this.endedObserver.next(TerminateReasonEnum.terminate);
            this.endedObserver = null;
          }
        },
        networkQualityReport: (report: INetworkQualityReportEvent) => {
          this._networkQuality.next(report);
        },
        streamMetadataUpdated: (event: IStreamMetadataChangedEvent) => {
          sentryLog(["rtc.on.streamMetadataUpdated", event]);
          this._streamMetadataChanged.next(event);
        },
        endpointMetadataUpdated: (event: IEndpointMetadataChangedEvent) => {
          sentryLog(["rtc.on.endpointMetadataUpdated", event]);
          this.participants[event.userEndpointId].metadata = {
            ...this.participants[event.userEndpointId].metadata,
            ...event.newMetadata,
          };
          this._endpointMetadataChanged.next(event);
        },
        conferenceMetadataRemoved: (event: IConferenceMetadataUpdatedEvent) => {
          if (this.conferenceMetadata.has(event.key)) {
            sentryLog([
              "rtc.on.conferenceMetadataRemoved",
              { ...event, value: undefined },
            ]);
            const meta = this.conferenceMetadata.get(event.key);
            this.conferenceMetadata.delete(event.key);

            if (event.userId !== this.myself.username) {
              this.conferenceMetadataQueue.delete(event.key);
              this._conferenceMetadataRemoved.next(meta);
            }
          }
        },
        conferenceMetadataSet: (event: IConferenceMetadataUpdatedEvent) => {
          const meta = ConferenceMetadataFactory.fromEvent(event);
          if (meta) {
            sentryLog([
              "rtc.on.conferenceMetadataSet",
              { ...event, value: undefined },
            ]);
            this.conferenceMetadata.set(event.key, meta);
            this.tryNotifyForMetadata(meta);
          }
        },
      };

      try {
        const metadata: IEndpointMetadata = {
          ...this.getMyMetadata(),
          origin,
        };

        const getAbraMetadata = (
          viewer: boolean,
          firstReducePublisherVideoBitrate: number = DEFAULT_ABR_VIEWER.firstReducePublisherVideoBitrate,
          minSecondsBeforeStoppingVideo: number = DEFAULT_ABR_VIEWER.minSecondsBeforeStoppingVideo,
          minSecondsBeforeStartingVideo: number = DEFAULT_ABR_VIEWER.minSecondsBeforeStartingVideo
        ) => ({
          "automatic-bandwidth-adaptation": viewer ? "enabled" : "disabled",
          "automatic-bandwidth-adaptation.first-reduce-publisher-video-bitrate": `${firstReducePublisherVideoBitrate}`,
          "automatic-bandwidth-adaptation.min-seconds-before-stopping-video": `${minSecondsBeforeStoppingVideo}`,
          "automatic-bandwidth-adaptation.min-seconds-before-starting-video": `${minSecondsBeforeStartingVideo}`,
        });

        let viewStreamMetadataProvider: ViewStreamMetadataProvider;

        const callQuality = this.local.getCallQualityOptions();
        if (callQuality && callQuality.viewer) {
          viewStreamMetadataProvider = (stream) =>
            getAbraMetadata(
              callQuality.viewer.autoAdapt,
              callQuality.viewer.firstReducePublisherVideoBitrate,
              callQuality.viewer.minSecondsBeforeStoppingVideo,
              callQuality.viewer.minSecondsBeforeStartingVideo
            );
        } else {
          const abra: IAutomaticBitrateAdaptationViewerPublicParams =
            this.config.publicParam(
              PublicParam.AUTOMATIC_BITRATE_ADAPTATION_VIEWER
            );

          if (abra) {
            viewStreamMetadataProvider = (stream) =>
              getAbraMetadata(
                abra.viewer,
                abra.firstReducePublisherVideoBitrate,
                abra.minSecondsBeforeStoppingVideo,
                abra.minSecondsBeforeStartingVideo
              );
          }
        }

        this.zone.runOutsideAngular(() =>
          this.auviousRtc
            .joinConference({
              id: roomName,
              eventHandlers,
              statistics: this.config.statistics,
              metadata,
              viewStreamMetadataProvider,
            })
            // avoid sentry logging, is handled in onfailed above
            .catch((ex) => debugError(ex))
        );
      } catch (ex) {
        this.errorHandler.handleError(ex);
        reject(ex);
      }
    });
  }

  private getMyMetadata(): IEndpointMetadata {
    const activeUser = this.userService.getActiveUser();
    const activeUserDetails = this.userService.getUserDetails();
    const roles = activeUser.getRoles();

    return {
      roles,
      capabilities: activeUser.getCapabilities(),
      language: this.config.language,
      type: EndpointTypeEnum.stream,
      mediaDevices: MediaDevices.getDeviceList(),
      name: activeUserDetails?.displayName || activeUserDetails?.name,
      avatarUrl: activeUserDetails?.avatarUrl,
      termsAccepted: activeUser.getClaim(CLAIM_TERMS_ACCEPTED),
      termsAcceptedAt: activeUser.getClaim(CLAIM_TERMS_ACCEPTED_AT),
    };
  }

  private async runPreTerminateHooks() {
    try {
      return Promise.all(this.preTerminateHooks.map((hook) => hook.run()));
    } catch (ex) {
      debugError(ex);
    }
  }

  public async terminateSession(reason?: TerminateReasonEnum) {
    try {
      await this.runPreTerminateHooks();

      this.session?.terminate(reason);
      this.session = null;

      this.endedObserver?.next(reason);
      this.endedObserver = null;
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }

  public async leaveSession(reason?: TerminateReasonEnum) {
    try {
      await this.runPreTerminateHooks();

      this.session?.leave(reason);
      this.session = null;

      this.endedObserver?.next(reason);
      this.endedObserver = null;
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }

  public get sessionExists(): boolean {
    return !!this.session;
  }

  public updateEndpointMetadata(metadata: IEndpointMetadata): Promise<void> {
    const existingMetadata = this.participants[this.myself.endpoint]?.metadata;
    const nuMetadata = { ...existingMetadata, ...metadata };
    this.participants[this.myself.endpoint].metadata = nuMetadata;
    return this.session.updateMetadata(nuMetadata);
  }

  public getStreamMetadata(streamId: string): IStreamMetadata | undefined {
    return this.streams[streamId]?.getMetadata();
  }

  public updateStreamMetadata(
    stream: IPublishedStream,
    metadata: IStreamMetadata
  ): void {
    const meta = { ...stream.getMetadata(), ...metadata };
    stream.updateMetadata(meta);
    this._streamMetadataChanged.next({
      userId: this.myself.username,
      userEndpointId: this.myself.endpoint,
      streamId: stream.id,
      newMetadata: meta,
    });
  }

  public setConferenceMetadata(data: BaseMetadata): Promise<void> {
    return this.session?.setConferenceMetadata(data.key, data.value);
  }

  public removeConferenceMetadata(
    key: ConferenceMetadataKeyEnum
  ): Promise<void> {
    return this.session?.removeConferenceMetadata(key);
  }

  public getConferenceMetadata(
    key: ConferenceMetadataKeyEnum
  ): IConferenceMetadata {
    return this.conferenceMetadata.get(key);
  }

  private tryNotifyForMetadata(data: BaseMetadata): boolean {
    // do not deliver to myself or if the data is not an instance of know metadata classes
    if (
      data.userId === this.myself.username ||
      !(data instanceof BaseMetadata)
    ) {
      return;
    }

    // check if target must be joined to deliver
    const targetExists =
      data instanceof PointerMetadata || data instanceof SnapshotMetadata
        ? !!this.endpoints[data.target.endpoint]
        : true;

    const senderExists =
     // for recordings that start with an API call, the api participant that started the recorder leaves the call
      data instanceof RecorderMetadata || data instanceof IntegrationMetadata
        ? true
        : !!this.endpoints[data.userEndpointId];
    const exists = targetExists && senderExists;

    if (exists) {
      debug("notify metadata: exists", data);

      // todo: wait for animation to end to fire this, not use timeout
      setTimeout((_) => {
        this._conferenceMetadataSet.next(data);
      }, 200);
    } else {
      debug("notify metadata: not here", data);
      this.conferenceMetadataQueue.set(data.key, data);
    }

    return exists;
  }

  public checkQueueForPendingMetadata() {
    this.conferenceMetadataQueue.forEach((data, key) => {
      if (this.tryNotifyForMetadata(data)) {
        debug("notify metadata: notified", data);
        this.conferenceMetadataQueue.delete(key);
      }
    });
  }

  public get isConferenceOnHold(): boolean {
    return this.session?.isOnHold();
  }

  public toggleCallHold(): Promise<void> {
    if (!this.session) {
      return;
    }
    if (this.session.isOnHold()) {
      return this.session
        .unhold()
        .then((_) => {
          this.setCallHoldState({ loading: true, isOnHold: false });
          Promise.resolve();
        })
        .catch((err) => {
          this.alertService.error("call-unhold-failed");
          return Promise.reject(err);
        });
    } else {
      return this.session
        .hold()
        .then((_) => {
          this.setCallHoldState({ loading: true, isOnHold: true });
          Promise.resolve();
        })
        .catch((err) => {
          this.alertService.error("call-hold-failed");
          return Promise.reject(err);
        });
    }
  }

  private setCallHoldState(state: ICallHoldState) {
    this.callHoldState = { ...this.callHoldState, ...state };
    this._callHoldState.next(this.callHoldState);
  }

  public getCallHoldState(): ICallHoldState {
    return this.callHoldState;
  }

  public bindElement(element: HTMLAudioElement | HTMLVideoElement, id: string) {
    const stream = this.getStream(id);

    if (!stream) {
      return;
    }

    const existing = Object.entries(this.streamElements).find(
      ([_, elm]) => elm === element
    );

    if (existing && existing[0] !== id) {
      delete this.streamElements[existing[0]];
    }

    this.streamElements[id] = element;
    debug("registered element for stream", element, stream);

    if (stream === this.local.mainStream.stream) {
      this.local.pipe.sink.setSink(element);
    } else {
      element.srcObject = stream.mediaStream;
    }

    requestAnimationFrame(() =>
      MediaDevices.syncSpeaker(
        // @ts-expect-error
        element
      ).catch(debugError)
    );
  }

  public unbindElement(
    element: HTMLAudioElement | HTMLVideoElement,
    id: string
  ) {
    if (element) {
      MediaDevices.desyncSpeaker(
        // @ts-expect-error
        element
      ).catch(() => {});
    }

    debug("unregistered element for stream", id);
  }

  public getElementById(id: string): HTMLVideoElement | HTMLAudioElement {
    return this.streamElements[id];
  }

  /** get any published or not stream */
  public getStream(id: string) {
    return (
      this.streams[id] ||
      (id === this.local.mainStream.stream?.id
        ? this.local.mainStream.stream
        : id === this.local.screenStream.stream?.id
        ? this.local.screenStream.stream
        : null)
    );
  }

  public getPublishedStream(id: string) {
    return this.streams[id];
  }

  /** shows user play button */
  public requestExplicitPlay() {
    this._streamsNeedPlay.next(true);
  }

  public playPausedStreams() {
    this._playPausedStreams.next(null);
  }

  private republishTimeout = -1;

  private async onLocalStreamError(event: {
    stream: IPublishedStream;
    error: Error;
  }) {
    this.errorHandler.handleError(event.error);

    if (event.stream.type !== StreamTypes.SCREEN) {
      if (this.republishTimeout !== -1) {
        clearTimeout(this.republishTimeout);
      }

      // in case of too many errors
      this.republishTimeout = window.setTimeout(() => {
        this.republishTimeout = -1;
        this.republish();
      }, 4000);
    }
  }

  public async republish() {
    this.unpublishStream(this.local.mainStream.stream?.type);
    this.publish(this.local.mainStream.stream);
  }

  public async publish(stream: IStream) {
    try {
      if (
        !stream ||
        this.isPublishingStream ||
        !this.session?.isEstablished()
      ) {
        return;
      }

      this.isPublishingStream = true;

      const streamToPublish: IPublishOptions = {
        mediaStream: new MediaStream(stream.mediaStream.getTracks()),
        type: stream.type,
        metadata: stream.getMetadata(),
      };

      this._localStreamWillPublish.next(streamToPublish);

      await this.zone.runOutsideAngular(() =>
        this.session.publish(streamToPublish)
      );

      if (stream.type === StreamTypes.SCREEN) {
        this.windowEventService.sendMessage(new DeviceCaptureSuccessAction());
      } else {
        this.setFacingModeForStream(stream, this.local.mainStream.facingMode);
      }
    } catch (ex) {
      debugError(
        `error while trying to get ${stream.type} stream: ${ex.message}`
      );

      if (stream.type === StreamTypes.SCREEN) {
        this.windowEventService.sendMessage(
          new DeviceCaptureErrorAction({ name: ex.name, message: ex.message })
        );
      }

      this.errorHandler.handleError(ex);
      this.alertService.error("Could not publish stream.", { ttl: 3000 });
    } finally {
      this.isPublishingStream = false;
    }
  }

  private unpublishStream(type: string): void {
    const stream = this.endpoints[this.myself.endpoint]?.[type];

    if (stream && this.session?.isEstablished()) {
      this.session.unpublish(stream);
    }
  }

  public unpublishLocalStreams() {
    if (!!this.myself && this.endpoints[this.myself.endpoint]) {
      Object.keys(this.endpoints[this.myself.endpoint]).forEach((key) =>
        this.unpublishStream(key)
      );
    }
  }

  public resolveScreenShareConflict(a: IPublishedStream, b: IPublishedStream) {
    return new Date(a.getMetadata().publishedAt).getTime() >
      new Date(b.getMetadata().publishedAt).getTime()
      ? b
      : a;
  }

  private areStreamsDifferent(a: MediaStream, b: MediaStream) {
    return (
      a !== b ||
      a?.getAudioTracks()[0] !== b?.getAudioTracks()[0] ||
      a?.getVideoTracks()[0] !== b?.getVideoTracks()[0]
    );
  }

  /** @returns - whether handled */
  private handleStreamAdded(
    stream: IStream | IPublishedStream,
    noMuteHandle?: boolean
  ) {
    debug("stream added", stream);
    if (!stream) {
      return;
    }

    // only one screen stream. Handle it seperately
    if (stream.type === StreamTypes.SCREEN) {
      if (
        this.screenShareStream &&
        this.screenShareStream.originator.endpoint !==
          stream.originator.endpoint
      ) {
        if (
          stream ===
          this.resolveScreenShareConflict(
            this.screenShareStream,
            stream as IPublishedStream
          )
        ) {
          if (this.isScreenShareStreamLocal) {
            this.local.closeScreenStream();
          } else {
            this.cleanStream(this.screenShareStream);
          }
        } else {
          // someone came second, they will close the stream soon
          return false;
        }
      }

      this.screenShareStream = stream as IPublishedStream;
    }

    if (stream.originator.endpoint === this.myself.endpoint) {
      this.local.replaceStream(stream as IPublishedStream);
    } else if (
      this.streamElements[stream.id] &&
      this.areStreamsDifferent(
        this.streamElements[stream.id].srcObject as MediaStream,
        stream.mediaStream
      )
    ) {
      const srcObject = this.streamElements[stream.id].srcObject as MediaStream;
      let oldTrack = srcObject?.getVideoTracks()[0];
      let newTrack = stream.mediaStream?.getVideoTracks()[0];

      if (oldTrack !== newTrack) {
        // video will flicker anyway
        this.streamElements[stream.id].srcObject = stream.mediaStream;
      } else {
        oldTrack = srcObject?.getAudioTracks()[0];
        newTrack = stream.mediaStream?.getAudioTracks()[0];

        if (oldTrack !== newTrack) {
          if (oldTrack) srcObject?.removeTrack(oldTrack);
          if (newTrack) srcObject?.addTrack(newTrack);
        }
      }
    }

    this.endpoints[stream.originator.endpoint] ??= {};

    // if incoming stream is already sick (leftover from previous tab), discard it
    if (
      this.endpointState[stream.originator.endpoint] === EndpointStateEnum.Sick
    ) {
      return false;
    }

    this.endpoints[stream.originator.endpoint] = {
      ...this.endpoints[stream.originator.endpoint],
      [stream.type]: stream,
    };

    if (stream.type === StreamTypes.SCREEN && !this.isScreenShareStreamLocal) {
      // TODO isWidget || isPopup
      this.windowEventService.sendMessage(
        new ScreenShareAvailabilityChangedAction(true)
      );
    }

    // TODO: why?
    // if (!noMuteHandle && this.isMutedWhileSwitchingCam) {
    //   setTimeout(() => {
    //     this.mute(StreamTrackKindEnum.audio).then(() => {
    //       this.isMutedWhileSwitchingCam = undefined;
    //       this._endpointState.next({ ...this.endpointState });
    //     });
    //   }, 300);
    // }

    return true;
  }

  public closeLocalStreams() {
    this.local.closeMainStream();

    if (this.myself && this.endpoints[this.myself.endpoint]) {
      Object.values(this.endpoints[this.myself.endpoint]).forEach((stream) => {
        this.cleanStream(stream);
      });
    }

    if (this.local.screenStream) {
      this.local.closeScreenStream();
    }
  }

  public get localMediaStream(): MediaStream | null {
    return this.localStream?.mediaStream || null;
  }

  public get localStream(): IPublishedStream | null {
    return this.local.mainStream.stream;
  }

  public get isScreenShareStreamLocal() {
    return this.screenShareStream?.originator.endpoint === this.myself.endpoint;
  }

  public isEndpointSick(stream: string) {
    return this.streamState[stream] === EndpointStateEnum.Sick;
  }

  public isMutedStream(
    stream: IStream | IPublishedStream,
    kind: StreamTrackKindEnum
  ): boolean {
    if (!stream) {
      return false;
    } else if (stream.isMuted) {
      return stream.isMuted(kind);
    } else if (
      stream.id in this.mutedStreams &&
      kind in this.mutedStreams[stream.id]
    ) {
      return this.mutedStreams[stream.id][kind];
    } else if (stream.type !== StreamTypes.VIDEO) {
      return (
        (kind === StreamTrackKindEnum.video &&
          stream.type === StreamTypes.MIC) ||
        (kind === StreamTrackKindEnum.audio && stream.type === StreamTypes.CAM)
      );
    } else {
      return false;
    }
  }

  public isMutedStreamId(id: string, kind: StreamTrackKindEnum): boolean {
    const stream = this.streams[id];

    if (!stream) {
      return false;
    } else if (stream.isMuted) {
      return stream.isMuted(kind);
    } else if (
      stream.id in this.mutedStreams &&
      kind in this.mutedStreams[stream.id]
    ) {
      return this.mutedStreams[stream.id][kind];
    } else if (stream.type !== StreamTypes.VIDEO) {
      return (
        (kind === StreamTrackKindEnum.video &&
          stream.type === StreamTypes.MIC) ||
        (kind === StreamTrackKindEnum.audio && stream.type === StreamTypes.CAM)
      );
    } else {
      return false;
    }
  }

  public terminateConferenceRequest(
    reason: TerminateReasonEnum = TerminateReasonEnum.terminate
  ) {
    this._terminateConference.next(reason);
  }

  public confirmTerminateConferenceRequest() {
    this._confirmTerminateConference.next();
  }

  private reset() {
    this.streamElements = {};
    this.endpoints = {};
    this.endpointState = {};
    this.streamState = {};
    this.participants = {};
    this.mutedStreams = {};
    this.streamWarningsTimeouts = {};
    this.callHoldState = { ...DEFAULT_CALL_HOLD_STATE };
    this.preTerminateHooks = [];
  }

  public destroy() {
    this.local.freezeStreamType = false;

    this.reset();
    this.conferenceMetadata.clear();
    this.conferenceMetadataQueue.clear();
    this._endpointMetadataChanged.next(null);
    this._conferenceMetadataSet.next(null);
    this._localStreamWillPublish.next(null);
    this._localStreamPublished.next(null);
    this._endpointStateChanged.next({
      endpoint: this.myself.endpoint,
      state: EndpointStateEnum.Left,
    });
  }

  public getParticipants() {
    return this.participants;
  }

  public getStreamsForEndpoint(endpoint: string): {
    [streamType: string]: IConferenceStream;
  } {
    return this.endpoints[endpoint];
  }

  private findFacingMode(stream: IStream) {
    if (StreamTypes.CAM === stream.type || StreamTypes.VIDEO === stream.type) {
      const metadata = this.local.getStreamMetadata(stream.mediaStream);

      // iOS 14.x has facingMode in settings
      // chrome 92.x has facingMode in constraints
      // firefox 91.x has facingMode in settings
      // safari 14.x has NO facingMode
      return metadata.video?.constraints?.facingMode ||
        metadata.video?.settings?.facingMode ||
        (stream.originator.endpoint === this.myself.endpoint &&
          this.local.pipe.streamOut === stream.mediaStream)
        ? this.local.pipe.input.facingMode
        : VideoFacingModeEnum.User;
    } else {
      return VideoFacingModeEnum.Environment;
    }
  }

  public getFacingModeForStream(stream: IStream) {
    return this.streamFacingMode.get(stream);
  }

  public setFacingModeForStream(
    stream: IStream,
    facingMode: VideoFacingModeEnum
  ) {
    this.streamFacingMode.set(stream, facingMode);
    this._facingModeChanged.next({ streamId: stream.id, facingMode });
  }

  public get myself(): IEndpoint {
    return this.rtcService.myself;
  }
}
