<div
  [attr.aria-label]="'Elapsed time' | translate"
  class="clock fx-row fx-align-center fx-justify-start"
>
  <ng-container *ngIf="!!hours">
    <div>{{ hours }}</div>
    <div>:</div>
  </ng-container>
  <div>{{ minutes }}</div>
  <div [class.not-visible]="secondOff">:</div>
  <div>{{ seconds }}</div>
</div>
