import {
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from "@angular/core";
import { Subscription } from "rxjs";
import { CardComponent } from "../card/card.component";

@Component({
  selector: "av-card-footer",
  templateUrl: "./card-footer.component.html",
  styleUrls: ["./card-footer.component.scss"],
})
export class CardFooterComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  constructor(@Optional() private parent?: CardComponent) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public get type() {
    return this.parent?.type;
  }
}
