<div class="panel-row-responsive">
  <div
    #rowPrimary
    class="panel-row-primary fx-row fx-align-center fx-justify-start"
  >
    <div>
      <ng-content select="[card-row-prefix]"></ng-content>
    </div>
    <av-tooltip *ngIf="icon" [enabled]="!!iconTooltip" [message]="iconTooltip">
      <av-icon [name]="icon" class="card-row-icon"></av-icon>
    </av-tooltip>
    <div class="fx-column fx-column-title">
      <h3>
        <ng-content select="[card-row-title]"></ng-content>
      </h3>
      <small class="text-muted" translate>
        <ng-content select="[card-row-subtitle]"></ng-content>
      </small>
    </div>
  </div>
  <div
    *ngIf="!bodyHidden"
    [class.panel-row-secondary-offset]="!!icon"
    class="panel-row-secondary fx-row fx-align-center"
  >
    <ng-content select="[card-row-body]"></ng-content>
  </div>
</div>
<div class="fx-column-last fx-row fx-align-center fx-justify-end">
  <ng-content select="[card-row-actions]"></ng-content>
</div>
