import {
  IConfigOptions,
  IContactInfo,
  IDCMessageHistoryResponse,
  IMessage,
} from "@auvious/integrations";
import { ITicket } from "../../../../core-ui/models";
import { IConversationStrategy } from "../../../../core-ui/models/strategies/IConversationStrategy";
import { KEY_ROOM_NAME, KEY_APPOINTMENT_ID } from "../../../app.enums";
import { IScheduledAppointmentContact, UserService } from "../../../../core-ui";
import {
  IAppointment,
  IAppointmentRoutingTalkdeskDC,
} from "../../../../core-ui/models/Appointment";
import { HttpClient } from "@angular/common/http";
import { DigitalConnectInteraction } from "../../DigitalConnectInteraction";
import { firstValueFrom } from "rxjs";
import { CLAIM_TALKDESK_ACCESS_TOKEN } from "../../../app.enums";

export class TalkdeskConversationStrategy implements IConversationStrategy {
  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) {}

  prepareConversationConfig(
    ticket: ITicket,
    appointment: IAppointment<IAppointmentRoutingTalkdeskDC>
  ): IConfigOptions {
    const config: IConfigOptions = {
      dcTouchpointId: appointment.interaction.routing.properties.touchpointId,
      pcOrganizationId: ticket.properties.organizationId,
      auviousEnvironment: location.host,
      applicationId: appointment.applicationId,
      chatMode: "talkdesk-digital-connect",
      kioskMode: false,
    };
    return config;
  }

  prepareConversationRequest(
    ticket: ITicket,
    appointment: IAppointment<IAppointmentRoutingTalkdeskDC>,
    contact: IScheduledAppointmentContact
  ): IContactInfo {
    const info: IContactInfo = {
      firstName: contact?.name?.firstName,
      lastName: contact?.name?.lastName,
      email: contact?.contact?.primaryEmail || "customer@auvious.com",
      subject: appointment.title,
      [KEY_ROOM_NAME]: appointment.interaction.routing.properties.conferenceId,
      [KEY_APPOINTMENT_ID]: ticket.properties.appointmentId,
      ...appointment.interaction.customFields,
    };
    return info;
  }

  leaveConversation(interaction: DigitalConnectInteraction) {
    return;
  }

  async getMessages(
    interaction: DigitalConnectInteraction
  ): Promise<IMessage[]> {
    const messages: IMessage[] = [];
    try {
      const response = await firstValueFrom(
        this.httpClient.get(
          `api/digital-connect/talkdesk/conversations/${interaction.getId()}/messages`,
          {
            headers: {
              Authorization: `Bearer ${this.userService
                .getActiveUser()
                .getClaim(CLAIM_TALKDESK_ACCESS_TOKEN)}`,
              "Content-Type": "application/json",
            },
          }
        )
      );

      return (response as IDCMessageHistoryResponse[]).map((msg) => ({
        id: msg.messageId,
        senderId:
          msg.author === "CUSTOMER"
            ? this.userService.getActiveUser().getId()
            : "AGENT",
        senderName: msg.name,
        text: msg.content,
        type: "text",
        isLocal: msg.author === "CUSTOMER",
        status: "ack",
        timestamp: new Date(msg.created),
      }));
    } catch (ex) {
      throw ex;
    }
  }
}
