<div class="container container-responsive">
  <av-card [backdrop]="false" *ngIf="applications.length > 0" class="apps">
    <av-card-header>
      <span translate>Choose your integration</span>
    </av-card-header>
    <av-card-body>
      <av-menu>
        <av-menu-item (click)="pickInstance(a)" *ngFor="let a of applications">
          {{ a.instance.name }}
        </av-menu-item>
      </av-menu>
    </av-card-body>
  </av-card>
</div>
