<av-menu class="expansion-header">
  <av-menu-item
    arrowType="toggle"
    [arrow]="true"
    [expanded]="isOpen"
    mode="dark"
    (select)="isOpen = !isOpen"
  >
    {{ title }}
  </av-menu-item>
</av-menu>
<div class="expansion-body" [class.expansion-body-open]="isOpen">
  <ng-content></ng-content>
</div>
