import { Injectable } from "@angular/core";
import { ApiResource } from "@auvious/common";
import { AuviousRtcService } from "./rtc.service";
import {
  AppointmentEventType,
  AppointmentParticipantStateEnum,
  AppointmentViewState,
  IAppointmentContact,
  IAppointmentRouting,
} from "../models/Appointment";
import { PageableRequest, IPagedResponse } from "../models/Pageable";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { IAppointmentLocale } from "../models/Appointment";
import {
  IAppointmentNotification,
  IAppointmentFormat,
} from "../models/Appointment";
import {
  IAppointment,
  IScheduleAppointmentRequest,
  IScheduleAppointmentOptions,
  IScheduledAppointmentContact,
} from "../models/Appointment";
import { GenericErrorHandler } from "./error-handlers.service";
import { ApplicationService } from "./application.service";
import {
  IWebhookSubscription,
  IWebhookSubscriptionBasicAuth,
  IWebhookSubscriptionClientCredentials,
} from "../models/Webhooks";
import { WebhookSubscriptionAuthenticationEnum } from "../core-ui.enums";
import { debug } from "./utils";
import moment from "moment";

@Injectable()
export class AppointmentService {
  private tagFormats: IAppointmentFormat[] = [];
  private supportedLocales: IAppointmentLocale[] = [];

  private appointmentResource: ApiResource;
  private notificationResource: ApiResource;

  // this should be behavioursubject because as a customer,
  // it is being set by the guard and then read by the appointment page
  // this is also used in CRUD operations while scheduling
  private _appointmentChanged = new BehaviorSubject<
    IAppointment<IAppointmentRouting>
  >(undefined);
  public appointmentChanged$ = this._appointmentChanged.asObservable();

  private _appointmentCreated = new Subject<string>();
  public appointmentCreated$ = this._appointmentCreated.asObservable();

  private _appointmentStateChanged = new Subject<{
    appointmentId: string;
    type: AppointmentEventType;
  }>();
  public appointmentStateChanged$ =
    this._appointmentStateChanged.asObservable();

  private _appointmentParticipantStateChanged = new Subject<{
    appointmentId: string;
    type: AppointmentEventType;
  }>();
  public appointmentParticipantStateChanged$ =
    this._appointmentParticipantStateChanged.asObservable();

  constructor(
    private rtcService: AuviousRtcService,
    private errorHandler: GenericErrorHandler,
    private applicationService: ApplicationService
  ) {
    this.rtcService.common$.subscribe((common) => {
      this.appointmentResource = common.apiResourceFactory("api/appointments");
      this.notificationResource =
        common.apiResourceFactory("api/notifications");
    });
  }

  public notifyChange(data: IAppointment<IAppointmentRouting>) {
    this._appointmentChanged.next(data);
  }

  public query(
    query: PageableRequest
  ): Promise<IPagedResponse<IAppointment<IAppointmentRouting>>> {
    query.set(
      "applicationId",
      this.applicationService.getActiveApplication().getId()
    );
    return this.appointmentResource
      .get({
        params: query.getParams(),
        urlPostfix: "/",
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public request(
    data: IScheduleAppointmentRequest
  ): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource
      .create(data, { urlPostfix: "/" })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public update(
    data: IScheduleAppointmentOptions
  ): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource
      .update(data, { urlPostfix: "/" })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   * Updates the state of the specified appointment and sends a related MQTT event
   * @param appointmentId Appointment id
   * @param state inprogress: CUSTOME & AGENT roles, end: AGENT role
   * @returns
   */
  public updateState(appointmentId: string, state: "inprogress" | "end") {
    return this.appointmentResource
      .update(undefined, {
        urlPostfix: `/${appointmentId}/${state}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public updateParticipantState(
    appointmentId: string,
    participantId: string,
    participantState: AppointmentParticipantStateEnum
  ) {
    return this.appointmentResource
      .update(
        { state: participantState },
        {
          urlPostfix: `/${appointmentId}/participants/${participantId}`,
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getViewState(
    appointment: IAppointment<IAppointmentRouting>
  ): AppointmentViewState {
    const customerState = appointment.participantIds.find(
      (p) => p.type === "CUSTOMER"
    ).state;

    const agentState =
      appointment.participantIds.find((p) => p.type === "AGENT")?.state ||
      "UNSET";

    switch (appointment.interaction.state) {
      case "PENDING":
        // check if end time passed
        return moment(appointment.start)
          .add(appointment.duration)
          .isBefore(moment())
          ? "EXPIRED"
          : "PENDING";

      case "COMPLETE":
        return "COMPLETED";
      case "IN_PROGRESS":
        // end time has passed
        if (
          moment().isAfter(moment(appointment.start).add(appointment.duration))
        ) {
          return customerState === "LEFT"
            ? agentState === "UNSET"
              ? "MISSED"
              : "ONGOING"
            : "OVERRUNING";
        } else if (
          // we are between start and end
          moment().isBetween(
            moment(appointment.start).subtract(1, "minute"),
            moment(appointment.start).add(appointment.duration)
          )
        ) {
          return "ONGOING";
        } else {
          return "ONGOING";
        }
    }
  }

  public remove(id: string): Promise<void> {
    return this.appointmentResource.deleteById(id).catch((ex) => {
      this.errorHandler.handleNotAuthenticatedError(ex);
      throw ex;
    });
  }

  public get(id: string): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource.getById(id).catch((ex) => {
      this.errorHandler.handleNotAuthenticatedError(ex);
      throw ex;
    });
  }

  public removeParticipant(
    appointmentId: string,
    participantId: string
  ): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource
      .delete({
        urlPostfix: `${appointmentId}/participants/${participantId}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getParticipantDetails(
    appointmentId: string,
    participantId: string
  ): Promise<IScheduledAppointmentContact> {
    return this.appointmentResource
      .get({
        urlPostfix: `${appointmentId}/participants/${participantId}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public addAgentToAppointment(
    id: string,
    userId: string
  ): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource
      .create(
        {
          id,
          userId,
        },
        {
          urlPostfix: "participants/agents/",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public addContactToAppointment(
    id: string,
    contact: IAppointmentContact
  ): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource
      .create(
        {
          id,
          customer: contact,
        },
        {
          urlPostfix: "participants/customers/",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public reassignAppointment(
    id: string,
    userId: string
  ): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource
      .update(
        {
          id,
          userId,
        },
        {
          urlPostfix: "reassign/",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public rescheduleAppointment(
    id: string,
    duration: string,
    start: Date,
    timezone: string,
    notifications: IAppointmentNotification[]
  ): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource
      .update(
        {
          id,
          duration,
          start,
          timezone,
          notifications,
        },
        {
          urlPostfix: "reschedule/",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public setAppointmentStateEnded(
    appointmentId: string
  ): Promise<IAppointment<IAppointmentRouting>> {
    return this.appointmentResource
      .update(null, {
        urlPostfix: `${appointmentId}/end`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public activate(appointmentId: string): Promise<{ url: string }> {
    return this.appointmentResource
      .create(null, {
        urlPostfix: `activate/${appointmentId}`,
      })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param applicationId current application id
   * @param code OTP you got from veriy
   * @returns 204 OK
   */
  public confirmSMSNotification(
    applicationId: string,
    code: string
  ): Promise<void> {
    return this.notificationResource
      .create(
        {
          applicationId,
          code,
        },
        {
          urlPostfix: "sms/confirm",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   *
   * @param applicationId current application id
   * @param code OTP you got from veriy
   * @returns 204 OK
   */
  public confirmEmailNotification(
    applicationId: string,
    code: string
  ): Promise<void> {
    return this.notificationResource
      .create(
        {
          applicationId,
          code,
        },
        {
          urlPostfix: "emails/confirm",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   * Sends an SMS with an OTP to be confirmed
   *
   * @param applicationId current application id
   * @param fromNumber Provisioned SMS number
   * @param toNumber testing device SMS number
   * @returns an OTP to be provided to "confirm" flow
   */
  public verifySMSNotification(
    applicationId: string,
    fromNumber: string,
    toNumber: string
  ): Promise<{ applicationId: string; validFor: string }> {
    return this.notificationResource
      .create(
        {
          applicationId,
          fromNumber,
          toNumber,
        },
        {
          urlPostfix: "sms/verify",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /**
   * Sends an email with an OTP to be confirmed
   *
   * @param applicationId current application id
   * @param fromNumber Provisioned SMS number
   * @param toNumber testing device SMS number
   * @returns an OTP to be provided to "confirm" flow
   */
  public verifyEmailNotification(
    applicationId: string,
    domain: string,
    fromName: string,
    toEmail: string
  ): Promise<{ applicationId: string; validFor: string }> {
    return this.notificationResource
      .create(
        {
          applicationId,
          domain,
          fromName,
          toEmail,
        },
        {
          urlPostfix: "emails/verify",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public testSMSNotification(
    applicationId: string,
    toNumber: string,
    notification: IAppointmentNotification,
    locale: string,
    timezone: string
  ) {
    return this.notificationResource
      .create(
        {
          applicationId,
          toNumber,
          locale,
          timezone,
          ...notification,
        },
        {
          urlPostfix: "sms/test",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public testWebhookNotification(
    applicationId: string,
    notification: IAppointmentNotification,
    locale: string,
    timezone: string,
    baseUrl: string,
    subscription?: IWebhookSubscription
  ) {
    const body = {
      applicationId,
      timezone,
      locale,
      type: notification.type,
      period: notification.period,
      properties: notification.properties,
      baseUrl: baseUrl,
      authentication:
        subscription?.authentication ||
        WebhookSubscriptionAuthenticationEnum.NONE,
      oAuth2ClientCredentials: undefined,
      basicAuth: undefined,
    };
    switch (subscription?.authentication) {
      case WebhookSubscriptionAuthenticationEnum.OAUTH2_CLIENT_CREDENTIALS:
        body.oAuth2ClientCredentials = (
          subscription as IWebhookSubscriptionClientCredentials
        ).oAuth2ClientCredentials;
        break;
      case WebhookSubscriptionAuthenticationEnum.HTTP_BASIC:
        body.basicAuth = (
          subscription as IWebhookSubscriptionBasicAuth
        ).basicAuth;
    }

    return this.notificationResource
      .create(body, { urlPostfix: "webhooks/test" })
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public testEmailNotification(
    applicationId: string,
    toEmail: string,
    notification: IAppointmentNotification,
    locale: string,
    timezone: string
  ) {
    return this.notificationResource
      .create(
        {
          applicationId,
          toEmail,
          locale,
          timezone,
          ...notification,
        },
        {
          urlPostfix: "emails/test",
        }
      )
      .catch((ex) => {
        this.errorHandler.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public async getTagFormats(): Promise<IAppointmentFormat[]> {
    if (this.tagFormats?.length === 0) {
      try {
        const page = await this.notificationResource
          .get({
            urlPostfix: "tags",
          })
          .catch((ex) => {
            this.errorHandler.handleNotAuthenticatedError(ex);
            throw ex;
          });
        this.tagFormats = page.content;
      } catch (ex) {}
    }
    return this.tagFormats;
  }

  public async getSupportedLocales(): Promise<IAppointmentLocale[]> {
    if (this.supportedLocales?.length === 0) {
      try {
        const page = await this.notificationResource
          .get({
            urlPostfix: "locales",
          })
          .catch((ex) => {
            this.errorHandler.handleNotAuthenticatedError(ex);
            throw ex;
          });
        this.supportedLocales = page.content;
      } catch (ex) {}
    }
    return this.supportedLocales;
  }

  public propagateEvent(event: {
    eventId: string;
    type: AppointmentEventType;
    appointmentId: string;
    timestamp: string;
  }) {
    switch (event.type) {
      case "APAgentJoinedEvent":
      case "APAgentLeftEvent":
      case "APCustomerJoinedEvent":
      case "APCustomerLeftEvent":
      case "APCustomerWaitingEvent":
        debug("appointment participant state changed", event);
        this._appointmentParticipantStateChanged.next(event);
        break;

      case "APRescheduledEvent":
      case "APReassignedEvent":
      case "APInProgressEvent":
      case "APCompletedEvent":
      case "APDeletedEvent":
      case "APExpiredEvent":
      case "APUpdatedEvent":
        debug("appointment state changed", event);
        this._appointmentStateChanged.next(event);
        break;
      case "APScheduledEvent":
        debug("appointment created", event);
        this._appointmentCreated.next(event.appointmentId);
        break;
    }
  }
}
