import moment, { Duration } from "moment";

import { IInteractionDetails, ConversationChannelEnum } from "../../core-ui";
import { Contact } from "./strategies/nice/types";

export class NiceInteractionDetails implements IInteractionDetails {
  private _startAt: Date;
  private _endAt: Date;
  private _from: string[];
  private _to: string[];
  private _queueName: string;
  private _duration: Duration;
  private _channel: ConversationChannelEnum;

  constructor(private contact: Contact) {
    this._startAt = new Date(contact.createdAt);
    this._endAt = new Date(contact.statusUpdatedAt);

    this._from = [contact.endUser.fullName.trim()];
    this._to = contact.inboxAssignee
      ? [
          `${contact.inboxAssigneeUser.firstName} ${contact.inboxAssigneeUser.surname}`.trim(),
        ]
      : [];
    this._queueName = contact.routingQueueId;
    this._duration =
      this._endAt &&
      this._startAt &&
      moment.duration(moment(this._endAt).diff(moment(this._startAt)));

    this._channel = ConversationChannelEnum.cxoneDFO;
  }

  getId() {
    return this.contact.id;
  }

  getDuration() {
    return this._duration;
  }

  getExternalParticipants() {
    return this._from;
  }

  getInternalParticipants() {
    return this._to;
  }

  getStartAt() {
    return this._startAt;
  }

  getEndAt() {
    return this._endAt;
  }

  getQueue() {
    return this._queueName;
  }

  getChannel(): ConversationChannelEnum {
    return this._channel;
  }

  isTranscriptAvailable(): boolean {
    return false;
  }
}
