import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "av-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.scss"],
})
export class ExpansionPanelComponent implements OnInit {
  @Input() title: string;

  isOpen = false;

  constructor() {}

  ngOnInit(): void {}
}
