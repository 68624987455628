<app-disclaimer
  *ngIf="!isAccepted"
  [interaction]="interaction"
  (accepted)="termsAccepted()"
  (rejected)="termsRejected()"
></app-disclaimer>

<ng-container *ngIf="isAccepted">
  <div
    class="callback-hud"
    [@slideInOut]="true"
    *ngIf="isCallbackWaitingVisible"
  >
    <h2 translate>Please wait</h2>
    <p translate>The next available agent will be with you shortly</p>
  </div>

  <app-room-ui
    [interaction]="interaction"
    [recorderOptions]="recorderOptions"
    (callEnding)="callEnding($event)"
    (callStarted)="callStarted()"
    (exit)="exit()"
    (callEnded)="callEnded($event)"
    (recorderStarted)="recorderStarted($event)"
    (recorderStopped)="recorderStopped($event)"
    (deviceSetupComplete)="deviceSetupDoneAndRoomJoined()"
    (callPendingRating)="callPendingRating($event)"
    (layoutChange)="viewChange($event)"
    (remotePopupClosed)="remotePopupClosed()"
  >
  </app-room-ui>

  <app-qr [data]="QRData" [visible]="QRData" (close)="closeQRPanel()"></app-qr>

  <app-conversation-launcher
    [interaction]="interaction"
    class="options-container"
    *ngIf="isMessagingAvailable"
  ></app-conversation-launcher>

  <ng-container *ngIf="shouldShowShareOptions">
    <app-share-link
      #shareLinkOptions
      [interaction]="interaction"
      [isOriginWidgetWithoutVideo]="isOriginWidgetWithoutVideo"
    ></app-share-link>
  </ng-container>
</ng-container>
