import { Injectable } from "@angular/core";

import { IApplication } from "../../core-ui/models/IApplication";
import { IQueueStrategy } from "../../core-ui/models/strategies";
import { ApplicationService } from "../../core-ui/services/application.service";
import { IQueue } from "../../core-ui";

@Injectable()
export class QueueService implements IQueueStrategy {
  constructor(private applicationService: ApplicationService) {}

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private get impl(): IQueueStrategy {
    return this.application.queueStrategy();
  }

  getMyQueues(): Promise<IQueue[]> {
    return this.impl.getMyQueues();
  }
  getAllQueues(): Promise<IQueue[]> {
    return this.impl.getAllQueues();
  }
  getEmailQueues(): Promise<IQueue[]> {
    return this.impl.getEmailQueues();
  }
  getSMSQueues(): Promise<IQueue[]> {
    return this.impl.getSMSQueues();
  }
}
