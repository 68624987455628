import { IInteractionScheduleRequest } from "../../core-ui/models/IInteraction";
import { ICallbackRequest } from "./IGenesysUser";

export class GenesysInteractionScheduleRequest
  implements IInteractionScheduleRequest
{
  private _dto: ICallbackRequest;

  constructor(
    queueId: string,
    agentId: string,
    name: string,
    phoneNumber: string,
    scheduledAt: string,
    metadata: { [key: string]: string }
  ) {
    this._dto = {
      queueId,
      routingData: {
        preferredAgentIds: agentId ? [agentId] : null,
      },
      callbackUserName: name,
      callbackNumbers: [phoneNumber || "000000"],
      callbackScheduledTime: scheduledAt,
      data: metadata,
    };
  }

  getScheduledAt() {
    return new Date(this._dto.callbackScheduledTime);
  }

  getDto(): ICallbackRequest {
    return this._dto;
  }
}
